import { yupResolver } from '@hookform/resolvers/yup';
import InputCheckboxButtonInline from 'components/Input/InputCheckboxButton/InputCheckboxButtonInline.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from '../../utils';
import FormNextButton from '../FormNextButton/FormNextButton.component';
import AxeFields from './AxeFields';

interface FormAxe extends FormProps<FormValues> {
  typologie: string;
  niveauxIntitule: any;
  macroPresta: any;
}

export type Niveaux = {
  axe: string;
  demandeInformations: boolean | undefined;
  niveau: string | undefined;
  prestation: string;
};

type FormValues = {
  niveaux: Niveaux[];
  besoin: {
    maternite: string | undefined;
    dentaire: string | undefined;
    optique: string | undefined;
    auditif: string | undefined;
    assistance: string | undefined;
    responsabiliteCivile: string | undefined;
    hospitalisation: string | undefined;
    fraisMedicaux: string | undefined;
  };
};

const defaultState: FormValues = {
  niveaux: [],
  besoin: {
    maternite: undefined,
    dentaire: undefined,
    optique: undefined,
    auditif: undefined,
    assistance: undefined,
    responsabiliteCivile: undefined,
    hospitalisation: undefined,
    fraisMedicaux: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  if (serieState.niveaux) {
    serieState.niveaux.push(...state.niveaux);
  } else {
    serieState.niveaux = state.niveaux;
  }
  if (state.description) {
    serieState.description = state.description;
  }
  if (state.besoin) {
    serieState.besoin = { ...serieState.besoin, ...state.besoin };
  }
  return serieState;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  niveaux: Yup.array().of(
    Yup.object().shape(
      {
        axe: Yup.string().required(),
        demandeInformations: Yup.boolean().when('niveau', {
          is: undefined,
          then: Yup.boolean().required(
            "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
          ),
          otherwise: Yup.boolean().oneOf([false])
        }),
        niveau: Yup.string().when('demandeInformations', {
          is: true,
          then: Yup.string(),
          otherwise: Yup.string().required('')
        }),
        prestation: Yup.string().required()
      },
      [['niveau', 'demandeInformations']]
    )
  ),
  besoin: Yup.object({
    maternite: Yup.string(),
    dentaire: Yup.string(),
    optique: Yup.string(),
    auditif: Yup.string(),
    assistance: Yup.string(),
    responsabiliteCivile: Yup.string(),
    hospitalisation: Yup.string(),
    fraisMedicaux: Yup.string()
  })
}).defined();

/**
 * Formulaire générique pour les axes de remboursement.
 * Prend un objet de macros prestations trié par axe et affiche l'enssemble de champs associés pour chacun d'eux.
 */
export default function FormAxe(props: FormAxe): ReactElement {
  const initialValues = assignValuesFromSource(
    defaultState,
    props.initialFormValues || {},
    (item: any, key: string) => {
      if (key === 'niveaux') {
        const initialNiveaux: any = [];
        Object.keys(props.macroPresta).map((key: any) => {
          return props.macroPresta[key].map((presta: any) => {
            const initialMacroValue = item.find((niveau: any) => niveau.prestation === presta.value);
            if (initialMacroValue) {
              initialNiveaux.push(initialMacroValue);
            }
          });
        });
        return initialNiveaux;
      } else {
        return item;
      }
    },
    true
  );

  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    register,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  // Initialize count for correct index of niveaux in payload
  let count = -1;
  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Décrivez-nous <span>les garanties souhaitées </span>
      </h1>

      <div className={'container-inputs-axe'}>
        {Object.keys(props.macroPresta).map((key: any) => {
          return props.macroPresta[key].map((presta: any) => {
            count++;
            return (
              <AxeFields
                register={register}
                typologie={props.typologie}
                trigger={trigger}
                errors={errors}
                index={count}
                axeName={`${key}:AXE_BESOIN_${props.typologie}`}
                control={control}
                setValue={setValue}
                niveaux={props.niveauxIntitule}
                prestation={presta.value}
                title={presta.meta?.libelle}
                key={presta.meta?.code}
                type={presta.meta?.code}
                noDoubtPossible
              />
            );
          });
        })}
      </div>

      <InputCheckboxButtonInline
        label={'Postes à garantir'}
        control={control}
        options={[
          { label: 'Hospitalisation', name: 'besoin.hospitalisation' },
          { label: 'Frais médicaux', name: 'besoin.fraisMedicaux' },
          { label: 'Dentaire', name: 'besoin.dentaire' },
          { label: 'Assistance / Rapatriement', name: 'besoin.assistance' },
          { label: 'Optique', name: 'besoin.optique' },
          { label: 'Responsabilité civile', name: 'besoin.responsabiliteCivile' },
          { label: 'Aides auditives', name: 'besoin.auditif' },
          {
            label: 'Maternité',
            name: 'besoin.maternite',
            tooltip: 'Exclusion dans les contrats de toute grossesse en cours.'
          }
        ]}
        noCenterLabel
        column
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
