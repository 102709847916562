import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';

import FormAutoImmatriculation from '../forms/AUTO/FormAutoImmatriculation.component';
import FormAutoInfoVehiculePart1 from '../forms/AUTO/FormAutoInfoVehiculePart1.component';
import FormAutoInfoVehiculePart2 from '../forms/AUTO/FormAutoInfoVehiculePart2.component';
import FormAutoContrat from '../forms/AUTO/FormAutoContrat.component';
import FormAutoUtilisationVehiculePart1 from '../forms/AUTO/FormAutoUtilisationVehiculePart1.component';
import FormAutoUtilisationVehiculePart2 from '../forms/AUTO/FormAutoUtilisationVehiculePart2.component';

import FormAutoConducteurPart1 from '../forms/AUTO/FormAutoConducteurPart1.component';
import FormAutoConducteurPart2 from '../forms/AUTO/FormAutoConducteurPart2.component';
import FormAutoConducteurPart3 from '../forms/AUTO/FormAutoConducteurPart3.component';
import FormAutoConducteurPart4 from '../forms/AUTO/FormAutoConducteurPart4.component';
import FormAutoConducteurPart5 from '../forms/AUTO/FormAutoConducteurPart5.component';
import FormAutoConducteurPart6 from '../forms/AUTO/FormAutoConducteurPart6.component';

import FormAutoConducteurChoix from '../forms/AUTO/FormAutoConducteurChoix.component';
import FormAutoCoordonnees from '../forms/AUTO/FormAutoCoordonnees.component';
import { fetchAssureurOptions } from 'fetches/options.fetch';
import parseSearch from 'utils/parseSearch';

const leftPaneDescriptions: StepDescriptionList = {
  Q1: {
    title: 'Pourquoi cette question ?',
    description: 'Afin de connaître la marque, le modèle et la date de mise en circulation de votre véhicule.'
  },
  Q2: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q3: {
    title: 'Pourquoi cette question ?',
    description: 'Cette question permet de cibler le meilleur niveau de couverture assurantiel.'
  },
  Q4: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q5: {
    title: 'Pourquoi cette question ?',
    description: "Ces données nous permettront de vous envoyer une proposition d'assurance."
  }
};

const id = parseSearch(window.location.search, 'id');

const projetAutoConfig: FormConfigWithUrl = {
  endpoint: 'projetAuto',
  prefetch: [fetchAssureurOptions(id)],
  formSteps: [
    {
      component: FormAutoImmatriculation,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q1} />
    },
    {
      component: FormAutoInfoVehiculePart1,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q1} />
    },
    {
      component: FormAutoInfoVehiculePart2,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q2} />
    },
    {
      component: FormAutoContrat,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q3} />
    },
    {
      component: FormAutoUtilisationVehiculePart1,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />
    },
    {
      component: FormAutoUtilisationVehiculePart2,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />
    },
    {
      component: FormAutoConducteurPart1,
      data: { type: 'conducteurPrincipal' }
    },
    {
      component: FormAutoConducteurPart2,
      data: { type: 'conducteurPrincipal' }
    },
    {
      component: FormAutoConducteurPart3,
      data: { type: 'conducteurPrincipal' }
    },
    {
      component: FormAutoConducteurPart4,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />,
      data: { type: 'conducteurPrincipal' }
    },
    {
      component: FormAutoConducteurPart5,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />,
      data: { type: 'conducteurPrincipal' }
    },
    {
      component: FormAutoConducteurPart6,
      data: { type: 'conducteurPrincipal' }
    },
    {
      component: FormAutoConducteurChoix,
      next: {
        selectNextStep: (prevStepState: { isConducteurSecondaire: string }): string | undefined => {
          return prevStepState.isConducteurSecondaire;
        },
        paths: {
          true: [
            {
              component: FormAutoConducteurPart1,
              data: { type: 'conducteurSecondaire' }
            },
            {
              component: FormAutoConducteurPart2,
              data: { type: 'conducteurSecondaire' }
            },
            {
              component: FormAutoConducteurPart3,
              data: { type: 'conducteurSecondaire' }
            },
            {
              component: FormAutoConducteurPart4,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />,
              data: { type: 'conducteurSecondaire' }
            },
            {
              component: FormAutoConducteurPart5,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />,
              data: { type: 'conducteurSecondaire' }
            },
            {
              component: FormAutoConducteurPart6,
              data: { type: 'conducteurSecondaire' }
            },
            {
              component: FormAutoCoordonnees,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />,
              data: { showCaptcha: true, askForConsent: true }
            }
          ],
          false: [
            {
              component: FormAutoCoordonnees,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />,
              data: { showCaptcha: true, askForConsent: true }
            }
          ]
        }
      }
    }
  ]
};

export default projetAutoConfig;
