import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useFetch } from 'hooks/useFetch.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { fetchTypeCouverturesExpatOptions } from 'fetches/options.fetch';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { RoundedCardSecondary } from '../../components/RoundedCard/RoundedCardSecondary';

type FormValues = {
  besoin: {
    typeCouverture: string | undefined;
  };
  description: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    typeCouverture: Yup.string().required()
  }),
  description: Yup.string()
}).defined();

const defaultState: FormValues = {
  besoin: {
    typeCouverture: undefined
  },
  description: undefined
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
    if (key === 'dateEffetSouhaitee') return new Date(item);
    return item;
  });
}

export default function FormExpatGarantiePart2(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };
  const typeCouvertures = useFetch(fetchTypeCouverturesExpatOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Décrivez-nous <span>les garanties souhaitées </span>
      </h1>

      <InputRadioButtonInline
        labelField={'Type de couverture'}
        {...register('besoin.typeCouverture')}
        options={typeCouvertures || []}
        noCenterLabel
        column={1}
        errors={errors}
      />

      <RoundedCardSecondary className={'padding margin no-center max-width'}>
        <FontAwesomeIcon style={{ marginTop: 4 }} icon={faInfoCircle} size={'lg'} />
        <ul style={{ margin: 0 }}>
          <li style={{ marginBottom: 8 }}>
            1er euro : les frais de santé sont pris en charge par votre assurance dès le 1er euro.
          </li>
          <li>
            Complément CFE : la Caisse des Français de l&apos;étranger est un organisme de Sécurité sociale à adhésion
            volontaire pour les expatriés.
          </li>
        </ul>
      </RoundedCardSecondary>

      <h1 className="title">
        Informations <span> complémentaires</span>
      </h1>

      <InputTextArea label="Facultatif" {...register('description')} errors={errors} rows={5} />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
