import React, { ReactElement, useMemo } from 'react';
import './Axe.scss';
import InputCheckBoxBoolean from '../Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchAxesNiveauRetenus } from '../../fetches/axes.fetch';
import InfoBulle from '../InfoBulle/InfoBulle';
import { Control } from 'react-hook-form';

import { ReactComponent as SvgMinimal } from 'assets/minimal.svg';
import { ReactComponent as SvgElementaire } from 'assets/elementaire.svg';
import { ReactComponent as SvgConfort } from 'assets/confort.svg';
import { ReactComponent as SvgOptimal } from 'assets/optimal.svg';

type AxeProps = {
  type: string;
  typologie: string;
  title: string;
  axeName: string;
  niveaux: any;
  setValue: any;
  control: Control<any>;
  index: number;
  prestation: string;
  errors: any;
  trigger: any;
  register: any;
  noDoubtPossible?: boolean;
};

/**
 * Composant d'affichage des champs pour un type de macro prestation passé en props.
 * Les input hidden servent à bien set les valeur du formulaire lors d'un suivant -> précédent.
 */
export default function AxeFields({
  prestation,
  typologie,
  type,
  title,
  niveaux,
  index,
  setValue,
  control,
  errors,
  trigger,
  axeName,
  register,
  noDoubtPossible
}: AxeProps): ReactElement {
  // Fetch niveauxRetenu by type and construct description to put in infobulle
  const niveauxRetenu = useFetch(() => fetchAxesNiveauRetenus(typologie, type));
  const description = useMemo(
    () =>
      niveauxRetenu &&
      niveauxRetenu
        .sort((a, b) => a.meta?.codeId - b.meta?.codeId)
        .reduce((concat: string, niveauRetenu: any, index: number) => {
          return `${concat} ${niveaux[index].label} : ${niveauRetenu.label} <br>`;
        }, ''),
    [niveauxRetenu]
  );

  const icons = [<SvgMinimal key="1" />, <SvgElementaire key="2" />, <SvgConfort key="3" />, <SvgOptimal key="4" />];
  return (
    <div className={'container-axe'}>
      {niveaux && (
        <div className={'container-radios'}>
          <InputRadioButtonIcon
            label={title}
            errors={errors}
            {...register(`niveaux[${index}].niveau`)}
            options={niveaux.map(({ label, value }: any, index: number) => ({ label, value, icon: icons[index] }))}
            onChange={e => {
              setValue(`niveaux[${index}]`, {
                niveau: e.target.value,
                prestation: prestation,
                axe: axeName,
                demandeInformations: false
              });
              trigger(`niveaux[${index}]`);
              return e;
            }}
            small
            noWrap={niveaux.length < 4}
          />
          {axeName && description && (
            <div className={'axe-info-bulle'}>
              <InfoBulle name={`description-${type}`} key={axeName} text={description || ''} />
            </div>
          )}
        </div>
      )}
      {!noDoubtPossible && (
        <div className={'checkbox-container'}>
          <InputCheckBoxBoolean
            className={'checkbox'}
            control={control}
            name={`niveaux[${index}].demandeInformations`}
            label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
            onChange={e => {
              setValue(`niveaux[${index}]`, {
                niveau: undefined,
                prestation: prestation,
                axe: axeName,
                demandeInformations: e.target.checked
              });
              trigger(`niveaux[${index}]`);
            }}
            errors={errors}
          />
        </div>
      )}
      <input {...register(`niveaux[${index}].axe`)} hidden />
      <input {...register(`niveaux[${index}].prestation`)} hidden />
    </div>
  );
}
