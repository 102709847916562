import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/Input/InputText/InputText.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { CIVILITES_OPTIONS, ROLE_RELATION_OPTIONS } from 'consts/consts';

type Key = 'principal' | 'secondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};

type Emprunteur = {
  emprunteur: {
    roleFoyerCode: string | undefined;
  };
  personne: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
  };
};

export type FormValues<K extends Key> = PartialRecord<K, Emprunteur>;

export default function FormEmprunteurAssureurs({
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken,
  data = { type: 'principal' }
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;

  const defaultState: FormValues<typeof type> = {
    [type]: {
      emprunteur: {
        roleFoyerCode: undefined
      },
      personne: {
        civilite: undefined,
        nom: undefined,
        prenom: undefined,
        naissanceDate: undefined
      }
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues<typeof type & any>> = Yup.object({
    [type]: Yup.object({
      emprunteur: Yup.object({
        roleFoyerCode: Yup.string()
      }),
      personne: Yup.object({
        civilite: Yup.string().typeError('Champ obligatoire').required(),
        nom: Yup.string().required(),
        prenom: Yup.string().required(),
        naissanceDate: Yup.date().typeError('Merci de renseigner une date valide').required()
      })
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Informations de l&apos;emprunteur <span>{type}</span>
      </h1>

      <h2 className="subtitle">Identité</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputRadioButtonInline
          labelField={'Civilité'}
          {...register(`${type}.personne.civilite`)}
          options={CIVILITES_OPTIONS}
          errors={errors}
        />
        <div className="layout-field-container">
          <InputText label={'Prénom'} {...register(`${type}.personne.prenom`)} errors={errors} />
          <InputText label={'Nom'} {...register(`${type}.personne.nom`)} errors={errors} />
        </div>

        <InputDate label={'Date de naissance'} name={`${type}.personne.naissanceDate`} control={control} />

        {type === 'secondaire' && (
          <InputSelect
            label="Type de relation"
            name={`${type}.emprunteur.roleFoyerCode`}
            options={ROLE_RELATION_OPTIONS || []}
            control={control}
            errors={errors}
          />
        )}

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
