import Yup from '../../../utils/Yup';
import { isNumeroSecuriteSocialeValide, NumeroSecu } from './NumeroSecu';

const enfantCode = 'ENFANT:ROLE_RELATION_TYPE';
const conjointCode = 'CONJOINT:ROLE_RELATION_TYPE';

export type FormValuesSanteTNS = {
  numeroSecuriteSocial: string | undefined;
  numeroSecuriteSocialBeneficiaires: Array<NumeroSecu> | undefined;
  codePostalNaissance: string | undefined;
  villeNaissance: string | undefined;
  paysNaissance: string | undefined;
  categorieSocioProfessionelleCode: string | undefined;
  categorieSocioProfessionelleLibelle: string | undefined;
  professionCode: string | undefined;
  professionLibelle: string | undefined;
  dateClotureExercice: string | undefined;

  consentement: boolean | undefined;
  stepCode: string;
};

const defaultState: FormValuesSanteTNS = {
  numeroSecuriteSocial: '',
  numeroSecuriteSocialBeneficiaires: [],
  codePostalNaissance: '',
  villeNaissance: '',
  paysNaissance: '',
  categorieSocioProfessionelleCode: '',
  categorieSocioProfessionelleLibelle: '',
  professionCode: '',
  professionLibelle: '',
  dateClotureExercice: '',

  stepCode: 'INFOS',
  consentement: false
};

const sortBeneficiaires = (a: NumeroSecu, b: NumeroSecu) => {
  if (b.roleCode === conjointCode) return 1;
  if (a.roleCode === enfantCode) return -1;
  return 0;
};

const validationSchema: Yup.SchemaOf<FormValuesSanteTNS> = Yup.object({
  numeroSecuriteSocial: Yup.string()
    .test(
      'numeroSecuriteSocial',
      "Le format du numéro de sécurité sociale n'est pas valide",
      isNumeroSecuriteSocialeValide
    )
    .required(),
  numeroSecuriteSocialBeneficiaires: Yup.array()
    .of(
      Yup.object()
        .shape({
          numeroSecu: Yup.string()
            .test(
              'numeroSecuriteSocial',
              "Le format du numéro de sécurité sociale n'est pas valide",
              isNumeroSecuriteSocialeValide
            )
            .required(),
          nom: Yup.string().required(),
          prenom: Yup.string().required(),
          naissanceDate: Yup.string().required(),
          roleCode: Yup.string().required()
        })
        .required()
    )
    .required(),
  codePostalNaissance: Yup.string().required(),
  villeNaissance: Yup.string().required(),
  paysNaissance: Yup.string().required(),
  categorieSocioProfessionelleCode: Yup.string().required(),
  categorieSocioProfessionelleLibelle: Yup.string().required(),
  professionCode: Yup.string().required(),
  professionLibelle: Yup.string().required(),
  dateClotureExercice: Yup.string().required(),
  stepCode: Yup.string().required(),
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

const getInitialFormValues: any = (initialFormValues: any) => {
  const state: FormValuesSanteTNS = defaultState;
  state.categorieSocioProfessionelleCode = initialFormValues?.categorieSocioProfessionelleCode ?? '';
  state.professionCode = initialFormValues?.professionCode ?? '';
  state.professionLibelle = initialFormValues?.professionLibelle ?? '';
  state.dateClotureExercice = initialFormValues?.dateClotureExercice ?? '';
  state.numeroSecuriteSocial = initialFormValues?.numeroSecuriteSocial ?? '';
  state.numeroSecuriteSocialBeneficiaires =
    initialFormValues?.numeroSecuriteSocialBeneficiaires?.sort(sortBeneficiaires) || [];
  state.paysNaissance = initialFormValues?.paysNaissance ?? '';
  state.codePostalNaissance = initialFormValues?.codePostalNaissance ?? '';
  state.villeNaissance = initialFormValues?.villeNaissance ?? '';

  return state;
};
export const SanteTNS = {
  defaultState,
  validationSchema,
  getInitialFormValues,
  conjointCode
};
