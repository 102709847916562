import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement, useMemo } from 'react';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import InputDate from '../../components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from '../../components/Input/InputSelect/InputSelect.component';
import InputText from '../../components/Input/InputText/InputText.component';
import { CIVILITES_OPTIONS } from '../../consts/consts';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import { assignValuesFromSource } from '../../utils';
import Yup from '../../utils/Yup';
import './FormResiliationDemande.scss';
import { LabelByMotifResiliation, MotifResiliation } from './data/MotifResiliation';
import { TypeContrat } from './data/TypeContrat';
import FormResiliationFooter from './FormResiliationFooter.component';

export type FormValues = {
  civilite: string;
  prenom: string;
  nom: string;
  numeroAdherent: string;
  telephone: string;
  email: string;
  typeContrat: string;
  motifResiliation: string;
  dateResiliationSouhaite: Date | undefined;
  motifResiliationAutre?: string;
};

const defaultState: FormValues = {
  civilite: '',
  prenom: '',
  nom: '',
  numeroAdherent: '',
  telephone: '',
  email: '',
  typeContrat: '',
  motifResiliation: '',
  dateResiliationSouhaite: undefined,
  motifResiliationAutre: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  civilite: Yup.string().required(),
  prenom: Yup.string().required(),
  nom: Yup.string().required(),
  numeroAdherent: Yup.string()
    .length(7, 'Votre numéro d’adhérent doit comporter 7 chiffres')
    .matches(/^\d+$/, 'Votre numéro d’adhérent doit comporter 7 chiffres')
    .required(),
  telephone: Yup.string()
    .required()
    .transform((val: string) => val.replace(/\s/g, ''))
    .max(255)
    .matches(/^(\+33|0)[67]\d{8}$/, 'Le numéro de téléphone doit être un numéro de portable valide'),
  email: Yup.string().max(255).email().required(),
  typeContrat: Yup.string().required(),
  motifResiliation: Yup.string().required(),
  motifResiliationAutre: Yup.string().when('motifResiliation', {
    is: MotifResiliation.AUTRE,
    then: Yup.string().required()
  }),
  dateResiliationSouhaite: Yup.date()
    .typeError('Merci de renseigner une date valide')
    .required()
    .test('dateResiliationSouhaite', 'La date de résiliation doit être supérieure à la date du jour', value =>
      value ? value > new Date() : false
    )
}).defined();

export default function FormResiliationInformations(props: FormProps<FormValues>): ReactElement {
  function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
    return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
  }

  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isValid },
    getValues
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = async (data: FormValues) => {
    props.goNextStep(data);
  };

  const typeContratOptions = [
    { label: 'Sâge autonomie', value: TypeContrat.SAGE_AUTONOMIE },
    {
      label: 'Paxivie',
      value: TypeContrat.PAXIVIE
    },
    {
      label: 'Tutl’r',
      value: TypeContrat.TUTLR
    },
    {
      label: 'Garantie Complémentaire Dépendance',
      value: TypeContrat.GARANTIE_COMPLEMENTAIRE_DEPENDANCE
    },
    {
      label: 'Hospiconfort',
      value: TypeContrat.HOSPICONFORT
    }
  ];
  const motifResiliationOptions = [
    { label: 'Mon contrat arrive à échéance', value: MotifResiliation.ECHEANCE },
    {
      label: 'Je trouve le tarif trop élevé',
      value: MotifResiliation.TARIF_ELEVE
    },
    {
      label: 'Je possède une couverture similaire avec mon employeur',
      value: MotifResiliation.COUVERTURE_SIMILAIRE_AUTRE_EMPLOYEUR
    },
    { label: 'Autre', value: MotifResiliation.AUTRE }
  ];

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formResiliationDemande">
      <p className="title">Vos informations</p>
      <InputRadioButtonInline
        labelField="Civilité *"
        {...register('civilite')}
        options={CIVILITES_OPTIONS}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText label="Prénom *" {...register('prenom')} errors={errors} preventNumber={true} />
        <InputText label="Nom *" {...register('nom')} errors={errors} preventNumber={true} />
      </div>
      <div className="layout-field-container">
        <InputText
          label="Numéro d'ahérent *"
          {...register('numeroAdherent')}
          errors={errors}
          tooltip="Retrouvez votre Numéro d’adhérent en haut à gauche de votre espace adhérent, ou sur vos relevés de cotisations annuels."
        />
      </div>
      <div className="layout-field-container">
        <InputText label="Téléphone portable *" {...register('telephone')} errors={errors} />
        <InputText label="Adresse email *" {...register('email')} errors={errors} />
      </div>
      <div className="layout-field-container">
        <InputSelect
          label="Type de contrat concerné *"
          {...register('typeContrat')}
          control={control}
          errors={errors}
          options={typeContratOptions || []}
        />
      </div>
      <div className="layout-field-container">
        <InputSelect
          label="Motif de résiliation *"
          {...register('motifResiliation')}
          control={control}
          errors={errors}
          options={motifResiliationOptions || []}
        />
      </div>
      {getValues('motifResiliation') === LabelByMotifResiliation[MotifResiliation.AUTRE] && (
        <div className="layout-field-container">
          <InputText label="Autre motif *" {...register('motifResiliationAutre')} errors={errors} />
        </div>
      )}
      <div className="layout-field-container-half">
        <InputDate label="Date de résiliation souhaitée *" name={'dateResiliationSouhaite'} control={control} />
      </div>
      <p>
        Cette demande de résiliation ne concerne pas les potentiels bénéficiaires rattachés à votre contrat et <br />
        est sans incidence sur les contidions de résiliation propres à chaque contrat conclu avec Tutélaire.
      </p>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} disabled={!isValid}>
        Poursuivre ma demande de résiliation
      </FormNextButton>
      <FormResiliationFooter />
      <br />
      <p className="formResiliationDemandeFooterCoord">
        <p>
          Tutélaire, mutuelle soumise aux dispositions du livre II du code de la mutualité
          <br />
          SIREN 775 682 164 – Siège social 157 avenue de France 75013 Paris
        </p>
      </p>
    </form>
  );
}
