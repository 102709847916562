import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactElement, ReactChildren } from 'react';
import './FormGenericLink.scss';

interface FormGenericLinkProps {
  children: ReactChildren | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
}

const FormGenericLink = ({
  children,
  onClick,
  loading = false,
  disabled = false,
  className
}: FormGenericLinkProps): ReactElement => {
  return (
    <div>
      <button
        onClick={onClick}
        type="button"
        disabled={loading || disabled}
        className={classNames(className, 'FormGenericLink')}
      >
        {children}
        <FontAwesomeIcon icon={faChevronRight} className="FormGenericLink__chevron" />
      </button>
    </div>
  );
};

export default FormGenericLink;
