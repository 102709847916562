import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputText from 'components/Input/InputText/InputText.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { CIVILITES_OPTIONS } from 'consts/consts';
import FormConsent from '../../components/FormConsent/FormConsent.component';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { fetchPaysOptions, fetchVilleOptions } from '../../fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';

type FormValues = {
  assure: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
    contactMail: string | undefined;
    contactTelephone: string | undefined;
    voie: string | undefined;
    complementAdresse: string | undefined;
    contactCodePostal: string | undefined;
    ville: string | undefined;
    pays: string;
  };
  consentement: boolean | undefined;
};

const defaultState: FormValues = {
  assure: {
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    naissanceDate: undefined,
    contactMail: undefined,
    contactTelephone: undefined,
    voie: undefined,
    complementAdresse: undefined,
    contactCodePostal: undefined,
    ville: '',
    pays: 'FR:PAYS'
  },
  consentement: false
};

export default function FormAutopersonne({
  goNextStep,
  initialFormValues,
  setLastFormState,
  previousFormValues,
  contenuConsentement,
  cocheConsentement,
  data,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
    if (key === 'naissanceDate') return new Date(item);
    return item;
  });

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    assure: Yup.object({
      civilite: Yup.string().nullable().required().nullable(),
      nom: Yup.string().required().nullable(),
      prenom: Yup.string().required().nullable(),
      naissanceDate: Yup.date().typeError('Merci de renseigner une date valide').required().nullable(),
      contactMail: Yup.string().required().nullable(),
      contactTelephone: Yup.string().required().nullable(),
      voie: Yup.string().max(255).required().nullable(),
      complementAdresse: Yup.string().max(255).notRequired(),
      contactCodePostal: Yup.string().required().nullable(),
      ville: Yup.string().max(255).required().nullable(),
      pays: Yup.string().required()
    }),
    consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
  }).defined();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    goNextStep(
      {
        ...data,
        utilisationHabitation: { ...previousFormValues.utilisationHabitation }
      },
      (serieState, state) => merge(serieState, state)
    );
  };

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'assure.contactCodePostal', fetchVilleOptions);
  const paysOptions = useFetch(fetchPaysOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Coordonnées du <span>souscripteur du contrat</span>
      </h1>

      <InputRadioButtonInline
        labelField="Civilité"
        {...register('assure.civilite')}
        options={CIVILITES_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputText label="Prénom" {...register('assure.prenom')} errors={errors} />

      <InputText label="Nom" {...register('assure.nom')} errors={errors} />

      <InputDate label="Date de naissance" name="assure.naissanceDate" control={control} />

      <InputText label="Email" {...register('assure.contactMail')} errors={errors} />

      <InputText label="Téléphone" {...register('assure.contactTelephone')} errors={errors} />

      <InputText label="Adresse" {...register('assure.voie')} errors={errors} />

      <InputText label="Complément" {...register('assure.complementAdresse')} errors={errors} />

      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('assure.contactCodePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="assure.ville"
          control={control}
          errors={errors}
          options={villeOptions || []}
        />
      </div>

      <InputTextAutoComplete
        label={'Pays'}
        name={'assure.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />

      {data?.askForConsent && (
        <FormConsent
          contenuConsentement={contenuConsentement}
          control={control}
          errors={errors}
          label={cocheConsentement}
          name="consentement"
        />
      )}
      <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
