import classNames from 'classnames';
import React, { FC } from 'react';
import { BarsLevel } from '../../../components/BarsLevel/BarsLevel';
import { fetchLibelleCodif } from '../../../fetches/options.fetch';
import { useFetch } from '../../../hooks/useFetch.hook';
import './GuaranteeRow.scss';

interface GuaranteeRowProps {
  proposedQuotes: any;
  typologieLabel: string;
  quoteMobileDisplay: number;
}

const GuaranteeRow: FC<GuaranteeRowProps> = ({ proposedQuotes, typologieLabel, quoteMobileDisplay }) => {
  const caracteristiquesCodif = useFetch(() =>
    fetchLibelleCodif('CARACTERISTIQUE_DEVIS_' + typologieLabel?.split(':')[0])
  );

  return (
    <div className={'row-guarantees'}>
      <div className={'title'}>
        <h3 className={'form-font-large'}>Garanties</h3>
        {caracteristiquesCodif?.map(codif => {
          return (
            <div key={codif.value} className={'codifContainer'}>
              <h5 className="form-font-small">{codif.label}</h5>
            </div>
          );
        })}
      </div>
      <div className={'row-guarantees content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          return (
            <div
              key={quote.numeroInterne}
              className={classNames(
                `item`,
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <div className={'barsContainer'}>
                {caracteristiquesCodif?.map((codif, index) => {
                  return (
                    <div key={index} className={'row-guarantees rowBar'}>
                      <BarsLevel
                        key={quote.numeroInterne + codif.value}
                        numberItem={4}
                        numberSelected={quote['caracteristique' + (index + 1)]}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GuaranteeRow;
