import _ from 'lodash';

function parseSearch(raw: string): Record<string, string> | undefined;
function parseSearch(raw: string, search?: string): string | undefined;
function parseSearch(raw: string, search?: string): Record<string, string> | string | undefined {
  const params = (raw || '').split('?')[1];

  const parsedSearch: Record<string, string> = _(params)
    .split('&')
    .map(param => param.split('='))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, string>);

  if (search) {
    return parsedSearch[search];
  }

  return parsedSearch;
}

export default parseSearch;
