import classNames from 'classnames';
import React, { FC } from 'react';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';

interface ProductRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
}

const ProductRow: FC<ProductRowProps> = ({ proposedQuotes, quoteMobileDisplay }) => {
  return (
    <div className={'row-product emptyTitle'}>
      <div className={'content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          return (
            <RoundedCard
              key={quote.numeroInterne}
              className={classNames(
                'secondary',
                'row-product__name',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }} className="product__name form-font-large">
                <h4>{quote.produit.nom}</h4>
                <h5>{quote.formule}</h5>
              </div>
            </RoundedCard>
          );
        })}
      </div>
    </div>
  );
};

export default ProductRow;
