import { yupResolver } from '@hookform/resolvers/yup';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { fetchVilleOptions } from 'fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from 'hooks/useFetchOptionsOnFieldChange.hook';
import React, { ReactElement, useMemo } from 'react';
import { assignValuesFromSource } from 'utils';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import './FormMandatSepaTitulaireCompte.scss';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputText from '../../components/Input/InputText/InputText.component';
import { CIVILITES_OPTIONS } from '../../consts/consts';
import { useUpsertClientMandatSepa } from '../../hooks/useMandatSepa.hook';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import parseSearch from '../../utils/parseSearch';
import Yup from '../../utils/Yup';
import { NumeroAdherent } from './components/NumeroAdherent';
import FormMandatSepaFooter from './FormMandatSepaFooter.component';

export type FormValues = {
  numeroClient?: string;
  civilite: string;
  prenom: string;
  nom: string;
  numeroAdherent: string;
  telephone: string;
  email: string;
  voie: string;
  codePostal: string;
  ville: string;
};

const defaultState: FormValues = {
  numeroClient: '',
  civilite: '',
  prenom: '',
  nom: '',
  numeroAdherent: '',
  telephone: '',
  email: '',
  voie: '',
  codePostal: '',
  ville: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  numeroClient: Yup.string().notRequired(),
  civilite: Yup.string().required(),
  prenom: Yup.string().required(),
  nom: Yup.string().required(),
  numeroAdherent: Yup.string()
    .length(7, 'Votre numéro d’adhérent doit comporter 7 chiffres')
    .matches(/^\d+$/, 'Votre numéro d’adhérent doit comporter 7 chiffres')
    .required(),
  telephone: Yup.string()
    .required()
    .transform((val: string) => val.replace(/\s/g, ''))
    .max(255)
    .matches(/^(\+33|0)[67]\d{8}$/, 'Le numéro de téléphone doit être un numéro de portable valide'),
  email: Yup.string().max(255).email().required(),
  voie: Yup.string().required(),
  codePostal: Yup.string().required(),
  ville: Yup.string().required()
}).defined();

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormMandatSepaTitulaireCompte(props: FormProps<FormValues>): ReactElement {
  const [loading, setLoading] = React.useState(false);
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = async (data: FormValues) => {
    setLoading(true);
    const id = parseSearch(window.location.search, 'id');
    const numeroClient = await useUpsertClientMandatSepa({ ...data, idFormulaire: id });
    setValue('numeroClient', numeroClient);
    setLoading(false);
    props.goNextStep(data);
  };

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'codePostal', fetchVilleOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formMandatSepaTitulaireCompte">
      <p className="title formMandatSepaTitulaireCompte__title">Titulaire du compte</p>
      <InputRadioButtonInline
        labelField="Civilité"
        {...register('civilite')}
        options={CIVILITES_OPTIONS}
        errors={errors}
      />

      <div className="layout-field-container">
        <InputText label="Prénom" {...register('prenom')} errors={errors} preventNumber={true} />
        <InputText label="Nom" {...register('nom')} errors={errors} preventNumber={true} />
      </div>
      <div className="layout-field-container">
        <NumeroAdherent register={register} errors={errors} />
      </div>
      <div className="layout-field-container">
        <InputText
          label="Téléphone portable"
          {...register('telephone')}
          errors={errors}
          tooltip="Veuillez vérifier l’exactitude du numéro de téléphone, un code va vous être envoyé afin de finaliser la signature du mandat Sepa."
        />
        <InputText label="Adresse email" {...register('email')} errors={errors} />
      </div>
      <div className="layout-field-container modification__information">
        La modification de vos coordonnées bancaires s&apos;appliquera par défaut sur tous les contrats pour lesquels
        vous êtes payeurs. Si vous ne souhaitez pas que ce soit le cas, nous vous invitons à nous contacter.
      </div>
      <InputText label="Adresse postale" {...register('voie')} errors={errors} />
      <div className="layout-field-container">
        <InputText label="Code postal" {...register('codePostal')} errors={errors} />
        <InputTextAutoComplete
          label="Ville"
          name="ville"
          control={control}
          errors={errors}
          options={villeOptions || []}
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} loading={loading}>
        Suivant
      </FormNextButton>
      <FormMandatSepaFooter />
    </form>
  );
}
