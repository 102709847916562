import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import Loader from 'react-loader-spinner';
import { SerieSubmitStatus } from 'types';
import './submit-overlay.scss';
import classNames from 'classnames';

interface SubmitOverlayProps {
  hideOverlay: () => void;
  errorMessage?: string;
  submitStatus: SerieSubmitStatus;
  title: string;
  subTitle: string;
  nomCommercial: string;
  baseLine: string;
  intermadiarySubmit?: boolean;
  siteCourtier: string;
  labelButtonBackToSite: string;
}

function SubmitOverlay(props: SubmitOverlayProps): ReactElement {
  return (
    <>
      <div
        data-testid="ongoing-submit-overlay"
        className={classNames(
          'pane ongoing',
          {
            opened: props.submitStatus !== SerieSubmitStatus.PENDING && !props.intermadiarySubmit
          },
          { closed: props.submitStatus === SerieSubmitStatus.PENDING }
        )}
      >
        <Loader type="Rings" color="var(--color-primary)" height={400} width={400} />
      </div>
      <div
        data-testid="failed-submit-overlay"
        className={`pane failed ${props.submitStatus === SerieSubmitStatus.FAILED ? 'opened' : 'closed'}`}
      >
        {props.siteCourtier && (
          <div className={'link-courtier'}>
            <a href={props.siteCourtier} target="_blank" rel="noreferrer">
              {`< ${props.labelButtonBackToSite ?? 'Retour vers le site'}`}
            </a>
          </div>
        )}
        <div className="layout-container layout-centered-content error-prompt">
          <FontAwesomeIcon icon={faTimesCircle} className="success-icon" />
          <h1>{"Une erreur est survenue lors de l'envoi du formulaire"}</h1>
          <p>{props.errorMessage}</p>
          <button
            tabIndex={props.submitStatus === SerieSubmitStatus.FAILED ? 0 : -1}
            onClick={() => props.hideOverlay()}
          >
            Retour au formulaire
          </button>
        </div>
      </div>
      <div
        data-testid="succeeded-submit-overlay"
        className={classNames(
          'pane succeeded',
          {
            opened: props.submitStatus === SerieSubmitStatus.SUCCEEDED && !props.intermadiarySubmit
          },
          { closed: props.submitStatus !== SerieSubmitStatus.SUCCEEDED }
        )}
      >
        {props.siteCourtier && (
          <div className={'link-courtier'}>
            <a href={props.siteCourtier} target="_blank" rel="noreferrer">
              {`< ${props.labelButtonBackToSite ?? 'Retour vers le site'}`}
            </a>
          </div>
        )}
        <div className="layout-container layout-centered-content success-prompt">
          <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
          <div className="success-title" dangerouslySetInnerHTML={{ __html: props.title ?? '' }} />
          <div className="success-subTitle" dangerouslySetInnerHTML={{ __html: props.subTitle ?? '' }} />
        </div>
        <div className="layout-container layout-centered-content success-baseline">
          <div className="success-nom-commercial" dangerouslySetInnerHTML={{ __html: props.nomCommercial ?? '' }} />
          <div className="success-baseline-text" dangerouslySetInnerHTML={{ __html: props.baseLine ?? '' }} />
        </div>
      </div>
    </>
  );
}

export default SubmitOverlay;
