import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputSlider from 'components/Input/InputSlider/InputSlider.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';

type FormValues = {
  principal: {
    quotite: any | undefined;
  };
  secondaire: {
    quotite: any | undefined;
  };
};

export default function FormEmprunteurAssureurs({
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken,
  previousFormValues
}: FormProps<FormValues>): ReactElement {
  const defaultState: FormValues = {
    principal: {
      quotite: 100
    },
    secondaire: {
      quotite: 100
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    principal: Yup.object({
      quotite: Yup.number().required()
    }),
    secondaire: Yup.object({
      quotite: Yup.number().required()
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const { handleSubmit, control } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">Quelle quotité souhaitez-vous ?</h1>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputSlider label="Emprunteur principal" name="principal.quotite" control={control} />

        {previousFormValues.nombreEmprunteur === 'DEUX_EMPRUNTEURS' && (
          <InputSlider label="Emprunteur secondaire" name="secondaire.quotite" control={control} />
        )}

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
