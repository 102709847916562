import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import FormNextButton from '../FormNextButton/FormNextButton.component';
import AxeFields from './AxeFields';
import { AXES_MACRO_PRESTATION_SANTE } from '../../fetches/axes.fetch';
import InputTextArea from '../Input/InputTextArea/InputTextArea.component';
import { assignValuesFromSource } from '../../utils';

interface FormAxe extends FormProps<FormValues> {
  typologie: string;
  niveauxIntitule: any;
  macroPresta: any;
}

export type Niveaux = {
  axe: string;
  demandeInformations: boolean | undefined;
  niveau: string | undefined;
  prestation: string;
};

type FormValues = {
  niveaux: Niveaux[];
  description?: string | undefined;
};

const defaultState: FormValues = {
  niveaux: [],
  description: undefined
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  if (serieState.niveaux) {
    serieState.niveaux.push(...state.niveaux);
  } else {
    serieState.niveaux = state.niveaux;
  }
  if (state.description) {
    serieState.description = state.description;
  }
  return serieState;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  niveaux: Yup.array().of(
    Yup.object().shape(
      {
        axe: Yup.string().required(),
        demandeInformations: Yup.boolean().when('niveau', {
          is: undefined,
          then: Yup.boolean().required(
            "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
          ),
          otherwise: Yup.boolean().oneOf([false])
        }),
        niveau: Yup.string().when('demandeInformations', {
          is: true,
          then: Yup.string(),
          otherwise: Yup.string().required('')
        }),
        prestation: Yup.string().required()
      },
      [['niveau', 'demandeInformations']]
    )
  ),
  description: Yup.string()
}).defined();

/**
 * Formulaire générique pour les axes de remboursement.
 * Prend un objet de macros prestations trié par axe et affiche l'enssemble de champs associés pour chacun d'eux.
 */
export default function FormAxe(props: FormAxe): ReactElement {
  const initialValues = assignValuesFromSource(
    defaultState,
    props.initialFormValues || {},
    (item: any, key: string) => {
      if (key === 'niveaux') {
        const initialNiveaux: any = [];
        Object.keys(props.macroPresta).map((key: any) => {
          return props.macroPresta[key].map((presta: any) => {
            const initialMacroValue = item.find((niveau: any) => niveau.prestation === presta.value);
            if (initialMacroValue) {
              initialNiveaux.push(initialMacroValue);
            }
          });
        });
        return initialNiveaux;
      } else {
        return item;
      }
    },
    true
  );

  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    register,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  // Initialize count for correct index of niveaux in payload
  let count = -1;
  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2 style={{ marginBottom: '4px' }}>
        A quelle hauteur souhaitez-vous être remboursé(e) pour :
        {props.data.subtitle && (
          <small style={{ fontSize: '16px' }}>
            <br />
            {props.data.subtitle}
          </small>
        )}
      </h2>

      <div className={'container-inputs-axe'}>
        {Object.keys(props.macroPresta).map((key: any) => {
          return props.macroPresta[key].map((presta: any) => {
            count++;
            return (
              <AxeFields
                register={register}
                typologie={props.typologie}
                trigger={trigger}
                errors={errors}
                index={count}
                axeName={`${key}:AXE_BESOIN_${props.typologie}`}
                control={control}
                setValue={setValue}
                niveaux={props.niveauxIntitule}
                prestation={presta.value}
                title={presta.meta?.libelle}
                key={presta.meta?.code}
                type={presta.meta?.code}
              />
            );
          });
        })}

        {props.data.typeAxe === AXES_MACRO_PRESTATION_SANTE.MEDECINE_DOUCE && (
          <div className={'container-autre-besoins'}>
            <h2>Autres besoins (facultatif)</h2>
            <InputTextArea label="" {...register('description')} errors={errors} rows={5} />
          </div>
        )}
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
