import React, { ReactElement, useEffect, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { useFetch } from '../../hooks/useFetch.hook';
import {
  fetchCategorieMetierOptions,
  fetchMetierByCategorieOptions,
  fetchStatutProOptions,
  fetchRegimeSecuOptions
} from '../../fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import InputTextAutoComplete from '../../components/Input/InputText/InputTextAutoComplete';
import { assignValuesFromSource } from '../../utils';

type FormValues = {
  assure: {
    statutProfessionnel: string | null | undefined;
    categorieProfessionnelle: string | null | undefined;
    profession: string | null | undefined;
    regime: string | null | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    categorieProfessionnelle: Yup.string().nullable(),
    profession: Yup.string().nullable(),
    statutProfessionnel: Yup.string(),
    regime: Yup.string()
  })
}).defined();

const defaultState: FormValues = {
  assure: {
    categorieProfessionnelle: '',
    profession: '',
    statutProfessionnel: '',
    regime: ''
  }
};

export default function FormDecouverteSituationPro(props: FormProps<FormValues>): ReactElement {
  const initialValues: any = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    handleSubmit,
    formState: { errors },
    watch,
    register,
    trigger,
    control,
    setValue
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const assureProfession = watch('assure.profession');

  const categoriesMetier = useFetch(fetchCategorieMetierOptions);
  const metiers = useFetchOptionsOnFieldChange(
    watch,
    'assure.categorieProfessionnelle',
    (categorie: string) => fetchMetierByCategorieOptions({ categorie: categorie, metier: 'NULL' }),
    input => !!input && input.length > 0
  );
  const isLabelMetierInitialized: any = useRef(false);

  /*
    @todo Faire en sorte que l'input autocomplete gére valeur/libelle comme un select
    This effect handle first setting of professionCodeLibelle if profession given by initialValues
   */
  useEffect(() => {
    if (!isLabelMetierInitialized.current && assureProfession && metiers) {
      const metier = metiers.find((metier: any) => {
        return metier.value === assureProfession;
      });
      if (metier) {
        setValue('assure.profession', metier.value);
        isLabelMetierInitialized.current = true;
      }
    }
  }, [isLabelMetierInitialized, assureProfession, metiers]);

  const statutsPro = useFetch(fetchStatutProOptions);
  const regimesSecu = useFetch(fetchRegimeSecuOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre Situation professionnelle : </h2>
      <input {...register('assure.profession')} hidden />
      <div className="layout-field-container">
        <InputSelect
          withClearAction
          isSearchable
          label="Catégorie métier"
          name="assure.categorieProfessionnelle"
          control={control}
          errors={errors}
          options={categoriesMetier || []}
          onClear={() => {
            setValue('assure.categorieProfessionnelle', '');
            setValue('assure.profession', '');
          }}
          onSelect={() => {
            isLabelMetierInitialized.current = true;
            setValue('assure.profession', '');
          }}
        />
        <InputTextAutoComplete
          mustSelected
          label="Métier"
          name="assure.profession"
          control={control}
          options={metiers || []}
          errors={errors}
          onSuggestionSelected={suggestion => {
            setValue('assure.profession', suggestion.value);
            trigger('assure.categorieProfessionnelle');
          }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <InputSelect
          className={'layout-flex-basis-auto'}
          label="Statut professionnel"
          name="assure.statutProfessionnel"
          control={control}
          errors={errors}
          options={statutsPro || []}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <InputSelect
          className={'layout-flex-basis-auto'}
          label="Régime de sécurité sociale"
          name="assure.regime"
          control={control}
          errors={errors}
          options={regimesSecu || []}
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
