import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import SanteParticulierAxes from '../forms/SANTE_PART/Axes/SanteParticulierAxes.component';
import FormSanteParticulierChoixAssure from '../forms/SANTE_PART/FormSanteParticulierChoixAssure.component';
import FormSanteParticulierConjointIdentite from '../forms/SANTE_PART/FormSanteParticulierConjointIdentite.component';
import FormSanteParticulierCoordonne from '../forms/SANTE_PART/FormSanteParticulierCoordonne.component';
import FormSanteParticulierEnfants from '../forms/SANTE_PART/FormSanteParticulierEnfants.component';
import FormSanteParticulierIdentite from '../forms/SANTE_PART/FormSanteParticulierIdentite.component';
import FormSanteParticulierSituationPro from '../forms/SANTE_PART/FormSanteParticulierSituationPro.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_CHOIX_ASSURE: {
    title: 'Pourquoi cette question ?',
    description:
      'Le nombre de bénéficiaire du contrat rentre en compte dans le calcul du tarif de votre complémentaire santé.'
  },
  STEP_AXE_1: {
    title: "Comprendre les garanties d'une surcomplémentaire santé",
    description:
      "Dans le cas d'un remboursement exprimé en pourcentage, le remboursement se calcule selon un montant donné par la Sécurité Sociale : la Base de Rembousement (BR). Les pourcentages indiqués sur un tableau de garanties correspondent au remboursement Sécurité Sociale + complémentaire santé. "
  },
  STEP_AXE_2: {
    title: "Comprendre les garanties d'une surcomplémentaire santé",
    description:
      'DPTAM (Dispositif de Pratique Tarifaire Maîtrisée) ou OPTAM (Option de Pratique Tarifaire Maitrisée). Les médecins de secteur 2 qui sont adhérents à l’OPTAM peuvent appliquer des tarifs plus élevés que ceux du secteur 1, mais dans la limite d’un taux de dépassement. En contrepartie de cet engagement, la sécurité sociale pratique des remboursements plus élevés que pour les médecins n’ayant pas adhéré à l’OPTAM.'
  },
  STEP_AXE_3: {
    title: "Comprendre les garanties d'une surcomplémentaire santé",
    description:
      "Les Médecines Naturelles n'étant pas prises en charge par la Sécurité Sociale, c’est la complémentaire santé qui vous rembourse au travers d’un forfait annuel exprimé en euros."
  },
  STEP_AXE_4: {
    title: "Comprendre les garanties d'une surcomplémentaire santé",
    description:
      'La réforme 100% Santé permet à l’assuré d’avoir accès à des équipements dentaires, optiques et audiologie de qualité qui sont pris en charge intégralement par la Sécurité Sociale et la complémentaire santé responsable.'
  },
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description: 'Votre âge est une donnée qui rentre en compte dans le calcul du tarif de votre complémentaire santé.'
  },
  STEP_IDENTITE_CONJOINT: {
    title: 'Pourquoi ces questions ?',
    description:
      'Certains régimes de sécurité sociale permettent d’avoir des abattements sur le tarif d’une complémentaire santé.'
  },
  STEP_SITUATION_PRO: {
    title: 'Pourquoi ces questions ?',
    description:
      'Votre activité professionnelle est une donnée qui rentre en compte dans le calcul du tarif de votre complémentaire santé.'
  },
  STEP_ENFANTS: {
    title: 'Pourquoi ces questions ?',
    description:
      'Certains régimes de sécurité sociale permettent d’avoir des abattements sur le tarif d’une complémentaire santé.'
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description:
      'Le département d’habitation est une donnée qui rentre en compte dans le calcul du tarif de votre complémentaire santé.'
  }
};

const besoinsEtIdentiteSouscripteur = [
  {
    component: SanteParticulierAxes,

    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_AXE_1} />
  },
  {
    component: FormSanteParticulierIdentite,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
  },
  {
    component: FormSanteParticulierSituationPro,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
  }
];

const projetSantePartConfig: FormConfigWithUrl = {
  endpoint: 'projetSanteParticulier',
  formSteps: [
    {
      component: FormSanteParticulierChoixAssure,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_CHOIX_ASSURE} />,
      next: {
        selectNextStep: (prevStepState: { typeAssures: string }): string | undefined => {
          return prevStepState.typeAssures;
        },
        paths: {
          VOUS: [
            ...besoinsEtIdentiteSouscripteur,
            {
              component: FormSanteParticulierCoordonne,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
              data: { askForConsent: true, showCaptcha: true }
            }
          ],
          COUPLE: [
            ...besoinsEtIdentiteSouscripteur,
            {
              component: FormSanteParticulierConjointIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE_CONJOINT} />
            },
            {
              component: FormSanteParticulierCoordonne,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
              data: { askForConsent: true, showCaptcha: true }
            }
          ],
          VOUS_ENFANTS: [
            ...besoinsEtIdentiteSouscripteur,
            {
              component: FormSanteParticulierEnfants,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENFANTS} />
            },
            {
              component: FormSanteParticulierCoordonne,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
              data: { askForConsent: true, showCaptcha: true }
            }
          ],
          COUPLE_ENFANTS: [
            ...besoinsEtIdentiteSouscripteur,
            {
              component: FormSanteParticulierConjointIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE_CONJOINT} />
            },
            {
              component: FormSanteParticulierEnfants,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENFANTS} />
            },
            {
              component: FormSanteParticulierCoordonne,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
              data: { askForConsent: true, showCaptcha: true }
            }
          ]
        }
      }
    }
  ]
};

export default projetSantePartConfig;
