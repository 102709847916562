import React, { ReactElement } from 'react';
import './titleWithIcon.scss';

type TitleWithIconProps = {
  title: ReactElement;
  icon: ReactElement;
};

export const TitleWithIcon = ({ title, icon }: TitleWithIconProps): ReactElement => {
  return (
    <div className={'title-with-icon'}>
      {icon}
      {title}
    </div>
  );
};
