import React, { ReactElement } from 'react';
import classNames from 'classnames';

const TitleFormWithLogo = (props: { src?: string; title: string; mobile?: boolean }): ReactElement => {
  return (
    <>
      {props.src && (
        <div className="logo-wrapper">
          <img className="logo" src={props.src} />
        </div>
      )}
      <div
        className={classNames('left-pane-title', {
          'left-pane-title__mobile': props.mobile
        })}
        dangerouslySetInnerHTML={{
          __html: props.title?.startsWith('<') ? props.title : `<p>${props.title ?? ''}</p>`
        }}
      />
    </>
  );
};

export default TitleFormWithLogo;
