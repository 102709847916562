import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import './InfoBulle.scss';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type AxeProps = {
  text: string;
  className?: string;
  name: string;
};

export default function InfoBulle({ className, text, name }: AxeProps): ReactElement {
  return (
    <span className={className}>
      <a data-tip data-for={name} className={'link-infobulle'}>
        <FontAwesomeIcon className={'fa-regular'} icon={faInfoCircle} />
      </a>
      <ReactTooltip backgroundColor={'var(--color-primary-complementary)'} id={name} type="info">
        <p
          className={'text-infobulle'}
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />
      </ReactTooltip>
    </span>
  );
}
