import classNames from 'classnames';
import React, { Dispatch, ReactChildren, ReactElement, SetStateAction } from 'react';
import Loader from 'react-loader-spinner';
import { useGoogleCaptcha } from '../../hooks/useGoogleCaptcha.hook';
import './form-next-button.scss';

interface FormNextButtonProps {
  children: ReactChildren | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
  showCaptcha: boolean;
  setCaptchaToken: Dispatch<SetStateAction<string | undefined>>;
}

const FormNextButton = ({
  children,
  onClick,
  loading = false,
  errorMessage,
  disabled = false,
  className,
  showCaptcha,
  setCaptchaToken
}: FormNextButtonProps): ReactElement => {
  const { captchaIsValid, captchaContainerId } = useGoogleCaptcha(showCaptcha, setCaptchaToken);

  return (
    <div>
      <div id={captchaContainerId} />
      <button
        onClick={onClick}
        type="submit"
        data-testid="next-step-button"
        disabled={loading || disabled || !captchaIsValid}
        className={classNames(className, 'form-next-button')}
      >
        <div className="button-container">
          {loading && (
            <div className="spinner-container">
              <Loader type="Rings" color="white" height={25} width={25} />
            </div>
          )}
          <div className="button-content">{children}</div>
        </div>
      </button>
      <br />
      {errorMessage && <span>{errorMessage}</span>}
    </div>
  );
};

export default FormNextButton;
