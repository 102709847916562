import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { CSSTransition } from 'react-transition-group';
import { SetLoadingStateFunction } from 'types';
import './app-loader.scss';

type AppChildrenFunction = (params: { setLoadingState: SetLoadingStateFunction }) => ReactElement;
interface AppLoaderProps {
  children: AppChildrenFunction;
}

/**
 *
 * Will display a loader and then render the app
 * the loader will be display untill setLoadingState has been called with true as params
 */

export function AppLoader(props: AppLoaderProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [firstMount, setFirstMount] = useState(true);

  useEffect(() => {
    setFirstMount(false);
  }, []);

  return (
    <>
      <CSSTransition in={loading} timeout={200} nodeRef={nodeRef} classNames="app-loader" data-testid="app-loader">
        <div ref={nodeRef} className="app-loader">
          <Loader type="Rings" color="var(--color-primary)" height={400} width={400} />
        </div>
      </CSSTransition>
      {!firstMount && props.children({ setLoadingState: setLoading })}
    </>
  );
}
