import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Extend from 'components/Extend/Extend';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import {
  fetchBanqueOptions,
  fetchTypeDifferesOptions,
  fetchTypePretOptions,
  fetchTypeTauxOptions
} from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, useWatch } from 'react-hook-form';
import './Credit.scss';
import { RELAIS_TYPE_PRET } from '../../FormEmprunteurCredits.component';

type EnfantFieldsProps = {
  index: number;
  register: any;
  errors: FieldErrors<any>;
  control: Control<any>;
  typeEmpruntCode: string;
  remove?: (index: number) => void;
};

export default function Credit({
  index,
  errors,
  register,
  control,
  typeEmpruntCode,
  remove
}: EnfantFieldsProps): ReactElement {
  const watch = useWatch({ name: 'credits', control });
  const typePret = useFetch(fetchTypePretOptions);
  const banque = useFetch(fetchBanqueOptions);
  const typeTaux = useFetch(fetchTypeTauxOptions);
  const typeDifferes = useFetch(fetchTypeDifferesOptions);
  const isChangementAssurance = typeEmpruntCode === 'CHANGEMENT_ASSURANCE:TYPE_PROJET_EMPRUNT';
  const isRelais = watch?.[index]?.typeCreditCode === RELAIS_TYPE_PRET;
  const estDiffere = watch?.[index]?.differe;

  const getLabelMontant = () => {
    if (isChangementAssurance) {
      return 'Montant capital restant dû';
    }
    return 'Montant';
  };

  return (
    <div className="Credit" key={index}>
      {remove && (
        <button className="Credit__remove no-default-style" onClick={() => remove(index)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      )}

      <div className="layout-field-container">
        <InputSelect
          name={`credits.${index}.typeCreditCode`}
          label="Type de prêt"
          options={typePret || []}
          control={control}
          errors={errors}
          isSearchable
        />
        <InputSelect
          name={`credits.${index}.banqueCode`}
          label="Banque"
          options={banque || []}
          control={control}
          errors={errors}
          isSearchable
        />
      </div>

      <InputDate label="Date de début" name={`credits.${index}.dateDebut`} control={control} />

      <hr />

      <div className="layout-field-container">
        <InputNumber
          label={getLabelMontant()}
          {...register(`credits.${index}.montant`)}
          errors={errors}
          min={0}
          step={1}
        />
        <InputNumber
          label="Durée en mois"
          {...register(`credits.${index}.dureeMois`)}
          errors={errors}
          min={0}
          step={1}
        />
      </div>

      <div className="layout-field-container">
        <InputNumber label="Taux" {...register(`credits.${index}.taux`)} errors={errors} min={0} step={0.01} />
        <InputSelect
          name={`credits.${index}.typeTauxCode`}
          label="Type de taux"
          options={typeTaux || []}
          control={control}
          errors={errors}
          isSearchable
        />
      </div>

      <Extend extend={!isRelais}>
        <InputCheckBoxBoolean
          control={control}
          errors={errors}
          label="Le prêt est différé"
          name={`credits.${index}.differe`}
        />
      </Extend>

      <Extend extend={estDiffere && !isRelais}>
        <div className="layout-field-container">
          <InputNumber
            label="Durée du différé en mois"
            {...register(`credits.${index}.delaiDiffere`)}
            errors={errors}
            min={0}
            step={1}
          />
          <InputSelect
            name={`credits.${index}.typeDiffereCode`}
            label="Type de différé"
            options={typeDifferes || []}
            control={control}
            errors={errors}
          />
        </div>
      </Extend>
      <Extend extend={isRelais}>
        <InputSelect
          name={`credits.${index}.typeDiffereCode`}
          label="Type de différé"
          options={typeDifferes || []}
          control={control}
          errors={errors}
        />
      </Extend>
    </div>
  );
}
