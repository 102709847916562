import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { Contrat, FormProps } from 'types';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { fetchCompagnieOptions } from '../../fetches/options.fetch';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import { useFetch } from '../../hooks/useFetch.hook';
import RisquesFields from './Risques/RisquesFields.component';
import Separator from '../../components/Separator/Separator.component';
import { AccumulateStateToSeriesContrat, defaultContrat, validationContrat } from './Risques/CommunUtil';

type FormValues = {
  SANTE: Contrat;
  PREVOYANCE: Contrat;
  CHOMAGE_DIRIGEANT: Contrat;
  HOMME_CLEF: Contrat;
};

const defaultState: FormValues = {
  SANTE: defaultContrat,
  PREVOYANCE: defaultContrat,
  CHOMAGE_DIRIGEANT: defaultContrat,
  HOMME_CLEF: defaultContrat
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  SANTE: validationContrat,
  PREVOYANCE: validationContrat,
  CHOMAGE_DIRIGEANT: validationContrat,
  HOMME_CLEF: validationContrat
});

export default function FormRisqueFamilleTNS(props: FormProps<FormValues>): ReactElement {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const compagnieList = useFetch(fetchCompagnieOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeriesContrat);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Comment, vous et votre famille, êtes-vous protégé(s) ? :</h2>
      <RisquesFields
        control={control}
        errors={errors}
        register={register}
        watch={watch}
        compagnieList={compagnieList}
        fieldsObjectName={'SANTE'}
        title={'Complémentaire mutuelle santé'}
      />
      <Separator />
      <RisquesFields
        control={control}
        errors={errors}
        register={register}
        watch={watch}
        compagnieList={compagnieList}
        title={'Assurance prévoyance'}
        fieldsObjectName={'PREVOYANCE'}
      />
      <Separator />
      <RisquesFields
        control={control}
        errors={errors}
        register={register}
        watch={watch}
        compagnieList={compagnieList}
        title={'Chômage du dirigeant'}
        fieldsObjectName={'CHOMAGE_DIRIGEANT'}
      />
      <Separator />
      <RisquesFields
        control={control}
        errors={errors}
        register={register}
        watch={watch}
        compagnieList={compagnieList}
        title={'Homme clé'}
        fieldsObjectName={'HOMME_CLEF'}
      />
      <Separator />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
