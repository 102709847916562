import React, { useEffect, useRef } from 'react';
import Animation from './Animation';
import './Extend.scss';

interface IExtend {
  children: React.ReactNode;
  extend: boolean;
  duration?: number;
}

function Extend({ children, extend, duration = 300 }: IExtend): JSX.Element {
  const contentParentRef = useRef<HTMLHeadingElement>(null);
  const contentChildrenRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const animation = new Animation();
    const contentParent = contentParentRef.current;
    const contentChildren = contentChildrenRef.current;

    if (!contentParent || !contentChildren) return;

    if (extend) {
      contentParent.style.display = 'block';
    }

    contentParent.style.overflow = 'hidden';
    contentChildren.style.overflow = 'auto';

    const heightParent = contentParent?.clientHeight || 0;
    const heightChildren = contentChildren?.clientHeight || 0;

    const fromTo: [number, number] = extend ? [heightParent, heightChildren] : [heightParent, 0];

    if (fromTo[0] === 0 && fromTo[1] === 0) {
      contentParent.style.height = '0px';
      contentParent.setAttribute('aria-expanded', 'false');
    } else if (fromTo[0] > fromTo[1]) {
      animation.start(duration, fromTo, data => {
        if (data === 0) {
          contentParent.setAttribute('aria-expanded', 'false');
          contentParent.style.height = '0';
          contentParent.style.display = 'none';
        } else {
          contentParent.style.height = `${data}px`;
        }
      });
    } else {
      animation.start(duration, fromTo, data => {
        if (data === heightChildren) {
          contentParent.style.height = 'auto';
          contentChildren.style.overflow = 'visible';
          contentParent.style.overflow = 'visible';
          contentParent.setAttribute('aria-expanded', 'true');
        } else {
          contentParent.style.height = `${data}px`;
        }
      });
    }

    return () => {
      animation.stop();
    };
  }, [extend, duration]);

  return (
    <div className="Extend" ref={contentParentRef}>
      <div ref={contentChildrenRef}>{children}</div>
    </div>
  );
}

export default Extend;
