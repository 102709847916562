import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from 'utils';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import { merge } from 'lodash';
import InputNumber from '../../components/Input/InputNumber/InputNumber.component';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';

type IncapaciteValues = {
  actif: string;
  demandeInformations: boolean;
  type: string;
  valeur: number;
};

interface FormValues {
  incapacite: IncapaciteValues | any;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  incapacite: Yup.object({
    demandeInformations: Yup.boolean().when('incapacite.valeur', {
      is: undefined,
      then: Yup.boolean().required(
        "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
      ),
      otherwise: Yup.boolean().oneOf([false])
    }),
    valeur: Yup.number().when('actif', {
      is: 'false',
      then: Yup.number().ignoreEmptyString(),
      otherwise: Yup.number().when('demandeInformations', {
        is: true,
        then: Yup.number().ignoreEmptyString(),
        otherwise: Yup.number()
          .min(0)
          .max(100)
          .ignoreEmptyString()
          .required(
            "Veuillez indiquer le niveau de pourcentage de couverture que vous désirez ou cocher la case de demande d'informations"
          )
      })
    })
  })
}).defined();

const defaultState: FormValues = {
  incapacite: { type: 'INCAPACITE', demandeInformations: false }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => {
    return item;
  });
}

export default function FormPrevoyanceIncapacite(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    setValue,
    getValues
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>{"Souhaitez-vous être couvert en cas d'arrêt de travail temporaire (Incapacité) ?"}</h2>

      <InputRadioButtonInline {...register('incapacite.actif')} options={OUI_NON_OPTIONS} errors={errors} />

      {getValues().incapacite.actif === 'true' && (
        <InputNumber
          min={0}
          max={100}
          label="A  hauteur de quel pourcentage de votre salaire souhaitez-vous être couvert ?"
          {...register('incapacite.valeur')}
          errors={errors}
        />
      )}

      <div className={'checkbox-container'}>
        <InputCheckBoxBoolean
          className={'checkbox'}
          control={control}
          name={'incapacite.demandeInformations'}
          label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
          onChange={e => {
            setValue(`incapacite`, {
              valeur: undefined,
              type: 'INCAPACITE',
              demandeInformations: e.target.checked
            });
            trigger('incapacite.demandeInformations');
            return e;
          }}
          errors={errors}
        />
      </div>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
