import classNames from 'classnames';
import React, { ReactNode } from 'react';
import './formGrid.scss';

type FormGridProps = {
  children: ReactNode;
  size?: 'medium' | 'large' | 'three-quarter';
};

// eslint-disable-next-line
export function FormGrid({ children, size = 'medium' }: FormGridProps) {
  return (
    <div
      className={classNames('form-grid', {
        'form-grid--medium': size === 'medium',
        'form-grid--large': size === 'large',
        'form-grid--three-quarter': size === 'three-quarter'
      })}
    >
      {children}
    </div>
  );
}
