import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputText from 'components/Input/InputText/InputText.component';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import { fetchDureeSejourOptions } from '../../fetches/options.fetch';
import { useFetch } from '../../hooks/useFetch.hook';
import './FormMiseEnRelation.scss';

type FormValues = {
  civilite: string | null;
  nom: string | undefined;
  prenom: string | undefined;
  contactMail: string;
  contactTelephone: string;
  dureeSejour?: string;
};

const defaultState: FormValues = {
  contactMail: '',
  contactTelephone: '',
  civilite: null,
  nom: undefined,
  prenom: undefined,
  dureeSejour: ''
};

export default function FormMiseEnRelationIdentite(props: FormProps<FormValues>): ReactElement {
  const dureeSejourValidation = props.showDureeSejour == 'true' ? Yup.string().required() : Yup.string().notRequired();

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    contactMail: Yup.string().max(255).email().required(),
    contactTelephone: Yup.string()
      .required()
      .transform((val: string) => val.replace(/\s/g, ''))
      .max(255)
      .telephoneInternational(),
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nom: Yup.string().required().max(255),
    prenom: Yup.string().required().max(255),
    dureeSejour: dureeSejourValidation
  }).defined();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  const dureeSejourOptions = useFetch(fetchDureeSejourOptions);

  return (
    <div className={'miseEnRelation'}>
      <form onSubmit={handleSubmit(handleNextClick)}>
        <h2>
          <span>Votre </span>
          <span style={{ color: 'var(--color-secondary)' }}>identité</span>
        </h2>
        <InputRadioButtonInline
          {...register('civilite')}
          labelField="Civilité"
          options={CIVILITES_OPTIONS}
          errors={errors}
        />
        <div className="layout-field-container">
          <InputText
            className="layout-flex-basis-auto inline-inputs"
            label="Prénom"
            {...register('prenom')}
            errors={errors}
            preventNumber={true}
          />
          <InputText
            className="layout-flex-basis-auto inline-inputs"
            label="Nom"
            {...register('nom')}
            errors={errors}
            preventNumber={true}
          />
        </div>
        <InputText trim label="Adresse email" {...register('contactMail')} errors={errors} />
        <InputText
          label="Téléphone"
          subLabel="(Si numéro étranger, renseignez l'indicatif pays)"
          {...register('contactTelephone')}
          errors={errors}
          preventTelephone={true}
        />
        {props.showDureeSejour === 'true' && (
          <InputRadioButtonInline
            className={'dureeSejour'}
            {...register('dureeSejour')}
            labelField="Durée du séjour"
            options={dureeSejourOptions || []}
            errors={errors}
          />
        )}
        <FormNextButton disabled={!isValid} showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
