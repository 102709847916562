export interface FormAPIResponse<T> {
  body: T;
  codeMessage: string | null;
  libelleMessage: string | null;
  statusHttp: string;
  success: boolean;
}

interface FetchError {
  statusText: string;
  status: number;
  message?: string | undefined;
}
interface APIError {
  message: string;
}

/**
 * hook that returns a function to call an API
 */

export const useCallApi =
  <T extends unknown, R>(
    callApi: (arg: T, successCallBack?: (data: any) => void) => Promise<Response>,
    handleSuccess: (parsedBody: R, successCallBack?: (data: any) => void, formData?: any) => void,
    handleError: (error: FetchError) => void
  ) =>
  (arg: T, successCallBack?: (data: any) => void): void => {
    callApi(arg).then((response: Response) => {
      if (!response.ok) {
        if (response.status === 403) {
          response
            .json()
            .then((parsedBody: APIError) => {
              handleError({
                statusText: parsedBody.message,
                status: response.status
              });
            })
            .catch(() => {
              handleError({
                statusText: 'Erreur lors du traitement de la réponse',
                status: -1
              });
            });
        } else
          response
            .json()
            .then((parsedBody: APIError) => {
              handleError({
                statusText: response.statusText,
                status: response.status,
                message: parsedBody.message
              });
            })
            .catch(() => {
              handleError({
                statusText: 'Erreur lors du traitement de la réponse',
                status: -1
              });
            });
      } else {
        parseBody(response)
          .then(parsedBody => {
            handleSuccess(parsedBody as R, successCallBack, arg);
          })
          .catch(() => {
            handleError({
              statusText: 'Erreur lors du traitement de la réponse',
              status: -1
            });
          });
      }
    });
  };

export default useCallApi;

async function parseBody(response: Response): Promise<any> {
  return new Promise(resolve => {
    response.text().then(function (text: string) {
      if (!text) return resolve(undefined);
      return resolve(JSON.parse(text));
    });
  });
}
