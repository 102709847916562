import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import { fetchPaysOptions, fetchVilleOptions } from '../../fetches/options.fetch';
import { merge } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import Yup from 'utils/Yup';
import InputText from 'components/Input/InputText/InputText.component';
import { useFetch } from 'hooks/useFetch.hook';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import { OUI_NON_OPTIONS } from '../../consts/consts';
import InputTextAutoComplete from '../../components/Input/InputText/InputTextAutoComplete';

export type FormValues = {
  utilisationHabitation: {
    estAdresseSouscripteur: string | undefined;
  };
  adresseLogement: {
    voie: string | undefined;
    complement: string | undefined;
    codePostal: string | undefined;
    ville: string | undefined;
    pays: string;
  };
};

const defaultState: FormValues = {
  utilisationHabitation: { estAdresseSouscripteur: 'true' },
  adresseLogement: {
    voie: undefined,
    complement: undefined,
    codePostal: undefined,
    ville: '',
    pays: 'FR:PAYS'
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues: any) {
  const initialValues = defaultState;
  if (initialFormValues && initialFormValues.assure) {
    initialValues.utilisationHabitation.estAdresseSouscripteur = 'true';
    initialValues.adresseLogement.voie = initialFormValues.assure.voie;
    initialValues.adresseLogement.complement = initialFormValues.assure.complement;
    initialValues.adresseLogement.ville = initialFormValues.assure.ville;
    initialValues.adresseLogement.codePostal = initialFormValues.assure.contactCodePostal;
    initialValues.adresseLogement.complement = initialFormValues.assure.complementAdresse;
  }

  return initialValues;
}

export default function FormHabitationInfoAdresse(props: FormProps<FormValues>): ReactElement {
  const initialValues = getInitialValues(props.initialFormValues);
  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    utilisationHabitation: Yup.object({
      estAdresseSouscripteur: Yup.string().required().nullable()
    }),
    adresseLogement: Yup.object({
      voie: Yup.string().max(255).required().nullable(),
      complement: Yup.string().max(255).notRequired(),
      codePostal: Yup.string().required().nullable(),
      ville: Yup.string().max(255).required().nullable(),
      pays: Yup.string().required()
    })
  }).defined();

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const estAdresseSouscripteur = watch('utilisationHabitation.estAdresseSouscripteur');
  useEffect(() => {
    if (estAdresseSouscripteur === 'true') {
      reset({
        ...getValues(),
        utilisationHabitation: { estAdresseSouscripteur: estAdresseSouscripteur },
        adresseLogement: {
          voie: initialValues.adresseLogement.voie,
          complement: initialValues.adresseLogement.complement,
          codePostal: initialValues.adresseLogement.codePostal,
          ville: initialValues.adresseLogement.ville,
          pays: initialValues.adresseLogement.pays
        }
      });
    } else {
      reset({
        ...getValues(),
        utilisationHabitation: { estAdresseSouscripteur: estAdresseSouscripteur },
        adresseLogement: {
          voie: '',
          complement: '',
          codePostal: '',
          ville: '',
          pays: ''
        }
      });
    }
  }, [estAdresseSouscripteur]);
  const handleNextClick = (data: FormValues) => {
    props.goNextStep(
      {
        habitation: { ...props.previousFormValues.habitation },
        utilisationHabitation: { ...props.previousFormValues.utilisationHabitation, ...data.utilisationHabitation },
        adresseLogement: { ...data.adresseLogement }
      },
      AccumulateStateToSeries
    );
  };

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'adresseLogement.codePostal', fetchVilleOptions);
  const paysOptions = useFetch(fetchPaysOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Où se trouve <span>ce logement</span> ?
      </h1>

      <InputRadioButtonInline
        labelField="L'adresse du logement correspond t'elle à celle du souscripteur ?"
        {...register('utilisationHabitation.estAdresseSouscripteur')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputText label="Adresse" {...register('adresseLogement.voie')} errors={errors} />

      <InputText label="Complément" {...register('adresseLogement.complement')} errors={errors} />

      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('adresseLogement.codePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="adresseLogement.ville"
          control={control}
          errors={errors}
          options={villeOptions || []}
        />
      </div>

      <InputTextAutoComplete
        label={'Pays'}
        name={'adresseLogement.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
