import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputText from 'components/Input/InputText/InputText.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchPaysOptions } from '../../fetches/options.fetch';
import InputDate from '../../components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import { assignValuesFromSource } from '../../utils';

type FormValues = {
  conjoint: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
    nationalite: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  conjoint: Yup.object({
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nom: Yup.string().required().max(255),
    prenom: Yup.string().required().max(255),
    naissanceDate: Yup.date().ageMinimum(18).required(),
    nationalite: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  conjoint: {
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    naissanceDate: undefined,
    nationalite: undefined
  }
};

export default function FormSanteParticulierConjointIdentite(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {}, (item: any, key: any) => {
    if (key === 'naissanceDate') return new Date(item);
    return item;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const pays = useFetch(fetchPaysOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1>
        Parlez-nous de <span>votre conjoint</span>
      </h1>
      <InputRadioButtonInline
        labelField="Civilité de votre conjoint"
        {...register('conjoint.civilite')}
        options={CIVILITES_OPTIONS}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText
          label="Prénom de votre conjoint"
          {...register('conjoint.prenom')}
          errors={errors}
          preventNumber={true}
        />
        <InputText label="Nom de votre conjoint" {...register('conjoint.nom')} errors={errors} preventNumber={true} />
      </div>
      <InputDate label="Date de naissance de votre conjoint" name={'conjoint.naissanceDate'} control={control} />

      <InputSelect
        label="Nationalité de votre conjoint"
        name="conjoint.nationalite"
        control={control}
        errors={errors}
        options={pays || []}
        isSearchable={true}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
