import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useFetch } from 'hooks/useFetch.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { fetchPaysOptions, fetchVilleOptions } from 'fetches/options.fetch';
import { CIVILITES_OPTIONS } from 'consts/consts';
import InputText from 'components/Input/InputText/InputText.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import FormConsent from '../../components/FormConsent/FormConsent.component';

type FormValues = {
  assure: {
    consentement: boolean | undefined;
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
    nationalite: string | undefined;

    contactMail: string | undefined;
    contactTelephone: string | undefined;
    contactCodePostal: string | undefined;
    ville: string | undefined;
    pays: string | undefined;
  };
};

const validationSchema: (askForConsent: boolean) => Yup.SchemaOf<FormValues> = askForConsent =>
  Yup.object({
    assure: Yup.object({
      civilite: Yup.string()
        .nullable()
        .required()
        .oneOf(
          CIVILITES_OPTIONS.map(option => option.value),
          'Champ obligatoire'
        ),
      nom: Yup.string().required().max(255),
      prenom: Yup.string().required().max(255),
      naissanceDate: Yup.date().ageMinimum(18).required(),
      nationalite: Yup.string().required(),

      contactMail: Yup.string().max(255).email().required(),
      contactTelephone: Yup.string()
        .required()
        .transform((val: string) => val.replace(/\s/g, ''))
        .max(255)
        .telephone(),
      contactCodePostal: Yup.string().required(),
      ville: Yup.string().required().max(255),
      pays: Yup.string().required(),
      ...(askForConsent
        ? {
            consentement: Yup.boolean().isTrue(
              'Vous devez obligatoirement cocher cette case pour valider votre demande.'
            )
          }
        : { consentement: Yup.boolean() })
    })
  }).defined();

const defaultState: FormValues = {
  assure: {
    consentement: false,
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    naissanceDate: undefined,
    nationalite: undefined,

    contactMail: '',
    contactTelephone: '',
    contactCodePostal: '',
    ville: '',
    pays: 'FR:PAYS'
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
    if (key === 'dateEffetSouhaitee') return new Date(item);
    return item;
  });
}

export default function FormExpatGarantiePart2(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema(props.data?.askForConsent ?? false)),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'assure.contactCodePostal', fetchVilleOptions);

  const pays = useFetch(fetchPaysOptions);

  const paysOptions = useFetch(fetchPaysOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Vos <span>coordonnées</span>
      </h1>
      {props.data?.askForConsent && (
        <FormConsent
          contenuConsentement={props.contenuConsentement}
          control={control}
          errors={errors}
          label={props.cocheConsentement}
          name="assure.consentement"
        />
      )}
      <InputRadioButtonInline
        labelField="Civilité"
        {...register('assure.civilite')}
        options={CIVILITES_OPTIONS}
        labelStyle={{ width: 'auto' }}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText label="Prénom" {...register('assure.prenom')} errors={errors} preventNumber={true} />
        <InputText label="Nom" {...register('assure.nom')} errors={errors} preventNumber={true} />
      </div>
      <div className={'layout-field-container'}>
        <InputDate label="Date de naissance" name={'assure.naissanceDate'} control={control} />
      </div>
      <InputSelect
        label="Nationalité"
        name="assure.nationalite"
        control={control}
        errors={errors}
        options={pays || []}
        isSearchable={true}
      />
      <InputText label="Adresse mail" {...register('assure.contactMail')} errors={errors} />
      <InputText
        label="Numéro de téléphone"
        subLabel="(Si numéro étranger, renseignez l'indicatif pays)"
        {...register('assure.contactTelephone')}
        errors={errors}
        preventTelephone={true}
      />
      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('assure.contactCodePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="assure.ville"
          control={control}
          errors={errors}
          options={villeOptions}
        />
      </div>
      <InputTextAutoComplete
        label={'Pays'}
        name={'assure.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
