import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useWatch, Control, FieldErrors } from 'react-hook-form';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { OUI_NON_OPTIONS } from 'consts/consts';
import { fetchResiliationMotifCodeOptions, fetchAssureurOptions } from 'fetches/options.fetch';
import './Assureur.scss';
import { useFetch } from 'hooks/useFetch.hook';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';
import parseSearch from 'utils/parseSearch';
import Extend from 'components/Extend/Extend';

type EnfantFieldsProps = {
  index: number;
  register: any;
  errors: FieldErrors<any>;
  control: Control<any>;
  name: string;
  remove: (index: number) => void;
};

export default function Assureur({ index, errors, register, control, name, remove }: EnfantFieldsProps): ReactElement {
  const resiliationMotifCodeOptions = useFetch(fetchResiliationMotifCodeOptions);
  const id = parseSearch(window.location.search, 'id');
  const assureurOptions = useFetch(() => fetchAssureurOptions(id));
  const watch = useWatch({ name, control });

  return (
    <div className="Assureur" key={index}>
      <button className="Assureur__remove no-default-style" onClick={() => remove(index)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>

      <InputSelect
        name={`${name}.${index}.assuranceNom`}
        label="Compagnie"
        options={assureurOptions?.map(e => ({ value: e.code, label: e.personne.raisonSociale })) || []}
        control={control}
        errors={errors}
        isSearchable
      />

      <InputDate label="Date de souscription" name={`${name}.${index}.debutDate`} control={control} />

      <InputRadioButtonInline
        labelField="Le contrat est-il toujours en cours ?"
        {...register(`${name}.${index}.estResilie`)}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <Extend extend={watch?.[index]?.estResilie === 'false'}>
        <InputDate label="Date de résiliation" name={`${name}.${index}.finDate`} control={control} />
        <InputSelect
          name={`${name}.${index}.resiliationMotifCode`}
          label="Motif de résiliation"
          options={resiliationMotifCodeOptions || []}
          control={control}
          errors={errors}
        />

        <InputTextArea
          label="Commentaires"
          {...register(`${name}.${index}.resiliationAutreMotif`)}
          errors={errors}
          rows={5}
        />
      </Extend>
    </div>
  );
}
