import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormGavIdentite from '../forms/GAV/FormGavIdentite.component';
import FormGavSituationPro from '../forms/GAV/FormGavSituationPro.component';
import FormSanteParticulierCoordonne from '../forms/SANTE_PART/FormSanteParticulierCoordonne.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description: 'Vous devez être majeur pour souscrire une assurance Garantie Accident de la Vie.'
  },
  STEP_SITUATION_PRO: {
    title: 'Pourquoi ces questions ?',
    description: 'Votre couverture peut varier suivant votre situation personnelle et/ou professionnelle.'
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description: "Afin de nous permettre d'établir une proposition d'assurance, vous devez fournir vos coordonnées."
  }
};

const projetGavConfig: FormConfigWithUrl = {
  endpoint: 'projetGav',
  formSteps: [
    {
      component: FormGavIdentite,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
    },
    {
      component: FormGavSituationPro,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
    },

    {
      component: FormSanteParticulierCoordonne,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
      data: { askForConsent: true, showCaptcha: true }
    }
  ]
};

export default projetGavConfig;
