import { FormConfigWithUrl } from 'types';
import FormPriseContactExpress from '../forms/PRISE_CONTACT_EXPRESS/FormPriseContactExpress.component';

const priseContactExpressConfig: FormConfigWithUrl = {
  endpoint: 'priseContactExpress',
  hideProgressBar: true,
  formSteps: [
    {
      component: FormPriseContactExpress,
      data: { showCaptcha: true }
    }
  ]
};

[];

export default priseContactExpressConfig;
