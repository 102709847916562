import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

const googleRecaptchaContainerId = 'google-recaptcha-';

type GoogleCaptcha = { captchaToken: any; captchaIsValid: boolean; captchaContainerId: string };

export const useGoogleCaptcha = (useCaptcha?: boolean, onCaptchaValidate?: (token: string) => void): GoogleCaptcha => {
  const [uid] = useState(_.uniqueId(googleRecaptchaContainerId));
  const [internalCaptchaToken, setInternalCaptchaToken] = useState<string | null>(null);
  const [grecaptchaWidgetId, setGrecaptchaWidgetId] = useState<number | null>(null);

  const renderCallback = useCallback((response: string) => {
    setInternalCaptchaToken(response);
    if (onCaptchaValidate) {
      onCaptchaValidate(response);
    }
  }, []);

  useEffect(() => {
    if (useCaptcha && grecaptchaWidgetId === null) {
      setGrecaptchaWidgetId(
        grecaptcha.enterprise.render(
          uid,
          {
            type: 'image',
            badge: 'bottomright',
            theme: 'light',
            action: 'SUBMIT',
            sitekey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY,
            callback: renderCallback
          },
          { action: 'submit-form' }
        )
      );
    }
  }, [useCaptcha, grecaptchaWidgetId]);

  const captchaIsValid: boolean = !useCaptcha || !!internalCaptchaToken;

  return {
    captchaIsValid,
    captchaToken: internalCaptchaToken,
    captchaContainerId: uid
  };
};
