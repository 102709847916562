import FormContactEntrepriseCoordonneesInterlocuteur from 'forms/CONTACT/entreprise/FormContactEntrepriseCoordonneesInterlocuteur.component';
import FormContactEntrepriseIdentite from 'forms/CONTACT/entreprise/FormContactEntrepriseIdentite.component';
import FormContactEntrepriseIdentiteInterlocuteur from 'forms/CONTACT/entreprise/FormContactEntrepriseIdentiteInterlocuteur.component';
import FormContactChoixProfil from 'forms/CONTACT/FormContactChoixProfil.component';
import FormContactDescription from 'forms/CONTACT/FormContactDescription.component';
import FormContactParticulierIdentite from 'forms/CONTACT/particulier/FormContactParticulierIdentite.component';
import FormContactParticulierInfoContact from 'forms/CONTACT/particulier/FormContactParticulierInfoContact.component';
import FormContactTnsEntreprise from 'forms/CONTACT/tns/FormContactTnsEntreprise.component';
import FormContactTnsIdentite from 'forms/CONTACT/tns/FormContactTnsIdentite.component';
import FormContactTnsInfosContact from 'forms/CONTACT/tns/FormContactTnsInfosContact.component';
import { FormConfigWithUrl } from 'types';

const contactConfig: FormConfigWithUrl = {
  endpoint: 'contact',
  formSteps: [
    {
      component: FormContactChoixProfil,
      next: {
        selectNextStep: (prevStepState: { profil: string }): string | undefined => {
          switch (prevStepState.profil) {
            case 'ENTREPRISE':
              return 'ENTREPRISE';
            case 'TNS':
              return 'TNS';
            case 'PARTICULIER':
              return 'PARTICULIER';
          }
        },
        paths: {
          PARTICULIER: [
            { component: FormContactParticulierIdentite },
            { component: FormContactParticulierInfoContact }
          ],
          ENTREPRISE: [
            {
              component: FormContactEntrepriseIdentite
            },
            { component: FormContactEntrepriseIdentiteInterlocuteur },
            {
              component: FormContactEntrepriseCoordonneesInterlocuteur
            }
          ],
          TNS: [
            { component: FormContactTnsIdentite },
            { component: FormContactTnsInfosContact },
            { component: FormContactTnsEntreprise }
          ]
        }
      }
    },
    {
      component: FormContactDescription,
      data: { showCaptcha: true }
    }
  ]
};

[];

export default contactConfig;
