import { FormNextStepsConfig, FormStepConfig, FormStepConfigWithNext, StepNode } from 'types';

/**
 * set the maxRemaining Steps property of a node
 * will climb the tree from a leave to the root
 * may stop is progression if node has a greater maxRemainingSteps value
 * Recursive function
 */

function setMaxRemainingSteps(node: StepNode, maxRemainingSteps: number) {
  if (maxRemainingSteps > node.maxRemainingSteps) node.maxRemainingSteps = maxRemainingSteps;
  node.parents?.forEach(parent => setMaxRemainingSteps(parent, maxRemainingSteps + 1));
}

let lastId = 0;

function createNode(config: FormStepConfig, pathName?: string): StepNode {
  lastId = lastId + 1;
  return {
    config,
    state: undefined,
    id: lastId,
    pathName,
    selectNextStep: () => undefined,
    position: 0, // has to be set later
    maxRemainingSteps: 0, // has to be set later
    children: []
  };
}

/**
 * Connect a node to a tree
 * returns the node if no parent is provided
 */

function addNode(node: StepNode, roots?: StepNode | StepNode[]): StepNode {
  if (roots) {
    if (Array.isArray(roots)) {
      node.parents = roots;
      roots.forEach(root => root.children.push(node));
      node.position = roots.reduce((maxPosition, root) => Math.max(maxPosition, root.position), 0) + 1;
    } else {
      node.parents = [roots];
      roots.children.push(node);
      node.position = roots.position + 1;
    }
  }
  return node;
}

/*
 * Return all leaves of a tree
 * Recursive function
 */

function getLeaves(root: StepNode, leaves: StepNode[]): StepNode[] {
  if (root.children.length === 0) {
    if (!leaves.find(leave => leave.id === root.id)) {
      return [root];
    }
    return [];
  }

  let childrenLeaves: StepNode[] = [];
  for (const child of root.children) {
    childrenLeaves = childrenLeaves.concat(getLeaves(child, [...leaves, ...childrenLeaves]));
  }
  return childrenLeaves;
}

/**
 * Return a function that returns the next stepNode in path when called
 */

function createNextStepSelector(node: StepNode, next?: FormNextStepsConfig): (prevStepState: any) => StepNode {
  if (!next) return () => node.children[0];
  return (prevStepState: any) => {
    const nextPath = next.selectNextStep(prevStepState);
    if (!nextPath) throw Error('no path returned from selector');
    const index = Object.keys(next.paths).indexOf(nextPath);
    if (index === -1 && node?.children[0]?.children[0]) return node?.children[0]?.children[0];
    if (index === -1) throw Error('Invalid path selection');
    return node.children[index];
  };
}

/**
 * Recursive function that will return a tree of StepNode based on a config object
 */

function buildStepTree(config: FormStepConfigWithNext[], root?: StepNode, pathName?: string): [StepNode, StepNode] {
  const configStage = config;
  let lastNode = root;
  let divergingLeaves: StepNode[] = [];

  if ((configStage?.length ?? 0) === 0) throw Error('No step in config file');

  for (const step of configStage) {
    const { next, ...filteredStepConfig } = step;
    // if we have leaves to merge
    if (divergingLeaves.length > 0)
      // create a node that is linked to multiple parents
      lastNode = addNode(createNode(filteredStepConfig, pathName), divergingLeaves);
    else {
      // create a node that has one parent
      lastNode = addNode(createNode(filteredStepConfig, pathName), lastNode);
    }
    if (!root) root = lastNode;
    // if config stage has multiple paths
    if (next) {
      // build sub tree for each path
      divergingLeaves = [];
      const divergeNode = lastNode;
      for (const path of Object.keys(next.paths)) {
        const [leaf] = buildStepTree(next.paths[path], divergeNode, path);
        // store divergingLeaves so we can merge them if there's a next step
        divergingLeaves.push(leaf);
      }
    }
    // assign a function that will return the next StepNode depending on the provided formValues
    lastNode.selectNextStep = createNextStepSelector(lastNode, next);
  }
  return [lastNode as StepNode, root as StepNode];
}

/**
 * Return a tree of StepNode based on the provided config
 */

export function getStepTree(config: FormStepConfigWithNext[]): StepNode {
  // Build tree
  const [, root] = buildStepTree(config);
  // retrieve leaves and order them by position (so the setMaxSteps function takes less operations)
  const leaves = getLeaves(root, []).sort((a, b) => a.position - b.position);
  // set the maxSteps property of each node in the the tree
  leaves.forEach(leave => setMaxRemainingSteps(leave, 0));
  return root;
}
