import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputText from 'components/Input/InputText/InputText.component';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { fetchFonctionsOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from '../../utils';

interface FormValues {
  interlocuteur: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    fonction: string | undefined;
  };
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  interlocuteur: Yup.object({
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nom: Yup.string().required().max(255),
    prenom: Yup.string().required().max(255),
    fonction: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  interlocuteur: {
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    fonction: undefined
  }
};

export default function FormIdentiteInterlocuteur(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const fonctionsOptions = useFetch(fetchFonctionsOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Interlocuteur</h2>
      <InputRadioButtonInline
        labelField="Civilité"
        {...register('interlocuteur.civilite')}
        options={CIVILITES_OPTIONS}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText label="Nom" {...register('interlocuteur.nom')} errors={errors} preventNumber={true} />
        <InputText label="Prénom" {...register('interlocuteur.prenom')} errors={errors} preventNumber={true} />
      </div>
      <InputSelect
        label="Fonction"
        name="interlocuteur.fonction"
        control={control}
        errors={errors}
        options={fonctionsOptions || []}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
