import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FC } from 'react';

import './BreadCrumb.scss';

interface iStep {
  stepCode: string;
  libelle: string;
}

type BreadCrumbProps = {
  activeStep?: string;
  currentStep?: string;
  steps: iStep[];
};

const BreadCrumb: FC<BreadCrumbProps> = ({ activeStep, steps, currentStep }) => {
  function getIndexStep(stepCode: string | undefined) {
    switch (stepCode) {
      case 'OFFRE': {
        return 0;
      }
      case 'INFOS': {
        return 1;
      }
      case 'PJ': {
        return 2;
      }
      case 'FRAIS': {
        return 3;
      }
      default: {
        return 4;
      }
    }
  }

  return (
    <div className={'breadcrumb'}>
      {steps.map((step, index) => {
        return (
          <div
            key={step.stepCode + index}
            className={classNames(
              'breadcrumb__item',
              {
                activeComplete: step.stepCode === activeStep && getIndexStep(currentStep) > index
              },
              {
                nonActiveComplete: step.stepCode !== activeStep && getIndexStep(currentStep) > index
              },
              {
                activeNonComplet: step.stepCode === activeStep && getIndexStep(currentStep) <= index
              }
            )}
          >
            <div className={'bubble'}>{index + 1}</div>
            <p>{step.libelle}</p>
            {index !== steps.length - 1 && <FontAwesomeIcon size={'lg'} icon={faChevronRight} />}
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
