import React, { ReactElement, useEffect, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { FieldError } from '../parts/FieldError/FieldError.component';
import Autosuggest, { RenderSuggestionParams } from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import './input-text.scss';
import { getIn } from 'utils';
import { Option } from 'types';
import classNames from 'classnames';

interface InputProps<O> {
  label: string;
  name: string;
  errors: FieldErrors<any>;
  options: Record<any, any>[];
  control: Control<any>;
  className?: string;
  autofocus?: boolean;
  onSuggestionSelected?: (value: O) => void;
  mustSelected?: boolean;
}

function renderSuggestion(suggestion: Option, { query }: RenderSuggestionParams) {
  if (!suggestion) return null;

  const matches = AutosuggestHighlightMatch(suggestion.label, query);
  const parts = AutosuggestHighlightParse(suggestion.label, matches);

  return (
    <span>
      {parts.map((part, index) => {
        const className = part.highlight ? 'react-autosuggest__suggestion-match' : undefined;

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
}

function getSuggestionValue<O extends Option>(suggestion: O) {
  return suggestion && suggestion.value;
}

export function InputTextAutoComplete<O extends Option>({
  label,
  name,
  errors,
  options,
  control,
  onSuggestionSelected,
  mustSelected = false,
  className
}: InputProps<O>): ReactElement {
  const [suggestions, setSuggestions] = useState<O[]>(options);
  useEffect(() => {
    setSuggestions(options);
  }, [JSON.stringify(options)]);

  const error = getIn(name, errors);

  function getSuggestions(value: string) {
    // get suggestions that match the input value
    const escapedValue = value.trim();
    if (escapedValue === '') {
      return options;
    }
    const regex = new RegExp('^' + escapedValue, 'i');

    return options.filter(option => regex.test(option.label));
  }

  return (
    <div className={classNames('inputText', className, { '-error': error })}>
      <label className={classNames('inputText__label', className, { '-error': error })}>{label}</label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          const valueToDisplay = options.find(opt => opt.value === field.value)?.label;
          return (
            <Autosuggest
              focusInputOnSuggestionClick={mustSelected}
              shouldRenderSuggestions={() => true}
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) => setSuggestions(getSuggestions(value))}
              onSuggestionsClearRequested={() => setSuggestions([])}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onSuggestionSelected={(e, { suggestion }) => {
                if (onSuggestionSelected) onSuggestionSelected(suggestion as O);
              }}
              inputProps={{
                ...field,
                value: valueToDisplay || field.value,
                onChange: (e, { newValue, method }) => {
                  // do not use e.target.value else autosuggest will crash
                  if (['down', 'up'].includes(method)) return;
                  field.onChange(newValue);
                },
                onBlur: () => {
                  if (mustSelected && !options.find(opt => opt.value === field.value)) {
                    field.onChange('');
                  }
                  field.onBlur();
                },
                autoComplete: 'off'
              }}
            />
          );
        }}
      />
      <FieldError>{error?.message}</FieldError>
    </div>
  );
}
InputTextAutoComplete.displayName = 'InputTextAutoComplete';

export default InputTextAutoComplete;
