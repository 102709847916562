import React, { ReactElement } from 'react';
import './Axe.scss';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';
import InfoBulle from '../InfoBulle/InfoBulle';
import { Control } from 'react-hook-form';
import { ReactComponent as SvgMinimal } from 'assets/minimal.svg';
import { ReactComponent as SvgElementaire } from 'assets/elementaire.svg';
import { ReactComponent as SvgConfort } from 'assets/confort.svg';
import { ReactComponent as SvgOptimal } from 'assets/optimal.svg';

type AxeFieldProps = {
  title: string;
  niveaux: any;
  control: Control<any>;
  errors: any;
  register: any;
  description?: string;
  name: string;
  noWrap?: boolean;
};

export default function AxeField({
  register,
  name,
  title,
  niveaux,
  control,
  errors,
  description,
  noWrap
}: AxeFieldProps): ReactElement {
  const icons = [<SvgMinimal key="1" />, <SvgElementaire key="2" />, <SvgConfort key="3" />, <SvgOptimal key="4" />];

  return (
    <div className={'container-axe'}>
      <div className={'container-radios'}>
        <InputRadioButtonIcon
          label={title}
          control={control}
          errors={errors}
          {...register(name)}
          options={niveaux.map(({ label, value }: any, index: number) => ({
            label,
            value,
            icon: <div className={'AxeField__icon'}>{icons[index]}</div>
          }))}
          small
          noWrap={noWrap}
        />
        {description && (
          <div className={'axe-info-bulle'}>
            <InfoBulle name={`description-${name}`} key={`description-${name}`} text={description || ''} />
          </div>
        )}
      </div>
    </div>
  );
}
