import classNames from 'classnames';
import React, { FC } from 'react';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';

interface ProductRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
}

const RecommandationRow: FC<ProductRowProps> = ({ proposedQuotes, quoteMobileDisplay }) => {
  return (
    <div className={'row-recommandation emptyTitle'}>
      <div className={'content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          if (!quote.favori) return <div className={'item'}></div>;
          return (
            <RoundedCard
              key={quote.numeroInterne}
              className={classNames(
                'primary',
                'row-recommandation__info',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <h4>Notre recommandation</h4>
            </RoundedCard>
          );
        })}
      </div>
    </div>
  );
};

export default RecommandationRow;
