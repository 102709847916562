import classNames from 'classnames';
import React, { ReactNode } from 'react';

type FormRowProps = {
  className?: string;
  size?: 'full' | 'two-third' | 'half';
  children: ReactNode;
};

// eslint-disable-next-line
export function FormRow({ className, children, size = 'full' }: FormRowProps) {
  return (
    <div
      className={classNames(
        'form-grid__row',
        {
          'form-grid__row--full': size === 'full',
          'form-grid__row--two-third': size === 'two-third',
          'form-grid__row--half': size === 'half'
        },
        className
      )}
    >
      {children}
    </div>
  );
}
