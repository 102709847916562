import React, { ReactElement } from 'react';
import InputSelect from '../../../components/Input/InputSelect/InputSelect.component';
import InputNumber from '../../../components/Input/InputNumber/InputNumber.component';
import { Control } from 'react-hook-form';
import InputRadioButtonInline from '../../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputDatePicker from '../../../components/Input/InputDatePicker/InputDatePicker.component';

type PropsFormUtil = {
  control: Control<any>;
  errors: any;
  register: any;
  compagnieList: any;
  fieldsObjectName: string;
  watch: any;
  title: string;
};

const REACTIONS = [
  {
    label: () => <span>😡</span>,
    value: 'pas du tout satisfait'
  },
  {
    label: () => <span>🙁</span>,
    value: 'peu satisfait'
  },
  {
    label: () => <span>🙂</span>,
    value: 'satisfait'
  },
  {
    label: () => <span>😀</span>,
    value: 'très satisfait'
  }
];

export default function RisquesFields({
  title,
  watch,
  fieldsObjectName,
  control,
  errors,
  register,
  compagnieList
}: PropsFormUtil): ReactElement {
  const compagnie = watch(`${fieldsObjectName}.produit.assureur.code`);
  return (
    <div>
      <h3 style={{ marginBottom: '16px' }}>{title}</h3>
      <div>
        <InputSelect
          isSearchable={true}
          withClearAction
          label="Compagnie"
          control={control}
          errors={errors}
          name={`${fieldsObjectName}.produit.assureur.code`}
          options={[{ label: '', value: null }, ...(compagnieList || [])]}
        />
      </div>
      {compagnie && (
        <>
          <div className="layout-2-col">
            <InputDatePicker
              className={'layout-flex-basis-auto'}
              label="Année de souscription (facultatif)"
              isYearPicker={true}
              control={control}
              {...register(`${fieldsObjectName}.anneeDebut`)}
              errors={errors}
            />
            <InputNumber
              className={'layout-flex-basis-auto'}
              label="Prime annuelle (approximative)"
              {...register(`${fieldsObjectName}.cotisation.montantAnnuel`)}
              errors={errors}
            />
          </div>
          <InputRadioButtonInline
            labelField={'Niveau de satisfaction générale sur ce contrat'}
            {...register(`${fieldsObjectName}.commentaire`)}
            options={REACTIONS}
            errors={errors}
          />
        </>
      )}
    </div>
  );
}
