import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useMemo } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';
import { ReactComponent as SvgChalet } from 'assets/chalet.svg';
import { ReactComponent as SvgAppart } from 'assets/appartment.svg';
import { ReactComponent as SvgHouse } from 'assets/house.svg';
import { ReactComponent as SvgPro } from 'assets/pro.svg';

type FormValues = {
  natureHabitationCode: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  natureHabitationCode: Yup.string().required()
}).defined();

const defaultState: FormValues = {
  natureHabitationCode: undefined
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormEmprunteurTypeCredit({
  previousFormValues,
  setLastFormState,
  formValues,
  goNextStep,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(previousFormValues), []);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isDirty }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => goNextStep(values))}
    >
      <h1 className="title">
        Description <span>de l&apos;emprunt</span>
      </h1>

      <InputRadioButtonIcon
        {...register('natureHabitationCode')}
        errors={errors}
        options={[
          {
            value: 'PRINCIPAL_PRIMO_ACCEDANT:NATURE_PROJET_EMPRUNT',
            label: 'Résidence principale',
            icon: <SvgHouse style={{ width: '100px', height: 'auto' }} />
          },
          {
            value: 'SECONDAIRE:NATURE_PROJET_EMPRUNT',
            label: 'Résidence secondaire',
            icon: <SvgChalet style={{ width: '100px', height: 'auto' }} />
          },
          {
            value: 'PRET_PROFESSIONNEL:NATURE_PROJET_EMPRUNT',
            label: 'Local professionel',
            icon: <SvgPro style={{ width: '80px', height: 'auto' }} />
          },
          {
            value: 'INVESTISSEMENT_LOCATIF:NATURE_PROJET_EMPRUNT',
            label: 'Investissement locatif',
            icon: <SvgAppart style={{ width: '100px', height: 'auto' }} />
          }
        ]}
      />
      {!!formValues && (
        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      )}
    </AutoSubmissionForm>
  );
}
