import LeftPaneDescription from 'components/LeftPane/LeftPaneDescription.component';
import FormMiseEnRelationChoixCrenaux from 'forms/MISE_EN_RELATION/FormMiseEnRelationChoixCrenaux';
import FormMiseEnRelationIdentite from 'forms/MISE_EN_RELATION/FormMiseEnRelationIdentite.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_IDENTITE: {
    title: 'Bon à savoir',
    description: `Nos conseillers sont à votre écoute du lundi au vendredi de 9h00 à 18h00 (sauf week-ends et jours fériés) au : 04 88 690 638 ou par mail à : <a style="color: white" href="mailto:bonjour@nils-expat.com">bonjour@nils-expat.com</a>`,
    descriptionAsHTML: true
  },
  STEP_CHOIX_CRENAUX: {
    title: 'Bon à savoir',
    description: 'Sélectionnez le jour et la plage horaire sur lesquels nos conseillers peuvent vous joindre.'
  }
};

const miseEnRelationConfig: FormConfigWithUrl = {
  endpoint: 'miseEnRelation',
  formSteps: [
    {
      component: FormMiseEnRelationIdentite,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
    },
    {
      component: FormMiseEnRelationChoixCrenaux,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_CHOIX_CRENAUX} />,
      data: { showCaptcha: true }
    }
  ]
};

export default miseEnRelationConfig;
