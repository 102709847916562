type codifRoleBeneficiaire = 'ENFANT:ROLE_RELATION_TYPE' | 'CONJOINT:ROLE_RELATION_TYPE';

export type NumeroSecu = {
  numeroSecu: string;
  nom: string;
  prenom: string;
  naissanceDate: string;
  roleCode: codifRoleBeneficiaire;
};

export const isNumeroSecuriteSocialeValide = (numero: string | undefined): boolean => {
  if (!numero) {
    return false;
  }
  // Supprimer les espaces éventuels dans le numéro de sécurité sociale
  const cleanedNumero = numero.replace(/\s/g, '');
  // Vérifier que le numéro est composé de 15 chiffres
  if (!/^\d{15}$/.test(cleanedNumero)) {
    return false;
  }
  // Vérifier que les 10 premiers chiffres représentent une date de naissance valide
  const mois = parseInt(cleanedNumero.slice(3, 5), 10);
  const annee = parseInt(cleanedNumero.slice(1, 3), 10);
  if (!isValidDate(mois, annee)) {
    return false;
  }
  // Vérifier que le 13ème chiffre est valide en utilisant la clé de contrôle
  const cleControle = parseInt(cleanedNumero.slice(13, 15), 10);
  const chiffresCle = parseInt(cleanedNumero.slice(0, 13), 10) % 97;

  return cleControle === 97 - chiffresCle;
};

const isValidDate = (mois: number, annee: number): boolean => {
  // Vérifier que les valeurs du jour, mois et année sont valides
  return !(mois < 1 || mois > 12 || annee < 0 || annee > 99);
};
