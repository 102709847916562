import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputText from 'components/Input/InputText/InputText.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { CIVILITES_OPTIONS } from 'consts/consts';
import InputDatePicker from '../../components/Input/InputDatePicker/InputDatePicker.component';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import { assignValuesFromSource } from '../../utils';

type FormValues = {
  assure: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nom: Yup.string().required().max(255),
    prenom: Yup.string().required().max(255),
    naissanceDate: Yup.date().ageMinimum(18).required()
  })
}).defined();

const defaultState: FormValues = {
  assure: {
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    naissanceDate: undefined
  }
};

export default function FormDecesIdentite(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = assignValuesFromSource(
    defaultState,
    props.initialFormValues || {},
    (item: any, key: string) => {
      if (key === 'naissanceDate') return new Date(item);
      return item;
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre identité:</h2>
      <InputRadioButtonInline
        labelField="Civilité"
        {...register('assure.civilite')}
        options={CIVILITES_OPTIONS}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText label="Nom" {...register('assure.nom')} errors={errors} preventNumber={true} />
        <InputText label="Prénom" {...register('assure.prenom')} errors={errors} preventNumber={true} />
      </div>
      <div className={'layout-field-container'}>
        <InputDatePicker
          className="layout-flex-basis-auto"
          label="Date de naissance"
          showMonthDropdown
          showYearDropdown
          name={'assure.naissanceDate'}
          errors={errors}
          control={control}
          birthdayDate
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
