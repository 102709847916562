import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { ColoredText } from 'components/ColoredText/ColoredText';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import Separator from 'components/Separator/Separator.component';
import React, { ReactElement, useState } from 'react';
import { FormProps } from 'types';
import './FormDemandeAdhesionStepFrais.scss';
import './FormDemandeAdhesionStepInit.scss';

interface FormValues {
  montantFrais: number | undefined;
  numeroPaiementStripe: string | undefined;
  publicClefApiStripe: string | undefined;
}

export default function FormDemandeAdhesionFrais(props: FormProps<FormValues>): ReactElement {
  const { initialFormValues } = props;

  const elements = useElements();
  const stripe = useStripe();
  const [paiementErrors, setPaiementErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [isPaiementValide, setIsPaiementValide] = useState(false);

  const handleSubmitClick = (e: any) => {
    e.preventDefault();
    setPaiementErrors('');
    if (elements) {
      setLoading(true);
      const card: StripeCardElement | null = elements.getElement(CardElement);
      if (stripe && card) {
        stripe
          .confirmCardPayment(initialFormValues?.numeroPaiementStripe, {
            payment_method: { card: card }
          })
          .then(result => {
            setLoading(false);
            if (result?.error) {
              setPaiementErrors(
                "Une erreur est survenue durant votre paiement, vous n'avez pas été débité. Merci de ré essayer ultérieurement"
              );
            } else {
              setIsPaiementValide(true);
            }
          });
      } else {
        setLoading(false);
        setPaiementErrors(
          "Une erreur est survenue durant votre paiement, vous n'avez pas été débité. Merci de ré essayer ultérieurement"
        );
      }
    }
  };

  const handleNextClick = () => {
    props.goNextStep({ ...initialFormValues });
  };

  return (
    <div className="adhesion-step-frais form-adhesion">
      <h2>Paiement des frais de dossier</h2>
      <p>
        Le montant des frais de dossier s{"'"}élève à{' '}
        <ColoredText className={'secondary'} text={`${initialFormValues?.montantFrais} €`} />
      </p>
      <p>
        Afin de poursuivre votre demande d{"'"}adhésion, vous devez procéder au règlement de ces frais par carte
        bancaire.
      </p>

      <Separator />
      {initialFormValues?.fraisDejaRegles === false && (
        <>
          <div className={'stripeCb'}>
            <CardElement
              options={{
                disabled: isPaiementValide,
                hidePostalCode: true,
                iconStyle: 'solid',
                hideIcon: false
              }}
              onChange={val => setDisabledSubmitBtn(!val.complete)}
            />
          </div>

          {paiementErrors && (
            <div style={{ color: 'red' }}>
              <ColoredText text={paiementErrors} color="red" />
            </div>
          )}

          {!isPaiementValide && (
            <FormNextButton
              onClick={handleSubmitClick}
              loading={loading}
              disabled={disabledSubmitBtn}
              showCaptcha={props.showCaptcha}
              setCaptchaToken={props.setCaptchaToken}
            >
              Je Valide mon paiement
            </FormNextButton>
          )}

          {isPaiementValide && (
            <>
              <ColoredText text="Votre paiement est validé, vous pouvez à présent signer les documents" color="green" />

              <FormNextButton
                className="form-adhesion-next-button margin-top no-default-style form-font-regular"
                onClick={handleNextClick}
                showCaptcha={props.showCaptcha}
                setCaptchaToken={props.setCaptchaToken}
              >
                Signer les documents
              </FormNextButton>
            </>
          )}
        </>
      )}

      {initialFormValues?.fraisDejaRegles === true && (
        <>
          <ColoredText text="Vos frais ont déjà été réglés. Vous pouvez à présent signer les documents" color="green" />

          <FormNextButton
            className="form-adhesion-next-button margin-top no-default-style form-font-regular"
            onClick={handleNextClick}
            showCaptcha={props.showCaptcha}
            setCaptchaToken={props.setCaptchaToken}
          >
            Signer les documents
          </FormNextButton>
        </>
      )}
    </div>
  );
}
