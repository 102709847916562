import { faCircle as faRegularCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircle as faSolidCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { ErrorLabel } from '../parts/ErrorLabel/ErrorLabel.component';
import './input-radio.scss';
import classNames from 'classnames';

interface InputRadioProps {
  label: string;
  errors: FieldErrors<any>;
  options: Option[];
  className?: string;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
}

interface Option<T = string> {
  label: string;
  value: T;
}

export const InputRadio = React.forwardRef<HTMLInputElement, InputRadioProps>(
  ({ errors, options, label, className, ...field }: InputRadioProps, ref) => {
    const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
    useEffect(() => {
      const inputEl = document.querySelector<HTMLInputElement>(`input[type=radio][name="${field.name}"]:checked`);
      if (inputEl === null) return;
      setSelectedValue(inputEl.value);
    }, []);

    function handleSelect(value: string): void {
      setSelectedValue(value);
    }
    const error = getIn(field.name, errors);
    return (
      <div
        className={classNames('input-radio-root', className, {
          'input-error': error
        })}
      >
        <p className="input-radio-label">{label}</p>
        <div className={`input-radio-group`}>
          {options.map(option => (
            <label
              key={option.value}
              className="input-radio"
              tabIndex={0}
              onClick={() => handleSelect(option.value)}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              }}
            >
              <input tabIndex={-1} type="radio" {...field} ref={ref} value={option.value} />
              <FontAwesomeIcon
                className="pin"
                icon={option.value === selectedValue ? faSolidCircle : faRegularCircle}
              />
              <div>{option.label}</div>
            </label>
          ))}
        </div>
        <ErrorLabel>{error?.message}</ErrorLabel>
      </div>
    );
  }
);
InputRadio.displayName = 'InputRadio';

export default InputRadio;
