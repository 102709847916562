import FormAnimalComplement from 'forms/COMPLEMENTAIRE_ANIMAL/FormAnimalComplement.component';
import FormAnimaldentite from 'forms/COMPLEMENTAIRE_ANIMAL/FormAnimalIdentite.component';
import FormAnimalIdentiteAnimal from 'forms/COMPLEMENTAIRE_ANIMAL/FormAnimalIdentiteAnimal.component';
import FormSanteParticulierCoordonne from 'forms/SANTE_PART/FormSanteParticulierCoordonne.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description:
      "Des informations d'identité sont nécéssaire pour la souscription de n'importe quel produit d'assurance."
  },
  STEP_IDENTITE_ANIMAL: {
    title: 'Pourquoi cette question ?',
    description: "Indiquez ici les informations d'identité de votre animal"
  },
  STEP_COMPLEMENT: {
    title: "Pourquoi demande t'on ces informations",
    description: "Les contrats de complémentaire santé animal s'adressent à des animaux en bonne santé général."
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description:
      'Il est important que nous puissions vous recontacter afin de proposer les meilleurs offres pour votre animal.'
  }
};

const projetAnimal: FormConfigWithUrl = {
  endpoint: 'projetComplementaireAnimal',
  formSteps: [
    {
      component: FormAnimaldentite,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
    },
    {
      component: FormAnimalIdentiteAnimal,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE_ANIMAL} />
    },
    {
      component: FormAnimalComplement,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COMPLEMENT} />
    },
    {
      component: FormSanteParticulierCoordonne,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
      data: { askForConsent: true, showCaptcha: true }
    }
  ]
};

export default projetAnimal;
