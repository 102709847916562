import React, { ReactElement } from 'react';
import './form-prec-button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface FormPrecButtonProps {
  onClick: () => void;
  activeStep: number;
}

export function FormPrecButton({ onClick, activeStep }: FormPrecButtonProps): ReactElement {
  return (
    <div className="prev-button-wrapper">
      {activeStep > 0 && (
        <button className="prev-button no-default-style" data-testid="prev-step-button" onClick={onClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
          <span>Précédent</span>
        </button>
      )}
    </div>
  );
}

export default FormPrecButton;
