import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputText from 'components/Input/InputText/InputText.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { fetchPaysOptions, fetchVilleOptions } from 'fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from 'hooks/useFetchOptionsOnFieldChange.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import { useFetch } from '../../../hooks/useFetch.hook';
import { ColoredText } from '../../../components/ColoredText/ColoredText';

type FormValues = {
  contactMail: string;
  contactTelephone: string;
  contactCodePostal: string;
  ville: string;
  pays: string;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  contactMail: Yup.string().max(255).email().required(),
  contactTelephone: Yup.string()
    .required()
    .transform((val: string) => val.replace(/\s/g, ''))
    .max(255)
    .telephone(),
  contactCodePostal: Yup.string().required(),
  ville: Yup.string()
    .required()
    .max(255)
    .matches(/^([^0-9]*)$/, 'Le champ ne doit pas contenir de chiffres'),
  pays: Yup.string().required()
}).defined();

const defaultState: FormValues = {
  contactMail: '',
  contactTelephone: '',
  contactCodePostal: '',
  ville: '',
  pays: 'FR:PAYS'
};

export default function FormPrescripteurParticulierInfoContact(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useSerieForm<FormValues>(props.setLastFormState, {
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'contactCodePostal', fetchVilleOptions);

  const paysOptions = useFetch(fetchPaysOptions);

  const handleNextClick = (data: FormValues) => {
    data.contactTelephone = data.contactTelephone.replace(/\s/g, '');
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>
        <ColoredText className={'_secondaire'} text="Informations " /> du contact
      </h2>
      <InputText label="Adresse email" {...register('contactMail')} errors={errors} />
      <InputText label="Téléphone" {...register('contactTelephone')} errors={errors} preventTelephone={true} />
      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('contactCodePostal')}
          errors={errors}
        />
        <InputTextAutoComplete label="Ville" name="ville" control={control} errors={errors} options={villeOptions} />
      </div>
      <InputTextAutoComplete
        label={'Pays'}
        name={'pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
