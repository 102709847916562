import React, { FC } from 'react';

import './RoundedCard.scss';
import classNames from 'classnames';

type RoundedCardProps = {
  className?: string;
};

// eslint-disable-next-line react/prop-types
export const RoundedCard: FC<RoundedCardProps> = ({ className, children }) => {
  return <div className={classNames('rounded-card', className)}>{children}</div>;
};
