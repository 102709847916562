import FormPrevoyanceAutresRisques from 'forms/PREVOYANCE/FormPrevoyanceAutresRisques.component';
import FormPrevoyanceChoixDate from 'forms/PREVOYANCE/FormPrevoyanceChoixDate.component';
import FormPrevoyanceDeces from 'forms/PREVOYANCE/FormPrevoyanceDeces.component';
import FormPrevoyanceIdentite from 'forms/PREVOYANCE/FormPrevoyanceIdentite.component';
import FormPrevoyanceIncapacite from 'forms/PREVOYANCE/FormPrevoyanceIncapacite.component';
import FormPrevoyanceInvalidite from 'forms/PREVOYANCE/FormPrevoyanceInvalidite.component';
import FormPrevoyanceSituationPro from 'forms/PREVOYANCE/FormPrevoyanceSituationPro.component';
import FormPrevoyanceTypeIndemnisation from 'forms/PREVOYANCE/FormPrevoyanceTypeIndemnisation.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormSanteParticulierConjointIdentite from '../forms/SANTE_PART/FormSanteParticulierConjointIdentite.component';
import FormSanteParticulierCoordonne from '../forms/SANTE_PART/FormSanteParticulierCoordonne.component';
import FormSanteParticulierEnfants from '../forms/SANTE_PART/FormSanteParticulierEnfants.component';
import FormParcoursTnsEntreprise from '../forms/TNS/FormParcoursTnsEntreprise.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_CHOIX_DATE: {
    title: 'Pourquoi cette question ?',
    description: 'Indiquez à partir de quand vous souhaitez être couvert'
  },
  STEP_DECES: {
    title: "Comprendre les garanties d'une prévoyance TNS",
    description:
      "Capital Décès : versement d'un capital au(x) bénéficiaire(s) désigné(s) en cas de décès de l'assuré. Ce capital peut être réévalué en cas de présence de garanties telles que la majoration conjoint, enfant ou le doublement décès accidentel."
  },
  STEP_INCAPACITE: {
    title: "Comprendre les garanties d'une prévoyance TNS",
    description:
      "Cette garantie permet de couvrir le risque d'incapacité à exercer son travail pendant un temps déterminé. L'assurance prévoyance verse des indemnités journalières mensuelles, aprés application de la franchise choisie au moment de l'adhésion."
  },
  STEP_INVALIDITE: {
    title: "Comprendre les garanties d'une prévoyance TNS",
    description:
      "L'invalidité intervient lorsque l'état de santé n'est plus succeptible d'évoluer : l'invalidité peut être partielle ou totale (généralement, au delà des 66% d'invalidité, il s'agit d'une invalidité totale). En cas d'invalidité, les contrats d'assurance prévoyance permettent le versement d'une rente ou d'un capital."
  },
  STEP_TYPE_INDEMNISATION: {
    title: "Comprendre les garanties d'une prévoyance TNS",
    description:
      "L'indemnisation forfaitaire prévoit le versement pur et simple des indemnités sans tenir compte du revenu d'activité déclaré. En revanche, s'il s'agit d'un contrat de prévoyance en mode indemnitaire, l'assurance tiendra non seulement compte des prestations versées par le régime obligatoire, mais également des prestations qui seraient versées par tout autre régime de prévoyance complémentaire."
  },
  STEP_AUTRE_RISQUES: {
    title: "Comprendre les garanties d'une prévoyance TNS",
    description:
      "D'autres risques peuvent être couvert via un contrat d'assurance en Prévoyance, comme par exemple vos frais professionnels"
  },
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description: 'Ces données rentrent en compte dans le calcul du tarif de votre prévoyance professionnelle.'
  },
  STEP_SITUATION_PRO: {
    title: 'Pourquoi ces questions ?',
    description: 'Ces données rentrent en compte dans le calcul du tarif de votre prévoyance professionnelle.'
  },
  STEP_ENTREPRISE: {
    title: 'Pourquoi ces questions ?',
    description: 'Les compagnies d’assurance demandent ces informations pour vérifier votre statut professionnel.'
  },
  STEP_IDENTITE_CONJOINT: {
    title: 'Pourquoi ces questions ?',
    description: 'Les informations sur votre conjoint permettent d’activer la majoration conjoint du capital décès.'
  },
  STEP_ENFANTS: {
    title: 'Pourquoi ces questions ?',
    description:
      'Les informations sur votre / vos enfant(s) permettent d’activer la majoration enfant du capital décès.'
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description: 'Ces données rentrent en compte dans le calcul du tarif de votre prévoyance professionnelle.'
  }
};

const besoins = [
  {
    component: FormPrevoyanceIncapacite,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_INCAPACITE} />
  },
  {
    component: FormPrevoyanceInvalidite,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_INVALIDITE} />
  },
  {
    component: FormPrevoyanceTypeIndemnisation,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_TYPE_INDEMNISATION} />
  },
  {
    component: FormPrevoyanceAutresRisques,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_AUTRE_RISQUES} />
  },
  {
    component: FormPrevoyanceIdentite,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
  },
  {
    component: FormPrevoyanceSituationPro,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
  },
  {
    component: FormParcoursTnsEntreprise,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE} />
  }
];

const projetPrevoyance: FormConfigWithUrl = {
  endpoint: 'projetPrevoyance',
  formSteps: [
    {
      component: FormPrevoyanceChoixDate,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_CHOIX_DATE} />
    },
    {
      component: FormPrevoyanceDeces,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DECES} />,
      next: {
        selectNextStep: (prevStepState: {
          renteConjoint: {
            valeur: string;
          };
          renteEducation: {
            valeur: string;
          };
        }): string | undefined => {
          if (prevStepState.renteConjoint.valeur === 'true' && prevStepState.renteEducation.valeur === 'true')
            return 'CONJOINT_ENFANTS';
          else if (prevStepState.renteConjoint.valeur === 'true' && prevStepState.renteEducation.valeur !== 'true')
            return 'CONJOINT';
          else if (prevStepState.renteConjoint.valeur !== 'true' && prevStepState.renteEducation.valeur === 'true')
            return 'ENFANTS';
          return 'SEUL';
        },
        paths: {
          SEUL: [...besoins],
          CONJOINT: [
            ...besoins,
            {
              component: FormSanteParticulierConjointIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE_CONJOINT} />
            }
          ],
          CONJOINT_ENFANTS: [
            ...besoins,
            {
              component: FormSanteParticulierConjointIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE_CONJOINT} />
            },
            {
              component: FormSanteParticulierEnfants,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENFANTS} />
            }
          ],
          ENFANTS: [
            ...besoins,
            {
              component: FormSanteParticulierEnfants,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENFANTS} />
            }
          ]
        }
      }
    },
    {
      component: FormSanteParticulierCoordonne,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
      data: { askForConsent: true, showCaptcha: true }
    }
  ]
};

export default projetPrevoyance;
