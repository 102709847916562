import React, { ReactElement } from 'react';
import { Control, Controller, FieldErrors, FieldValues, UseControllerProps } from 'react-hook-form';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-select.scss';
import { getIn } from 'utils';
import { Option } from 'types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import InfoBulle from '../../InfoBulle/InfoBulle';

type InputProps<T extends FieldValues> = UseControllerProps<T> & {
  label: string;
  name: string;
  tooltip?: string;
  errors: FieldErrors<any>;
  options: Option[];
  control: Control<T>;
  className?: string;
  autofocus?: boolean;
  onSelect?: any;
  withClearAction?: boolean;
  isSearchable?: boolean;
  onClear?: () => void;
};

export function InputSelect<T extends FieldValues>({
  tooltip,
  label,
  name,
  errors,
  control,
  options,
  className,
  onSelect,
  withClearAction,
  isSearchable,
  onClear
}: InputProps<T>): ReactElement {
  const error = getIn(name, errors);
  return (
    <div className={classNames('InputSelect', className)}>
      <label className={classNames('InputSelect__label', { '-error': error })}>
        {label}
        {tooltip && (
          <InfoBulle
            className={'inputSelect__tooltip'}
            name={`description-tooltip-${label}`}
            key={`description-tooltip`}
            text={tooltip}
          />
        )}
      </label>

      <Controller
        render={({ field }) => (
          <div>
            <ReactSelect
              className={classNames('InputSelect__container', { '-error': error })}
              isSearchable={isSearchable || false}
              {...field}
              placeholder="Sélectionner dans la liste"
              value={options.find(option => option.value === field.value)}
              options={options}
              onChange={selectedOption => {
                field.onChange(selectedOption?.value);
                onSelect && onSelect(selectedOption?.value);
              }}
              classNamePrefix="InputSelect"
              isLoading={!options.length}
            />
            {withClearAction && field.value && (
              <div className={'InputSelect__clear'}>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => {
                    field.onChange(null);
                    onClear && onClear();
                  }}
                />
              </div>
            )}
          </div>
        )}
        name={name}
        control={control}
      />
      <FieldError>{error?.message}</FieldError>
    </div>
  );
}

InputSelect.displayName = 'InputSelect';

export default InputSelect;
