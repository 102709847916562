import React, { ReactElement } from 'react';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import { useFieldArray } from 'react-hook-form';
import FormGenericButton from 'components/FormGenericButton/FormGenericButton.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Infraction from './Components/Infraction/Infraction.component';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

type Key = 'conducteurPrincipal' | 'conducteurSecondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};
type Infraction = {
  typeCode: string | undefined;
  moins5Ans: boolean | undefined;
  nombre: number | undefined;
};
export type Form = {
  infractions: Infraction[];
};
export type FormValues<K extends Key> = PartialRecord<K, Form>;

export default function FormAutoConducteurPart4({
  goNextStep,
  initialFormValues,
  formValues,
  data = { type: 'conducteurPrincipal' },
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const isConducteurPrincipal = type === 'conducteurPrincipal';

  const defaultInfraction: Infraction = {
    typeCode: undefined,
    moins5Ans: false,
    nombre: undefined
  };

  const defaultState: FormValues<typeof type> = {
    [type]: {
      infractions: []
    }
  };

  const validationSchema = Yup.object({
    [type]: Yup.object({
      infractions: Yup.array().of(
        Yup.object({
          typeCode: Yup.string().nullable().required(),
          moins5Ans: Yup.boolean().nullable().required(),
          nombre: Yup.number().typeError('Champ obligatoire').required()
        })
      )
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const { fields, append, remove } = useFieldArray<FormValues<Key>>({
    control,
    name: `${type}.infractions`
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Conducteur <span>{isConducteurPrincipal ? 'principal' : 'secondaire'}</span>
      </h1>

      <h2 className="subtitle">Le conducteur {isConducteurPrincipal} a t&apos;il commis des infractions ?</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        {fields.length ? (
          fields.map((item, index) => (
            <Infraction
              key={index}
              index={index}
              register={register}
              control={control}
              errors={errors}
              remove={remove}
              type={type}
              watch={watch}
            />
          ))
        ) : (
          <div className="Infraction" data-empty="Aucune infraction" onClick={() => append(defaultInfraction)}></div>
        )}

        <div className="layout-button-container-responsive">
          <FormGenericButton onClick={() => append(defaultInfraction)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter une infraction
          </FormGenericButton>

          <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
            Suivant
          </FormNextButton>
        </div>
      </form>
    </div>
  );
}
