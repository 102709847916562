import React from 'react';
import { ChangeHandler, FieldErrors, UseFormRegisterReturn } from 'react-hook-form';
import { getIn } from 'utils';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-number.scss';
import classNames from 'classnames';
import InfoBulle from '../../InfoBulle/InfoBulle';

interface InputProps extends UseFormRegisterReturn {
  className?: string;
  tooltip?: string;
  label: string;
  errors: FieldErrors<any>;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  min?: number | string;
  max?: number | string;
  step?: number;
}

export const InputNumber = React.forwardRef<HTMLInputElement, InputProps>(
  ({ min, max, step, label, errors, className, tooltip, ...field }: InputProps, ref) => {
    const error = getIn(field.name, errors);
    return (
      <div className={classNames('inputNumber', className)}>
        <label className={classNames('inputNumber__label', { '-error': error })}>
          {label}
          {tooltip && (
            <InfoBulle
              className={'inputNumber__tooltip'}
              name={`description-tooltip-${label}`}
              key={`description-tooltip`}
              text={tooltip}
            />
          )}
        </label>
        <input
          className={classNames('inputNumber__field', className, { '-error': error })}
          {...field}
          type="number"
          ref={ref}
          min={min}
          max={max}
          step={step}
        />
        <FieldError>{error?.message}</FieldError>
      </div>
    );
  }
);
InputNumber.displayName = 'InputNumber';

export default InputNumber;
