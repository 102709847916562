import InputCheckBoxBoolean, {
  InputCheckBoxBooleanProps
} from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import React, { ReactElement } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';

type FormConsentProps = {
  errors: DeepMap<any, FieldError>;
  control: Control<any>;
  contenuConsentement: string;
} & InputCheckBoxBooleanProps;

const defaultValues = {
  contenu:
    'Afin de vous fournir le contenu demandé, nous devons stocker et traiter vos données personnelles. Si vous nous autorisez à stocker vos données personnelles à cette fin, cochez la case ci-dessous.',
  coche: "J'accepte le stockage et le traitement de mes données personnelles."
};

const FormConsent = ({ contenuConsentement, label, ...inputProps }: FormConsentProps): ReactElement => (
  <div style={{ maxWidth: 700 }}>
    <p dangerouslySetInnerHTML={{ __html: contenuConsentement || defaultValues.contenu }}></p>
    <InputCheckBoxBoolean
      errorClassName={'errorWithoutFloat'}
      label={label || defaultValues.coche}
      {...inputProps}
      onChange={e => e}
    />
  </div>
);
export default FormConsent;
