import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormHabitationInfoAdresse from '../forms/HABITATION/FormHabitationInfoAdresse.component';
import FormHabitationCaracteristiques1 from '../forms/HABITATION/FormHabitationCaracteristiques1.component';
import FormHabitationCaracteristiques2 from '../forms/HABITATION/FormHabitationCaracteristiques2.component';
import FormHabitationCaracteristiques3 from '../forms/HABITATION/FormHabitationCaracteristiques3.component';
import FormHabitationAntecedentsAssurance from '../forms/HABITATION/FormHabitationAntecedentsAssurance.component';
import FormHabitationCoordonnees from '../forms/HABITATION/FormHabitationCoordonnees.component';
import { fetchAssureurOptions } from 'fetches/options.fetch';
import parseSearch from 'utils/parseSearch';
import FormHabitationChoix from '../forms/HABITATION/FormHabitationChoix.component';
import FormHabitationInfo from '../forms/HABITATION/FormHabitationInfo.component';
import FormHabitationInfoComplementaire from '../forms/HABITATION/FormHabitationInfoComplementaire.component';

const leftPaneDescriptions: StepDescriptionList = {
  Q1: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q2: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q3: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q4: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q5: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q6: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q7: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q8: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q9: {
    title: 'Pourquoi cette question ?',
    description: "Ces données nous permettront de vous envoyer une proposition d'assurance."
  }
};

const id = parseSearch(window.location.search, 'id');
const projetHabitationConfig: FormConfigWithUrl = {
  endpoint: 'projetHabitation',
  prefetch: [fetchAssureurOptions(id)],
  formSteps: [
    {
      component: FormHabitationChoix,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q1} />
    },
    {
      component: FormHabitationInfo,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q2} />
    },
    {
      component: FormHabitationInfoComplementaire,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q3} />
    },
    {
      component: FormHabitationInfoAdresse,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />
    },
    {
      component: FormHabitationCaracteristiques1,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />
    },
    {
      component: FormHabitationCaracteristiques2,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q6} />
    },
    {
      component: FormHabitationCaracteristiques3,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q7} />
    },
    {
      component: FormHabitationAntecedentsAssurance,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q8} />
    },
    {
      component: FormHabitationCoordonnees,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q9} />,
      data: { showCaptcha: true, askForConsent: true }
    }
  ]
};

export default projetHabitationConfig;
