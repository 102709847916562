import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputText from 'components/Input/InputText/InputText.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { CIVILITES_OPTIONS } from 'consts/consts';
import InputRadio from 'components/Input/InputRadio/InputRadio.component';

type FormValues = {
  interlocuteur: {
    civilite: string | null;
    nom: string;
    prenom: string;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  interlocuteur: Yup.object({
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nom: Yup.string().required().max(255),
    prenom: Yup.string().required().max(255)
  }).defined()
}).defined();
const defaultState: FormValues = {
  interlocuteur: {
    nom: '',
    prenom: '',
    civilite: null
  }
};

export default function FormContactTnsIdentite(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre identité</h2>
      <InputRadio
        label="Civilité"
        {...register('interlocuteur.civilite')}
        errors={errors}
        options={CIVILITES_OPTIONS}
      />
      <InputText label="Nom" {...register('interlocuteur.nom')} errors={errors} preventNumber={true} />
      <InputText label="Prénom" {...register('interlocuteur.prenom')} errors={errors} preventNumber={true} />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
