import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from 'utils';
import { merge } from 'lodash';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputRadio from 'components/Input/InputRadio/InputRadio.component';

type FormValues = {
  besoin: {
    interventionRecente: string | undefined;
    maladieChronique: string | undefined;
    visitesMedicalesMultiples: string | undefined;
    finsProfessionnels: string | undefined;
    resiliation: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    interventionRecente: Yup.string().required(),
    maladieChronique: Yup.string().required(),
    visitesMedicalesMultiples: Yup.string().required(),
    finsProfessionnels: Yup.string().required(),
    resiliation: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    interventionRecente: undefined,
    maladieChronique: undefined,
    visitesMedicalesMultiples: undefined,
    finsProfessionnels: undefined,
    resiliation: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => {
    return item;
  });
}

export default function FormAnimalComplement(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>{"Compléments d'informations concernant votre animal"}</h2>

      <InputRadio
        label="Votre animal a-t-il subi une intervention chirurgicale au cours des 6 derniers mois (autre qu'une chirurgie de convenance  telle que la stérilisation) ?"
        {...register('besoin.interventionRecente')}
        errors={errors}
        options={OUI_NON_OPTIONS}
      />
      <InputRadio
        label="Votre animal est-il atteint d'une maladie chronique ou récidivante (au moins 3 fois la même maladie  au cours de la dernière année) ?"
        {...register('besoin.maladieChronique')}
        errors={errors}
        options={OUI_NON_OPTIONS}
      />

      <InputRadio
        label="Votre animal a-t-il consulté un vétérinaire  plus de deux fois au cours des 3 derniers mois ou est il sous traitement (en dehors des vaccinations préventives) ?"
        {...register('besoin.visitesMedicalesMultiples')}
        errors={errors}
        options={OUI_NON_OPTIONS}
      />

      <InputRadio
        label="Votre animal est il utilisé à des fins professionnelles ?"
        {...register('besoin.finsProfessionnels')}
        errors={errors}
        options={OUI_NON_OPTIONS}
      />

      <InputRadio
        label="Avez vous fait fait l'objet d'une résiliation par un assureur durant les 36 derniers mois ?"
        {...register('besoin.resiliation')}
        errors={errors}
        options={OUI_NON_OPTIONS}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
