import * as ibantools from 'ibantools';
import React, { ReactElement } from 'react';
import Yup from '../../utils/Yup';
import InputCheckBoxBoolean from '../Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputText from '../Input/InputText/InputText.component';

const isIBANValid = (value: string | undefined) => {
  return ibantools.isValidIBAN(value ?? '');
};
const isBICValid = (value: string | undefined) => {
  return ibantools.isValidBIC(value ?? '');
};

export type RibValues = {
  sameRib: boolean;
  rib: {
    beneficiaire: string;
    iban: string;
    bic: string;
  };
  ribVersement: {
    beneficiaire: string;
    iban: string;
    bic: string;
  };
};

export const RIB_DEFAULT_STATE: RibValues = {
  sameRib: false,
  rib: {
    beneficiaire: '',
    iban: '',
    bic: ''
  },
  ribVersement: {
    beneficiaire: '',
    iban: '',
    bic: ''
  }
};

export const RIB_VALIDATION_SCHEMA = {
  sameRib: Yup.boolean().notRequired(),
  rib: Yup.object({
    beneficiaire: Yup.string().required(),
    iban: Yup.string().test('iban', "La valeur de l'IBAN n'est pas valide", isIBANValid).required(),
    bic: Yup.string().test('bic', "La valeur de du BIC n'est pas valide", isBICValid).required()
  }),
  ribVersement: Yup.object({
    beneficiaire: Yup.string().required(),
    iban: Yup.string().test('iban', "La valeur de l'IBAN n'est pas valide", isIBANValid).required(),
    bic: Yup.string().test('bic', "La valeur de du BIC n'est pas valide", isBICValid).required()
  })
};

type PropsValue = {
  register: any;
  errors: any;
  control: any;
  setValue: any;
  getValues: any;
};

export default function InfoRib({ register, errors, control, setValue, getValues }: PropsValue): ReactElement {
  const onChange = () => {
    const values = getValues();
    if (values.sameRib) setValue('ribVersement', values.rib);
  };

  return (
    <>
      <h2>
        Coordonnées bancaires
        <br />
        <small className="form-font-regular label-pj">* Champs obligatoire</small>
      </h2>
      <div>
        <h3>Compte de prélèvement des cotisations</h3>
        <InputText
          label={`Bénéficiaire *`}
          required
          {...register('rib.beneficiaire')}
          errors={errors}
          onBlur={onChange}
        />
        <InputText label={`IBAN *`} required {...register('rib.iban')} errors={errors} onBlur={onChange} />
        <InputText label={`BIC *`} required {...register('rib.bic')} errors={errors} onBlur={onChange} />
      </div>
      <div>
        <h3>Compte de versements des remboursements</h3>
        <InputCheckBoxBoolean
          label={'Utiliser le même compte que pour les prélèvements.'}
          {...register('sameRib')}
          onChange={onChange}
          control={control}
          errors={errors}
        />
        {!getValues().sameRib && (
          <>
            <InputText label={`Bénéficiaire *`} {...register('ribVersement.beneficiaire')} errors={errors} />
            <InputText label={`IBAN *`} required {...register('ribVersement.iban')} errors={errors} />
            <InputText label={`BIC *`} required {...register('ribVersement.bic')} errors={errors} />
          </>
        )}
      </div>
    </>
  );
}
