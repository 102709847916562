import FormDecouverteDescription from 'forms/DECOUVERTE_PROSPECT/FormDecouverteDescription.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormDecouverteEntrepriseEntreprise from '../forms/DECOUVERTE_PROSPECT/FormDecouverteEntrepriseEntreprise.component';
import FormRisqueActivitePro from '../forms/DECOUVERTE_PROSPECT/FormRisqueActivitePro.component';
import FormRisqueSalarie from '../forms/DECOUVERTE_PROSPECT/FormRisqueSalarie.component';
import FormActiviteEntreprise from '../forms/PROJET_MANUEL/FormActiviteEntreprise.component';
import FormComplementEntreprise from '../forms/PROJET_MANUEL/FormComplementEntreprise.component';
import FormIdentiteInterlocuteur from '../forms/PROJET_MANUEL/FormIdentiteInterlocuteur.component';
import FormInterlocuteurCoordonne from '../forms/PROJET_MANUEL/FormInterlocuteurCoordonne.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_ENTREPRISE: {
    title: 'Pourquoi ces questions ?',
    description: 'Ces informations permettent de mieux vous connaitre.'
  },
  STEP_COMPLEMENT_ENTREPRISE: {
    title: 'Pourquoi ces questions ?',
    description: 'Ces informations permettent de mieux vous connaitre.'
  },
  STEP_ACTIVITE: {
    title: 'Pourquoi ces questions ?',
    description:
      "Ces informations permettent de mieux vous connaitre et rentre en compte dans le calcul du tarif de plusieurs produits d'assurance."
  },
  EMPTY_STEP: {
    title: '',
    description: ''
  },
  STEP_RISQUE: {
    title: "Votre équipement actuel de contrats d'assurance",
    description:
      "En complétant la liste de vos contrats d'assurance actuels, vous nous permettez de vérifier que ces derniers sont toujours en phase avec vos besoins et votre situation. Par la même, nous nous assurerons qu'ils sont optimums avec les produits du marché."
  }
};

const decouverteEntreprise: FormConfigWithUrl = {
  endpoint: 'decouverte',
  formSteps: [
    {
      component: FormDecouverteEntrepriseEntreprise,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE} />
    },
    {
      component: FormComplementEntreprise,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COMPLEMENT_ENTREPRISE} />
    },
    {
      component: FormActiviteEntreprise,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ACTIVITE} />
    },
    {
      component: FormIdentiteInterlocuteur,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.EMPTY_STEP} />
    },
    {
      component: FormRisqueActivitePro,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
    },
    {
      component: FormRisqueSalarie,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
    },
    {
      component: FormInterlocuteurCoordonne,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.EMPTY_STEP} />
    },
    {
      component: FormDecouverteDescription,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.EMPTY_STEP} />,
      data: { showCaptcha: true }
    }
  ]
};

export default decouverteEntreprise;
