import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import Yup from '../../utils/Yup';
import './FormResiliationDemande.scss';

interface FormValues {}

const defaultState: FormValues = {};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({}).defined();

export default function FormResiliationDemande(props: FormProps<FormValues>): ReactElement {
  const {
    handleSubmit,
    formState: {}
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });
  const handleNextClick = async (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formResiliationDemande">
      <p className="title">Résiliation de votre contrat prévoyance</p>
      <p className="intro">
        Conformément à la règlementation et dans le cadre de la résiliation par voie électronique,
        <br />
        vous pouvez désormais demander la résiliation de votre contrat prévoyance via notre
        <br />
        formulaire en ligne.
        <br />
        Cette demande sera traitée sous réserve des conditions contractuelles et légales applicables
        <br />
        à votre contrat.
        <br />
      </p>
      <p className="intro">
        Si vous souhaitez être accompagné dans votre démarche ou connaître les modalités
        <br />
        d’évolution de votre contrat , nos conseillers se tiennent à votre disposition par email à<br />
        l’adresse <a href="mailto:contact@tutelaire.fr">contact@tutelaire.fr</a>, via notre formulaire de contact ou par
        téléphone du lundi au
        <br />
        vendredi de 9h00 à 12h00 et de 13h30 à 17h00 au numéro suivant : 0 969 398 399*.
      </p>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Faire une demande de résiliation
      </FormNextButton>
      <p className="formResiliationResiliationFooterCoord">
        Tutélaire, mutuelle soumise aux dispositions du livre II du code de la mutualité
        <br />
        SIREN 775 682 164 – Siège social 157 avenue de France 75013 Paris
        <br />
        <br />* (appel non surtaxé + coût d’un appel local)
      </p>
    </form>
  );
}
