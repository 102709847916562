import React, { useEffect, useRef, useState } from 'react';
import { format, isValid } from 'date-fns';
import { useController, Control, FieldValues, UseControllerProps } from 'react-hook-form';
import classNames from 'classnames';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './InputDate.scss';
import { getIn } from 'utils';

type iInputDate<T extends FieldValues> = UseControllerProps<T> & {
  name: string;
  label?: React.ReactNode;
  control: Control<T>;
  fullWidth?: boolean;
};

function InputDate<T extends FieldValues>({ name, label, control, fullWidth }: iInputDate<T>): JSX.Element {
  const [focus, setFocus] = useState(false);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isDirty },
    formState: { errors, isSubmitted }
  } = useController({
    name,
    control
  });

  const error = getIn(name, errors);
  const errorMessage = (isDirty || isSubmitted) && error && error.message;

  const [dayState, setDayState] = useState('');
  const [monthState, setMonthState] = useState('');
  const [yearState, setYearState] = useState('');
  const day = useRef('');
  const month = useRef('');
  const year = useRef('');

  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const isDirtyInput = useRef(false);

  let sto: any = null;

  useEffect(() => {
    function select(e: FocusEvent) {
      (e.target as HTMLInputElement).select();
    }

    monthRef.current?.addEventListener('focus', select);
    yearRef.current?.addEventListener('focus', select);

    return () => {
      monthRef.current?.removeEventListener('focus', select);
      yearRef.current?.removeEventListener('focus', select);
    };
  }, []);

  function convertDate(y: string, m: string, d: string): Date | undefined {
    const yint = parseInt(y, 10);
    const mint = parseInt(m, 10);
    const dint = parseInt(d, 10);

    if (Number.isNaN(yint) || Number.isNaN(mint) || Number.isNaN(dint)) {
      return undefined;
    }

    const dt = new Date(yint, mint - 1, dint);

    return dt;
  }

  function handleChange(blur?: boolean) {
    if (!onChange) return;

    const date = convertDate(year.current, month.current, day.current);

    if (!isValid(date) && !isDirtyInput.current && !blur) return;

    if (year.current.length === 4 && month.current.length === 2 && day.current.length === 2) {
      onChange(date);
    }

    if (!isDirtyInput.current) isDirtyInput.current = true;

    if (blur) {
      onChange(date);
    }
  }

  function handleFocus() {
    setFocus(true);
    clearTimeout(sto);
  }

  function handleBlur() {
    handleChange();
    sto = setTimeout(async () => {
      setFocus(false);
      handleChange(true);
      if (onBlur) onBlur();
    });
  }

  useEffect(() => {
    if (value && isValid(value)) {
      day.current = format(value, 'dd');
      month.current = format(value, 'MM');
      year.current = format(value, 'yyyy');
      setDayState(day.current);
      setMonthState(month.current);
      setYearState(year.current);
    }
  }, [value]);

  return (
    <div className={classNames('InputDate', { '-fullWidth': fullWidth })} ref={ref}>
      {label && <span className={classNames('InputDate__label', { '-error': errorMessage })}>{label}</span>}
      <span className={classNames('InputDate__fieldGroup', { '-error': errorMessage })} data-focus={focus}>
        <input
          name={`${name}-day`}
          type="text"
          inputMode="numeric"
          placeholder="JJ"
          maxLength={2}
          value={dayState}
          onChange={e => {
            const val = e.target.value.replace(/\D/g, '');
            day.current = val;
            handleChange();
            setDayState(val);
            if (val.length === 2) monthRef?.current?.focus();
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="InputDate__day"
        />

        <span className="InputDate__sep">/</span>

        <input
          name={`${name}-month`}
          type="text"
          inputMode="numeric"
          placeholder="MM"
          maxLength={2}
          value={monthState}
          onChange={e => {
            const val = e.target.value.replace(/\D/g, '');
            month.current = val;
            handleChange();
            setMonthState(val);
            if (val.length === 2) yearRef?.current?.focus();
          }}
          ref={monthRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="InputDate__month"
        />

        <span className="InputDate__sep">/</span>

        <input
          name={`${name}-yeah`}
          type="text"
          inputMode="numeric"
          placeholder="AAAA"
          maxLength={4}
          value={yearState}
          onChange={e => {
            const val = e.target.value.replace(/\D/g, '');
            year.current = val;
            handleChange();
            setYearState(val);
          }}
          ref={yearRef}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="InputDate__year"
        />
      </span>
      <FieldError>{errorMessage}</FieldError>
    </div>
  );
}

InputDate.defaultProps = {
  label: undefined,
  required: undefined,
  maxDate: undefined,
  minDate: undefined,
  fullWidth: undefined
};

export default InputDate;
