import React, { CSSProperties, ReactNode } from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { FieldError } from '../parts/FieldError/FieldError.component';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './input-radio-button-inline.scss';
import classNames from 'classnames';
import { getIn } from 'utils';
import Loader from 'react-loader-spinner';

type InputRadioButtonInlineProps = {
  name: string;
  options: Option[];
  className?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  labelField?: ReactNode;
  column?: boolean | 1 | 2 | 3 | 4;
  noCenterLabel?: boolean;
  fullWidthLabel?: boolean;
  labelStyle?: CSSProperties;
  errors?: FieldErrors<any>;
  disabled?: boolean;
};

interface Option<T = string> {
  label: string | (() => any);
  value: T;
}

export const InputRadioButtonInline = React.forwardRef<HTMLInputElement, InputRadioButtonInlineProps>(
  (
    {
      options,
      className,
      labelField,
      column,
      noCenterLabel,
      labelStyle,
      onChange,
      onBlur,
      fullWidthLabel,
      name,
      errors,
      disabled
    }: InputRadioButtonInlineProps,
    ref
  ) => {
    const error = errors && getIn(name, errors);
    const errorMessage = error && error.message;

    return (
      <div style={labelStyle} className={classNames(className, 'InputRadioInline')}>
        <div>
          <div className={classNames('InputRadioInline__label', { '-error': !!errorMessage })}>{labelField}</div>
        </div>
        <div className={classNames('InputRadioInline__container', { '-column': column })}>
          {options.length ? (
            options.map(option => (
              <label
                key={option.value}
                tabIndex={0}
                className={classNames(
                  'InputRadioInline__group',
                  { [`-column-${column === true ? 2 : column}`]: column },
                  { '-error': !!errorMessage },
                  { '-noCenterLabel': noCenterLabel },
                  { '-fullWidthLabel': fullWidthLabel }
                )}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    e.preventDefault();
                    e.currentTarget.click();
                  }
                }}
                title={typeof option.label === 'function' ? option.label() : option.label}
              >
                <input
                  type="radio"
                  name={name}
                  tabIndex={-1}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={option.value}
                  ref={ref}
                  className="InputRadioInline__input"
                  disabled={disabled}
                />
                <span
                  className={classNames(
                    'InputRadioInline__button',
                    { [`-column-${column === true ? 2 : column}`]: column },
                    { '-error': !!errorMessage },
                    { '-noCenterLabel': noCenterLabel },
                    { '-fullWidthLabel': fullWidthLabel }
                  )}
                >
                  {typeof option.label === 'function' ? option.label() : option.label}
                  <FontAwesomeIcon icon={faCheck} className="InputRadioInline__checkmark" />
                </span>
              </label>
            ))
          ) : (
            <div className="spinner-container" style={{ display: 'flex', color: '#AAA' }}>
              Chargement...
              <Loader type="Rings" color="#AAA" height={25} width={25} />
            </div>
          )}
        </div>
        <FieldError>{errorMessage}</FieldError>
      </div>
    );
  }
);

InputRadioButtonInline.displayName = 'InputRadioButtonInline';

export default InputRadioButtonInline;
