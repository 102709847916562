import classNames from 'classnames';
import React, { ReactNode } from 'react';

type FormFieldProps = {
  className?: string;
  size?: 'full' | 'three-quarter' | 'half' | 'one-third' | 'quarter';
  children: ReactNode;
};

// eslint-disable-next-line
export function FormField({ className, size = 'full', children }: FormFieldProps) {
  return (
    <div
      className={classNames(
        'form-grid__field',
        {
          'form-grid__field--full': size === 'full',
          'form-grid__field--three-quarter': size === 'three-quarter',
          'form-grid__field--half': size === 'half',
          'form-grid__field--one-third': size === 'one-third',
          'form-grid__field--quarter': size === 'quarter'
        },
        className
      )}
    >
      {children}
    </div>
  );
}
