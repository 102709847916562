import { yupResolver } from '@hookform/resolvers/yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { useFetch } from 'hooks/useFetch.hook';
import { fetchStatusProTypeOptions } from 'fetches/options.fetch';

type Key = 'principal' | 'secondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};

type Emprunteur = {
  emprunteur: {
    porteChargesLourdes: string | undefined;
    travailEnHauteur: string | undefined;
    deplacementsFrequents: string | undefined;
  };
  personne: {
    fumeurRegulier: string | undefined;
    categorieProfessionnelle: string | undefined;
  };
};

export type FormValues<K extends Key> = PartialRecord<K, Emprunteur>;

export default function FormEmprunteurAssureurs({
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken,
  data = { type: 'principal' }
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const statusProType = useFetch(fetchStatusProTypeOptions);

  const defaultState: FormValues<typeof type> = {
    [type]: {
      emprunteur: {
        porteChargesLourdes: undefined,
        travailEnHauteur: undefined,
        deplacementsFrequents: undefined
      },
      personne: {
        fumeurRegulier: undefined,
        categorieProfessionnelle: undefined
      }
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues<typeof type & any>> = Yup.object({
    [type]: Yup.object({
      emprunteur: Yup.object({
        porteChargesLourdes: Yup.string().typeError('Champ obligatoire'),
        travailEnHauteur: Yup.string().typeError('Champ obligatoire'),
        deplacementsFrequents: Yup.string().typeError('Champ obligatoire')
      }),
      personne: Yup.object({
        fumeurRegulier: Yup.string().typeError('Champ obligatoire'),
        categorieProfessionnelle: Yup.string().required()
      })
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Informations de l&apos;emprunteur <span>{type}</span>
      </h1>

      <h2 className="subtitle">Informations complémentaires</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputRadioButtonInline
          labelField="L'emprunteur porte t'il des charges lourdes ?"
          {...register(`${type}.emprunteur.porteChargesLourdes`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputRadioButtonInline
          labelField="L'emprunteur travaille t'il en hauteur ?"
          {...register(`${type}.emprunteur.travailEnHauteur`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputRadioButtonInline
          labelField="L'emprunteur effectue t'il de nombreux déplacements ?"
          {...register(`${type}.emprunteur.deplacementsFrequents`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputRadioButtonInline
          labelField="L'emprunteur fume t'il ?"
          {...register(`${type}.personne.fumeurRegulier`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <InputSelect
          label="Statut professionnel"
          name={`${type}.personne.categorieProfessionnelle`}
          options={statusProType || []}
          control={control}
          errors={errors}
        />

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
