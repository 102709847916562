import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputText from 'components/Input/InputText/InputText.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import InputRadio from 'components/Input/InputRadio/InputRadio.component';
import { CIVILITES_OPTIONS } from 'consts/consts';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { useFetch } from 'hooks/useFetch.hook';
import { fetchFonctionsOptions } from 'fetches/options.fetch';

type FormValues = {
  interlocuteur: {
    civilite: string | null;
    nom: string;
    prenom: string;
    fonction: string;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  interlocuteur: Yup.object({
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nom: Yup.string().required().max(255),
    prenom: Yup.string().required().max(255),
    fonction: Yup.string().required()
  }).defined()
}).defined();

const defaultState: FormValues = {
  interlocuteur: {
    civilite: null,
    nom: '',
    prenom: '',
    fonction: ''
  }
};

export default function FormContactEntrepriseIdentite(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const fonctions = useFetch(fetchFonctionsOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>{"Identité de l'interlocuteur"}</h2>
      <InputRadio
        label="Civilité"
        {...register('interlocuteur.civilite')}
        errors={errors}
        options={CIVILITES_OPTIONS}
      />
      <div className="layout-field-container">
        <InputText label="Nom" {...register('interlocuteur.nom')} errors={errors} />
        <InputText label="Prénom" {...register('interlocuteur.prenom')} errors={errors} />
      </div>
      <InputSelect
        label="Fonction"
        name="interlocuteur.fonction"
        control={control}
        errors={errors}
        options={fonctions || []}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
