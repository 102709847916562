type PrescripteurResponse = {
  isValid: boolean;
  numeroPrescripteur: string;
};

export const useCheckEmailPrescripteur = (email: string, idFormulaire?: string): Promise<PrescripteurResponse> => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/checkEmail`, {
      method: 'POST',
      body: JSON.stringify({ email, idFormulaire }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        resolve(res);
      })
      .catch(err => reject(err));
  });
};
