import React, { ReactElement } from 'react';

export default function Separator(): ReactElement {
  return (
    <hr
      style={{
        border: 'none',
        borderTop: '1px solid #E2E5EA',
        margin: '32px 0'
      }}
    />
  );
}
