import React, { FunctionComponent } from 'react';

interface ComponentProps {
  isReadyToRender: any;
  children: any;
}

const LoaderWrapper: FunctionComponent<ComponentProps> = props => {
  const { isReadyToRender, children } = props;
  const renderChild = () => {
    return typeof children === 'function' ? children() : children;
  };
  return <>{isReadyToRender ? renderChild() : null}</>;
};

export default LoaderWrapper;
