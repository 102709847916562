import { faSquare as faRegularSquare } from '@fortawesome/free-regular-svg-icons';
import { faSquare as faSolidSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { ErrorLabel } from '../parts/ErrorLabel/ErrorLabel.component';
import './input-checkbox.scss';
import InfoBulle from '../../InfoBulle/InfoBulle';

export interface InputCheckBoxBooleanProps {
  control: Control<any>;
  tooltip?: string;
  label: string;
  errors: FieldErrors<any>;
  className?: string;
  errorClassName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}

export default function InputCheckBoxBoolean({
  tooltip,
  errors,
  label,
  control,
  className,
  errorClassName,
  onChange,
  name
}: InputCheckBoxBooleanProps): ReactElement {
  const error = getIn(name, errors);

  return (
    <div
      className={classNames('input-checkbox-boolean-root', className, {
        'input-error': error
      })}
    >
      <label
        className="input-checkbox-boolean-label"
        tabIndex={0}
        onKeyDown={e => {
          if (e.code === 'Enter') {
            e.preventDefault();
            e.currentTarget.click();
          }
        }}
      >
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <div className="input-checkbox-boolean-input-wrapper">
                <input
                  tabIndex={-1}
                  type="checkbox"
                  {...field}
                  checked={field.value === true}
                  onChange={e => {
                    field.onChange(e.target.checked);
                    onChange && onChange(e);
                  }}
                />
                <FontAwesomeIcon className="pin" icon={field.value === true ? faSolidSquare : faRegularSquare} />
              </div>
            );
          }}
          control={control}
        />
        <span>
          <span dangerouslySetInnerHTML={{ __html: label }} />
          {tooltip && (
            <InfoBulle
              className={'inputCheckBoxBoolean__tooltip'}
              name={`description-tooltip-${label}`}
              key={`description-tooltip`}
              text={tooltip}
            />
          )}
        </span>
      </label>

      <div>
        <ErrorLabel className={errorClassName}>{error?.message}</ErrorLabel>
      </div>
    </div>
  );
}
