import { ObfuscatedLink } from 'components/ObfuscatedLink/ObfuscatedLink.component';
import React, { ReactElement } from 'react';
import './left-pane.scss';
import TitleFormWithLogo from '../Title/TitleFormWithLogo';

interface LeftPaneProps {
  title: string;
  subTitle: string;
  urlLogo: string;
  urlFicheLegale: string;
  hideFicheLegale: string;
  description?: any;
  siteCourtier?: string;
  nomCourtier?: string;
  labelButtonBackToSite: string;
}

export function LeftPane(props: LeftPaneProps): ReactElement {
  return (
    <div id="left-pane">
      {props.siteCourtier && (
        <div className={'link-courtier'}>
          <a href={props.siteCourtier} target="_blank" rel="noreferrer">
            {`< ${props.labelButtonBackToSite ?? 'Retour vers le site'}`}
          </a>
        </div>
      )}
      <TitleFormWithLogo src={props.urlLogo} title={props.title} />
      {props.urlFicheLegale && props.hideFicheLegale === 'false' && (
        <div className="fiche-download">
          <ObfuscatedLink href={props.urlFicheLegale}>Informations légales du cabinet</ObfuscatedLink>
        </div>
      )}
      <div
        className="left-pane-subtitle"
        dangerouslySetInnerHTML={{
          __html: props.subTitle
        }}
      />
      {props.description}
    </div>
  );
}
