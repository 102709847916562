import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import Yup from '../../utils/Yup';
import FormMandatSepaFooter from './FormMandatSepaFooter.component';
import './FormMandatSepaCoordonneesBancaires.scss';

interface FormValues {}

const defaultState: FormValues = {};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({}).defined();

export default function FormMandatSepaVosCoordonneesBancaires(props: FormProps<FormValues>): ReactElement {
  const {
    handleSubmit,
    formState: {}
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });
  const handleNextClick = async (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formMandatSepaVosCoordonneesBancaires">
      <p className="title">Mettez à jour vos coordonnées bancaires !</p>
      <p className="intro">
        Vous souhaitez nous transmettre vos nouvelles coordonnées bancaires pour le prélèvement de vos cotisations ?
        <br />
        En quelques clics, c’est facile !
      </p>
      <p>
        Cette opération s’effectue entièrement en ligne. Elle est réservée aux adhérents Tutélaire munis d’une adresse
        mail et d’un téléphone portable. Dans le cas contraire, vous pouvez télécharger les documents nécessaires dans
        votre “Espace Personnel Adhérent”, les imprimer et nous les renvoyer comme indiqué dans le formulaire, dans la
        rubrique “Mes services”.
      </p>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>

      <FormMandatSepaFooter />
    </form>
  );
}
