import { useEffect, useRef, useState } from 'react';
import { cancelable, CancelablePromiseType } from 'cancelable-promise';

/**
 * Fetch option when the value the provided name change
 *
 */

export function useFetchOptionsOnFieldChange<O, V = string>(
  watch: (name: string) => V,
  name: string,
  fetchOptions: (value: V) => Promise<O[]>,
  shouldFetch?: (value: V) => boolean
): O[] {
  const value = watch(name);
  const pendingPromiseRef = useRef<CancelablePromiseType<void> | null>(null);
  const [state, setState] = useState<{
    lastValueFetched: V | undefined;
    options: O[];
  }>({
    lastValueFetched: undefined,
    options: []
  });

  useEffect(() => {
    if (value !== undefined && (!state.lastValueFetched === undefined || state.lastValueFetched !== value)) {
      if (pendingPromiseRef.current) {
        // Cancel pending promises so we don't end up with incoherent options
        pendingPromiseRef.current.cancel();
        pendingPromiseRef.current = null;
      }

      if (shouldFetch && !shouldFetch(value)) {
        return setState({
          lastValueFetched: undefined,
          options: []
        });
      }

      pendingPromiseRef.current = cancelable(fetchOptions(value))
        .then(options => {
          setState({
            lastValueFetched: value,
            options
          });
        })
        .catch(err => {
          console.error(JSON.stringify(err));
          setState({
            lastValueFetched: undefined,
            options: []
          });
        });
    }
  }, [value]);

  return state.options;
}
