import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from '../../../types';
import Yup from 'utils/Yup';
import { NIVEAUX_SANTE_OPTIONS } from 'consts/consts';
import { merge } from 'lodash';
import { assignValuesFromSource } from 'utils';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import AxeField from 'components/Axe/AxeField';

type FormValues = {
  niveauGP: {
    soinCourants: string | undefined;
    hospitalisation: string | undefined;
    dentaire: string | undefined;
    optique: string | undefined;
    audition: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  niveauGP: Yup.object({
    soinCourants: Yup.string()
      .nullable()
      .required()
      .oneOf(
        NIVEAUX_SANTE_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    hospitalisation: Yup.string()
      .nullable()
      .required()
      .oneOf(
        NIVEAUX_SANTE_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    dentaire: Yup.string()
      .nullable()
      .required()
      .oneOf(
        NIVEAUX_SANTE_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    optique: Yup.string()
      .nullable()
      .required()
      .oneOf(
        NIVEAUX_SANTE_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    audition: Yup.string()
      .nullable()
      .required()
      .oneOf(
        NIVEAUX_SANTE_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      )
  })
}).defined();

const defaultState: FormValues = {
  niveauGP: {
    soinCourants: undefined,
    hospitalisation: undefined,
    dentaire: undefined,
    optique: undefined,
    audition: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => {
    return item;
  });
}

export default function SanteParticulierAxes(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Quel niveau de remboursement souhaitez vous pour :</h2>
      <AxeField
        register={register}
        errors={errors}
        control={control}
        niveaux={NIVEAUX_SANTE_OPTIONS}
        title={'Les soins courants'}
        name={'niveauGP.soinCourants'}
        description={
          "Minimal: Niveau de garantie jusqu'à 150% BR<br>Elémentaire: Niveau de garantie jusqu'à 250% BR<br>Confort: Niveau de garantie jusqu'à 350% BR<br>Optimal: Niveau de garantie supérieur à 350% BR"
        }
      />

      <AxeField
        register={register}
        errors={errors}
        control={control}
        niveaux={NIVEAUX_SANTE_OPTIONS}
        title={"L'hospitalisation"}
        name={'niveauGP.hospitalisation'}
        description={
          "Minimal: Niveau de garantie jusqu'à 150% BR<br>Elémentaire: Niveau de garantie jusqu'à 250% BR<br>Confort: Niveau de garantie jusqu'à 350% BR<br>Optimal: Niveau de garantie supérieur à 350% BR"
        }
      />

      <AxeField
        register={register}
        errors={errors}
        control={control}
        niveaux={NIVEAUX_SANTE_OPTIONS}
        title={'Le dentaire'}
        name={'niveauGP.dentaire'}
        description={
          "Minimal: Niveau de garantie jusqu'à 150% BR<br>Elémentaire: Niveau de garantie jusqu'à 250% BR<br>Confort: Niveau de garantie jusqu'à 350% BR<br>Optimal: Niveau de garantie supérieur à 350% BR"
        }
      />

      <AxeField
        register={register}
        errors={errors}
        control={control}
        niveaux={NIVEAUX_SANTE_OPTIONS}
        title={"L'optique"}
        name={'niveauGP.optique'}
        description={
          "Minimal: Niveau de garantie jusqu'à 100€<br>Elémentaire: Niveau de garantie jusqu'à 200€<br>Confort: Niveau de garantie jusqu'à 400€<br>Optimal: Niveau de garantie supérieur à 400€"
        }
      />

      <AxeField
        register={register}
        errors={errors}
        control={control}
        niveaux={NIVEAUX_SANTE_OPTIONS}
        title={'Les aides auditives'}
        name={'niveauGP.audition'}
        description={
          "Minimal: Niveau de garantie jusqu'à 400€<br>Elémentaire: Niveau de garantie jusqu'à 650€<br>Confort: Niveau de garantie jusqu'à 850€<br>Optimal: Niveau de garantie jusqu'à 1450€"
        }
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
