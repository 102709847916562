import { Option } from 'types';
import { CodificationResult } from './options.fetch';

export interface AxesBesoinsResult {
  codeId: number;
  domaine: string;
  codeType: string;
  code: string;
  libelle: string;
  ordreAffichage: Array<any>;
  codeInfoString: string;
}

export const AXES_MACRO_PRESTATION_SANTE: {
  HOSPITALISATION: string[];
  SOINS_COURANTS_CONSULTATIONS: string[];
  DENTAIRE_OPTIQUE_AUDITION: string[];
  MEDECINE_DOUCE: string[];
} = {
  HOSPITALISATION: ['HOSPITALISATION'],
  SOINS_COURANTS_CONSULTATIONS: ['SOINS_COURANTS_CONSULTATIONS'],
  DENTAIRE_OPTIQUE_AUDITION: ['DENTAIRE', 'OPTIQUE', 'AUDITION'],
  MEDECINE_DOUCE: ['MEDECINE_DOUCE']
};

export const AXES_MACRO_PRESTATION_EXPATRIE = { GLOBAL: ['GLOBAL'] };

export function fetchAxesBesoins(typologie: string): Promise<AxesBesoinsResult[]> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/codifications/lister/PORTEFEUILLE/AXE_BESOIN_${typologie}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(res.body);
      })
      .catch(err => reject(err));
  });
}

export function fetchAxesMacroPrestation(typologie: string, axe: string): Promise<Option[]> {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_URL_PTF_API}lyaptf/api/codifications/lister/PORTEFEUILLE/MACROPRESTATION_${typologie}_${axe}`
    )
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as CodificationResult[]).map(item => ({
            label: item.libelle,
            value: item.codeInfoString,
            meta: item
          }))
        );
        resolve(res.body);
      })
      .catch(err => reject(err));
  });
}

export function fetchAxesNiveauRetenus(typologie: string, macroPrestation: string): Promise<Option[]> {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_URL_PTF_API}lyaptf/api/codifications/lister/PORTEFEUILLE/NIVEAU_RETENU_COURTIER_${typologie}_${macroPrestation}`
    )
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as CodificationResult[]).map(item => ({
            label: item.libelle,
            value: item.codeInfoString,
            meta: item
          }))
        );
      })
      .catch(err => reject(err));
  });
}
