import classNames from 'classnames';
import React from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-text.scss';
import InfoBulle from '../../InfoBulle/InfoBulle';

export interface InputProps {
  className?: string;
  label: string;
  tooltip?: string;
  subLabel?: string;
  name: string;
  errors: FieldErrors<any>;
  preventNumber?: boolean;
  preventTelephone?: boolean;
  preventAlphatic?: boolean;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  trim?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const InputText = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      tooltip,
      label,
      subLabel,
      errors,
      className,
      disabled,
      preventAlphatic,
      preventTelephone,
      preventNumber,
      onBlur,
      trim,
      ...field
    }: InputProps,
    ref
  ) => {
    const error = getIn(field.name, errors);

    return (
      <div className={classNames('inputText', className, { '-error': error })}>
        <label className={classNames('inputText__label', className, { '-error': error })}>
          {label}
          {tooltip && (
            <InfoBulle
              className={'inputText__tooltip'}
              name={`description-tooltip-${label}`}
              key={`description-tooltip`}
              text={tooltip}
            />
          )}
        </label>

        {subLabel && (
          <div className={classNames('inputText__sublabel', className, { '-error': error })}>
            <small>{subLabel}</small>
          </div>
        )}
        <input
          className={classNames('inputText__field', className, { '-error': error })}
          disabled={!!disabled}
          ref={ref}
          {...field}
          onBlur={e => {
            if (trim) {
              const valueRef = e.target.value;
              const valueTrim = valueRef.trim();

              if (valueTrim !== valueRef) {
                e.target.value = valueTrim;
                field.onChange(e);
              }
            }
            onBlur(e);
          }}
          onKeyPress={e => {
            if (preventNumber && '01234567489+*/'.indexOf(e.key) !== -1) {
              e.preventDefault();
            }
            if (preventAlphatic && '01234567489'.indexOf(e.key) === -1) {
              e.preventDefault();
            }
            if (preventTelephone && '+-01234567489'.indexOf(e.key) === -1) {
              e.preventDefault();
            }
          }}
        />
        <FieldError>{error?.message}</FieldError>
      </div>
    );
  }
);
InputText.displayName = 'Input';

export default InputText;
