import Yup from '../../../utils/Yup';
import { isNumeroSecuriteSocialeValide, NumeroSecu } from './NumeroSecu';

const enfantCode = 'ENFANT:ROLE_RELATION_TYPE';
const conjointCode = 'CONJOINT:ROLE_RELATION_TYPE';

export type FormValuesSanteParticulier = {
  numeroSecuriteSocial: string | undefined;
  numeroSecuriteSocialBeneficiaires: Array<NumeroSecu> | undefined;

  consentement: boolean | undefined;
  stepCode: string;
};

const defaultState: FormValuesSanteParticulier = {
  numeroSecuriteSocial: '',
  numeroSecuriteSocialBeneficiaires: [],

  stepCode: 'INFOS',
  consentement: false
};

const validationSchema: Yup.SchemaOf<FormValuesSanteParticulier> = Yup.object({
  numeroSecuriteSocial: Yup.string()
    .test(
      'numeroSecuriteSocial',
      "Le format du numéro de sécurité sociale n'est pas valide",
      isNumeroSecuriteSocialeValide
    )
    .required(),
  numeroSecuriteSocialBeneficiaires: Yup.array()
    .of(
      Yup.object()
        .shape({
          numeroSecu: Yup.string()
            .test(
              'numeroSecuriteSocial',
              "Le format du numéro de sécurité sociale n'est pas valide",
              isNumeroSecuriteSocialeValide
            )
            .required(),
          nom: Yup.string().required(),
          prenom: Yup.string().required(),
          naissanceDate: Yup.string().required(),
          roleCode: Yup.string().required()
        })
        .required()
    )
    .required(),

  stepCode: Yup.string().required(),
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

const sortBeneficiaires = (a: NumeroSecu, b: NumeroSecu) => {
  if (b.roleCode === conjointCode) return 1;
  if (a.roleCode === enfantCode) return -1;
  return 0;
};

const getInitialFormValues: any = (initialFormValues: any) => {
  const state: FormValuesSanteParticulier = defaultState;

  state.numeroSecuriteSocial = initialFormValues?.numeroSecuriteSocial ?? '';
  state.numeroSecuriteSocialBeneficiaires =
    initialFormValues?.numeroSecuriteSocialBeneficiaires?.sort(sortBeneficiaires) || [];

  return state;
};

export const SanteParticulier = {
  defaultState,
  validationSchema,
  getInitialFormValues,
  conjointCode
};
