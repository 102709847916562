import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useCallback, useState } from 'react';
import { AppFailureType, DisplayFailureFunction } from 'types';
import './app-failure.scss';

type AppFailureChildrenFunction = (params: { displayFailure: DisplayFailureFunction }) => ReactElement;
type AppFailureProps = {
  title: string;
  children: React.ReactNode;
  type?: AppFailureType;
};

/**
 * Visual for errors in app
 */

export const AppFailure: FC<AppFailureProps> = ({ title, children, type = AppFailureType.ERROR }: AppFailureProps) => (
  <div data-testid="app-failure" className="app-failure layout-container layout-centered-content layout-full-screen">
    <div
      className={classNames(
        'jumbotron',
        { error: type === AppFailureType.ERROR },
        { info: type !== AppFailureType.ERROR }
      )}
    >
      <h1>{title}</h1>
      <div>{children}</div>
    </div>
  </div>
);

type AppFailureLoaderType = ({ children }: AppFailureLoaderProps) => ReactElement;

interface AppFailureLoaderProps {
  children: AppFailureChildrenFunction;
}

type DisplayState = {
  title?: string;
  content?: ReactNode;
  type?: AppFailureType;
};

/**
 * Wrapper that provides a way to display errors to its children
 */
const AppFailureLoader: AppFailureLoaderType = ({ children }: AppFailureLoaderProps) => {
  const [displayState, setDisplayState] = useState<DisplayState>({});

  const displayFailure = useCallback(
    (title, content, type = AppFailureType.ERROR) => {
      setDisplayState({ title, content, type });
    },
    [setDisplayState]
  );

  return (
    <>
      {displayState.title && (
        <AppFailure title={displayState.title} type={displayState.type}>
          {displayState.content}
        </AppFailure>
      )}
      {children({ displayFailure })}
    </>
  );
};

export default AppFailureLoader;
