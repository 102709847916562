import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Control, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { getIn } from 'utils';
import { ReactComponent as Chevron } from './chevron.svg';
import './InputDateSelectMonthDay.scss';

type iInputDateSelect<T extends FieldValues> = UseControllerProps<T> & {
  name: string;
  label?: React.ReactNode;
  control: Control<T>;
  fullWidth?: boolean;
};

function InputDateSelectMonthDay<T extends FieldValues>({
  name,
  label,
  control,
  fullWidth
}: iInputDateSelect<T>): JSX.Element {
  const {
    field: { onChange, onBlur, ref },
    fieldState: { isDirty },
    formState: { errors, isSubmitted }
  } = useController({
    name,
    control
  });

  const error = getIn(name, errors);
  const errorMessage = (isDirty || isSubmitted) && error && error.message;

  const [monthState, setMonthState] = useState('');
  const [dayState, setDayState] = useState('');

  useEffect(() => {
    const date = dayState + '/' + monthState;
    if (dayState !== '' && monthState !== '') {
      onChange(date);
    }
  }, [dayState, monthState]);

  const dayList = Array.from({ length: 31 }, (_, index) => index + 1);
  return (
    <div className={classNames('InputDateSelect', { '-fullWidth': fullWidth })} ref={ref}>
      {label && <span className={classNames('InputDateSelect__label', { '-error': errorMessage })}>{label}</span>}
      <div className={classNames('InputDateSelect__fieldGroup', { '-error': errorMessage })}>
        <div className={classNames('InputDateSelect__day', { '-error': errorMessage })}>
          <select
            name={`${name}-yeah`}
            placeholder="Jours"
            value={dayState || ''}
            onChange={e => {
              const { value } = e.target;
              setDayState(value);
            }}
            onBlur={onBlur}
            className={classNames('InputDateSelect__day__field')}
            data-placeholder={dayState === '' ? 'true' : 'false'}
          >
            <option value="" disabled>
              Jours
            </option>
            {dayList.map(y => (
              <option key={y}>{y}</option>
            ))}
          </select>
          <Chevron className="InputDateSelect__chevron" />
        </div>
        <div className={classNames('InputDateSelect__month', { '-error': errorMessage })}>
          <select
            name={`${name}-month`}
            value={monthState || ''}
            onChange={e => {
              const { value } = e.target;
              setMonthState(value);
            }}
            onBlur={onBlur}
            className={classNames('InputDateSelect__month__field')}
            data-placeholder={monthState === '' ? 'true' : 'false'}
          >
            <option value="" disabled>
              Mois
            </option>
            <option value="01">Janvier</option>
            <option value="02">Février</option>
            <option value="03">Mars</option>
            <option value="04">Avril</option>
            <option value="05">Mai</option>
            <option value="06">Juin</option>
            <option value="07">Juillet</option>
            <option value="08">Août</option>
            <option value="09">Septembre</option>
            <option value="10">Octobre</option>
            <option value="11">Novembre</option>
            <option value="12">Décembre</option>
          </select>
          <Chevron className="InputDateSelect__chevron" />
        </div>
      </div>
    </div>
  );
}

export default InputDateSelectMonthDay;
