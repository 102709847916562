import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Control, FieldErrors } from 'react-hook-form';
import { fetchNatureSinistreOptions, fetchTypeSinistreOptions } from 'fetches/options.fetch';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';

import './Sinistre.scss';
import InputDate from 'components/Input/InputDate/InputDate.component';
import { useFetch } from 'hooks/useFetch.hook';

type SinistreFieldsProps = {
  index: number;
  type: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  remove: (index: number) => void;
};

export default function Sinistre({ index, type, errors, control, remove }: SinistreFieldsProps): ReactElement {
  const natureSinistreOptions = useFetch(fetchNatureSinistreOptions);
  const typeSinistreOptions = useFetch(fetchTypeSinistreOptions);

  return (
    <div className="Sinistre" key={index}>
      <button
        className="Sinistre__remove no-default-style"
        onClick={() => {
          remove(index);
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>

      <InputDate label="Date du sinistre" name={`${type}.sinistres.${index}.survenanceDate`} control={control} />
      <InputSelect
        label="Type de sinistre"
        name={`${type}.sinistres.${index}.typeCode`}
        options={typeSinistreOptions || []}
        control={control}
        errors={errors}
      />
      <InputSelect
        label="Nature du sinistre"
        name={`${type}.sinistres.${index}.natureCode`}
        options={natureSinistreOptions || []}
        control={control}
        errors={errors}
      />

      <InputSelect
        label="Taux de responsabilité"
        name={`${type}.sinistres.${index}.responsabiliteTaux`}
        options={[
          { value: 0, label: '0%' },
          { value: 50, label: '50%' },
          { value: 100, label: '100%' }
        ]}
        control={control}
        errors={errors}
      />
    </div>
  );
}
