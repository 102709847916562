import classNames from 'classnames';
import React, { FC } from 'react';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';
import { lireFractionnementByPeriod } from '../../../utils';

interface CostRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
}

const CostRow: FC<CostRowProps> = ({ proposedQuotes, quoteMobileDisplay }) => {
  const formatMontant = (montant: number) => {
    const splitMontant = montant.toFixed(2).split('.');
    return (
      <>
        {splitMontant[0]},<small>{splitMontant[1] ?? '00'}</small> €
      </>
    );
  };

  return (
    <div className={'row-cost'}>
      <div className={'title'}>
        <h3 className={'form-font-large'}>Cotisation</h3>
      </div>
      <div className={'content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          return (
            <RoundedCard
              key={quote.numeroInterne}
              className={classNames(
                'primary',
                'card',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <b className="montant-label text-colored secondary form-font-title">
                {formatMontant(+quote.montant)}{' '}
                <small>
                  TTC{' '}
                  {lireFractionnementByPeriod(quote.fractionnementCode) !== '' &&
                    '/ ' + lireFractionnementByPeriod(quote.fractionnementCode)}
                </small>
              </b>
            </RoundedCard>
          );
        })}
      </div>
    </div>
  );
};

export default CostRow;
