import React, { FC } from 'react';
import classNames from 'classnames';
import './ButtonCard.scss';

interface ButtonProps {
  className?: string;
  onClick: (e: any) => void;
  children?: React.ReactNode;
}

// eslint-disable-next-line react/prop-types
export const ButtonCard: FC<ButtonProps> = ({ className, onClick, children }) => {
  return (
    <button type={'button'} onClick={onClick} className={classNames('button-card', className)}>
      {children}
    </button>
  );
};
