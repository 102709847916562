import { Card } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import './ConfirmationDialog.scss';
import Loader from 'react-loader-spinner';

type ConfimationDialogProps = {
  title: string;
  description: ReactElement;
  confirmationButton: string;
  isOpen: boolean;
  close: () => void;
  callback: () => void;
};

export default function ConfirmationDialog(props: ConfimationDialogProps): ReactElement {
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      {props.isOpen && (
        <>
          <div className={'confirmation-background'} onClick={props.close}></div>
          <Card className={'confirmation-card'}>
            <h2>{props.title}</h2>
            <div>{props.description}</div>
            <div className={'confirmation-card__button'}>
              <button className="cancel-button no-default-style" onClick={props.close}>
                Annuler
              </button>
              <button
                className="action-button no-default-style"
                disabled={submitting}
                onClick={() => {
                  setSubmitting(true);
                  props.callback();
                }}
              >
                {props.confirmationButton}
                {submitting && <Loader type="Rings" color="white" height={21} width={25} />}
              </button>
            </div>
          </Card>
        </>
      )}
    </>
  );
}
