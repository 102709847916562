export interface sivResult {
  immat: string;
  co2: number;
  energie: number;
  energieNGC: string;
  genreVCG: number;
  genreVCGNGC: string;
  puisFisc: number;
  carrosserieCG: string;
  marque: string;
  modele: string;
  date1erCirFr: string;
  collection: false;
  vin: string;
  boiteVitesse: string;
  puisFiscReel: number;
  nrPassagers: number;
  nbPortes: number;
  typeMine: string;
  couleur: unknown;
  poids: string;
  cylindres: number;
  sraId: string;
  sraGroup: number;
  sraCommercial: string;
}

export function fetchSIV(id: string | undefined, immatriculation: string | undefined): Promise<sivResult> {
  return fetch(`${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${id}/vehicule/${immatriculation}`).then(
    async res => {
      try {
        return await res.json();
      } catch (e) {
        throw new Error(
          "Désolé, nous n'avons pas trouvé votre véhicule. Vérifiez votre saisie ou renseignez manuellement."
        );
      }
    }
  );
}
