import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputText from 'components/Input/InputText/InputText.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import { fetchVilleOptions } from '../../fetches/options.fetch';

type FormValues = {
  besoin: {
    stationnement: {
      voie: string | undefined;
      complement: string | undefined;
      codePostal: string;
      ville: string;
    };
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    stationnement: Yup.object({
      voie: Yup.string().required(),
      complement: Yup.string(),
      codePostal: Yup.string().required(),
      ville: Yup.string().required()
    })
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    stationnement: {
      voie: undefined,
      complement: undefined,
      codePostal: '',
      ville: ''
    }
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormAutoUtilisationVehicule(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    watch,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'besoin.stationnement.codePostal', fetchVilleOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Adresse de stationnement de <span>votre véhicule</span>
      </h1>

      <InputText label="Voie de stationnement" {...register('besoin.stationnement.voie')} errors={errors} />

      <InputText label="Complément" {...register('besoin.stationnement.complement')} errors={errors} />

      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('besoin.stationnement.codePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="besoin.stationnement.ville"
          control={control}
          errors={errors}
          options={villeOptions || []}
        />
      </div>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
