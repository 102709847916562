import { AXES_MACRO_PRESTATION_EXPATRIE } from 'fetches/axes.fetch';
import FormExpatCoordonees from 'forms/EXPATRIE/FormExpatCoordonees.component';
import FormExpatDestination from 'forms/EXPATRIE/FormExpatDestination.component';
import FormExpatGarantiePart1 from 'forms/EXPATRIE/FormExpatGarantiePart1.component';
import FormExpatGarantiePart2 from 'forms/EXPATRIE/FormExpatGarantiePart2.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormExpatConjointIdentite from '../forms/EXPATRIE/FormExpatConjointIdentite.component';
import FormExpatEnfants from '../forms/EXPATRIE/FormExpatEnfants.component';
import SanteExpatVoyage from '../forms/EXPATRIE/FormExpatVoyage.component';
import FormSanteParticulierChoixAssure from '../forms/SANTE_PART/FormSanteParticulierChoixAssure.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_1: {
    title: 'Pourquoi cette question ?',
    description:
      'La durée du séjour est prise en compte dans le calcul du tarif de votre complémentaire santé expatriation.'
  },
  STEP_2: {
    title: 'Pourquoi cette question ?',
    description:
      'Le pays de destination principale est pris en compte dans le calcul du tarif de votre complémentaire santé expatriation. '
  },
  STEP_3: {
    title: 'Pourquoi cette question ?',
    description:
      'Le nombre de bénéficiaires du contrat est pris en compte dans le calcul du tarif de votre complémentaire santé expatriation.'
  },
  STEP_4: {
    title: 'Pourquoi ces questions ?',
    description:
      'Les personnes devant bénéficier du contrat complémentaire santé expatriation doivent être expressément déclarées.'
  },
  STEP_5: {
    title: 'Pourquoi ces questions ?',
    description:
      'Les personnes devant bénéficier du contrat complémentaire santé expatriation doivent être expressément déclarées.'
  },
  STEP_6: {
    title: 'Comprendre les garanties d’une complémentaire santé expatriés',
    description:
      'Les formules choisies rentrent en compte dans le calcul du tarif de votre complémentaire santé expatriation.'
  },
  STEP_7: {
    title: 'Comprendre les garanties d’une complémentaire santé expatriés',
    description:
      "La CFE (Caisse des Français à l'Etranger) permet à un expatrié de conserver à l'étranger les prestations services en France par la Sécurité Sociale. Son adhésion est facultative et payante."
  },
  STEP_8: {
    title: 'Comprendre les garanties d’une complémentaire santé expatriés',
    description: "Afin de nous permettre d'établir une proposition d'assurance, vous devez renseigner vos coordonnées."
  }
};

const besoins = [
  {
    component: FormExpatGarantiePart1,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_6} />,
    data: { typeAxe: AXES_MACRO_PRESTATION_EXPATRIE.GLOBAL }
  },
  {
    component: FormExpatGarantiePart2,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_7} />
  },
  {
    component: FormExpatCoordonees,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_8} />,
    data: { askForConsent: true, showCaptcha: true }
  }
];

const projetSanteExpatConfig: FormConfigWithUrl = {
  endpoint: 'projetSanteExpat',
  formSteps: [
    {
      component: SanteExpatVoyage,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_1} />
    },
    {
      component: FormExpatDestination,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_2} />
    },
    {
      component: FormSanteParticulierChoixAssure,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_3} />,
      next: {
        selectNextStep: (prevStepState: { typeAssures: string }): string | undefined => {
          return prevStepState.typeAssures;
        },
        paths: {
          VOUS: besoins,
          COUPLE: [
            {
              component: FormExpatConjointIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_4} />
            },
            ...besoins
          ],
          VOUS_ENFANTS: [
            {
              component: FormExpatEnfants,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_5} />
            },
            ...besoins
          ],
          COUPLE_ENFANTS: [
            {
              component: FormExpatConjointIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_4} />
            },
            {
              component: FormExpatEnfants,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_5} />
            },
            ...besoins
          ]
        }
      }
    }
  ]
};

export default projetSanteExpatConfig;
