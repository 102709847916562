import React, { ReactElement } from 'react';
import { DisplayFailureFunction } from 'types';

interface ErrorBoundaryProps {
  children: ReactElement;
  displayFailure: DisplayFailureFunction;
}

/**
 * Call prop.onError() on children uncaught exception
 */

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error): void {
    this.props.displayFailure('Une erreur est survenue', <p>{error.message}</p>);
  }
  render(): ReactElement {
    return this.props.children;
  }
}
