import React, { MouseEventHandler, ReactElement, ReactNode, useRef } from 'react';
import './obfuscatedLink.scss';
import classNames from 'classnames';

type ObfuscatedLinkProps = {
  href: string;
  children?: ReactNode | string;
  className?: string;
};

export function ObfuscatedLink({ href, children, className }: ObfuscatedLinkProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  const handleLeftClick: MouseEventHandler<HTMLDivElement> = event => {
    if (event.ctrlKey) {
      const newWindow = window.open(href, '_blank', 'noopener noreferrer');
      if (newWindow) newWindow.focus();
    } else {
      document.location.href = decodeURIComponent(href);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = event => {
    if (event.key === 'Enter' && document.activeElement === event.target) {
      ref.current?.click();
    }
  };

  return (
    <div
      ref={ref}
      tabIndex={0}
      className={classNames('obf', className)}
      onClick={handleLeftClick}
      onKeyDown={handleKeyDown}
    >
      {children}
    </div>
  );
}
