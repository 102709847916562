import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import {
  fetchTypeTrajetOptions,
  fetchFrequenceUtilisationOptions,
  fetchTypeParkingCollectifOptions,
  fetchTypeParkingOptions,
  fetchTypeParkingIndividuelOptions
} from 'fetches/options.fetch';
import { merge } from 'lodash';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';
import { useFetch } from 'hooks/useFetch.hook';

type FormValues = {
  besoin: {
    typeTrajetCode: string | undefined;
    frequenceCode: string | undefined;
    nombreKmParcourus: number | undefined;
    typeStationnementCode: string | undefined;
    sousTypeStationnementCode: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    typeTrajetCode: Yup.string().required(),
    frequenceCode: Yup.string().required(),
    nombreKmParcourus: Yup.number().required(),
    typeStationnementCode: Yup.string().required(),
    sousTypeStationnementCode: Yup.string().when('typeStationnementCode', {
      is: (value: string) => ['un'].includes(value),
      then: schema => schema.required()
    })
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    typeTrajetCode: undefined,
    frequenceCode: undefined,
    nombreKmParcourus: undefined,
    typeStationnementCode: undefined,
    sousTypeStationnementCode: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormAutoUtilisationVehicule(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const typeTrajetOptions = useFetch(fetchTypeTrajetOptions);
  const frequenceUtilisationOptions = useFetch(fetchFrequenceUtilisationOptions);
  const typeParkingOptions = useFetch(fetchTypeParkingOptions);
  const typeParkingCollectifOptions = useFetch(fetchTypeParkingCollectifOptions);
  const typeParkingIndividuelOptions = useFetch(fetchTypeParkingIndividuelOptions);

  const getSousTypeParkingOptions = useCallback(
    (typeParking: string | undefined) => {
      switch (typeParking) {
        case 'COLLECTIF_CLOS:TYPE_PARKING':
          return typeParkingCollectifOptions;
        case 'INDIVIDUEL:TYPE_PARKING':
          return typeParkingIndividuelOptions;
        default:
          return [];
      }
    },
    [typeParkingIndividuelOptions, typeParkingCollectifOptions]
  );

  const {
    watch,
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const typeStationnementCode = watch('besoin.typeStationnementCode');

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Utilisation de <span>votre véhicule</span>
      </h1>

      <InputSelect
        label="Type de trajet"
        name="besoin.typeTrajetCode"
        control={control}
        options={typeTrajetOptions || []}
        errors={errors}
      />

      <InputSelect
        label="Fréquence d'utilisation"
        name="besoin.frequenceCode"
        control={control}
        options={frequenceUtilisationOptions || []}
        errors={errors}
      />

      <InputNumber
        {...register('besoin.nombreKmParcourus')}
        min={0}
        label="Nombre de km parcourus par an"
        errors={errors}
      />

      <InputSelect
        label="Type de stationnement"
        name="besoin.typeStationnementCode"
        control={control}
        options={typeParkingOptions || []}
        errors={errors}
      />

      {typeStationnementCode &&
        ['COLLECTIF_CLOS:TYPE_PARKING', 'INDIVIDUEL:TYPE_PARKING'].includes(typeStationnementCode) && (
          <InputSelect
            key={typeStationnementCode}
            label="Veuillez préciser le type de parking collectif"
            name="besoin.sousTypeStationnementCode"
            control={control}
            options={getSousTypeParkingOptions(typeStationnementCode) ?? []}
            errors={errors}
          />
        )}
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
