import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { merge } from 'lodash';
import React, { ReactElement, useState } from 'react';
import FormConsent from '../../../components/FormConsent/FormConsent.component';
import FormNextButton from '../../../components/FormNextButton/FormNextButton.component';
import InputText from '../../../components/Input/InputText/InputText.component';
import { ErrorLabel } from '../../../components/Input/parts/ErrorLabel/ErrorLabel.component';
import { useSerieForm } from '../../../hooks/useSerieForm.hook';
import { AccumulateStateToSerie, FormProps, SerieSubmitStatus } from '../../../types';
import { NumeroSecu } from '../ReglesGestion/NumeroSecu';
import { SanteParticulier } from '../ReglesGestion/SanteParticulier';

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

export const FormDemandeAdhesionStepInfosSanteParticulier = (props: FormProps<any>): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const { initialFormValues } = props;

  const initialValues = SanteParticulier.getInitialFormValues(initialFormValues);
  const numeroSecuBeneficiaires = initialFormValues?.numeroSecuBeneficiaires || [];

  //Trier les elements de numeroSecuBeneficiaires pour que le conjoint soit en premier
  numeroSecuBeneficiaires.sort((a: NumeroSecu) => {
    if (a.roleCode === SanteParticulier.conjointCode) {
      return -1;
    }
    return 1;
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors, isValid },
    register,
    getValues
  } = useSerieForm<any>(props.setLastFormState, {
    resolver: yupResolver(SanteParticulier.validationSchema as any),
    defaultValues: initialValues || SanteParticulier.defaultState
  });

  const handleNextClick = (data: any) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };
  return (
    <div className="form-adhesion">
      <div className={'adhesion-step-infos'}>
        <h2>
          Informations complémentaires <br />
          <small className="form-font-regular label-pj">
            Afin de compléter votre demande d&apos;adhésion, nous avons besoin des informations suivantes :
          </small>
        </h2>
        {
          <form
            onSubmit={(...props) => {
              setIsLoading(isValid);
              return handleSubmit(handleNextClick)(...props);
            }}
          >
            <div className={'num-secu-ontainer'}>
              <InputText label={'Numéro de Sécurité Sociale'} {...register('numeroSecuriteSocial')} errors={errors} />
              {numeroSecuBeneficiaires.length > 1 && (
                <button
                  onClick={event => {
                    event.preventDefault();
                    const numero = getValues('numeroSecuriteSocial');
                    numeroSecuBeneficiaires.forEach((element: NumeroSecu, index: number) => {
                      setValue(`numeroSecuriteSocialBeneficiaires.${index}.numeroSecu`, numero);
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faCopy} /> Copier pour tous les autres
                </button>
              )}
            </div>

            {numeroSecuBeneficiaires.map((element: NumeroSecu, index: number) => (
              <InputText
                key={index}
                label={`Numéro de Sécurité Sociale ${
                  element.roleCode === SanteParticulier.conjointCode
                    ? `du conjoint`
                    : `de l'enfant ${element.prenom} ${element.nom}`
                }`}
                {...register(`numeroSecuriteSocialBeneficiaires.${index}.numeroSecu`, {
                  onChange: () => {
                    setValue(`numeroSecuriteSocialBeneficiaires.${index}.nom`, element.nom);
                    setValue(`numeroSecuriteSocialBeneficiaires.${index}.prenom`, element.prenom);
                    setValue(`numeroSecuriteSocialBeneficiaires.${index}.naissanceDate`, element.naissanceDate);
                    setValue(`numeroSecuriteSocialBeneficiaires.${index}.roleCode`, element.roleCode);
                  }
                })}
                errors={errors}
              />
            ))}
            <input {...register('stepCode')} hidden />

            <div className="form-consentement-container">
              <FormConsent
                contenuConsentement={props.contenuConsentement}
                control={control}
                errors={errors}
                label={props.cocheConsentement}
                {...register('consentement')}
              />
            </div>

            <div className="next-button-container">
              <FormNextButton
                showCaptcha={props.showCaptcha}
                setCaptchaToken={props.setCaptchaToken}
                loading={isLoading}
              >
                {props.readOnly ? 'Suivant' : 'Je valide ces informations'}
              </FormNextButton>
              {props.submitIntermediaryStepState?.status === SerieSubmitStatus.FAILED && (
                <div className="error-submit">
                  <ErrorLabel>{props.submitIntermediaryStepState?.message}</ErrorLabel>
                </div>
              )}
            </div>
          </form>
        }
      </div>
    </div>
  );
};
