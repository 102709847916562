import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from 'utils';
import { merge } from 'lodash';
import { OUI_NON_OPTIONS, SEXES_ANIMAUX_OPTIONS, TYPES_ANIMAUX_OPTIONS } from 'consts/consts';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputText from 'components/Input/InputText/InputText.component';
import { useFetch } from 'hooks/useFetch.hook';
import { fetchRacesChatsOptions, fetchRacesChiensOptions } from 'fetches/options.fetch';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputDatePicker from 'components/Input/InputDatePicker/InputDatePicker.component';
import InputRadio from 'components/Input/InputRadio/InputRadio.component';

type FormValues = {
  besoin: {
    typeAnimal: string | undefined;
    nomAnimal: string | undefined;
    raceAnimal: string | undefined;
    naissanceDateAnimal: Date | undefined;
    sexeAnimal: string | undefined;
    puce: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    typeAnimal: Yup.string()
      .nullable()
      .required()
      .oneOf(
        TYPES_ANIMAUX_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    nomAnimal: Yup.string().required().max(255),
    raceAnimal: Yup.string().required().max(255),
    naissanceDateAnimal: Yup.date().required(),
    sexeAnimal: Yup.string().required(),
    puce: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    typeAnimal: undefined,
    nomAnimal: undefined,
    raceAnimal: undefined,
    naissanceDateAnimal: undefined,
    sexeAnimal: undefined,
    puce: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
    if (key === 'naissanceDateAnimal') return new Date(item);
    if (key === 'puce') return item + '';
    return item;
  });
}

export default function FormAnimalIdentiteAnimal(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const racesChiens = useFetch(fetchRacesChiensOptions);
  const racesChats = useFetch(fetchRacesChatsOptions);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre animal</h2>
      <InputRadioButtonInline
        className="input-radio-label-large"
        {...register('besoin.typeAnimal')}
        options={TYPES_ANIMAUX_OPTIONS}
        errors={errors}
      />
      <InputRadio
        label="L'animal est il pucé ou tatoué ?"
        {...register('besoin.puce')}
        errors={errors}
        options={OUI_NON_OPTIONS}
      />

      <InputText label="Nom de l'animal" {...register('besoin.nomAnimal')} errors={errors} />

      <InputSelect
        label="Race de l'animal"
        name="besoin.raceAnimal"
        control={control}
        errors={errors}
        options={
          getValues().besoin.typeAnimal === 'CHIEN:TYPE_ANIMAL'
            ? racesChiens || []
            : getValues().besoin.typeAnimal === 'CHAT:TYPE_ANIMAL'
            ? racesChats || []
            : []
        }
      />

      <InputDatePicker
        className="layout-flex-basis-auto"
        label="Date de naissance"
        showMonthDropdown
        showYearDropdown
        name={'besoin.naissanceDateAnimal'}
        errors={errors}
        control={control}
      />

      <InputRadio
        label="Sexe de l'animal"
        {...register('besoin.sexeAnimal')}
        errors={errors}
        options={SEXES_ANIMAUX_OPTIONS}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
