import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from 'utils';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import { merge } from 'lodash';
import InputNumber from '../../components/Input/InputNumber/InputNumber.component';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';

type CapitalDecesValues = {
  demandeInformations: boolean;
  type: string;
  valeur: number;
};

type RenteEducationValues = {
  demandeInformations: boolean;
  type: string;
  valeur: string;
};

type RenteConjointValues = {
  demandeInformations: boolean;
  type: string;
  valeur: string;
};

interface FormValues {
  capitalDeces: CapitalDecesValues | any;
  renteConjoint: RenteConjointValues | any;
  renteEducation: RenteEducationValues | any;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  capitalDeces: Yup.object({
    demandeInformations: Yup.boolean().when('capitalDeces.valeur', {
      is: undefined,
      then: Yup.boolean().required(
        "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
      ),
      otherwise: Yup.boolean().oneOf([false])
    }),
    valeur: Yup.number().when('demandeInformations', {
      is: true,
      then: Yup.number().ignoreEmptyString(),
      otherwise: Yup.number()
        .ignoreEmptyString()
        .min(0)
        .required(
          "Veuillez indiquer un capital en pourcentage de votre salaire annuel ou cocher la case de demande d'informations"
        )
    })
  }),
  renteConjoint: Yup.object({
    demandeInformations: Yup.boolean().when('renteConjoint.valeur', {
      is: undefined,
      then: Yup.boolean().required(
        "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
      ),
      otherwise: Yup.boolean().oneOf([false])
    }),
    valeur: Yup.string().when('demandeInformations', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .required(
          "Veuillez indiquer si vous souhaitez une rente pour votre conjoint ou cocher la case de demande d'informations"
        )
    })
  }),
  renteEducation: Yup.object({
    demandeInformations: Yup.boolean().when('renteEducation.valeur', {
      is: undefined,
      then: Yup.boolean().required(
        "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
      ),
      otherwise: Yup.boolean().oneOf([false])
    }),
    valeur: Yup.string().when('demandeInformations', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .required(
          "Veuillez indiquer si vous souhaitez une rente pour vos enfant(s) ou cocher la case de demande d'informations"
        )
    })
  })
}).defined();

const defaultState: FormValues = {
  capitalDeces: { type: 'CAPITAL_DECES', demandeInformations: false },
  renteConjoint: { type: 'RENTE_CONJOINT', demandeInformations: false },
  renteEducation: { type: 'RENTE_EDUCATION', demandeInformations: false }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => {
    return item;
  });
}

export default function FormPrevoyanceDeces(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    setValue
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>A quelle hauteur souhaitez-vous être couvert en cas de décès:</h2>
      <InputNumber
        label="Montant du capital décès (en % du salaire)"
        {...register('capitalDeces.valeur')}
        errors={errors}
      />
      <div className={'checkbox-container'}>
        <InputCheckBoxBoolean
          className={'checkbox'}
          control={control}
          name={'capitalDeces.demandeInformations'}
          label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
          onChange={e => {
            setValue(`capitalDeces`, {
              valeur: undefined,
              type: 'CAPITAL_DECES',
              demandeInformations: e.target.checked
            });
            trigger('capitalDeces.demandeInformations');
            return e;
          }}
          errors={errors}
        />
      </div>

      <InputRadioButtonInline
        labelField="Rente conjoint"
        {...register('renteConjoint.valeur')}
        options={OUI_NON_OPTIONS}
        errors={errors}
      />
      <div className={'checkbox-container'}>
        <InputCheckBoxBoolean
          className={'checkbox'}
          control={control}
          name={'renteConjoint.demandeInformations'}
          label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
          onChange={e => {
            setValue(`renteConjoint`, {
              valeur: undefined,
              type: 'RENTE_CONJOINT',
              demandeInformations: e.target.checked
            });
            trigger('renteConjoint.demandeInformations');
            return e;
          }}
          errors={errors}
        />
      </div>

      <InputRadioButtonInline
        labelField="Rente éducation"
        {...register('renteEducation.valeur')}
        options={OUI_NON_OPTIONS}
        errors={errors}
      />
      <div className={'checkbox-container'}>
        <InputCheckBoxBoolean
          className={'checkbox'}
          control={control}
          name={'renteEducation.demandeInformations'}
          label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
          onChange={e => {
            setValue(`renteEducation`, {
              valeur: undefined,
              type: 'RENTE_EDUCATION',
              demandeInformations: e.target.checked
            });
            trigger('renteEducation.demandeInformations');
            return e;
          }}
          errors={errors}
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
