import Yup from '../../../utils/Yup';
import { AccumulateStateToSerie } from '../../../types';

export const validationContrat = Yup.object({
  produit: Yup.object({
    assureur: Yup.object({
      code: Yup.string().nullable()
    })
  }),
  anneeDebut: Yup.date().nullable(),
  cotisation: Yup.object({
    fractionnement: Yup.string(),
    fractionnementLibelle: Yup.string(),
    montantAnnuel: Yup.mixed().nullable()
  }).when('produit.assureur.code', {
    is: (val: string) => !!val,
    then: Yup.object({
      fractionnement: Yup.string(),
      fractionnementLibelle: Yup.string(),
      montantAnnuel: Yup.number().ignoreEmptyString().required()
    }),
    otherwise: Yup.object({
      fractionnement: Yup.string(),
      fractionnementLibelle: Yup.string(),
      montantAnnuel: Yup.mixed().nullable()
    })
  }),
  commentaire: Yup.string().when('produit.assureur.code', {
    is: (val: string) => !!val,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable()
  }),
  typologieCode: Yup.string().when('produit.assureur.code', {
    is: (val: string) => !!val,
    then: Yup.string(),
    otherwise: Yup.string().nullable()
  })
});

export const defaultContrat = {
  produit: {
    assureur: {
      code: undefined
    }
  },
  anneeDebut: undefined,
  cotisation: {
    fractionnement: 'ANNUEL:FRACTIONNEMENT',
    fractionnementLibelle: 'Annuel',
    montantAnnuel: undefined
  },
  commentaire: undefined,
  typologieCode: undefined
};

export function insertIf<T>(condition: boolean, ...array: T[]): T[] {
  return condition ? array : [];
}

export const AccumulateStateToSeriesContrat: AccumulateStateToSerie = (serieState, state) => {
  const stateToAccumulate = serieState.contrats || [];
  for (const key in state) {
    if (state.hasOwnProperty(key) && state[key].produit.assureur.code) {
      // Set properties that not have any input related to
      state[key].typologieCode = `${key}:TYPOLOGIE`;
      state[key].produit.type = 'portefeuille';
      state[key].cotisation.fractionnement = 'ANNUEL:FRACTIONNEMENT';
      state[key].cotisation.fractionnementLibelle = 'Annuel';
      stateToAccumulate.push(state[key]);
    }
  }
  return { ...serieState, contrats: stateToAccumulate };
};
