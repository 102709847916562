import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import './left-pane-description.scss';

type LeftPaneDescriptionProps = {
  descriptionObj: {
    beforeTitle?: string;
    title?: string;
    hideTitleIcon?: boolean;
    description?: string;
    descriptionAsHTML?: boolean;
  };
};

export default function LeftPaneDescription({ descriptionObj }: LeftPaneDescriptionProps): ReactElement | null {
  if (!descriptionObj.title && !descriptionObj.description) return null;
  return (
    <div>
      {descriptionObj.beforeTitle && <p className={'before-title'}>{descriptionObj.beforeTitle}</p>}
      <hr className={'separator'} />

      <div className={'title-description-container'}>
        {descriptionObj.title && (
          <>
            {descriptionObj.hideTitleIcon === true ? null : <FontAwesomeIcon icon={faInfoCircle} />}
            <h2>{descriptionObj.title}</h2>
          </>
        )}
      </div>
      {!descriptionObj.descriptionAsHTML && <p className={'description-step'}>{descriptionObj.description}</p>}
      {descriptionObj.descriptionAsHTML && descriptionObj.description && (
        <div dangerouslySetInnerHTML={{ __html: descriptionObj.description }} />
      )}
    </div>
  );
}
