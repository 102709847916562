import React, { FC } from 'react';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';
import classNames from 'classnames';
import { formatLyaMontant } from 'utils';
import './CostFraisRow.scss';

interface CostFraisRowProps {
  montantFrais: number;
  proposedQuotes: any;
}

const CostFraisRow: FC<CostFraisRowProps> = ({ proposedQuotes, montantFrais }) => {
  const numberOfColumnsClassname = `columns-${proposedQuotes?.length}`;
  return (
    <div className={'row-frais-cost'}>
      <div className={'title'}>
        <h3 className={'form-font-large'}>Frais de dossier</h3>
      </div>
      <div className={classNames('bloc-frais', 'frais__general', numberOfColumnsClassname)}>
        <RoundedCard className={'border card'}>
          <b className="montant-label text-colored secondary form-font-title">{formatLyaMontant(montantFrais)}</b>
        </RoundedCard>
      </div>
    </div>
  );
};

export default CostFraisRow;
