import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _, { chain } from 'lodash';
import React, { FC } from 'react';
import { LinkWithIcon } from '../../components/Link/LinkWithIcon.component';

import './ListDocsDevis.scss';

interface ListDocsDevisProps {
  quote: any;
  className?: string;
  documents: any;
}

const labelDocDevis: { [key in string]: string } = {
  AUTRE: 'Autres',
  EXEMPLES_REMBOURSEMENT: 'Exemples remboursement',
  FICHE_PRODUIT: 'IPID',
  PLAQUETTE_COMMERCIALE: 'Plaquette commerciale',
  CONDITIONS_GENERALES: 'Conditions générales',
  DEVIS: 'Proposition'
};

const docCategoriesOrder: { [key in string]: number } = {
  AUTRE: 6,
  EXEMPLES_REMBOURSEMENT: 5,
  FICHE_PRODUIT: 3,
  PLAQUETTE_COMMERCIALE: 2,
  CONDITIONS_GENERALES: 4,
  DEVIS: 1
};

const getLabelDoc = (categories: string[]) => {
  const firstLabelFound = _(categories)
    .map(categorie => labelDocDevis[categorie])
    .compact()
    .first();

  return firstLabelFound ?? categories[0];
};

const getCategoryOrder = (categories: string[]) => {
  const firstCategoryFound = _(categories)
    .map(categorie => docCategoriesOrder[categorie])
    .compact()
    .first();

  return firstCategoryFound ?? 100;
};

const ListDocsDevis: FC<ListDocsDevisProps> = ({ quote, className, documents }) => {
  const getQuoteDocuments = (numInterne: number) => {
    return chain(documents)
      .filter(document => {
        return document.proprietes?.DEVIS_NUMERO === numInterne;
      })
      .sortBy(document, doc => getCategoryOrder(doc.categories))
      .value();
  };

  return (
    <>
      <div key={quote.numeroInterne} className={classNames('list-documents form-font-regular', className)}>
        {getQuoteDocuments(quote.numeroInterne).map((doc: any, iDoc: any) => (
          <LinkWithIcon
            key={iDoc}
            className={'primary'}
            icon={<FontAwesomeIcon size={'lg'} icon={faDownload} />}
            labelLink={getLabelDoc(doc?.categories)}
            link={doc.url}
          />
        ))}
      </div>
    </>
  );
};

export default ListDocsDevis;
