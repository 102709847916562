import { faCheckCircle, faEye, faPaperclip, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import './FieldUploadFile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { createRef, useState } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { DocumentsSouscription } from '../../types';
import { getIn } from '../../utils';
import { ErrorLabel } from '../Input/parts/ErrorLabel/ErrorLabel.component';

const FORMAT_ACCEPTED = ['application/msword', 'application/pdf', 'image/gif', 'image/jpeg', 'image/png'];

type FieldUploadFileProps = {
  onFileUploaded: (file: File) => void;
  onFileDelete: () => void;
  formatAccepted: string[];
  extensionAccepted?: string;
  onFormatRejected?: (error: string) => void;
  label: string;
  className?: string;
  name: string;
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  value: DocumentsSouscription;
  readOnly?: boolean;
};

// eslint-disable-next-line
export const FieldUploadFile = (props: FieldUploadFileProps) => {
  const {
    onFileUploaded,
    formatAccepted,
    extensionAccepted,
    onFormatRejected,
    label,
    className,
    value,
    onFileDelete,
    errors
  } = props;
  const refInput = createRef<HTMLInputElement>();
  const error = getIn(props.name, errors);

  const [preview, setPreview] = useState<string | ArrayBuffer>(value?.document?.url ?? '');

  function handleChangefile() {
    setPreview('');
    if (!refInput.current || !refInput.current.files || refInput.current.files.length === 0) return;
    if (!formatAccepted.includes(refInput.current.files[0].type)) {
      const strError = `Le format du fichier ne convient pas. Formats acceptés : ${formatAccepted
        .map((format: string) => format.replace(/application\/|image\/|text\//gi, ''))
        .join(', ')}`;
      if (onFormatRejected) {
        onFormatRejected(strError);
      }
    } else {
      onFileUploaded && onFileUploaded(refInput.current.files[0]);
      setPreview(URL.createObjectURL(refInput.current.files[0]));
    }
  }

  const onFilePreview = () => {
    window.open(String(preview), '_blank');
  };

  return (
    <div className={classNames('field-upload-file', className)}>
      {!value?.document?.fileName && !value?.document?.fileUploadedName && (
        <div className={'field-upload-file__input'} onClick={() => refInput?.current?.click()}>
          <label htmlFor={props.name}>
            <FontAwesomeIcon size={'sm'} icon={faUpload} />
            {label}
          </label>
          <input
            name={props.name}
            ref={refInput}
            type="file"
            onChange={() => handleChangefile()}
            hidden
            accept={extensionAccepted}
          />
        </div>
      )}

      {!props.readOnly && value?.document?.fileUploadedName && (
        <div className={'field-upload-file__uploaded'}>
          <div className={'field-upload-file__uploaded__title'}>
            <FontAwesomeIcon icon={faPaperclip} />
            <p>
              {label}: <u>{value?.document?.fileUploadedName}</u>
            </p>
          </div>
          <div className={'field-upload-file__uploaded__actions'}>
            {preview && <FontAwesomeIcon className={'icon-preview'} icon={faEye} onClick={() => onFilePreview()} />}
            <FontAwesomeIcon className={'icon-delete'} icon={faTrashAlt} onClick={() => onFileDelete()} />
          </div>
        </div>
      )}
      {props.readOnly && value?.document?.fileUploadedName && (
        <div className={'field-upload-file__uploaded'}>
          <div className={'field-upload-file__uploaded__title'}>
            <FontAwesomeIcon icon={faPaperclip} />
            <p>
              {label}: <u>{value?.document?.fileUploadedName}</u>
            </p>
          </div>
          <div className={'field-upload-file__uploaded__actions'}>
            {preview && <FontAwesomeIcon className={'icon-preview'} icon={faEye} onClick={() => onFilePreview()} />}
            <FontAwesomeIcon className={'icon-success'} icon={faCheckCircle} />
          </div>
        </div>
      )}
      {error && <ErrorLabel>{error?.document?.fileUploadedName?.message}</ErrorLabel>}
    </div>
  );
};

FieldUploadFile.defaultProps = {
  formatAccepted: FORMAT_ACCEPTED
};

FieldUploadFile.displayName = 'FieldUploadFile';

export default FieldUploadFile;
