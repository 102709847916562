import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';

type FormValues = {
  type: string;
};

const defaultState: FormValues = {
  type: ''
};

type personaLabel = {
  [key: string]: string;
};

const personnas_LABEL: personaLabel = {
  ENTREPRISE: 'Une entreprise',
  PARTICULIER: 'Un particulier',
  TNS: 'Un travailleur indépendant (chef d’entreprise, auto entrepreneur, libéral, etc…)'
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  type: Yup.string().oneOf(['PARTICULIER', 'TNS', 'ENTREPRISE']).required()
}).defined();

const getInitialValues = (assure: any, personnas: string[]): { type: string } => {
  let value = '';
  if (personnas.includes('TNS') && personnas.includes('ENTREPRISE') && !personnas.includes('PARTICULIER')) {
    if (assure) {
      value = assure.hasOwnProperty('raisonSociale') ? 'ENTREPRISE' : 'TNS';
    }
  }
  return { type: value };
};

export default function FormDecouverteChoixProfilDynamique(props: FormProps<FormValues>): ReactElement {
  const initialValues = getInitialValues(props.initialFormValues?.assure, props.personnas);
  const {
    watch,
    handleSubmit,
    formState: { isDirty, errors },
    register
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  return (
    <>
      <AutoSubmissionForm<FormValues>
        watch={watch}
        isDirty={isDirty}
        onSubmit={handleSubmit(values => {
          props.goNextStep(values);
        })}
      >
        <h2>Vous êtes</h2>
        <InputRadioButtonInline
          column
          {...register('type')}
          options={props.personnas.map((persona: string) => ({
            label: personnas_LABEL[persona],
            value: persona
          }))}
          errors={errors}
        />
        {(!!props.formValues || !!initialValues) && (
          <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
            Suivant
          </FormNextButton>
        )}
      </AutoSubmissionForm>
    </>
  );
}
