import { CIVILITES_OPTIONS } from '../../../consts/consts';
import InputText from '../../../components/Input/InputText/InputText.component';
import InputSelect from '../../../components/Input/InputSelect/InputSelect.component';
import React, { ReactElement } from 'react';
import InputRadioButtonInline from '../../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './enfantFields.scss';
import { Control, FieldErrors } from 'react-hook-form';
import InputDate from 'components/Input/InputDate/InputDate.component';

type EnfantFieldsProps = {
  index: number;
  register: any;
  errors: FieldErrors<any>;
  control: Control<any>;
  regimesSecu?: any;
  pays?: any;
  deleteChild: any;
};

export default function EnfantFields({
  index,
  register,
  errors,
  control,
  regimesSecu,
  pays,
  deleteChild
}: EnfantFieldsProps): ReactElement {
  const ordinalNumber = [
    'Premier',
    'Deuxième',
    'Troisième',
    'Quatrième',
    'Cinquiéme',
    'Sixième',
    'Septième',
    'Huitième',
    'Neuvème',
    'Dixième',
    'Onzième',
    'Douzième'
  ];

  return (
    <div className={'enfant-fields-bloc'}>
      <div className={'header-enfant-bloc'}>
        <div className={'title-enfant'}>
          <h3>
            <span>{ordinalNumber[index]} enfant</span>
          </h3>
        </div>
        {index > 0 && (
          <div className={'actions-enfant'} onClick={() => deleteChild(index)}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </div>
        )}
      </div>
      <InputRadioButtonInline
        {...register(`enfants[${index}].civilite`)}
        control={control}
        options={CIVILITES_OPTIONS}
        label="Civilité de votre enfant"
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText
          label="Prénom de votre enfant"
          {...register(`enfants[${index}].prenom`)}
          errors={errors}
          preventNumber={true}
        />
        <InputText
          label="Nom de votre enfant"
          {...register(`enfants[${index}].nom`)}
          errors={errors}
          preventNumber={true}
        />
      </div>
      <InputDate label="Date de naissance de votre enfant" name={`enfants[${index}].naissanceDate`} control={control} />

      {regimesSecu && (
        <InputSelect
          label="Régime de sécurité sociale de votre enfant"
          name={`enfants[${index}].regime`}
          control={control}
          errors={errors}
          options={regimesSecu || []}
        />
      )}
      {pays && (
        <InputSelect
          label="Nationalité de votre enfant"
          name={`enfants[${index}].nationalite`}
          control={control}
          errors={errors}
          options={pays || []}
          isSearchable={true}
        />
      )}
    </div>
  );
}
