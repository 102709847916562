import color from 'color';
import FormSerie from 'components/FormSerie/FormSerie.component';
import { series } from 'configs';
import useCallApi from 'hooks/useCallAPI.hook';
import React, { useEffect, useState, VFC } from 'react';
import { useLocation } from 'react-router';
import {
  AppFailureType,
  DisplayFailureFunction,
  DistributeurReponse,
  FormParams,
  SetLoadingStateFunction
} from 'types';
import { getUrlParam } from '../../utils';

/**
 * update css variables with fetched values
 */
function updateStylesVariables(primaryColor: string, secondaryColor: string): void {
  const root = document.documentElement;
  const primaryColorFormatted = color(primaryColor);
  root.style.setProperty('--color-primary', primaryColorFormatted.string());
  if (secondaryColor) {
    root.style.setProperty('--color-secondary', color(secondaryColor).string());
    root.style.setProperty('--color-secondary-light', color(secondaryColor).alpha(0.1).toString());
  } else {
    root.style.setProperty('--color-secondary', primaryColorFormatted.string());
  }

  root.style.setProperty('--color-primary-alpha', primaryColorFormatted.alpha(0.1).string());

  root.style.setProperty(
    '--color-primary-complementary',
    primaryColorFormatted.negate().saturationl(0).lightness(20).string()
  );
  root.style.setProperty('--color-primary-light', color(primaryColor).saturationl(9).lightness(78).string());
  root.style.setProperty('--color-primary-extra-light', color(primaryColor).saturationl(9).lightness(90).string());
  root.style.setProperty('--color-primary-dark', color(primaryColor).saturationl(77).lightness(15).string());
  root.style.setProperty('--color-primary-transparent', color(primaryColor).fade(0.9).string());
}

interface FormLoaderProps {
  displayFailure: DisplayFailureFunction;
  setLoadingState: SetLoadingStateFunction;
}

interface DocumenResponse {
  url: string;
}

/**
 * Fetch the form associated to the param id in url
 * then load a form serie with the fetched params
 */
const FormLoader: VFC<FormLoaderProps> = (props: FormLoaderProps) => {
  // call API to retrieve Form
  const location = useLocation();
  const [formParams, setFormParams] = useState<FormParams | undefined>(undefined);
  const [urlFicheLegale, setUrlFicheLegale] = useState<string>('');
  const [distributeur, setDistributeur] = useState<DistributeurReponse>();

  const fetchForm = useCallApi<string, FormParams>(
    (url: string) => fetch(url),
    data => {
      updateStylesVariables(data.parametres.FORMULAIRE_COULEUR_PRIMAIRE, data.parametres.FORMULAIRE_COULEUR_SECONDAIRE);
      setFormParams(data);
      props.setLoadingState(false);
    },
    error => {
      switch (error.status) {
        case 404:
          return props.displayFailure(
            "Le formulaire n'existe pas",
            <>
              <p>Aucun formulaire ne correspond à cette adresse.</p>
            </>
          );
        case 403:
          return props.displayFailure(
            'Le formulaire est inactif',
            <>
              <p>Le formulaire correspondant est désactivé.</p>
            </>,
            AppFailureType.INFO
          );
        default:
          return props.displayFailure(
            'Une erreur est survenue',
            <>
              <p>{error.message || 'Veuillez réessayer ultérieurement.'}</p>
            </>
          );
      }
    }
  );

  const fetchFicheInformationLegale = useCallApi<string, DocumenResponse>(
    (url: string) => fetch(url),
    data => {
      setUrlFicheLegale(data.url);
      props.setLoadingState(false);
    },
    error => console.error(error)
  );

  const fetchInformationsDistributeur = useCallApi<string, DistributeurReponse>(
    (url: string) => fetch(url),
    data => {
      setDistributeur(data);
      props.setLoadingState(false);
    },
    error => console.error(error)
  );

  useEffect(() => {
    fetchForm(
      `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(location.search, 'id')}/${
        getUrlParam(location.search, 'p') || ''
      }`
    );

    fetchFicheInformationLegale(
      `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(
        location.search,
        'id'
      )}/documents/ficheReglementaireCabinet`
    );

    fetchInformationsDistributeur(
      `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${getUrlParam(location.search, 'id')}/cabinet`
    );
  }, []);

  return (
    <>
      {formParams && (
        <FormSerie
          updateRequestFormParams={updatedFormParams => {
            setFormParams(updatedFormParams);
          }}
          formParams={formParams}
          distributeur={distributeur}
          seriesConfigs={series}
          urlFicheLegale={urlFicheLegale}
        />
      )}
    </>
  );
};

export default FormLoader;
