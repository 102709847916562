import React, { ReactElement } from 'react';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { OUI_NON_OPTIONS } from 'consts/consts';

type Key = 'conducteurPrincipal' | 'conducteurSecondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};
export type Form = {
  permis: {
    conduiteAccompagnee: string | undefined;
    suspension: string | undefined;
    annulation: string | undefined;
  };
  dejaAssureEnTantQuePrincipal: string | undefined;
  dejaAssureEnTantQueSecondaire: string | undefined;
};
export type FormValues<K extends Key> = PartialRecord<K, Form>;

export default function FormAutoConducteurPart3({
  goNextStep,
  initialFormValues,
  formValues,
  data = { type: 'conducteurPrincipal' },
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const isConducteurPrincipal = type === 'conducteurPrincipal';

  const defaultState: FormValues<typeof type> = {
    [type]: {
      permis: {
        conduiteAccompagnee: undefined,
        suspension: undefined,
        annulation: undefined
      },
      dejaAssureEnTantQuePrincipal: undefined,
      dejaAssureEnTantQueSecondaire: undefined
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues<Key & any>> = Yup.object({
    [type]: Yup.object({
      permis: Yup.object({
        conduiteAccompagnee: Yup.string().typeError('Champ obligatoire').required(),
        suspension: Yup.string().typeError('Champ obligatoire').required(),
        annulation: Yup.string().typeError('Champ obligatoire').required()
      }),
      dejaAssureEnTantQuePrincipal: Yup.string().typeError('Champ obligatoire').required(),
      dejaAssureEnTantQueSecondaire: Yup.string().typeError('Champ obligatoire').required()
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Conducteur <span>{isConducteurPrincipal ? 'principal' : 'secondaire'}</span>
      </h1>

      <h2 className="subtitle">Antécédents d&apos;assurance</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputRadioButtonInline
          labelField={'Déjà assuré en tant que conducteur principal ?'}
          {...register(`${type}.dejaAssureEnTantQuePrincipal`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <InputRadioButtonInline
          labelField={'Déjà assuré en tant que conducteur secondaire ?'}
          {...register(`${type}.dejaAssureEnTantQueSecondaire`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <InputRadioButtonInline
          labelField={'Obtention du permis suite à la conduite accompagnée ?'}
          {...register(`${type}.permis.conduiteAccompagnee`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <InputRadioButtonInline
          labelField={"Le permis du conducteur principal a-t-il fait l'objet de suspension(s) ?"}
          {...register(`${type}.permis.suspension`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <InputRadioButtonInline
          labelField={"Le permis du conducteur principal a-t-il fait l'objet d'annulation(s) ?"}
          {...register(`${type}.permis.annulation`)}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
