import React from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-text-area.scss';
import classNames from 'classnames';
import InfoBulle from 'components/InfoBulle/InfoBulle';

interface InputProps {
  label: string;
  tooltip?: string;
  errors: FieldErrors<any>;
  className?: string;
  name: string;
  onChange: ChangeHandler;
  rows?: number;
}

export const InputTextArea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  ({ label, tooltip, errors, className, rows, ...field }: InputProps, ref) => {
    const error = getIn(field.name, errors);
    return (
      <div
        className={classNames('inputTextarea', className, {
          'input-error': error
        })}
      >
        <label className={classNames('inputTextarea__label', { '-error': error })}>
          {label}
          {tooltip && (
            <InfoBulle
              className={'inputText__tooltip'}
              name={`description-tooltip-${label}`}
              key={`description-tooltip`}
              text={tooltip}
            />
          )}
        </label>
        <textarea
          className={classNames('inputTextarea__field', { '-error': error })}
          ref={ref}
          {...field}
          rows={rows}
        />
        <FieldError>{error?.message}</FieldError>
      </div>
    );
  }
);
InputTextArea.displayName = 'InputTextArea';

export default InputTextArea;
