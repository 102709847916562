import React, { ReactElement } from 'react';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import { useFetch } from 'hooks/useFetch.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { fetchTypePermisOptions } from 'fetches/options.fetch';
import InputDate from 'components/Input/InputDate/InputDate.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';

type Key = 'conducteurPrincipal' | 'conducteurSecondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};
export type Form = {
  permis: {
    obtentionDate: Date | undefined;
    typeCode: string | undefined;
  };
  crm: string | undefined;
  nbAnnneeCRM05: number | undefined;
};
export type FormValues<K extends Key> = PartialRecord<K, Form>;

export default function FormAutoConducteurPart2({
  goNextStep,
  initialFormValues,
  formValues,
  data = { type: 'conducteurPrincipal' },
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const isConducteurPrincipal = type === 'conducteurPrincipal';
  const typePermisOptions = useFetch(fetchTypePermisOptions);

  const defaultState: FormValues<typeof type> = {
    [type]: {
      permis: {
        obtentionDate: undefined,
        typeCode: undefined
      },
      crm: undefined,
      nbAnnneeCRM05: undefined
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues<Key & any>> = Yup.object({
    [type]: Yup.object({
      permis: Yup.object({
        obtentionDate: Yup.date().typeError('Merci de renseigner une date valide').required(),
        typeCode: Yup.string().required()
      }),
      crm: Yup.string().typeError('Merci de renseigner un nombre').min(0.5).max(4).required(),
      nbAnnneeCRM05: Yup.number().when('crm', {
        is: '0.5',
        then: Yup.number().typeError("Merci de renseigner un nombre d'année").required(),
        otherwise: Yup.number()
          .nullable()
          .typeError('Merci de renseigner un numbre')
          .transform((_, val) => (val !== '' ? Number(val) : null))
      })
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });
  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };
  const crm = watch(`${type}.crm`);
  console.dir([crm]);

  return (
    <div>
      <h1 className="title">
        Conducteur <span>{isConducteurPrincipal ? 'principal' : 'secondaire'}</span>
      </h1>

      <h2 className="subtitle">Permis</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputDate
          label={"Date d'obtention du permis de conduire"}
          name={`${type}.permis.obtentionDate`}
          control={control}
        />

        <InputSelect
          name={`${type}.permis.typeCode`}
          label={'Type de permis'}
          options={typePermisOptions || []}
          control={control}
          errors={errors}
        />

        <InputNumber
          label={'Coefficient de Bonus/Malus (CRM)'}
          {...register(`${type}.crm`)}
          errors={errors}
          min={0.5}
          max={4}
          step={0.01}
        />

        {crm === '0.5' && (
          <InputNumber label="Nombre d'année(s) à 0.5" {...register(`${type}.nbAnnneeCRM05`)} errors={errors} min={0} />
        )}

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
