import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { useFetch } from '../../hooks/useFetch.hook';
import {
  fetchStatutProOptions,
  fetchRegimeSecuOptions,
  fetchCaisseRetraiteOptions,
  fetchClassePrevoyanceCarmfOptions,
  fetchClassePrevoyanceCarpvOptions,
  fetchClassePrevoyanceCavecOptions,
  fetchClassePrevoyanceCavomOptions,
  fetchNbAnneesRegimeSecuOptions
} from '../../fetches/options.fetch';
import { assignValuesFromSource } from '../../utils';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';

type FormValues = {
  assure: {
    statutProfessionnel: string | null | undefined;
    regime: string | null | undefined;
    nbAnneesRegime: string | null | undefined;
    caisseRetraite: string | null | undefined;
    classePrevoyance: string | null | undefined;
    dividendes: number | null | undefined;
    salaire: number | null | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    statutProfessionnel: Yup.string().required(),
    regime: Yup.string().required(),
    caisseRetraite: Yup.string().required(),
    classePrevoyance: Yup.string().when('caisseRetraite', {
      is: (val: string) =>
        ['CARMF:CAISSE_RETRAITE', 'CARPV:CAISSE_RETRAITE', 'CAVEC:CAISSE_RETRAITE', 'CAVOM:CAISSE_RETRAITE'].includes(
          val
        ),
      then: Yup.string().required(),
      otherwise: Yup.string().nullable()
    }),
    nbAnneesRegime: Yup.string().required(),
    dividendes: Yup.number().ignoreEmptyString().required(),
    salaire: Yup.number().ignoreEmptyString().required()
  })
}).defined();

const defaultState: FormValues = {
  assure: {
    statutProfessionnel: '',
    regime: '',
    caisseRetraite: '',
    classePrevoyance: '',
    nbAnneesRegime: '',
    dividendes: undefined,
    salaire: undefined
  }
};

export default function FormPrevoyanceSituationPro(props: FormProps<FormValues>): ReactElement {
  const initialValues: any = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const statutsPro = useFetch(fetchStatutProOptions);
  const regimesSecu = useFetch(fetchRegimeSecuOptions);
  const nbAnneesRegime = useFetch(fetchNbAnneesRegimeSecuOptions);
  const caissesRetraite = useFetch(fetchCaisseRetraiteOptions);
  const classePrevoyanceCarmf = useFetch(fetchClassePrevoyanceCarmfOptions);
  const classePrevoyanceCarpv = useFetch(fetchClassePrevoyanceCarpvOptions);
  const classePrevoyanceCavec = useFetch(fetchClassePrevoyanceCavecOptions);
  const classePrevoyanceCavom = useFetch(fetchClassePrevoyanceCavomOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre Situation professionnelle : </h2>
      <div className="layout-field-container">
        <InputSelect
          label="Statut professionnel"
          name="assure.statutProfessionnel"
          control={control}
          errors={errors}
          options={statutsPro || []}
        />

        <InputSelect
          label="Caisse de retraite "
          name="assure.caisseRetraite"
          control={control}
          errors={errors}
          options={caissesRetraite || []}
        />
      </div>
      {getValues().assure.caisseRetraite === 'CARMF:CAISSE_RETRAITE' && (
        <div className="layout-field-container">
          <InputSelect
            label="Classe de prévoyance"
            name="assure.classePrevoyance"
            control={control}
            errors={errors}
            tooltip={
              "Classe de cotisations de l'assuré aux prestations de Prévoyance prévues par sa caisse de retraite"
            }
            options={classePrevoyanceCarmf || []}
          />
        </div>
      )}
      {getValues().assure.caisseRetraite === 'CARPV:CAISSE_RETRAITE' && (
        <div className="layout-field-container">
          <InputSelect
            label="Classe de prévoyance"
            name="assure.classePrevoyance"
            control={control}
            errors={errors}
            tooltip={
              "Classe de cotisations de l'assuré aux prestations de Prévoyance prévues par sa caisse de retraite"
            }
            options={classePrevoyanceCarpv || []}
          />
        </div>
      )}
      {getValues().assure.caisseRetraite === 'CAVEC:CAISSE_RETRAITE' && (
        <div className="layout-field-container">
          <InputSelect
            label="Classe de prévoyance"
            name="assure.classePrevoyance"
            control={control}
            errors={errors}
            tooltip={
              "Classe de cotisations de l'assuré aux prestations de Prévoyance prévues par sa caisse de retraite"
            }
            options={classePrevoyanceCavec || []}
          />
        </div>
      )}
      {getValues().assure.caisseRetraite === 'CAVOM:CAISSE_RETRAITE' && (
        <div className="layout-field-container">
          <InputSelect
            label="Classe de prévoyance"
            name="assure.classePrevoyance"
            control={control}
            errors={errors}
            tooltip={
              "Classe de cotisations de l'assuré aux prestations de Prévoyance prévues par sa caisse de retraite"
            }
            options={classePrevoyanceCavom || []}
          />
        </div>
      )}

      <div className="layout-field-container">
        <InputNumber label="Salaire annuel" {...register('assure.salaire')} errors={errors} />

        <InputNumber label="Dividendes" {...register('assure.dividendes')} errors={errors} />
      </div>
      <div className="layout-field-container" style={{ alignItems: 'flex-end' }}>
        <InputSelect
          label="Régime de sécurité sociale"
          name="assure.regime"
          control={control}
          errors={errors}
          options={regimesSecu || []}
        />

        <InputSelect
          label="Nb d'années d'inscription au régime actuel"
          name="assure.nbAnneesRegime"
          control={control}
          errors={errors}
          options={nbAnneesRegime || []}
        />
      </div>
      <div className="layout-field-container">
        <InputCheckBoxBoolean
          control={control}
          label="Exercice de travaux manuels "
          name="assure.travauxManuels"
          errors={errors}
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
