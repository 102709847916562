import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';
import { OUI_NON_OPTIONS } from 'consts/consts';
import { fetchAssureurOptions, fetchResiliationMotifCodeOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, useWatch } from 'react-hook-form';
import parseSearch from 'utils/parseSearch';
import './Assureur.scss';

type EnfantFieldsProps = {
  index: number;
  register: any;
  errors: FieldErrors<any>;
  control: Control<any>;
  type: string;
  remove: (index: number) => void;
};

export default function Assureur({ index, errors, register, type, control, remove }: EnfantFieldsProps): ReactElement {
  const resiliationMotifCodeOptions = useFetch(fetchResiliationMotifCodeOptions);
  const id = parseSearch(window.location.search, 'id');
  const assureurOptions = useFetch(() => fetchAssureurOptions(id));
  const watchAntecedents = useWatch({ name: `${type}.antecedents`, control });

  return (
    <div className="Assureur" key={index}>
      <button className="Assureur__remove no-default-style" onClick={() => remove(index)}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>

      <InputSelect
        name={`${type}.antecedents.${index}.assuranceNom`}
        label="Compagnie"
        options={
          assureurOptions?.map(e => ({ value: e.personne.raisonSociale, label: e.personne.raisonSociale })) || []
        }
        control={control}
        errors={errors}
        isSearchable
      />

      <InputDate label="Date de souscription" name={`${type}.antecedents.${index}.debutDate`} control={control} />

      <InputRadioButtonInline
        labelField="Le contrat est-il toujours en cours ?"
        {...register(`${type}.antecedents.${index}.estResilie`)}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      {watchAntecedents?.[index]?.estResilie === 'false' && (
        <>
          <InputDate label="Date de résiliation" name={`${type}.antecedents.${index}.finDate`} control={control} />
          <InputSelect
            name={`${type}.antecedents.${index}.resiliationMotifCode`}
            label="Motif de résiliation"
            options={resiliationMotifCodeOptions || []}
            control={control}
            errors={errors}
          />

          <InputRadioButtonInline
            labelField="Y a-t-il eu récidive de non paiement ?"
            {...register(`${type}.antecedents.${index}.recidiveNPP`)}
            options={OUI_NON_OPTIONS}
            column={2}
            errors={errors}
          />

          <InputRadioButtonInline
            labelField="Le contentieux a-t-il été soldé ?"
            {...register(`${type}.antecedents.${index}.contentieuxSolde`)}
            options={OUI_NON_OPTIONS}
            column={2}
            errors={errors}
          />

          <InputTextArea
            label="Commentaires"
            {...register(`${type}.antecedents.${index}.resiliationAutreMotif`)}
            errors={errors}
            rows={5}
          />
        </>
      )}
    </div>
  );
}
