import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputText from 'components/Input/InputText/InputText.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputRadio from 'components/Input/InputRadio/InputRadio.component';
import Yup from 'utils/Yup';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { ColoredText } from '../../../components/ColoredText/ColoredText';

type FormValues = {
  civilite: string | null;
  nom: string | undefined;
  prenom: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  civilite: Yup.string()
    .nullable()
    .required()
    .oneOf(
      CIVILITES_OPTIONS.map(option => option.value),
      'Champ obligatoire'
    ),
  nom: Yup.string().required().max(255),
  prenom: Yup.string().required().max(255)
}).defined();

const defaultState: FormValues = {
  civilite: null,
  nom: undefined,
  prenom: undefined
};

export default function FormPrescripteurParticulierIdentite(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>
        <ColoredText className={'_secondaire'} text="Identité " />
        du contact:
      </h2>
      <InputRadio label="Civilité" {...register('civilite')} errors={errors} options={CIVILITES_OPTIONS} />
      <InputText label="Nom" {...register('nom')} errors={errors} preventNumber={true} />
      <InputText label="Prénom" {...register('prenom')} errors={errors} preventNumber={true} />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
