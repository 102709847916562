import React, { ReactElement } from 'react';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import { useFieldArray } from 'react-hook-form';
import FormGenericButton from 'components/FormGenericButton/FormGenericButton.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Assureur from './components/Assureur/Assureur.component';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

type Assureur = {
  assuranceNom: string | undefined;
  debutDate: Date | undefined;
  finDate: Date | undefined;
  resiliationMotifCode: string | undefined;
  resiliationAutreMotif: string | undefined;
  estResilie: string | undefined;
};
export type FormValues = {
  antecedents: Assureur[];
};
export default function FormHabitationAntecedentsAssurance({
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const defaultSinistre: Assureur = {
    assuranceNom: undefined,
    debutDate: undefined,
    finDate: undefined,
    resiliationMotifCode: undefined,
    resiliationAutreMotif: undefined,
    estResilie: undefined
  };

  const defaultState: FormValues = {
    antecedents: []
  };

  const validationSchema = Yup.object({
    antecedents: Yup.array().of(
      Yup.object({
        assuranceNom: Yup.string().required(),
        debutDate: Yup.date().typeError('Merci de renseigner une date valide').required(),
        estResilie: Yup.string().nullable().required(),
        finDate: Yup.date().when('estResilie', {
          is: 'false',
          then: schema => schema.typeError('Merci de renseigner une date valide').required(),
          otherwise: schema => schema.transform(() => undefined).nullable()
        }),
        resiliationMotifCode: Yup.string().when('estResilie', {
          is: 'false',
          then: schema => schema.required()
        }),
        resiliationAutreMotif: Yup.string().when('estResilie', {
          is: 'false',
          then: schema => schema
        })
      })
    )
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  const { fields, append, remove } = useFieldArray<FormValues>({
    control,
    name: 'antecedents'
  });

  const handleNextClick = (data: FormValues) => {
    goNextStep({ antecedents: data.antecedents.map(e => ({ ...e, resiliationDate: e.finDate })) });
  };

  return (
    <div>
      <h1 className="title">
        Antécédents <span>d&apos;assurance</span>
      </h1>

      <form onSubmit={handleSubmit(handleNextClick)}>
        {fields.length ? (
          fields.map((item, index) => (
            <Assureur
              key={index}
              index={index}
              register={register}
              control={control}
              errors={errors}
              remove={remove}
              name={'antecedents'}
            />
          ))
        ) : (
          <div className="Assureur" data-empty="Aucun antécédent d'assurance" onClick={() => append(defaultSinistre)} />
        )}

        <div className="layout-button-container-responsive">
          <FormGenericButton onClick={() => append(defaultSinistre)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un antécédent
          </FormGenericButton>

          <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
            Suivant
          </FormNextButton>
        </div>
      </form>
    </div>
  );
}
