import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenericButton from 'components/FormGenericButton/FormGenericButton.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import Assureur from './Components/Assureur/Assureur.component';

type Key = 'conducteurPrincipal' | 'conducteurSecondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};
type Assureur = {
  assuranceNom: string | undefined;
  debutDate: Date | undefined;
  finDate: Date | undefined;
  resiliationMotifCode: string | undefined;
  resiliationAutreMotif: string | undefined;
  recidiveNPP: string | undefined;
  contentieuxSolde: string | undefined;
  estResilie: string | undefined;
};
export type Form = {
  antecedents: Assureur[];
};
export type FormValues<K extends Key> = PartialRecord<K, Form>;

export default function FormAutoConducteurPart6({
  goNextStep,
  initialFormValues,
  formValues,
  data = { type: 'conducteurPrincipal' },
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const isConducteurPrincipal = type === 'conducteurPrincipal';

  const defaultSinistre: Assureur = {
    assuranceNom: undefined,
    debutDate: undefined,
    finDate: undefined,
    resiliationMotifCode: undefined,
    resiliationAutreMotif: undefined,
    recidiveNPP: undefined,
    contentieuxSolde: undefined,
    estResilie: undefined
  };

  const defaultState: FormValues<typeof type> = {
    [type]: {
      antecedents: []
    }
  };

  const validationSchema = Yup.object({
    [type]: Yup.object({
      antecedents: Yup.array().of(
        Yup.object({
          assuranceNom: Yup.string().required(),
          debutDate: Yup.date().typeError('Merci de renseigner une date valide').required(),
          estResilie: Yup.string().nullable().required(),
          finDate: Yup.date()
            .typeError('Merci de renseigner une date valide')
            .when('estResilie', {
              is: 'false',
              then: schema => schema.required()
            }),
          resiliationMotifCode: Yup.string().when('estResilie', {
            is: 'false',
            then: schema => schema.required()
          }),
          resiliationAutreMotif: Yup.string(),
          recidiveNPP: Yup.string()
            .nullable()
            .when('estResilie', {
              is: 'false',
              then: schema => schema.required()
            }),
          contentieuxSolde: Yup.string()
            .nullable()
            .when('estResilie', {
              is: 'false',
              then: schema => schema.required()
            })
        })
      )
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const { fields, append, remove } = useFieldArray<FormValues<Key>>({
    control,
    name: `${type}.antecedents`
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep({ [type]: { antecedents: data[type]?.antecedents.map(e => ({ ...e, resiliationDate: e.finDate })) } });
  };

  return (
    <div>
      <h1 className="title">
        Conducteur <span>{isConducteurPrincipal ? 'principal' : 'secondaire'}</span>
      </h1>

      <h2 className="subtitle">Antécédents d&apos;assurance</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        {fields.length ? (
          fields.map((item, index) => (
            <Assureur
              key={index}
              index={index}
              register={register}
              control={control}
              errors={errors}
              remove={remove}
              type={type}
            />
          ))
        ) : (
          <div
            className="Assureur"
            data-empty="Aucun antécédent d'assurance"
            onClick={() => append(defaultSinistre)}
          ></div>
        )}

        <div className="layout-button-container-responsive">
          <FormGenericButton onClick={() => append(defaultSinistre)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un antécédent
          </FormGenericButton>

          <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
            Suivant
          </FormNextButton>
        </div>
      </form>
    </div>
  );
}
