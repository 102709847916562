import React, { ReactElement } from 'react';

import './ColoredText.scss';
import classNames from 'classnames';

export const ColoredText = ({
  text,
  className,
  color
}: {
  text: string;
  color?: string;
  className?: string;
}): ReactElement => {
  return <span className={classNames('text-colored', className, color)}>{text}</span>;
};
