import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { fetchAnneeConstructionOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import Extend from 'components/Extend/Extend';

type FormValues = {
  habitation: {
    anneeConstruction: string | undefined;
    surfaceHabitable: number | undefined;
    nbPieces: number | undefined;
    presenceGrandesPieces: string | undefined;
    nbGrandesPieces: number | undefined;
  };
  utilisationHabitation: {
    emmenagementDate: Date | undefined;
  };
};

const defaultState: FormValues = {
  habitation: {
    anneeConstruction: undefined,
    surfaceHabitable: undefined,
    nbPieces: undefined,
    presenceGrandesPieces: undefined,
    nbGrandesPieces: undefined
  },
  utilisationHabitation: {
    emmenagementDate: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormHabitationInfoComplementaire(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    habitation: Yup.object({
      anneeConstruction: Yup.string().required().nullable(),
      surfaceHabitable: Yup.number().typeError('Merci de renseigner un nombre').required().nullable(),
      nbPieces: Yup.number().typeError('Merci de renseigner un nombre').required().nullable(),
      presenceGrandesPieces: Yup.string().required().nullable(),
      nbGrandesPieces: Yup.number().when('presenceGrandesPieces', {
        is: 'true',
        then: schema => schema.typeError('Merci de renseigner un nombre').required().nullable(),
        otherwise: schema => schema.nullable().transform(value => (isNaN(value) ? undefined : value))
      })
    }),
    utilisationHabitation: Yup.object({
      emmenagementDate: Yup.date()
    })
  }).defined();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(
      {
        habitation: { ...props.previousFormValues.habitation, ...data.habitation },
        utilisationHabitation: { ...props.previousFormValues.utilisationHabitation, ...data.utilisationHabitation }
      },
      AccumulateStateToSeries
    );
  };

  const grandePiece = watch('habitation.presenceGrandesPieces');

  const anneeConstructionOptions = useFetch(fetchAnneeConstructionOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Quel est le <span>type de logement</span> à assurer ?
      </h1>

      <InputDate
        label="Date d'emménagement (facultative)"
        control={control}
        name="utilisationHabitation.emmenagementDate"
      />

      <InputSelect
        label="Année de construction"
        name="habitation.anneeConstruction"
        control={control}
        options={anneeConstructionOptions || []}
        errors={errors}
      />

      <InputNumber {...register('habitation.surfaceHabitable')} label="Surface habitable" errors={errors} />

      <InputNumber {...register('habitation.nbPieces')} label="Nb pièces principales > 9m2" errors={errors} />

      <InputRadioButtonInline
        labelField="Le logement possède de grandes pièces (>30m2)"
        {...register('habitation.presenceGrandesPieces')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <Extend extend={grandePiece === 'true'}>
        <InputNumber {...register('habitation.nbGrandesPieces')} label="Combien ?" errors={errors} />
      </Extend>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
