import React, { ReactElement } from 'react';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import { useFieldArray } from 'react-hook-form';
import FormGenericButton from 'components/FormGenericButton/FormGenericButton.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sinistre from './Components/Sinistre/Sinistre.component';
import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

type Key = 'conducteurPrincipal' | 'conducteurSecondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};
type Sinistre = {
  survenanceDate: Date | undefined;
  typeCode: string | undefined;
  natureCode: string | undefined;
  responsabiliteTaux: number | undefined;
};
export type Form = {
  sinistres: Sinistre[];
};
export type FormValues<K extends Key> = PartialRecord<K, Form>;

export default function FormAutoConducteurPart5({
  goNextStep,
  initialFormValues,
  formValues,
  data = { type: 'conducteurPrincipal' },
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const isConducteurPrincipal = type === 'conducteurPrincipal';

  const defaultSinistre: Sinistre = {
    survenanceDate: undefined,
    typeCode: undefined,
    natureCode: undefined,
    responsabiliteTaux: undefined
  };

  const defaultState: FormValues<Key> = {
    [type]: {
      sinistres: []
    }
  };

  const validationSchema = Yup.object({
    [type]: Yup.object({
      sinistres: Yup.array().of(
        Yup.object({
          survenanceDate: Yup.date().typeError('Merci de renseigner une date valide').required(),
          typeCode: Yup.string().nullable().required(),
          natureCode: Yup.string().required(),
          responsabiliteTaux: Yup.number().required()
        })
      )
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const { fields, append, remove } = useFieldArray<FormValues<Key>>({
    control,
    name: `${type}.sinistres`
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Conducteur <span>{isConducteurPrincipal ? 'principal' : 'secondaire'}</span>
      </h1>

      <h2 className="subtitle">Le conducteur {isConducteurPrincipal} a t&apos;il déclaré des sinistres ?</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        {fields.length ? (
          fields.map((item, index) => (
            <Sinistre key={index} index={index} control={control} errors={errors} remove={remove} type={type} />
          ))
        ) : (
          <div className="Infraction" data-empty="Aucun sinistre" onClick={() => append(defaultSinistre)}></div>
        )}

        <div className="layout-button-container-responsive">
          <FormGenericButton onClick={() => append(defaultSinistre)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un sinistre
          </FormGenericButton>

          <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
            Suivant
          </FormNextButton>
        </div>
      </form>
    </div>
  );
}
