import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import {
  fetchEtageLogementOptions,
  fetchLogementTypeOptions,
  fetchNatureLogementOptions,
  fetchPeriodeLocationLogementOptions
} from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import Extend from 'components/Extend/Extend';

type FormValues = {
  habitation: {
    estMeuble: string | undefined;
    etage?: string | undefined;
  };
  utilisationHabitation: {
    utilisation: string | undefined;
    nbMoisLoue: string | undefined;
    activitePro: string | undefined;
    occupantType: string | undefined;
  };
};

const defaultState: FormValues = {
  habitation: { estMeuble: undefined, etage: undefined },
  utilisationHabitation: {
    utilisation: undefined,
    nbMoisLoue: undefined,
    occupantType: undefined,
    activitePro: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormHabitationInfo(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const isApartment = props.previousFormValues.habitation.nature === 'APPARTEMENT:TYPE_LOGEMENT';

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    habitation: Yup.object({
      etage: isApartment ? Yup.string().required().nullable() : Yup.string(),
      estMeuble: Yup.string().required().nullable()
    }),
    utilisationHabitation: Yup.object({
      utilisation: Yup.string().required().nullable(),
      nbMoisLoue: Yup.string().when('typeResidence', {
        is: 'SECONDAIRE:NATURE_LOGEMENT',
        then: schema => schema.required(),
        otherwise: schema => schema.nullable()
      }),
      occupantType: Yup.string().required().nullable(),
      activitePro: Yup.string().required().nullable()
    })
  }).defined();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(
      {
        habitation: { ...props.previousFormValues.habitation, ...data.habitation },
        utilisationHabitation: { ...props.previousFormValues.utilisationHabitation, ...data.utilisationHabitation }
      },
      AccumulateStateToSeries
    );
  };

  const etageLogementOptions = useFetch(fetchEtageLogementOptions);
  const logementTypeOptions = useFetch(fetchLogementTypeOptions);
  const utilisationLogementOptions = useFetch(fetchNatureLogementOptions);
  const periodeLocationLogementOptions = useFetch(fetchPeriodeLocationLogementOptions);

  const utilisation = watch('utilisationHabitation.utilisation');

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Quel est le <span>type de logement</span> à assurer ?
      </h1>
      {isApartment && (
        <InputSelect
          label="Étage"
          control={control}
          name="habitation.etage"
          options={etageLogementOptions || []}
          errors={errors}
        />
      )}

      <InputSelect
        label="Type d'occupation"
        control={control}
        name="utilisationHabitation.occupantType"
        options={logementTypeOptions || []}
        errors={errors}
      />

      <InputRadioButtonInline
        labelField="Type de résidence"
        {...register('utilisationHabitation.utilisation')}
        options={utilisationLogementOptions || []}
        column={2}
        errors={errors}
      />

      <Extend extend={utilisation === 'SECONDAIRE:NATURE_LOGEMENT'}>
        <InputSelect
          label="Est-il proposé à la location ?"
          name="utilisationHabitation.nbMoisLoue"
          control={control}
          options={periodeLocationLogementOptions || []}
          errors={errors}
        />
      </Extend>

      <InputRadioButtonInline
        labelField="Le logement est-il meublé ?"
        {...register('habitation.estMeuble')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputRadioButtonInline
        labelField="Une activité professionnelle est exercée dans le logement"
        {...register('utilisationHabitation.activitePro')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
