import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGenericButton from 'components/FormGenericButton/FormGenericButton.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import Credit from './Components/Credit/Credit.component';

export const RELAIS_TYPE_PRET = 'RELAIS:TYPE_PRET';

type Credit = {
  typeCreditCode: string | undefined;
  banqueCode: string | undefined;
  dateDebut: Date | undefined;
  montant: number | undefined;
  taux: string | undefined;
  dureeMois: number | undefined;
  typeTauxCode: string | undefined;
  differe: boolean;
  delaiDiffere: number | undefined;
  typeDiffereCode: string | undefined;
};

type FormValues = {
  credits: Credit[];
};

const defaultCredit: Credit = {
  typeCreditCode: undefined,
  banqueCode: undefined,
  dateDebut: undefined,
  montant: undefined,
  taux: undefined,
  dureeMois: undefined,
  typeTauxCode: undefined,
  differe: false,
  delaiDiffere: undefined,
  typeDiffereCode: undefined
};

export default function FormEmprunteurCredits({
  previousFormValues,
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const defaultState: FormValues = {
    credits: []
  };

  const typeEmpruntCode = previousFormValues?.typeEmpruntCode;

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    credits: Yup.array()
      .of(
        Yup.object({
          typeCreditCode: Yup.string().required(),
          banqueCode: Yup.string().required(),
          dateDebut: Yup.date().typeError('Merci de renseigner une date valide').required(),
          montant: Yup.number().ignoreEmptyString().min(0).required(),
          taux: Yup.number().typeError('Champ obligatoire').min(0).required().nullable(),
          dureeMois: Yup.number().ignoreEmptyString().min(0).required(),
          typeTauxCode: Yup.string().required(),
          differe: Yup.boolean().required(),
          delaiDiffere: Yup.number()
            .ignoreEmptyString()
            .when('differe', {
              is: true,
              then: Yup.number().ignoreEmptyString().required('Champ obligatoire pour un prêt avec différé'),
              otherwise: Yup.number().ignoreEmptyString().nullable()
            }),
          typeDiffereCode: Yup.string().when('typeCreditCode', {
            is: RELAIS_TYPE_PRET,
            then: Yup.string().required("Champ obligatoire pour le type de prêt 'Relais'"),
            otherwise: Yup.string().when('differe', {
              is: true,
              then: Yup.string().required('Champ obligatoire pour un prêt avec différé'),
              otherwise: Yup.string().nullable()
            })
          })
        })
      )
      .notRequired()
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});
  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  const { fields, append, remove } = useFieldArray<FormValues>({
    control,
    name: 'credits'
  });
  useEffect(() => {
    if (!fields.length) {
      append(defaultCredit);
    }
  }, [fields]);

  const handleNextClick = (data: FormValues) => {
    goNextStep({
      credits: data?.credits || []
    });
  };

  return (
    <div>
      <h1 className="title">
        Prêt(s) immobilier <span> à assurer</span>
      </h1>

      <form onSubmit={handleSubmit(handleNextClick)}>
        {fields.length ? (
          fields.map((item, index) => (
            <Credit
              key={item.id}
              index={index}
              register={register}
              control={control}
              errors={errors}
              remove={index > 0 || fields.length > 1 ? remove : undefined}
              typeEmpruntCode={typeEmpruntCode}
            />
          ))
        ) : (
          <div className="Credit" data-empty="Aucun prêt" onClick={() => append(defaultCredit)} />
        )}

        <div className="layout-button-container-responsive">
          <FormGenericButton onClick={() => append(defaultCredit)}>
            <FontAwesomeIcon icon={faPlusCircle} /> Ajouter un prêt
          </FormGenericButton>

          <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
            Suivant
          </FormNextButton>
        </div>
      </form>
    </div>
  );
}
