import React, { FormEventHandler, ReactElement, ReactNode, RefObject, useEffect, useRef } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

function useCallFunctionOnValidChanges<T extends FieldValues>(
  watch: UseFormWatch<T>,
  isDirty: boolean,
  buttonRef: RefObject<HTMLButtonElement>
) {
  const values = watch();

  useEffect(() => {
    if (isDirty && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [JSON.stringify(values)]);
}

interface AutoSubmissionFormProps<T extends FieldValues> {
  children: ReactNode;
  watch: UseFormWatch<T>;
  isDirty: boolean;
  onSubmit: FormEventHandler;
}

/**
 *  will trigger props.onSubmit() onValidChange
 */

export function AutoSubmissionForm<T extends FieldValues>({
  children,
  watch,
  isDirty,
  onSubmit
}: AutoSubmissionFormProps<T>): ReactElement {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useCallFunctionOnValidChanges<T>(watch, isDirty, buttonRef);
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onSubmit(e);
      }}
    >
      {children}
      <button ref={buttonRef} tabIndex={-1} style={{ display: 'none' }} type="submit" />
    </form>
  );
}
