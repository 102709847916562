export enum TypeContrat {
  SAGE_AUTONOMIE,
  PAXIVIE,
  TUTLR,
  GARANTIE_COMPLEMENTAIRE_DEPENDANCE,
  HOSPICONFORT
}

export const LabelByTypeContrat: Record<TypeContrat, string> = {
  [TypeContrat.SAGE_AUTONOMIE]: 'Sâge Autonomie',
  [TypeContrat.PAXIVIE]: 'Paxivie',
  [TypeContrat.TUTLR]: 'Tutl’r',
  [TypeContrat.GARANTIE_COMPLEMENTAIRE_DEPENDANCE]: 'Garantie Complémentaire Dépendance',
  [TypeContrat.HOSPICONFORT]: 'HospiConfort'
};
