import React, { FC } from 'react';

import './RoundedCard.scss';
import classNames from 'classnames';

type RoundedCardSecondaryProps = {
  className?: string;
};

// eslint-disable-next-line react/prop-types
export const RoundedCardSecondary: FC<RoundedCardSecondaryProps> = ({ className, children }) => {
  return <div className={classNames('rounded-card border card secondary', className)}>{children}</div>;
};
