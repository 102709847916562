import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';

import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { OUI_NON_OPTIONS } from 'consts/consts';
import { fetchCouvertureAutresPaysExpat, fetchMotifSejour, fetchPaysOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import Extend from 'components/Extend/Extend';

type FormValues = {
  besoin: {
    paysDestination: string | undefined;
    couvertureExpatSupp: string | undefined;
    couvertureExpat: string | undefined;
    motifSejour: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    paysDestination: Yup.string().required(),
    couvertureExpatSupp: Yup.string().required(),
    couvertureExpat: Yup.string().when('couvertureExpatSupp', {
      is: 'true',
      then: Yup.string().required()
    }),
    motifSejour: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    paysDestination: undefined,
    couvertureExpatSupp: undefined,
    couvertureExpat: undefined,
    motifSejour: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
    if (key === 'dateEffetSouhaitee') return new Date(item);
    return item;
  });
}

export default function FormExpatDestination(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const paysDestinations = useFetch(fetchPaysOptions);
  const motifSejourOptions = useFetch(fetchMotifSejour);
  const couvertureExpatOptions = useFetch(fetchCouvertureAutresPaysExpat);

  const watchCouvertureExpat = watch('besoin.couvertureExpatSupp');

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Votre <span>projet</span>
      </h1>

      <InputSelect
        label="Pays de destination principal"
        name="besoin.paysDestination"
        control={control}
        errors={errors}
        options={paysDestinations || []}
        isSearchable={true}
      />

      <InputRadioButtonInline
        labelField={"Souhaitez vous couvrir d'autres zones en plus de votre pays d'expatriation ?"}
        {...register('besoin.couvertureExpatSupp')}
        options={OUI_NON_OPTIONS}
        onChange={async e => {
          if (e.target.value === 'false') setValue('besoin.couvertureExpat', 'NON:COUVERTURE_AUTRES_PAYS_EXPAT');
          setValue('besoin.couvertureExpatSupp', e.target.value);
        }}
        column
        errors={errors}
      />

      <Extend extend={watchCouvertureExpat === 'true'}>
        <InputRadioButtonInline
          labelField={'Précisez :'}
          {...register('besoin.couvertureExpat')}
          options={
            couvertureExpatOptions?.filter(couv => !couv.value.includes('NON:COUVERTURE_AUTRES_PAYS_EXPAT')) ?? []
          }
          column
          errors={errors}
        />
      </Extend>

      <InputRadioButtonInline
        labelField={'Quel est le motif de votre séjour ?'}
        {...register('besoin.motifSejour')}
        options={motifSejourOptions ?? []}
        column
        errors={errors}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
