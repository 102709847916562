import React, { ReactElement } from 'react';
import Yup from 'utils/Yup';
import FormChampPerso from './FormChampsPersos.component';
import { FormProps } from '../../types';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchLibelleCodif } from '../../fetches/options.fetch';
import LoaderWrapper from '../../components/LoaderWrapper/LoaderWrapper.component';

const FIELDS_TYPES = {
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  STRING: 'STRING',
  SELECT: 'SELECT'
};

const TYPE_VALIDATION = {
  [FIELDS_TYPES.BOOLEAN]: (obligatoire: boolean) => {
    const validation = Yup.mixed();
    return obligatoire ? validation.required() : validation.nullable();
  },
  [FIELDS_TYPES.DATE]: (obligatoire: boolean) => {
    const validation = Yup.date();
    return obligatoire ? validation.required() : validation.nullable();
  },
  [FIELDS_TYPES.NUMBER]: (obligatoire: boolean) => {
    const validation = Yup.number().ignoreEmptyString();
    return obligatoire ? validation.required() : validation.nullable();
  },
  [FIELDS_TYPES.STRING]: (obligatoire: boolean) => {
    const validation = Yup.string();
    return obligatoire ? validation.required() : validation.nullable();
  },
  [FIELDS_TYPES.SELECT]: (obligatoire: boolean) => {
    const validation = Yup.mixed();
    return obligatoire ? validation.required() : validation.nullable();
  }
};

const getValeurParDefaut = (champ: any) => {
  if (!champ.valeurParDefaut) return undefined;

  if (champ.type === FIELDS_TYPES.BOOLEAN) {
    return champ.valeurParDefaut === 'Oui';
  }

  return champ.valeurParDefaut;
};

function useChampsPersonnaliseWithForm(
  fields: any,
  baseValidationSchema = {},
  baseTemplate = {},
  champPersonnalisesAttr: any
) {
  if (fields === undefined) return { validationSchema: undefined, initialValues: undefined };
  if (fields === null) return { validationSchema: baseValidationSchema, template: baseTemplate };
  const champsPersonnaliseValidation = fields.reduce((obj: any, item: any) => {
    obj = {
      ...obj,
      [`--${item.id}`]: Yup.object().shape({
        type: Yup.string().nullable(),
        valeur: TYPE_VALIDATION[item.type](item.obligatoire)
      })
    };
    return obj;
  }, {});

  const extendedValidationSchema = Yup.object().shape({
    ...baseValidationSchema,
    [champPersonnalisesAttr]: Yup.object().shape({
      ...champsPersonnaliseValidation
    })
  });
  const extendedTemplate = fields.reduce(
    (obj: any, item: any) => {
      obj = {
        ...obj,
        [champPersonnalisesAttr]: {
          ...obj[champPersonnalisesAttr],
          [`--${item.id}`]: {
            valeur: getValeurParDefaut(item),
            type: item.type === 'SELECT' ? 'STRING' : item.type
          }
        }
      };
      return obj;
    },
    { ...baseTemplate, [champPersonnalisesAttr]: {} }
  );
  return {
    validationSchema: extendedValidationSchema,
    template: extendedTemplate
  };
}

export default function WrapperFormChampPerso(props: FormProps<any>): ReactElement {
  const { template, validationSchema } = useChampsPersonnaliseWithForm(
    props.champsPersonnalises,
    {},
    {},
    'champsPersonnalises'
  );

  const typologies = useFetch(fetchLibelleCodif);

  return (
    <>
      <LoaderWrapper isReadyToRender={validationSchema !== undefined && template !== undefined && typologies}>
        <FormChampPerso typologies={typologies} validationSchema={validationSchema} template={template} {...props} />
      </LoaderWrapper>
    </>
  );
}
