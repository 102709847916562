import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputText from 'components/Input/InputText/InputText.component';
import { CIVILITES_OPTIONS, ROLE_RELATION_OPTIONS } from 'consts/consts';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';

type Key = 'conducteurPrincipal' | 'conducteurSecondaire';
type PartialRecord<K extends Key, T> = {
  [P in K]?: T;
};
export type Form = {
  roleFoyerCode: string | undefined;
  personne: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
  };
};
export type FormValues<K extends Key> = PartialRecord<K, Form>;

export default function FormAutoConducteurPart1({
  goNextStep,
  initialFormValues,
  formValues,
  data = { type: 'conducteurPrincipal' },
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues<Key>, { type: Key }>): ReactElement {
  const { type } = data;
  const isConducteurPrincipal = type === 'conducteurPrincipal';

  const defaultState: FormValues<typeof type> = {
    [type]: {
      roleFoyerCode: undefined,
      personne: {
        civilite: undefined,
        nom: undefined,
        prenom: undefined,
        naissanceDate: undefined
      }
    }
  };

  const validationSchema: Yup.SchemaOf<FormValues<typeof type & any>> = Yup.object({
    [type]: Yup.object({
      roleFoyerCode: Yup.string(),
      personne: Yup.object({
        civilite: Yup.string().typeError('Champ obligatoire').required(),
        nom: Yup.string().required(),
        prenom: Yup.string().required(),
        naissanceDate: Yup.date().typeError('Merci de renseigner une date valide').required()
      })
    })
  }).defined();

  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues<Key>>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues<Key>) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues<Key>) => {
    goNextStep(data);
  };

  return (
    <div>
      <h1 className="title">
        Conducteur <span>{isConducteurPrincipal ? 'principal' : 'secondaire'}</span>
      </h1>

      <h2 className="subtitle">Identité</h2>

      <form onSubmit={handleSubmit(handleNextClick)}>
        <InputRadioButtonInline
          labelField={'Civilité'}
          {...register(`${type}.personne.civilite`)}
          options={CIVILITES_OPTIONS}
          column={2}
          errors={errors}
        />
        <InputText label={'Prénom'} {...register(`${type}.personne.prenom`)} errors={errors} />
        <InputText label={'Nom'} {...register(`${type}.personne.nom`)} errors={errors} />
        <InputDate label={'Date de naissance'} name={`${type}.personne.naissanceDate`} control={control} />

        {!isConducteurPrincipal && (
          <InputSelect
            name={`${type}.roleFoyerCode`}
            label={'Type de relation avec le conducteur principal'}
            options={ROLE_RELATION_OPTIONS}
            control={control}
            errors={errors}
          />
        )}

        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      </form>
    </div>
  );
}
