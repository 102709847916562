import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { fetchPeriodeInhabitationLogementOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InfoBulle from 'components/InfoBulle/InfoBulle';

type FormValues = {
  utilisationHabitation: {
    valeurObjetsMobiliers: number | undefined;
    presenceObjetsValeur: string | undefined;
    presenceAlarme: string | undefined;
    periodeInhabitation: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  utilisationHabitation: Yup.object({
    valeurObjetsMobiliers: Yup.number().typeError('Merci de renseigner un montant').required(),
    presenceObjetsValeur: Yup.string().required().nullable(),
    presenceAlarme: Yup.string().required().nullable(),
    periodeInhabitation: Yup.string().required().nullable()
  })
}).defined();

const defaultState: FormValues = {
  utilisationHabitation: {
    valeurObjetsMobiliers: undefined,
    presenceObjetsValeur: undefined,
    presenceAlarme: undefined,
    periodeInhabitation: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormHabitationCaracteristiques(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(
      {
        habitation: { ...props.previousFormValues.habitation },
        utilisationHabitation: { ...props.previousFormValues.utilisationHabitation, ...data.utilisationHabitation }
      },
      AccumulateStateToSeries
    );
  };

  const periodeInhabitationLogementOptions = useFetch(fetchPeriodeInhabitationLogementOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">Caractéristiques spéciales</h1>

      <InputNumber
        {...register('utilisationHabitation.valeurObjetsMobiliers')}
        label="Valeur mobilière à assurer"
        errors={errors}
      />
      <InputRadioButtonInline
        labelField={
          <>
            Présence d’objets de valeur ?{' '}
            <InfoBulle
              name={`description-objectsValeur`}
              key={'objectsValeur'}
              text={
                'Bijoux et métal précieux dont la valeur unitaire est supérieure à 300 €, tout bien mobilier d’une valeur unitaire supérieure à 8000 € indexés, collections et ensembles dont la valeur globale est supérieure à 16000 €'
              }
            />
          </>
        }
        {...register('utilisationHabitation.presenceObjetsValeur')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <InputRadioButtonInline
        labelField="Le logement possède t'il une alarme ?"
        {...register('utilisationHabitation.presenceAlarme')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <InputSelect
        label="Période ou le logement n'est pas habité"
        name="utilisationHabitation.periodeInhabitation"
        control={control}
        options={periodeInhabitationLogementOptions || []}
        errors={errors}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
