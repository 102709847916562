import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { fetchTypeAchatOptions, fetchTitulaireCarteGriseOptions } from 'fetches/options.fetch';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import { useFetch } from 'hooks/useFetch.hook';

type FormValues = {
  besoin: {
    vehicule: {
      achatDate: Date | undefined;
      modifieTechniquement: string | undefined;
    };
    typeTitulaireCode: string | undefined;
    achatTypeCode: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    vehicule: Yup.object({
      achatDate: Yup.date().typeError('Merci de renseigner une date valide').required(),
      modifieTechniquement: Yup.string().nullable().required()
    }),
    typeTitulaireCode: Yup.string().required(),
    achatTypeCode: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    vehicule: {
      achatDate: undefined,
      modifieTechniquement: undefined
    },
    typeTitulaireCode: undefined,
    achatTypeCode: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormAutoInfoVehicule(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);
  const typeAchatOptions = useFetch(fetchTypeAchatOptions);
  const titulaireCarteGriseOptions = useFetch(fetchTitulaireCarteGriseOptions);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Votre <span>véhicule</span>
      </h1>

      <InputDate label="Date d'achat" name="besoin.vehicule.achatDate" control={control} />

      <InputSelect
        label="Type d'achat"
        name="besoin.achatTypeCode"
        control={control}
        options={typeAchatOptions || []}
        errors={errors}
      />

      <InputRadioButtonInline
        labelField="Le véhicule a-t-il subi des modifications techniques ?"
        {...register('besoin.vehicule.modifieTechniquement')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputSelect
        label="Titulaire de la carte grise"
        name="besoin.typeTitulaireCode"
        control={control}
        options={titulaireCarteGriseOptions || []}
        errors={errors}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
