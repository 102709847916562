import { FormConfigWithUrl } from 'types';
import FormPrescripteurChoixProfil from '../forms/PRESCRIPTEUR/FormPrescripteurChoixProfil';
import FormPrescripteurParticulierIdentite from '../forms/PRESCRIPTEUR/particulier/FormPrescripteurParticulierIdentite.component';
import FormPrescripteurParticulierInfoContact from '../forms/PRESCRIPTEUR/particulier/FormPrescripteurParticulierInfoContact.component';
import FormPrescripteurEntrepriseIdentiteInterlocuteur from '../forms/PRESCRIPTEUR/entreprise/FormPrescripteurEntrepriseIdentiteInterlocuteur.component';
import FormPrescripteurEntrepriseCoordonneesInterlocuteur from '../forms/PRESCRIPTEUR/entreprise/FormPrescripteurEntrepriseCoordonneesInterlocuteur.component';
import FormPrescripteurTnsIdentite from '../forms/PRESCRIPTEUR/tns/FormPrescripteurTnsIdentite.component';
import FormPrescripteurTnsEntreprise from '../forms/PRESCRIPTEUR/tns/FormPrescripteurTnsEntreprise.component';
import FormPrescripteurTnsInfosContact from '../forms/PRESCRIPTEUR/tns/FormPrescripteurTnsInfosContact.component';
import FormPrescripteurDescription from '../forms/PRESCRIPTEUR/FormPrescripteurDescription.component';
import FormPrescripteurCheckEmail from '../forms/PRESCRIPTEUR/FormPrescripteurCheckEmail.component';
import FormContactEntrepriseIdentite from '../forms/CONTACT/entreprise/FormContactEntrepriseIdentite.component';

const prescripteurConfig: FormConfigWithUrl = {
  endpoint: 'contact', //même endpoint que la création d'un contact
  formSteps: [
    {
      component: FormPrescripteurCheckEmail
    },
    {
      component: FormPrescripteurChoixProfil,
      next: {
        selectNextStep: (prevStepState: { profil: string }): string | undefined => {
          switch (prevStepState.profil) {
            case 'ENTREPRISE':
              return 'ENTREPRISE';
            case 'TNS':
              return 'TNS';
            case 'PARTICULIER':
              return 'PARTICULIER';
          }
        },
        paths: {
          PARTICULIER: [
            { component: FormPrescripteurParticulierIdentite },
            { component: FormPrescripteurParticulierInfoContact }
          ],
          ENTREPRISE: [
            {
              component: FormContactEntrepriseIdentite
            },
            { component: FormPrescripteurEntrepriseIdentiteInterlocuteur },
            {
              component: FormPrescripteurEntrepriseCoordonneesInterlocuteur
            }
          ],
          TNS: [
            { component: FormPrescripteurTnsIdentite },
            { component: FormPrescripteurTnsInfosContact },
            { component: FormPrescripteurTnsEntreprise }
          ]
        }
      }
    },
    {
      component: FormPrescripteurDescription,
      data: { showCaptcha: true }
    }
  ]
};

[];

export default prescripteurConfig;
