export enum MotifResiliation {
  ECHEANCE,
  TARIF_ELEVE,
  COUVERTURE_SIMILAIRE_AUTRE_EMPLOYEUR,
  AUTRE
}

export const LabelByMotifResiliation: Record<MotifResiliation, string> = {
  [MotifResiliation.ECHEANCE]: 'Mon contrat arrive à échéance',
  [MotifResiliation.TARIF_ELEVE]: 'Je trouve le tarif trop élevé',
  [MotifResiliation.COUVERTURE_SIMILAIRE_AUTRE_EMPLOYEUR]: 'Je possède une couverture similaire avec mon employeur',
  [MotifResiliation.AUTRE]: 'Autre'
};
