import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ChangeHandler, Control, useController } from 'react-hook-form';
import classNames from 'classnames';
import InfoBulle from 'components/InfoBulle/InfoBulle';

import './InputCheckboxButton.scss';

interface InputCheckboxButtonProps {
  onChange?: ChangeHandler;
  control: Control<any>;
  column?: boolean | 1 | 2 | 3 | 4;
  label: string;
  name: string;
  noCenterLabel?: boolean;
  error?: boolean;
  tooltip?: string;
}

export const InputCheckboxButton = React.forwardRef<HTMLInputElement, InputCheckboxButtonProps>(
  ({ name, control, label, column, noCenterLabel, error, tooltip }: InputCheckboxButtonProps, ref) => {
    const {
      field: { onChange, onBlur, value }
    } = useController({
      name,
      control
    });

    return (
      <div className={classNames('InputCheckboxButton', { [`-column-${column === true ? 2 : column}`]: column })}>
        <label
          className={classNames('InputCheckboxButton__label', {
            '-selected': !!value,
            '-error': error,
            '-noCenterLabel': noCenterLabel
          })}
        >
          <input
            type="checkbox"
            name={name}
            id={name}
            ref={ref}
            checked={!!value}
            onChange={onChange}
            onBlur={onBlur}
          />
          {label}
          {!!value && <FontAwesomeIcon icon={faCheck} className="InputRadioInline__checkmark" />}
        </label>
        {tooltip && <InfoBulle name={`description-${name}`} key={`description-${name}`} text={tooltip || ''} />}
      </div>
    );
  }
);
InputCheckboxButton.displayName = 'InputCheckboxButton';

export default InputCheckboxButton;
