import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import InputDatePicker from '../../components/Input/InputDatePicker/InputDatePicker.component';
import { assignValuesFromSource } from '../../utils';

type FormValues = {
  dateEffetSouhaitee: Date | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  dateEffetSouhaitee: Yup.date().required()
}).defined();

const defaultState: FormValues = {
  dateEffetSouhaitee: undefined
};

export default function FormPrevoyanceChoixDate(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = assignValuesFromSource(
    defaultState,
    props.initialFormValues || {},
    (item: any, key: string) => {
      if (key === 'dateEffetSouhaitee') return new Date(item);
      return item;
    }
  );

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Quelle date de début souhaitez-vous pour votre futur contrat de prévoyance ?</h2>
      <div className={'layout-field-container'}>
        <InputDatePicker
          className="layout-flex-basis-auto"
          label="Date de début d'effet souhaitée"
          showMonthDropdown
          showYearDropdown
          name={'dateEffetSouhaitee'}
          errors={errors}
          control={control}
        />
      </div>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
