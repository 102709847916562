import { yupResolver } from '@hookform/resolvers/yup';
import FormConsent from 'components/FormConsent/FormConsent.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';
import { insertIf } from 'forms/DECOUVERTE_PROSPECT/Risques/CommunUtil';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import moment from 'moment';
import 'moment/locale/fr';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';

type FormValues = {
  reglages: string | null;
  jourRappel: string | undefined;
  heureRappel: string | undefined;
  description: string | undefined;
  consentement: boolean | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  reglages: Yup.string().required(),
  jourRappel: Yup.string().when('reglages', {
    is: 'true',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),
  heureRappel: Yup.string().when('reglages', {
    is: 'true',
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired()
  }),
  description: Yup.string().max(4096),
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

const defaultState: FormValues = {
  reglages: null,
  jourRappel: '',
  heureRappel: '',
  description: '',
  consentement: false
};

const canShowDescription = (values: FormValues): boolean => {
  if (values.reglages === 'false') return true;
  else if (values.reglages === 'true' && values.jourRappel && values.heureRappel) return true;
  else return false;
};

export function capitalizeFirstLetter(elt: string): string {
  return elt.charAt(0).toUpperCase() + elt.slice(1);
}

const isoWeekdays = {
  LUNDI: 1,
  MARDI: 2,
  MERCREDI: 3,
  JEUDI: 4,
  VENDREDI: 5,
  SAMEDI: 6,
  DIMANCHE: 7
};

let demain;
let apresDemain;

const currentWeekday = moment().isoWeekday();

switch (currentWeekday) {
  case isoWeekdays.JEUDI:
    demain = moment().add(1, 'days');
    apresDemain = moment().add(4, 'days');
    break;
  case isoWeekdays.VENDREDI:
    demain = moment().add(3, 'days');
    apresDemain = moment().add(4, 'days');
    break;
  case isoWeekdays.SAMEDI:
    demain = moment().add(2, 'days');
    apresDemain = moment().add(3, 'days');
    break;
  case isoWeekdays.DIMANCHE:
  case isoWeekdays.LUNDI:
  case isoWeekdays.MARDI:
  case isoWeekdays.MERCREDI:
  default:
    demain = moment().add(1, 'days');
    apresDemain = moment().add(2, 'days');
    break;
}

export const JOUR_DISPONIBLES: { label: string; value: string }[] =
  // Si on est le matin, on propose AUjourdhui, demain et aprés demain.
  // SI on est déjà l'aprés midi, on ne propose que demain et aprés demain
  [
    ...insertIf(
      parseInt(moment().format('HH')) < 12 &&
        currentWeekday !== isoWeekdays.SAMEDI &&
        currentWeekday !== isoWeekdays.DIMANCHE,
      {
        label: capitalizeFirstLetter(moment().locale('fr').format('dddd DD/MM')),
        value: moment().format('YYYY-MM-DD')
      }
    ),
    {
      label: capitalizeFirstLetter(demain.locale('fr').format('dddd DD/MM')),
      value: demain.format('YYYY-MM-DD')
    },
    {
      label: capitalizeFirstLetter(apresDemain.locale('fr').format('dddd DD/MM')),
      value: apresDemain.format('YYYY-MM-DD')
    }
  ];

export function getPlageHoraires(jourRappel: string | undefined): { label: string; value: string }[] {
  if (!jourRappel) {
    return [];
  }

  if (moment().format('YYYY-MM-DD') === jourRappel) {
    return [
      {
        label: '14h-15h',
        value: moment().set('hour', 14).format('HH')
      }
    ];
  }

  return [
    {
      label: '11h-12h',
      value: moment(jourRappel).set('hour', 11).format('HH')
    },
    {
      label: '14h-15h',
      value: moment(jourRappel).set('hour', 14).format('HH')
    }
  ];
}

export default function FormMiseEnRelationChoixCrenaux(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2 style={{ marginTop: 48, color: 'var(--color-primary)' }}>
        Choisissez les <span style={{ color: 'var(--color-secondary)' }}>modalités de rappel</span>
      </h2>
      <h3 style={{ marginTop: 48, marginBottom: 24 }}>Vous souhaitez un rappel :</h3>

      <InputRadioButtonInline
        className="input-radio-label-large"
        {...register('reglages')}
        options={[
          {
            label: 'Immédiat',
            value: 'false'
          },
          {
            label: 'À un autre moment',
            value: 'true'
          }
        ]}
        errors={errors}
      />

      {getValues().reglages === 'true' && (
        <>
          <h3 style={{ marginTop: 48, marginBottom: 24 }}>Choisissez la date et l&apos;heure :</h3>

          <InputRadioButtonInline
            className="input-radio-label-large"
            {...register('jourRappel')}
            options={JOUR_DISPONIBLES}
            errors={errors}
          />
          {getValues().jourRappel && (
            <>
              <InputRadioButtonInline
                className="input-radio-label-large"
                {...register('heureRappel')}
                options={getPlageHoraires(getValues().jourRappel)}
                errors={errors}
              />
            </>
          )}
        </>
      )}

      {canShowDescription(getValues()) && (
        <div>
          <h2 style={{ marginTop: 48, color: 'var(color-primary)' }}>
            Votre <span style={{ color: 'var(--color-secondary)' }}>message</span>
          </h2>
          <span>Facultatif</span>
          <InputTextArea label="" {...register('description')} errors={errors} />
          <FormConsent
            contenuConsentement={props.contenuConsentement}
            control={control}
            errors={errors}
            label={props.cocheConsentement}
            {...register('consentement')}
          />
        </div>
      )}

      <FormNextButton disabled={!isValid} showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
