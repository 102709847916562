import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as SvgNouveau } from 'assets/nouveau.svg';
import { ReactComponent as SvgUpdate } from 'assets/update.svg';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useMemo } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';

type FormValues = {
  typeEmpruntCode: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  typeEmpruntCode: Yup.string().required()
}).defined();

const defaultState: FormValues = {
  typeEmpruntCode: undefined
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormEmprunteurTypeAssurance({
  previousFormValues,
  setLastFormState,
  formValues,
  goNextStep,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(previousFormValues), []);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isDirty }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues || initialValues || defaultState) as FormValues
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => goNextStep(values))}
    >
      <h1 className="title">
        Description <span>de l&apos;emprunt</span>
      </h1>

      <InputRadioButtonIcon
        {...register('typeEmpruntCode')}
        errors={errors}
        options={[
          {
            value: 'NOUVEAU:TYPE_PROJET_EMPRUNT',
            label: 'Nouvel emprunt',
            icon: <SvgNouveau style={{ width: '100px', height: 'auto' }} />
          },
          {
            value: 'CHANGEMENT_ASSURANCE:TYPE_PROJET_EMPRUNT',
            label: "Changement d'assurance",
            icon: <SvgUpdate style={{ width: '100px', height: 'auto' }} />
          }
        ]}
        noWrap
      />
      {!!formValues && (
        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      )}
    </AutoSubmissionForm>
  );
}
