import { merge } from 'lodash';
import moment from 'moment/moment';
import {
  AccumulateStateToSerie,
  FormConfigWithUrl,
  FormParams,
  LastFormStateInstance,
  SeriesConfigs,
  StepNode
} from '../../types';
import { Typo } from '../../forms/DEMANDE_ADHESION/ReglesGestion/Typo';

const padTo2Digits = (num: number): string => num.toString().padStart(2, '0');

const formatDate = (date: Date): string =>
  [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-');

export const castToISODateRecursively = (obj: Record<string, unknown>): void => {
  const needToBeCastKeys = ['naissanceDate', 'naissanceDateAnimal', 'dateEffetSouhaitee'];

  const keys = Object.keys(obj);
  keys.forEach(key => {
    if (obj[key] instanceof Object && !(obj[key] instanceof Date)) {
      castToISODateRecursively(obj[key] as Record<string, unknown>);
    } else if (needToBeCastKeys.includes(key) && obj[key] instanceof Date) {
      const currDate: Date = obj[key] as Date;
      obj[key] = new Date(formatDate(currDate)).toISOString();
    }
  });
};

export const isReadOnly = (
  type: string,
  currentConfig: FormConfigWithUrl,
  actualNode: StepNode,
  stepCodeFromInit: string
): boolean => {
  const step = actualNode.config.stepCode;
  if (!step) return false;
  const currentIndexStepInOrder = currentConfig.stepOrder?.findIndex(val => val === step);
  const indexStepOrderFromInit = currentConfig.stepOrder?.findIndex(val => val === stepCodeFromInit);
  if (!indexStepOrderFromInit || !currentIndexStepInOrder) return false;
  if (indexStepOrderFromInit === -1 || currentIndexStepInOrder === -1) return false;
  return indexStepOrderFromInit > currentIndexStepInOrder;
};

// each form will update this buffer on update
export const getLastFormStateInstance = (): LastFormStateInstance<any> => {
  let formState: any;
  return {
    get: () => formState,
    set: newState => {
      formState = newState;
    }
  };
};

export const getConfig = (formParams: FormParams, seriesConfigs: SeriesConfigs): FormConfigWithUrl => {
  if (formParams.type === 'DEMANDE_ADHESION') {
    const currentTypo = formParams.request.typologieLabel.split(':')[0];
    const withInfos = Object.values(Typo).includes(currentTypo);

    if (formParams.request?.avecFrais) {
      if (withInfos) {
        return seriesConfigs['demandeAdhesionAvecFraisAvecInfos'];
      }
      return seriesConfigs['demandeAdhesionAvecFraisSansInfos'];
    }
    if (withInfos) {
      return seriesConfigs['demandeAdhesionSansFraisAvecInfos'];
    }
    return seriesConfigs['demandeAdhesionSansFraisSansInfos'];
  }

  if (formParams.type === 'PROJET') {
    if (seriesConfigs[formParams.cible]) {
      return seriesConfigs[formParams.cible];
    } else {
      return seriesConfigs['PROJET_MANUEL'];
    }
  }

  if (!seriesConfigs[formParams.type]) {
    throw Error(`No config found for type ${formParams}`);
  }

  return seriesConfigs[formParams.type];
};

export const getTitlePanelLeft = (
  currentConfig: FormConfigWithUrl,
  formParams: FormParams,
  keyParams: string
): string => {
  if (currentConfig.isTitleLeftCommercial && formParams.parametres.FORMULAIRE_MARQUE_COMMERCIALE) {
    return formParams.parametres.FORMULAIRE_MARQUE_COMMERCIALE;
  }
  if (formParams.parametres[`${keyParams}_TITRE_PAGE_GAUCHE`]) {
    return formParams.parametres[`${keyParams}_TITRE_PAGE_GAUCHE`];
  }

  return formParams.parametres.FORMULAIRE_MARQUE_COMMERCIALE;
};

export const defaultAccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addBesoinsPrevoyance = (toSubmit: any): any => {
  const result = [];
  result.push({ ...toSubmit.capitalDeces, type: 'CAPITAL_DECES' });
  result.push({ ...toSubmit.renteEducation, type: 'RENTE_EDUCATION' });
  result.push({ ...toSubmit.renteConjoint, type: 'RENTE_CONJOINT' });
  result.push({ ...toSubmit.incapacite, type: 'INCAPACITE' });
  result.push({ ...toSubmit.invalidite, type: 'INVALIDITE' });
  result.push({ ...toSubmit.typeIndemnisation, type: 'TYPE_INDEMNISATION' });
  result.push({ ...toSubmit.risquesAutres, type: 'AUTRES_RISQUES' });

  return result;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addNiveauxSanteParticulier = (toSubmit: any): any => {
  return [
    {
      axe: 'HOSPITALISATION:AXE_BESOIN_SANTE',
      prestation: 'FRAIS_HOSPITALISATION:MACROPRESTATION_SANTE_HOSPITALISATION',
      niveau: toSubmit.niveauGP.hospitalisation,
      demandeInformations: false
    },
    {
      axe: 'HOSPITALISATION:AXE_BESOIN_SANTE',
      prestation: 'CHAMBRE_PARTICULIERE:MACROPRESTATION_SANTE_HOSPITALISATION',
      niveau: toSubmit.niveauGP.hospitalisation,
      demandeInformations: false
    },
    {
      axe: 'SOINS_COURANTS_CONSULTATIONS:AXE_BESOIN_SANTE',
      prestation: 'DEPASSEMENT_HONORAIRES:MACROPRESTATION_SANTE_SOINS_COURANTS_CONSULTATIONS',
      niveau: toSubmit.niveauGP.soinCourants,
      demandeInformations: false
    },
    {
      axe: 'DENTAIRE:AXE_BESOIN_SANTE',
      prestation: 'SOINS_DENTAIRES:MACROPRESTATION_SANTE_DENTAIRE',
      niveau: toSubmit.niveauGP.dentaire,
      demandeInformations: false
    },
    {
      axe: 'OPTIQUE:AXE_BESOIN_SANTE',
      prestation: 'EQUIPEMENT_LUNETTES_LENTILLES:MACROPRESTATION_SANTE_OPTIQUE',
      niveau: toSubmit.niveauGP.optique,
      demandeInformations: false
    },
    {
      axe: 'AUDITION:AXE_BESOIN_SANTE',
      prestation: 'PROTHESES_AUDITIVES:MACROPRESTATION_SANTE_AUDITION',
      niveau: toSubmit.niveauGP.audition,
      demandeInformations: false
    },
    {
      axe: 'MEDECINE_DOUCE:AXE_BESOIN_SANTE',
      prestation: 'CONSULTATIONS:MACROPRESTATION_SANTE_MEDECINE_DOUCE',
      niveau: toSubmit.niveauGP.soinCourants,
      demandeInformations: false
    }
  ];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const creerDateRappel = (toSubmit: any): any => {
  if (toSubmit.reglages === 'false') return undefined;

  return moment(toSubmit.jourRappel + ' ' + toSubmit.heureRappel, 'YYYY-MM-DD HH:00:00').toDate();
};
