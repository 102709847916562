import { yupResolver } from '@hookform/resolvers/yup';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import InputText from '../../components/Input/InputText/InputText.component';
import InputTextAutoComplete from '../../components/Input/InputText/InputTextAutoComplete';
import { fetchPaysOptions, fetchVilleOptions } from '../../fetches/options.fetch';
import { useFetch } from '../../hooks/useFetch.hook';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import { assignValuesFromSource } from '../../utils';

type FormValues = {
  assure: {
    contactMail: string;
    contactTelephone: string;
    contactCodePostal: string;
    ville: string;
    pays: string;
    voie: string;
    complementAdresse: string | undefined;
  };
};

const defaultState: FormValues = {
  assure: {
    contactMail: '',
    contactTelephone: '',
    contactCodePostal: '',
    ville: '',
    pays: 'FR:PAYS',
    voie: '',
    complementAdresse: undefined
  }
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    contactMail: Yup.string().max(255).email().required(),
    contactTelephone: Yup.string()
      .required()
      .transform((val: string) => val.replace(/\s/g, ''))
      .max(255)
      .telephone(),
    voie: Yup.string().required().max(255),
    complementAdresse: Yup.string().max(255).notRequired(),
    contactCodePostal: Yup.string().required(),
    ville: Yup.string().required().max(255),
    pays: Yup.string().required()
  })
}).defined();

export default function FormDecouverteCoordonne(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'assure.contactCodePostal', fetchVilleOptions);

  const paysOptions = useFetch(fetchPaysOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Vos coordonnées :</h2>
      <InputText label="Adresse" {...register('assure.voie')} errors={errors} />
      <InputText label="Complément d'adresse (facultatif)" {...register('assure.complementAdresse')} errors={errors} />
      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('assure.contactCodePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="assure.ville"
          control={control}
          errors={errors}
          options={villeOptions}
        />
      </div>
      <InputTextAutoComplete
        label={'Pays'}
        name={'assure.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />
      <InputText label="Email personnel" {...register('assure.contactMail')} errors={errors} />

      <InputText label="Téléphone" {...register('assure.contactTelephone')} errors={errors} preventTelephone={true} />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
