import AppFailureLoader from 'components/AppFailureLoader/AppFailureLoader.component';
import { AppLoader } from 'components/AppLoader/AppLoader.component';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary.component';
import FormLoader from 'components/FormLoader/FormLoader.component';
import fr from 'date-fns/locale/fr';
import moment from 'moment';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { BrowserRouter as Router } from 'react-router-dom';

registerLocale('fr', fr);
setDefaultLocale('fr');
moment.locale('fr');

const App: React.FC = () => (
  <AppFailureLoader>
    {({ displayFailure }) => (
      <AppLoader>
        {({ setLoadingState }) => (
          <ErrorBoundary displayFailure={displayFailure}>
            <Router>
              <FormLoader setLoadingState={setLoadingState} displayFailure={displayFailure} />
            </Router>
          </ErrorBoundary>
        )}
      </AppLoader>
    )}
  </AppFailureLoader>
);

export default App;
