export const CIVILITES_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Monsieur',
    value: 'MONSIEUR'
  },
  {
    label: 'Madame',
    value: 'MADAME'
  }
];

export const DOCUMENT_IDENTITE_OPTIONS: { label: string; value: string }[] = [
  {
    label: "Carte d'identité",
    value: 'CNI'
  },
  {
    label: 'Passeport',
    value: 'PASSEPORT'
  },
  {
    label: 'Permis de conduire',
    value: 'PERMIS'
  },
  {
    label: 'Titre de séjour',
    value: 'TITRE_SEJOUR'
  }
];

export const CIVILITES_OPTIONS_DECONTRACTE: { label: string; value: string }[] = [
  {
    label: 'Une femme',
    value: 'MADAME'
  },
  {
    label: 'Un homme',
    value: 'MONSIEUR'
  }
];

export const OUI_NON_OPTIONS: {
  label: string;
  value: string;
  meta: undefined;
}[] = [
  {
    label: 'Oui',
    meta: undefined,
    value: 'true'
  },
  {
    label: 'Non',
    meta: undefined,
    value: 'false'
  }
];

export const TYPES_ANIMAUX_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Chien',
    value: 'CHIEN:TYPE_ANIMAL'
  },
  {
    label: 'Chat',
    value: 'CHAT:TYPE_ANIMAL'
  }
];

export const NIVEAUX_SANTE_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Minimal',
    value: 'NIVEAU_1:INTITULE_NIVEAU_RETENU_SANTE'
  },
  {
    label: 'Elémentaire',
    value: 'NIVEAU_2:INTITULE_NIVEAU_RETENU_SANTE'
  },
  {
    label: 'Confort',
    value: 'NIVEAU_3:INTITULE_NIVEAU_RETENU_SANTE'
  },
  {
    label: 'Optimal',
    value: 'NIVEAU_4:INTITULE_NIVEAU_RETENU_SANTE'
  }
];

export const SEXES_ANIMAUX_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Femelle',
    value: 'FEMELLE:SEXE_ANIMAL'
  },
  {
    label: 'Mâle',
    value: 'MALE:SEXE_ANIMAL'
  }
];

export const ROLE_RELATION_OPTIONS: { label: string; value: string }[] = [
  {
    label: 'Aucun lien familial',
    value: ''
  },
  {
    label: 'Enfant',
    value: 'ENFANT:ROLE_RELATION_TYPE'
  },
  {
    label: 'Parent',
    value: 'PARENT:ROLE_RELATION_TYPE'
  },
  {
    label: 'Conjoint',
    value: 'CONJOINT:ROLE_RELATION_TYPE'
  },
  {
    label: 'Frère/Soeur',
    value: 'FRATERIE:ROLE_RELATION_TYPE'
  }
];
