import React, { ReactElement } from 'react';
import './LinkWithIcon.scss';
import classNames from 'classnames';
import { ObfuscatedLink } from 'components/ObfuscatedLink/ObfuscatedLink.component';

type TitleWithIconProps = {
  icon: ReactElement;
  link: string;
  labelLink: string;
  className?: string;
};

export const LinkWithIcon = ({ link, icon, labelLink, className }: TitleWithIconProps): ReactElement => {
  return (
    <ObfuscatedLink href={link} className={classNames('link-with-icon', className)}>
      {icon}
      <b>{labelLink}</b>
    </ObfuscatedLink>
  );
};
