import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { useFetch } from '../../hooks/useFetch.hook';
import {
  fetchStatutProOptions,
  fetchRegimeSecuOptions,
  fetchSituationMatrimonialeOptions
} from '../../fetches/options.fetch';
import { assignValuesFromSource } from '../../utils';

type FormValues = {
  assure: {
    situationMatrimoniale: string | undefined;
    statutProfessionnel: string | null | undefined;
    regime: string | null | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    statutProfessionnel: Yup.string().required(),
    regime: Yup.string().required(),
    situationMatrimoniale: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  assure: {
    statutProfessionnel: '',
    regime: '',
    situationMatrimoniale: ''
  }
};

export default function FormSanteTNSSituationPro(props: FormProps<FormValues>): ReactElement {
  const initialValues: any = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const statutsPro = useFetch(fetchStatutProOptions);
  const regimesSecu = useFetch(fetchRegimeSecuOptions);
  const situationMat = useFetch(fetchSituationMatrimonialeOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre situation personnelle:</h2>
      <div style={{ display: 'flex' }}>
        <InputSelect
          label="Situation matrimoniale"
          name="assure.situationMatrimoniale"
          control={control}
          errors={errors}
          options={situationMat || []}
        />
      </div>

      <h2 style={{ marginTop: '15px' }}>Votre Situation professionnelle : </h2>

      <div style={{ display: 'flex' }}>
        <InputSelect
          className={'layout-flex-basis-auto'}
          label="Statut professionnel"
          name="assure.statutProfessionnel"
          control={control}
          errors={errors}
          options={statutsPro || []}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <InputSelect
          className={'layout-flex-basis-auto'}
          label="Régime de sécurité sociale"
          name="assure.regime"
          control={control}
          errors={errors}
          options={regimesSecu || []}
        />
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
