import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { fetchSystemeChauffageLogementOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import Extend from 'components/Extend/Extend';
import InputText from 'components/Input/InputText/InputText.component';

type FormValues = {
  utilisationHabitation: {
    nbAdultes: number | undefined;
    nbEnfants: number | undefined;
    nbEnfantMajeurs: number | undefined;
    presenceChiensDangereux: string | undefined;
  };
  habitation: {
    systemeChauffage: string | undefined;
    systemeChauffageInstalleParUnPro: string | undefined;
    systemeChauffageAutre: string | undefined;
    presenceEnergieRenouvelable: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  utilisationHabitation: Yup.object({
    nbAdultes: Yup.number().typeError('Merci de renseigner un nombre').required().nullable(),
    nbEnfants: Yup.number().typeError('Merci de renseigner un nombre').required().nullable(),
    nbEnfantMajeurs: Yup.number().when('nbEnfant', {
      is: (e: number) => e > 0,
      then: schema => schema.typeError('Merci de renseigner un nombre').required().nullable(),
      otherwise: schema => schema.nullable().transform(value => (isNaN(value) ? undefined : value))
    }),
    presenceChiensDangereux: Yup.string().required().nullable()
  }),
  habitation: Yup.object({
    systemeChauffage: Yup.string().required().nullable(),
    systemeChauffageInstalleParUnPro: Yup.string().when('systemeChauffage', {
      is: (e: string) => 'AUTRE:SYSTEME_CHAUFFAGE_LOGEMENT' !== e,
      then: schema => schema.required().nullable(),
      otherwise: schema => schema.nullable()
    }),
    systemeChauffageAutre: Yup.string().when('systemeChauffage', {
      is: (e: string) => 'AUTRE:SYSTEME_CHAUFFAGE_LOGEMENT' === e,
      then: schema => schema.required().nullable(),
      otherwise: schema => schema.nullable()
    }),
    presenceEnergieRenouvelable: Yup.string().required().nullable()
  })
}).defined();

const defaultState: FormValues = {
  utilisationHabitation: {
    nbAdultes: undefined,
    nbEnfants: undefined,
    nbEnfantMajeurs: undefined,
    presenceChiensDangereux: undefined
  },
  habitation: {
    systemeChauffage: undefined,
    systemeChauffageInstalleParUnPro: undefined,
    systemeChauffageAutre: undefined,
    presenceEnergieRenouvelable: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormHabitationCaracteristiques(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(
      {
        habitation: { ...props.previousFormValues.habitation, ...data.habitation },
        utilisationHabitation: { ...props.previousFormValues.utilisationHabitation, ...data.utilisationHabitation }
      },
      AccumulateStateToSeries
    );
  };

  const nbEnfant = watch('utilisationHabitation.nbEnfants');
  const systemeChauffage = watch('habitation.systemeChauffage');

  const systemeChauffageLogementOptions = useFetch(fetchSystemeChauffageLogementOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">Caractéristiques spéciales</h1>

      <InputNumber
        {...register('utilisationHabitation.nbAdultes')}
        label="Nombre d’adultes vivant au domicile"
        errors={errors}
      />

      <InputNumber
        {...register('utilisationHabitation.nbEnfants')}
        label="Nombre d’enfants vivant au domicile"
        errors={errors}
      />

      <Extend extend={!!nbEnfant && nbEnfant > 0}>
        <InputNumber
          {...register('utilisationHabitation.nbEnfantMajeurs')}
          label="Présence d’enfants > 18 ans"
          errors={errors}
        />
      </Extend>

      <InputRadioButtonInline
        labelField="Chien(s) de catégorie 1 ou 2"
        {...register('utilisationHabitation.presenceChiensDangereux')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputSelect
        label="Systeme de chauffage"
        name="habitation.systemeChauffage"
        control={control}
        options={systemeChauffageLogementOptions || []}
        errors={errors}
      />

      <Extend extend={!!systemeChauffage && 'AUTRE:SYSTEME_CHAUFFAGE_LOGEMENT' !== systemeChauffage}>
        <InputRadioButtonInline
          labelField="Son installation a été faite par un professionnel ?"
          {...register('habitation.systemeChauffageInstalleParUnPro')}
          options={OUI_NON_OPTIONS}
          column={2}
          errors={errors}
        />
      </Extend>
      <Extend extend={!!systemeChauffage && 'AUTRE:SYSTEME_CHAUFFAGE_LOGEMENT' === systemeChauffage}>
        <InputText label="Pouvez vous préciser ?" {...register('habitation.systemeChauffageAutre')} errors={errors} />
      </Extend>

      <InputRadioButtonInline
        labelField="Présence d’énergies renouvelables ?"
        {...register('habitation.presenceEnergieRenouvelable')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
