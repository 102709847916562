import FormDecouverteIdentite from 'forms/DECOUVERTE_PROSPECT/FormDecouverteIdentite.component';
import FormDecouverteSituationPerso from 'forms/DECOUVERTE_PROSPECT/FormDecouverteSituationPerso.component';
import FormDecouverteSituationPro from 'forms/DECOUVERTE_PROSPECT/FormDecouverteSituationPro.component';
import React from 'react';
import { FormConfigWithUrl, FormStepConfigWithNext, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormDecouverteDescription from '../forms/DECOUVERTE_PROSPECT/FormDecouverteDescription.component';
import FormActiviteEntreprise from '../forms/PROJET_MANUEL/FormActiviteEntreprise.component';
import FormComplementEntreprise from '../forms/PROJET_MANUEL/FormComplementEntreprise.component';
import FormDecouverteChoixProfilDynamique from '../forms/PROJET_MANUEL/FormDecouverteChoixProfilDynamique.component';
import FormIdentiteInterlocuteur from '../forms/PROJET_MANUEL/FormIdentiteInterlocuteur.component';
import FormInterlocuteurCoordonne from '../forms/PROJET_MANUEL/FormInterlocuteurCoordonne.component';
import FormManuelEntreprise from '../forms/PROJET_MANUEL/FormManuelEntreprise.component';
import WrapperFormChampPerso from '../forms/PROJET_MANUEL/WrapperFormChampPerso.component';
import FormSanteParticulierCoordonne from '../forms/SANTE_PART/FormSanteParticulierCoordonne.component';
import FormParcoursTnsEntreprise from '../forms/TNS/FormParcoursTnsEntreprise.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_CHOIX_ASSURE: {
    title: '',
    description: ''
  },
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description:
      "Votre âge est une donnée qui rentre en compte dans le calcul du tarif de certains produits d'assurance."
  },
  STEP_SITUATION_PERSO: {
    title: 'Pourquoi ces questions ?',
    description: "Ce sont des données qui rentrent en comptent dans les calculs de plusieurs produits d'assurance."
  },
  STEP_SITUATION_PRO: {
    title: 'Pourquoi ces questions ?',
    description:
      "Votre activité professionnelle est une donnée qui rentre en compte dans le calcul du tarif de plusieurs produits d'assurance."
  },
  STEP_ENTREPRISE_TNS: {
    title: 'Pourquoi ces questions ?',
    description: 'Les compagnies d’assurance demandent ces informations pour vérifier votre statut professionnel.'
  },
  STEP_ENTREPRISE_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description: 'Ces informations permettent de mieux vous connaitre.'
  },
  STEP_ENTREPRISE_ACTIVITE: {
    title: 'Pourquoi ces questions ?',
    description:
      "Ces informations permettent de mieux vous connaitre et rentre en compte dans le calcul du tarif de plusieurs produits d'assurance."
  },
  STEP_RISQUE: {
    title: "Votre équipement actuel de contrats d'assurance",
    description:
      "En complétant la liste de vos contrats d'assurance actuels, vous nous permettez de vérifier que ces derniers sont toujours en phase avec vos besoins et votre situation. Par la même, nous nous assurerons qu'ils sont optimums avec les produits du marché."
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description:
      "Le département d’habitation est une donnée qui rentre en compte dans le calcul du tarif de plusieurs produits d'assurance."
  },
  STEP_DESCRIPTION_EMPTY: {
    title: '',
    description: ''
  }
};

export const STEPS_ENTREPRISE: FormStepConfigWithNext[] = [
  {
    component: FormManuelEntreprise,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE_IDENTITE} />
  },
  {
    component: FormComplementEntreprise,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE_IDENTITE} />
  },
  {
    component: FormActiviteEntreprise,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE_ACTIVITE} />
  },
  {
    component: FormIdentiteInterlocuteur,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />
  },
  // CHAMP PERSO
  {
    component: WrapperFormChampPerso,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />
  },
  // FIN COMMUNE
  {
    component: FormInterlocuteurCoordonne,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />
  },
  {
    component: FormDecouverteDescription,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />,
    data: { showCaptcha: true }
  }
];

export const STEPS_PART: FormStepConfigWithNext[] = [
  {
    component: FormDecouverteIdentite,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
  },
  {
    component: FormDecouverteSituationPerso,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PERSO} />
  },
  {
    component: FormDecouverteSituationPro,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
  },
  // CHAMP PERSO
  {
    component: WrapperFormChampPerso,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />
  },
  // FIN COMMUNE
  {
    component: FormSanteParticulierCoordonne,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />,
    data: {
      askForConsent: false,
      labelNextButton: 'Suivant'
    }
  },
  {
    component: FormDecouverteDescription,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />,
    data: { showCaptcha: true }
  }
];

export const STEPS_TNS: FormStepConfigWithNext[] = [
  {
    component: FormDecouverteIdentite,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
  },
  {
    component: FormDecouverteSituationPerso,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PERSO} />
  },
  {
    component: FormDecouverteSituationPro,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
  },
  {
    component: FormParcoursTnsEntreprise,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE_TNS} />
  },
  // CHAMP PERSO
  {
    component: WrapperFormChampPerso,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />
  },
  // FIN COMMUNE
  {
    component: FormSanteParticulierCoordonne,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />,
    data: {
      askForConsent: false,
      labelNextButton: 'Suivant'
    }
  },
  {
    component: FormDecouverteDescription,
    description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION_EMPTY} />,
    data: { showCaptcha: true }
  }
];

interface steps {
  [key: string]: FormStepConfigWithNext[];
}

export const STEP_BY_personnas: steps = {
  ENTREPRISE: STEPS_ENTREPRISE,
  PARTICULIER: STEPS_PART,
  TNS: STEPS_TNS
};

const projetManuel: FormConfigWithUrl = {
  endpoint: 'projetManuel',
  formSteps: [
    {
      component: FormDecouverteChoixProfilDynamique,
      next: {
        selectNextStep: (prevStepState: { type: string }): string | undefined => {
          return prevStepState.type;
        },
        paths: {
          // PATHS will be generate on FormSeries component
        }
      }
    }
  ]
};

export default projetManuel;
