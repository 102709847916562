import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ReactElement } from 'react';
import '../SubmitOverlay/submit-overlay.scss';

interface UnavailableOverlayProps {
  title: string;
  nomCommercial: string;
  baseLine: string;
  isOpen?: boolean;
}

function UnavailableOverlay(props: UnavailableOverlayProps): ReactElement {
  return (
    <div
      data-testid="unavailable-overlay"
      className={classNames('pane succeeded', {
        opened: props.isOpen,
        closed: !props.isOpen
      })}
    >
      <div className="layout-container layout-centered-content success-prompt">
        <FontAwesomeIcon icon={faExclamationCircle} className="success-icon" />
        <div className="success-title" dangerouslySetInnerHTML={{ __html: props.title ?? '' }} />
      </div>
      <div className="layout-container layout-centered-content success-baseline">
        <div className="success-nom-commercial" dangerouslySetInnerHTML={{ __html: props.nomCommercial ?? '' }} />
        <div className="success-baseline-text" dangerouslySetInnerHTML={{ __html: props.baseLine ?? '' }} />
      </div>
    </div>
  );
}

export default UnavailableOverlay;
