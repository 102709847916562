import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useMemo } from 'react';
import FieldGenerator from './FieldGenerator.component';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from '../../types';
import { merge } from 'lodash';

interface FormValuesChampPerso extends FormProps<any> {
  validationSchema: any;
  template: any;
  typologies: any;
}

export default function FormChampPerso(props: FormValuesChampPerso): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useSerieForm<any>(props.setLastFormState, {
    resolver: yupResolver(props.validationSchema),
    defaultValues: props.formValues || props.template
  });

  // remove string key to keep only field id from submit
  const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
    const tabChampsPersos: any[] = [];
    if (state.champsPersonnalises) {
      Object.keys(state.champsPersonnalises).map(key => {
        const id: string = key.replace('--', '');
        const type = props.champsPersonnalises?.find(champ => champ.id === parseInt(id))?.type;
        tabChampsPersos.push({
          ...state.champsPersonnalises[key],
          idChampsPersonnalisePTF: id,
          type: type === 'SELECT' ? 'STRING' : type
        });
      });
    }
    return merge(serieState, {
      champsPersonnalises: tabChampsPersos
    });
  };

  const handleNextClick = (data: any) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const labelTypo = useMemo(
    () => props.typologies.find((typo: any) => typo.value === `${props.cible}:TYPOLOGIE`)?.label,
    [props.cible]
  );

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Recueil du besoin {labelTypo} </h2>
      <FieldGenerator
        setValue={setValue}
        fields={props.champsPersonnalises}
        champPersonnaliseAttr={'champsPersonnalises'}
        control={control}
        errors={errors}
        register={register}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
