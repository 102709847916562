import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from 'utils';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import { merge } from 'lodash';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';

type RisqueAutresValues = {
  actif: string;
  demandeInformations: boolean;
  type: string;
  valeur: number;
};

interface FormValues {
  risquesAutres: RisqueAutresValues | any;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  risquesAutres: Yup.object({
    demandeInformations: Yup.boolean().when('risquesAutres.valeur', {
      is: undefined,
      then: Yup.boolean().required(
        "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
      ),
      otherwise: Yup.boolean().oneOf([false])
    }),
    valeur: Yup.string().nullable()
  })
}).defined();

const defaultState: FormValues = {
  risquesAutres: { type: 'AUTRES_RISQUES', demandeInformations: false }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => {
    return item;
  });
}

export default function FormPrevoyanceAutresRisques(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
    control,
    setValue
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>{'Souhaitez-vous que votre contrat de prévoyance vous couvre pour d’autres risques ?'}</h2>

      <InputRadioButtonInline {...register('risquesAutres.actif')} options={OUI_NON_OPTIONS} errors={errors} />

      {getValues().risquesAutres.actif === 'true' && (
        <InputTextArea label="Si oui, lesquels" {...register('risquesAutres.valeur')} errors={errors} />
      )}

      <div className={'checkbox-container'}>
        <InputCheckBoxBoolean
          className={'checkbox'}
          control={control}
          name={'risquesAutres.demandeInformations'}
          label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
          onChange={e => {
            setValue(`risquesAutres`, {
              valeur: undefined,
              type: 'AUTRES_RISQUES',
              demandeInformations: e.target.checked
            });
            trigger('risquesAutres.demandeInformations');
            return e;
          }}
          errors={errors}
        />
      </div>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
