import { fetchAxesIntituleNiveauxExpat } from '../../fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import React, { ReactElement } from 'react';
import FormAxeExpat, { Niveaux } from 'components/Axe/FormAxeExpat.component';
import { fetchAxesMacroPrestation } from 'fetches/axes.fetch';
import LoaderWrapper from 'components/LoaderWrapper/LoaderWrapper.component';
import { FormProps } from '../../types';

type FormValues = {
  niveaux: Niveaux[];
  besoin: {
    maternite: string | undefined;
    dentaire: string | undefined;
    optique: string | undefined;
    auditif: string | undefined;
    assistance: string | undefined;
    responsabiliteCivile: string | undefined;
    hospitalisation: string | undefined;
    fraisMedicaux: string | undefined;
  };
};

export default function FormExpatGarantiePart1(props: FormProps<FormValues>): ReactElement {
  const niveauxIntitule = useFetch(fetchAxesIntituleNiveauxExpat);
  const macroPresta: any = {};
  for (const type in props.data.typeAxe) {
    macroPresta[props.data.typeAxe[type]] = useFetch(() =>
      fetchAxesMacroPrestation('EXPATRIE', props.data.typeAxe[type])
    );
  }

  const readyToRender =
    Object.values(macroPresta).filter(presta => !!presta).length === props.data.typeAxe.length && !!niveauxIntitule;

  return (
    <LoaderWrapper isReadyToRender={readyToRender}>
      <FormAxeExpat typologie="EXPATRIE" niveauxIntitule={niveauxIntitule} macroPresta={macroPresta} {...props} />
    </LoaderWrapper>
  );
}
