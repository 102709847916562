import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormResiliationDemande from '../forms/RESILIATION/FormResiliationDemande.component';
import FormResiliationInformations from '../forms/RESILIATION/FormResiliationInformations.component';
import FormResiliationImportDocuments from '../forms/RESILIATION/FormResiliationImportDocuments.component';
import FormResiliationConfirmation from '../forms/RESILIATION/FormResiliationConfirmation.component';

const leftPaneDescriptions: StepDescriptionList = {
  OTHER_STEPS: {
    beforeTitle:
      'Cette opération s’effectue entièrement en ligne. Elle est réservée aux adhérents Tutélaire munis d’une adresse e-mail active.'
  }
};

const resiliationConfig: FormConfigWithUrl = {
  formSteps: [
    {
      component: FormResiliationDemande,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormResiliationInformations,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormResiliationImportDocuments,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormResiliationConfirmation,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />,
      data: { showCaptcha: true }
    }
  ]
};

[];

export default resiliationConfig;
