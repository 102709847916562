import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import { assignValuesFromSource } from '../../utils';
import { useFetch } from 'hooks/useFetch.hook';
import { fetchNiveauxCouvertureIJOptions } from 'fetches/options.fetch';

type FormValues = {
  besoin: {
    niveauGarantie: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    niveauGarantie: Yup.string().required().max(255)
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    niveauGarantie: undefined
  }
};

export default function FormIJBesoins(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {}, (item: any) => {
    return item;
  });

  const niveaux = useFetch(fetchNiveauxCouvertureIJOptions);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Montant des indemnités hospitalisation souhaité:</h2>
      <div className={'container-axe'}>
        <div className={'container-radios'}>
          <InputRadioButtonInline {...register('besoin.niveauGarantie')} options={niveaux || []} errors={errors} />
        </div>
      </div>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
