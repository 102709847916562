import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import { fetchAssureurOptions } from 'fetches/options.fetch';
import parseSearch from 'utils/parseSearch';

import FormEmprunteurTypeAssurance from '../forms/EMPRUNTEUR/FormEmprunteurTypeAssurance.component';
import FormEmprunteurTypeCredit from '../forms/EMPRUNTEUR/FormEmprunteurTypeCredit.component';
import FormEmprunteurNombreEmprunteur, {
  FormValues
} from '../forms/EMPRUNTEUR/FormEmprunteurNombreEmprunteur.component';
import FormEmprunteurCoordonnees from '../forms/EMPRUNTEUR/FormEmprunteurCoordonnees.component';
import FormEmprunteurCredits from '../forms/EMPRUNTEUR/FormEmprunteurCredits.component';
import FormEmprunteurEmprunteursPart1 from '../forms/EMPRUNTEUR/FormEmprunteurEmprunteursPart1.component';
import FormEmprunteurEmprunteursPart2 from '../forms/EMPRUNTEUR/FormEmprunteurEmprunteursPart2.component';
import FormEmprunteurEmprunteursQuotite from '../forms/EMPRUNTEUR/FormEmprunteurEmprunteursQuotite.component';

const leftPaneDescriptions: StepDescriptionList = {
  Q1: {
    title: 'Pourquoi cette question ?',
    description: 'En cas de changement d’assurance des démarches seront à effectuer auprès de votre assureur actuel.'
  },
  Q2: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q3: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q4: {
    title: 'Pourquoi cette question ?',
    description: 'La date de naissance est une donnée tarifante.'
  },
  Q5: {
    title: 'Pourquoi cette question ?',
    description: 'Ces données sont des données tarifantes.'
  },
  Q6: {
    title: 'Pourquoi cette question ?',
    description:
      'La quotité d’assurance du prêt immobilier est la part de l’emprunt garantie pour chacun des assurés. Cette quotité doit être égale à 100% en cas d’emprunteurs multiples.'
  },
  Q7: {
    title: 'Pourquoi cette question ?',
    description: "Ces données nous permettront de vous envoyer une proposition d'assurance."
  }
};

const id = parseSearch(window.location.search, 'id');

const projetAutoConfig: FormConfigWithUrl = {
  endpoint: 'projetCredit',
  prefetch: [fetchAssureurOptions(id)],
  formSteps: [
    {
      component: FormEmprunteurTypeAssurance,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q1} />
    },
    {
      component: FormEmprunteurTypeCredit,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q2} />
    },
    {
      component: FormEmprunteurCredits,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q3} />
    },
    {
      component: FormEmprunteurNombreEmprunteur,
      next: {
        selectNextStep: (prevStepState: FormValues): string | undefined => {
          return prevStepState.nombreEmprunteur;
        },
        paths: {
          UN_EMPRUNTEUR: [
            {
              data: { type: 'principal' },
              component: FormEmprunteurEmprunteursPart1,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />
            },
            {
              data: { type: 'principal' },
              component: FormEmprunteurEmprunteursPart2,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />
            },
            {
              component: FormEmprunteurEmprunteursQuotite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q6} />
            }
          ],
          DEUX_EMPRUNTEURS: [
            {
              data: { type: 'principal' },
              component: FormEmprunteurEmprunteursPart1,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q4} />
            },
            {
              data: { type: 'principal' },
              component: FormEmprunteurEmprunteursPart2,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />
            },
            {
              data: { type: 'secondaire' },
              component: FormEmprunteurEmprunteursPart1,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />
            },
            {
              data: { type: 'secondaire' },
              component: FormEmprunteurEmprunteursPart2,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q5} />
            },
            {
              component: FormEmprunteurEmprunteursQuotite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q6} />
            }
          ]
        }
      }
    },
    {
      data: { showCaptcha: true, askForConsent: true },
      component: FormEmprunteurCoordonnees,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.Q7} />
    }
  ]
};

export default projetAutoConfig;
