import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { fetchDureesSejourExpatrieOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';

type FormValues = {
  dateEffetSouhaitee: Date | undefined;
  needPrecision: boolean | undefined;
  besoin: {
    dureeSejourYear: string | undefined;
    dureeSejour: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  dateEffetSouhaitee: Yup.date().typeError('Merci de renseigner une date valide').required(),
  needPrecision: Yup.boolean(),
  besoin: Yup.object({
    dureeSejourYear: Yup.string().required(),
    dureeSejour: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  dateEffetSouhaitee: undefined,
  needPrecision: false,
  besoin: {
    dureeSejourYear: undefined,
    dureeSejour: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
    if (key === 'dateEffetSouhaitee') return new Date(item);
    return item;
  });
}

export const enum eDureesSejour {
  moinsDe12Mois = 'moinsDe12Mois',
  SUP_12_MOIS = 'SUP_12_MOIS:DUREE_SEJOUR_EXPATRIE'
}

export const enum eDureesSejourFromPTF {
  PLUS_12 = 'PLUS_12:DUREE_SEJOUR'
}

export default function FormExpatVoyage(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    register
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const dureeSejour = useFetch(fetchDureesSejourExpatrieOptions);

  const watchNeedPrecision = watch('needPrecision');

  const dureesSejour = [
    { value: eDureesSejour.moinsDe12Mois, label: 'Moins de 12 mois' },
    { value: eDureesSejour.SUP_12_MOIS, label: 'Plus de 12 mois' }
  ];

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Votre <span>projet</span>
      </h1>

      <InputRadioButtonInline
        labelField={'Combien de temps partez-vous ?'}
        {...register('besoin.dureeSejourYear')}
        options={dureesSejour}
        className={'dualRadioButton'}
        onChange={async e => {
          if (e.target.value === eDureesSejour.SUP_12_MOIS) {
            setValue('besoin.dureeSejour', eDureesSejour.SUP_12_MOIS);
            setValue('needPrecision', false);
          } else {
            setValue('needPrecision', true);
          }
        }}
        column
        errors={errors}
      />

      {watchNeedPrecision && (
        <InputSelect
          label="Durée du séjour en mois"
          name="besoin.dureeSejour"
          control={control}
          errors={errors}
          options={dureeSejour?.filter(e => e.value !== eDureesSejour.SUP_12_MOIS) || []}
          isSearchable={true}
        />
      )}

      <InputDate label="Quelle est votre date de départ ?" name="dateEffetSouhaitee" control={control} />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
