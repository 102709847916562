import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { MouseEventHandler, ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import Separator from '../../components/Separator/Separator.component';
import { fetchRegimeSecuOptions } from '../../fetches/options.fetch';
import { useFetch } from '../../hooks/useFetch.hook';
import { assignValuesFromSource } from '../../utils';
import EnfantFields from './Enfants/EnfantFields';

type Enfant = {
  civilite: string | null;
  nom: string | undefined;
  prenom: string | undefined;
  naissanceDate: Date | undefined;
  regime: string | undefined;
};

type FormValues = {
  enfants: Enfant[];
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  enfants: Yup.array().of(
    Yup.object({
      civilite: Yup.string()
        .nullable()
        .required()
        .oneOf(
          CIVILITES_OPTIONS.map(option => option.value),
          'Champ obligatoire'
        ),
      nom: Yup.string().required().max(255),
      prenom: Yup.string().required().max(255),
      naissanceDate: Yup.date().required(),
      regime: Yup.string().required()
    })
  )
}).defined();

const defaultState: FormValues = {
  enfants: [
    {
      civilite: '',
      nom: '',
      prenom: '',
      naissanceDate: undefined,
      regime: ''
    }
  ]
};

export default function FormSanteParticulierEnfants(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(
    defaultState,
    props.initialFormValues || {},
    (item: any, key: any) => {
      if (key === 'enfants') {
        item.map((enfant: Enfant) => {
          if (enfant.naissanceDate) {
            enfant.naissanceDate = new Date(enfant.naissanceDate);
          }
        });
      }
      return item;
    },
    true
  );

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const regimesSecu = useFetch(fetchRegimeSecuOptions);
  const enfants = getValues('enfants');

  const deleteChild = (indexToDelete: number) => {
    const newFormValues: Enfant[] = [...getValues('enfants')];
    newFormValues.splice(indexToDelete, 1);
    reset({ enfants: newFormValues }, { keepErrors: true, keepTouched: true, keepDirty: true });
  };

  const addChild: MouseEventHandler = (e): void => {
    e.preventDefault();
    const newFormValues: Enfant[] = [...getValues('enfants')];
    newFormValues.push({
      civilite: '',
      nom: '',
      prenom: '',
      naissanceDate: undefined,
      regime: ''
    });
    reset({ enfants: newFormValues }, { keepErrors: true, keepTouched: true, keepDirty: true });
  };

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Vos enfants :</h2>

      {enfants &&
        enfants.map((item: Enfant, i: number) => (
          <div key={`child-${i}`}>
            <EnfantFields
              key={`enfant-${i}`}
              index={i}
              register={register}
              errors={errors}
              control={control}
              regimesSecu={regimesSecu}
              deleteChild={(index: number) => deleteChild(index)}
            />
            {i !== enfants.length - 1 && <Separator key={`separator-${i}`} />}
          </div>
        ))}
      <hr
        style={{
          border: 'none',
          borderTop: '1px solid #E2E5EA',
          margin: '16px 0px 0px 0px'
        }}
      />
      <div>
        <button className={'secondary'} onClick={addChild}>
          Ajouter un enfant
        </button>
      </div>
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
