import { yupResolver } from '@hookform/resolvers/yup';
import FormConsent from 'components/FormConsent/FormConsent.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';

type FormValues = {
  description: string | undefined;
  consentement: boolean | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  description: Yup.string().max(4096),
  consentement: Yup.boolean().isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

const defaultState: FormValues = {
  description: '',
  consentement: false
};

export default function FormDecouverteDescription({
  cocheConsentement,
  contenuConsentement,
  formValues,
  goNextStep,
  initialFormValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>
        {'Informations complémentaires '}
        <span className="de-emphasis">(facultatif)</span>
      </h2>
      <InputTextArea label="" {...register('description')} errors={errors} />
      <FormConsent
        contenuConsentement={contenuConsentement}
        control={control}
        errors={errors}
        label={cocheConsentement}
        {...register('consentement')}
      />
      <FormNextButton disabled={!isValid} showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
