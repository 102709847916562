import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import { fetchStatutJuridiqueOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import InputNumber from '../../components/Input/InputNumber/InputNumber.component';
import InputText from '../../components/Input/InputText/InputText.component';
import { assignValuesFromSource } from '../../utils';

interface FormValues {
  entreprise: {
    statutJuridique?: string;
    nombreSalaries: number | undefined;
    url: string | null | undefined;
  };
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  entreprise: Yup.object({
    statutJuridique: Yup.string(),
    nombreSalaries: Yup.number().ignoreEmptyString().min(0),
    url: Yup.string()
  })
}).defined();

const defaultState: FormValues = {
  entreprise: {
    statutJuridique: undefined,
    nombreSalaries: undefined,
    url: undefined
  }
};

export default function FormComplementEntreprise(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const statutJuridiquesOptions = useFetch(fetchStatutJuridiqueOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Compléments d&apos;informations</h2>
      <div className="layout-field-container">
        <InputSelect
          label="Statut juridique (facultatif)"
          name="entreprise.statutJuridique"
          control={control}
          errors={errors}
          options={statutJuridiquesOptions || []}
        />
        <InputNumber
          min={0}
          className="layout-flex-basis-auto"
          label="Nombre de salariés"
          {...register('entreprise.nombreSalaries')}
          errors={errors}
        />
      </div>
      <InputText label="Site Web" {...register('entreprise.url')} errors={errors} />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
