import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputDateSelect from 'components/Input/InputDateSelect/InputDateSelect.component';
import InputText from 'components/Input/InputText/InputText.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';

type FormValues = {
  besoin: {
    vehicule: {
      marque: string | undefined;
      modele: string | undefined;
      finition: string | undefined;
      premiereMiseEnCirculationDate: Date | undefined;
    };
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  besoin: Yup.object({
    vehicule: Yup.object({
      marque: Yup.string().required(),
      modele: Yup.string().required(),
      finition: Yup.string().required(),
      premiereMiseEnCirculationDate: Yup.date().typeError('Merci de renseigner une date valide').required()
    })
  })
}).defined();

const defaultState: FormValues = {
  besoin: {
    vehicule: {
      marque: undefined,
      modele: undefined,
      finition: undefined,
      premiereMiseEnCirculationDate: undefined
    }
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormAutoInfoVehicule(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Votre <span>véhicule</span>
      </h1>

      <div className="layout-button-container-responsive">
        <InputText
          label="Quelle est sa marque ?"
          {...register('besoin.vehicule.marque')}
          errors={errors}
          placeholder="ex: Peugeot"
        />
        <InputText
          label="Quel est son modèle ?"
          {...register('besoin.vehicule.modele')}
          errors={errors}
          placeholder="ex: 3008"
        />
      </div>

      <InputText
        label="Quelle est sa finition ?"
        {...register('besoin.vehicule.finition')}
        errors={errors}
        placeholder="ex: 1.2 PURE TECH 130 STYLE"
      />

      <InputDateSelect
        label="Quelle est sa date de mise en circulation ?"
        name="besoin.vehicule.premiereMiseEnCirculationDate"
        control={control}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
