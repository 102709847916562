import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputText from 'components/Input/InputText/InputText.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import {
  fetchFonctionsOptions,
  fetchPaysOptions,
  fetchStatutJuridiqueOptions,
  fetchVilleOptions
} from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { useFetchOptionsOnFieldChange } from 'hooks/useFetchOptionsOnFieldChange.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { removeProperty } from 'utils';
import Yup from 'utils/Yup';
import { ColoredText } from '../../../components/ColoredText/ColoredText';
import { EntrepriseInputsWithFetch } from 'components/Input/EntrepriseInputWithFetch/EntrepriseInputsWithFetch';

type EntrepriseValues = {
  siret: string;
  raisonSociale: string;
  voie: string;
  complementAdresse: string;
  codePostal: string;
  ville: string;
  pays: string;
  statutJuridique?: string;
};

interface FormValues {
  enCoursDeCreation: boolean;
  entreprise: EntrepriseValues | any; // TODO strongly type
  interlocuteur: {
    fonction: string;
  };
}

const entrepriseFields = {
  raisonSociale: Yup.string().required().max(500),
  voie: Yup.string().required().max(255),
  complementAdresse: Yup.string().max(255).notRequired(),
  codePostal: Yup.string().required(),
  ville: Yup.string().required().max(255),
  pays: Yup.string().required(),
  statutJuridique: Yup.string()
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  interlocuteur: Yup.object({
    fonction: Yup.string().required()
  }),

  enCoursDeCreation: Yup.boolean().required(),
  statutJuridique: Yup.string().nullable(),
  entreprise: Yup.object().when('enCoursDeCreation', {
    is: true,
    then: Yup.object({
      siret: Yup.string().length(0),
      ...entrepriseFields
    }).defined(),
    otherwise: Yup.object({
      siret: Yup.string().required().siret(),
      ...entrepriseFields
    }).defined()
  })
}).defined();

const defaultState: FormValues = {
  enCoursDeCreation: false,
  interlocuteur: {
    fonction: ''
  },
  entreprise: {
    statutJuridique: '',
    siret: '',
    raisonSociale: '',
    voie: '',
    complementAdresse: '',
    codePostal: '',
    ville: '',
    pays: 'FR:PAYS'
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, removeProperty<FormValues>('enCoursDeCreation', state));
};

export default function FormPrescripteurTnsEntreprise(props: FormProps<FormValues>): ReactElement {
  const form = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = form;

  const villeOptions = useFetchOptionsOnFieldChange(watch, 'entreprise.codePostal', fetchVilleOptions);

  const statutJuridiquesOptions = useFetch(fetchStatutJuridiqueOptions);
  const fonctionsOptions = useFetch(fetchFonctionsOptions);
  const paysOptions = useFetch(fetchPaysOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const enCoursDeCreation = watch('enCoursDeCreation');
  useEffect(() => {
    if (enCoursDeCreation) {
      setValue('entreprise.siret', '');
      trigger('entreprise.siret');
    }
  }, [enCoursDeCreation]);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>
        <ColoredText className={'_secondaire'} text="Informations " /> {"de l'entreprise"}
      </h2>
      <InputCheckBoxBoolean
        control={control}
        label="Entreprise en cours de création"
        name="enCoursDeCreation"
        errors={errors}
      />
      <EntrepriseInputsWithFetch form={form} />
      <InputText label="Adresse" {...register('entreprise.voie')} errors={errors} />
      <InputText
        label="Complément d'adresse (facultatif)"
        {...register('entreprise.complementAdresse')}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('entreprise.codePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="entreprise.ville"
          control={control}
          errors={errors}
          options={villeOptions}
        />
      </div>
      <InputTextAutoComplete
        label={'Pays'}
        name={'entreprise.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />
      <InputSelect
        label="Fonction"
        name="interlocuteur.fonction"
        control={control}
        errors={errors}
        options={fonctionsOptions || []}
      />
      <InputSelect
        label="Statut juridique (facultatif)"
        name="entreprise.statutJuridique"
        control={control}
        errors={errors}
        options={statutJuridiquesOptions || []}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
