import React, { ReactElement } from 'react';
import { useFetch } from '../../../hooks/useFetch.hook';
import { fetchAxesIntituleNiveauxSante } from '../../../fetches/options.fetch';
import { AXES_MACRO_PRESTATION_SANTE, fetchAxesMacroPrestation } from '../../../fetches/axes.fetch';
import LoaderWrapper from '../../../components/LoaderWrapper/LoaderWrapper.component';
import FormAxe, { Niveaux } from '../../../components/Axe/FormAxe.component';
import { FormProps } from '../../../types';

type FormValues = {
  niveaux: Niveaux[];
};

export default function SanteTNSAxes(props: FormProps<FormValues>): ReactElement {
  const niveauxIntitule = useFetch(fetchAxesIntituleNiveauxSante);
  const macroPresta: any = {};
  for (const type in props.data.typeAxe) {
    macroPresta[props.data.typeAxe[type]] = useFetch(() => fetchAxesMacroPrestation('SANTE', props.data.typeAxe[type]));
  }

  const readyToRender =
    Object.values(macroPresta).filter(presta => !!presta).length === props.data.typeAxe.length && !!niveauxIntitule;

  // TODO HotFix to remove (this fix reverse order or prestation display when type axe is HOSPITALISATION.
  if (readyToRender && props.data.typeAxe === AXES_MACRO_PRESTATION_SANTE.HOSPITALISATION)
    macroPresta[AXES_MACRO_PRESTATION_SANTE.HOSPITALISATION[0]] = macroPresta[
      AXES_MACRO_PRESTATION_SANTE.HOSPITALISATION[0]
    ]
      .slice()
      .reverse();

  return (
    <LoaderWrapper isReadyToRender={readyToRender}>
      <FormAxe typologie="SANTE" niveauxIntitule={niveauxIntitule} macroPresta={macroPresta} {...props} />
    </LoaderWrapper>
  );
}
