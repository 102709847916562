import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormMandatSepaVosCoordonneesBancaires from '../forms/MANDAT_SEPA/FormMandatSepaCoordonneesBancaires.component';
import FormMandatSepaImportDocuments from '../forms/MANDAT_SEPA/FormMandatSepaImportDocuments.component';
import FormMandatSepaSignature from '../forms/MANDAT_SEPA/FormMandatSepaSignature.component';
import FormMandatSepaTitulaireCompte from '../forms/MANDAT_SEPA/FormMandatSepaTitulaireCompte.component';
import FormMandatSepaVotreRIB from '../forms/MANDAT_SEPA/FormMandatSepaVotreRIB.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_COORDONNEES_BANCAIRES: {
    beforeTitle:
      'Cette opération s’effectue entièrement en ligne. Elle est réservée aux adhérents Tutélaire munis d’une adresse mail et d’un numéro  de portable.',
    title: 'De quoi avez-vous besoin ?',
    hideTitleIcon: true,
    description: "De votre numéro d'adhérent, de votre RIB et d’un document d’identité en format dématérialisé."
  },
  STEP_VOS_COORDONNEES: {
    beforeTitle:
      'Cette opération s’effectue entièrement en ligne. Elle est réservée aux adhérents Tutélaire munis d’une adresse mail et d’un numéro de portable.',
    title: "Où trouver mon numéro d'adhérent ?",
    hideTitleIcon: true,
    description: "Retrouvez votre numéro d'adhérent sur vos relevés de cotisations annuels.",
    descriptionAsHTML: true
  },
  OTHER_STEPS: {
    description:
      'Cette opération s’effectue entièrement en ligne. Elle est réservée aux adhérents Tutélaire munis d’une adresse mail et d’un numéro  de portable.'
  }
};

const mandatSepaConfig: FormConfigWithUrl = {
  formSteps: [
    {
      component: FormMandatSepaVosCoordonneesBancaires,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES_BANCAIRES} />
    },
    {
      component: FormMandatSepaTitulaireCompte,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_VOS_COORDONNEES} />
    },
    {
      component: FormMandatSepaVotreRIB,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormMandatSepaImportDocuments,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />
    },
    {
      component: FormMandatSepaSignature,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.OTHER_STEPS} />,
      data: { showCaptcha: true }
    }
  ]
};

[];

export default mandatSepaConfig;
