import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputDate from 'components/Input/InputDate/InputDate.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputText from 'components/Input/InputText/InputText.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { CIVILITES_OPTIONS, OUI_NON_OPTIONS } from 'consts/consts';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import FormConsent from '../../components/FormConsent/FormConsent.component';
import { fetchVilleOptions } from '../../fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';

import type { FormValues as FormValuesConducteur } from './FormAutoConducteurPart1.component';

type FormValues = {
  assure: {
    estSouscripteur: string | undefined;
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    naissanceDate: Date | undefined;
    contactMail: string | undefined;
    contactTelephone: string | undefined;
    contactCodePostal: string | undefined;
    ville: string | undefined;
    voie: string | undefined;
    complementAdresse: string | undefined;
  };
};

const defaultState: FormValues = {
  assure: {
    estSouscripteur: undefined,
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    naissanceDate: undefined,
    contactMail: undefined,
    contactTelephone: undefined,
    contactCodePostal: '',
    ville: '',
    voie: undefined,
    complementAdresse: undefined
  }
};

export default function FormAutopersonne({
  goNextStep,
  initialFormValues,
  setLastFormState,
  previousFormValues,
  contenuConsentement,
  cocheConsentement,
  data,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    assure: Yup.object({
      estSouscripteur: Yup.string().typeError('Champ obligatoire').required(),
      civilite: Yup.string().nullable().required(),
      nom: Yup.string().required(),
      prenom: Yup.string().required(),
      naissanceDate: Yup.date().typeError('Merci de renseigner une date valide').required(),
      contactMail: Yup.string().required(),
      contactTelephone: Yup.string().required(),
      contactCodePostal: Yup.string().required(),
      ville: Yup.string().required(),
      voie: Yup.string().required(),
      complementAdresse: Yup.string(),

      ...(data.askForConsent
        ? {
            consentement: Yup.boolean().isTrue(
              'Vous devez obligatoirement cocher cette case pour valider votre demande.'
            )
          }
        : { consentement: Yup.boolean() })
    })
  }).defined();

  const { conducteurPrincipal }: FormValuesConducteur<'conducteurPrincipal'> = previousFormValues;
  const { conducteurSecondaire }: FormValuesConducteur<'conducteurSecondaire'> = previousFormValues;
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues || defaultState
  });

  const estSouscripteur = watch('assure.estSouscripteur');

  useEffect(() => {
    if (estSouscripteur === 'true') {
      reset({
        assure: {
          ...getValues().assure,
          civilite: conducteurPrincipal?.personne.civilite,
          nom: conducteurPrincipal?.personne.nom,
          prenom: conducteurPrincipal?.personne.prenom,
          naissanceDate: conducteurPrincipal?.personne.naissanceDate
        }
      });
    } else if (estSouscripteur === 'false') {
      reset({
        assure: {
          ...getValues().assure,
          nom: initialValues?.assure?.nom,
          prenom: initialValues?.assure?.prenom,
          naissanceDate: initialValues?.assure?.naissanceDate
        }
      });
    }
  }, [estSouscripteur]);

  const handleNextClick = (data: FormValues) => {
    const conducteurs = [{ ...conducteurPrincipal, rang: 'PRINCIPAL', selection: true }];
    if (conducteurSecondaire) conducteurs.push({ ...conducteurSecondaire, rang: 'SECONDAIRE', selection: true });

    goNextStep(
      {
        assure: { ...data.assure, pays: 'FR:PAYS' },
        besoin: { ...previousFormValues.besoin, achatDate: previousFormValues.besoin.vehicule.achatDate, conducteurs },
        estSouscripteurConducteurPrincipal: data.assure.estSouscripteur
      },
      (serieState, state) => merge(serieState, state)
    );
  };
  const villeOptions = useFetchOptionsOnFieldChange(watch, 'assure.contactCodePostal', fetchVilleOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Coordonnées du <span>souscripteur du contrat</span>
      </h1>

      <InputRadioButtonInline
        labelField="Le conducteur principal est-il le souscripteur ?"
        {...register('assure.estSouscripteur')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputRadioButtonInline
        labelField="Civilité"
        {...register('assure.civilite')}
        options={CIVILITES_OPTIONS}
        column={2}
        errors={errors}
      />

      <InputText label="Prénom" {...register('assure.prenom')} errors={errors} />

      <InputText label="Nom" {...register('assure.nom')} errors={errors} />

      <InputDate label="Date de naissance" name="assure.naissanceDate" control={control} />

      <InputText label="Email" {...register('assure.contactMail')} errors={errors} />

      <InputText label="Téléphone" {...register('assure.contactTelephone')} errors={errors} />

      <InputText label="Adresse" {...register('assure.voie')} errors={errors} />

      <InputText label="Complément" {...register('assure.complementAdresse')} errors={errors} />

      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('assure.contactCodePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="assure.ville"
          control={control}
          errors={errors}
          options={villeOptions || []}
        />
      </div>

      {data?.askForConsent && (
        <FormConsent
          contenuConsentement={contenuConsentement}
          control={control}
          errors={errors}
          label={cocheConsentement}
          name="assure.consentement"
        />
      )}
      <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
