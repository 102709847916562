import './BlocConseilQuotes.scss';
import classNames from 'classnames';
import React, { FC } from 'react';

interface BlocConseilQuotesProps {
  conseil: string;
  className?: string;
}

const BlocConseilQuotes: FC<BlocConseilQuotesProps> = ({ conseil, className }: BlocConseilQuotesProps) => {
  return (
    <div className={classNames('bloc-conseil-quotes', className)}>
      <h2 className="form-font-large">Notre conseil</h2>
      <div className="bloc-conseil-quotes__text" dangerouslySetInnerHTML={{ __html: conseil }} />
    </div>
  );
};

export default BlocConseilQuotes;
