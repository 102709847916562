import React, { ReactElement, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { assignValuesFromSource } from 'utils';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import { merge } from 'lodash';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { fetchTypeIndemnisationOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';

type TypeIndeminisationValues = {
  demandeInformations: boolean;
  type: string;
  valeur: number;
};

interface FormValues {
  typeIndemnisation: TypeIndeminisationValues | any;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  typeIndemnisation: Yup.object({
    demandeInformations: Yup.boolean().when('typeIndemnisation.valeur', {
      is: undefined,
      then: Yup.boolean().required(
        "Veuillez choisir un niveau de garantie ou cocher la case de demande d'informations"
      ),
      otherwise: Yup.boolean().oneOf([false])
    }),
    valeur: Yup.string().when('demandeInformations', {
      is: true,
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .nullable()
        .required("Veuillez choisir un type d'indemnisation ou cocher la case de demande d'informations")
    })
  })
}).defined();

const defaultState: FormValues = {
  typeIndemnisation: { type: 'TYPE_INDEMNISATION', demandeInformations: false }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => {
    return item;
  });
}

export default function FormPrevoyanceTypeIndemnisation(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const typeIndeminisationOptions = useFetch(fetchTypeIndemnisationOptions);

  const {
    handleSubmit,
    formState: { errors },
    trigger,
    control,
    register,
    setValue
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>{'Type d’indemnisation souhaitée pour l’incapacité et l’invalidité :'}</h2>

      <InputRadioButtonInline
        {...register('typeIndemnisation.valeur')}
        options={typeIndeminisationOptions || []}
        errors={errors}
      />

      <div className={'checkbox-container'}>
        <InputCheckBoxBoolean
          className={'checkbox'}
          control={control}
          name={'typeIndemnisation.demandeInformations'}
          label="Vous ne savez pas et vous souhaitez échanger avec votre courtier à ce sujet"
          onChange={e => {
            setValue(`typeIndemnisation`, {
              valeur: undefined,
              type: 'TYPE_INDEMNISATION',
              demandeInformations: e.target.checked
            });
            trigger('typeIndemnisation.demandeInformations');
            return e;
          }}
          errors={errors}
        />
      </div>

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
