import { useEffect } from 'react';
import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

function useTriggerOnValuesChange(watch: () => any, setLastFormState: (newState: any) => void): void {
  const values = watch();
  useEffect(() => {
    setLastFormState(values);
  });
}

/**
 * wrapper for useForm that will setLastFormState() on update
 */

export function useSerieForm<T extends FieldValues>(
  setLastFormState: (newState: T) => void,
  options?: UseFormProps<T>
): UseFormReturn<T> {
  const formParams = useForm<T>({
    criteriaMode: 'all',
    mode: 'all',
    ...options
  });
  useTriggerOnValuesChange(formParams.watch, setLastFormState);
  return formParams;
}
