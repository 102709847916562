import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputTextArea from 'components/Input/InputTextArea/InputTextArea.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';

type FormValues = {
  description: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  description: Yup.string().max(4096)
}).defined();

const defaultState: FormValues = {
  description: ''
};

export default function FormPrescripteurDescription(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>
        {'Description de votre demande '}
        <span className="de-emphasis">(facultatif)</span>
      </h2>
      <InputTextArea label="" {...register('description')} errors={errors} rows={5} />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} disabled={!isValid}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
