import React, { ReactElement } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-date.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import moment from 'moment';
import InfoBulle from '../../InfoBulle/InfoBulle';

interface InputProps {
  className?: string;
  tooltip?: string;
  label: string;
  name: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  isYearPicker?: boolean;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  birthdayDate?: boolean;
}

// format string to format DD/MM/YYYY
export function formatToDDMMYYYY(newStr: string): string | null {
  let res = '';
  let slashHasBeenFound = false;
  for (let i = 0; i < newStr.length; i++) {
    if (i === 2 || i === 4 + Number(slashHasBeenFound)) {
      if (newStr[i] !== '/') res = res.concat('/');
      else if (i === 2) slashHasBeenFound = true;
    } else if (!/^\d+$/.test(newStr[i])) return null;
    if (!/^(\d|\/)+$/.test(newStr[i])) return null;
    res = res.concat(newStr[i]);
  }
  return res;
}

// returns false if we have deleted the last char, returns true otherwise
function needFormat(newStr: string, oldStr: string): boolean {
  return !(newStr.length === oldStr.length - 1 && oldStr.slice(0, -1) === newStr);
}

function formatUserString(newValue: string | undefined, oldValue: string | undefined): string {
  if (!newValue) return '';
  if (!oldValue || needFormat(newValue, oldValue)) {
    const formatted = formatToDDMMYYYY(newValue);
    if (formatted === null) return '';
    return formatted;
  }
  return newValue;
}

export default function InputDatePicker({
  tooltip,
  control,
  label,
  errors,
  className,
  name,
  isYearPicker,
  showMonthDropdown,
  showYearDropdown,
  birthdayDate
}: InputProps): ReactElement {
  const error = getIn(name, errors);
  return (
    <div className={classNames('input-date', className, { 'input-error': error })}>
      <label>
        {label}
        {tooltip && (
          <InfoBulle
            className={'inputDatePicker__tooltip'}
            name={`description-tooltip-${label}`}
            key={`description-tooltip`}
            text={tooltip}
          />
        )}
      </label>
      <Controller
        control={control}
        render={({ field }) => {
          const value = field.value ? new Date(field.value) : undefined;
          // An invalid date object returns NaN for getTime() and NaN is the only
          // object not strictly equal to itself.
          if (!isYearPicker && value && value.getTime() !== value.getTime())
            throw Error('Invalid date provided to datePicker');
          return (
            <DatePicker
              withPortal
              autoComplete="off"
              dropdownMode="select"
              calendarClassName={isYearPicker ? 'calendar-year' : 'calendar-month'}
              selected={value}
              openToDate={birthdayDate ? new Date('1967-01-01') : new Date()}
              dateFormat={isYearPicker ? 'yyyy' : 'dd/MM/yyyy'}
              showYearPicker={isYearPicker}
              showMonthDropdown={showMonthDropdown || false}
              showYearDropdown={showYearDropdown || false}
              onChangeRaw={event => {
                if (!isYearPicker) {
                  const formattedValue = formatUserString(event.target.value, moment(field.value).format('L'));
                  event.target.value = formattedValue;
                }
              }}
              {...field}
            />
          );
        }}
        name={name}
      />
      <div>
        <FieldError>{error?.message}</FieldError>
      </div>
    </div>
  );
}
InputDatePicker.displayName = 'Input';
