import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { AccumulateStateToSerie, FormProps } from 'types';
import InputRadioButton from 'components/Input/InputRadioButton/InputRadioButton.component';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';

type FormValues = {
  profil: string;
};

const defaultState: FormValues = {
  profil: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  profil: Yup.string().oneOf(['PARTICULIER', 'TNS']).required()
}).defined();

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  state.type = 'PROSPECT';
  return merge(serieState, state);
};

export default function FormDecouverteChoixProfil(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    watch,
    handleSubmit,
    formState: { isDirty, errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => {
        props.goNextStep(values, AccumulateStateToSeries);
      })}
    >
      <h2>Vous êtes</h2>
      <InputRadioButton
        errors={errors}
        {...register('profil')}
        options={[
          { label: 'Un particulier', value: 'PARTICULIER' },
          {
            label: 'Un travailleur indépendant (chef d’entreprise, auto entrepreneur, libéral, etc…) ',
            value: 'TNS'
          }
        ]}
      />
    </AutoSubmissionForm>
  );
}
