import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchSituationMatrimonialeOptions } from '../../fetches/options.fetch';
import { assignValuesFromSource } from '../../utils';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';

type FormValues = {
  assure: {
    fumeurRegulier: boolean;
    pratiqueSportARisque: boolean;
    situationMatrimoniale: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    fumeurRegulier: Yup.boolean().required(),
    pratiqueSportARisque: Yup.boolean().required(),
    situationMatrimoniale: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  assure: {
    fumeurRegulier: false,
    pratiqueSportARisque: false,
    situationMatrimoniale: undefined
  }
};

export default function FormDecouverteSituationPerso(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const situationMat = useFetch(fetchSituationMatrimonialeOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre situation personnelle:</h2>
      <InputSelect
        label="Situation matrimoniale"
        name="assure.situationMatrimoniale"
        control={control}
        errors={errors}
        options={situationMat || []}
      />
      <InputCheckBoxBoolean
        control={control}
        label="Fumeur régulier sur les deux dernières années"
        name="assure.fumeurRegulier"
        errors={errors}
      />
      <InputCheckBoxBoolean
        control={control}
        label="Pratiques de sports à risques"
        name="assure.pratiqueSportARisque"
        errors={errors}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
