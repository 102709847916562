import { SeriesConfigs } from 'types';
import CONTACT from './CONTACT.config';
import PRESCRIPTEUR from './CONTACT_PRESCRIPTEUR.config';
import DECOUVERTE_ENTREPRISE from './DECOUVERTE_ENTREPRISE.config';
import DECOUVERTE_PROSPECT from './DECOUVERTE_PROSPECT.config';
import {
  demandeAdhesionAvecFraisAvecInfos,
  demandeAdhesionAvecFraisSansInfos,
  demandeAdhesionSansFraisAvecInfos,
  demandeAdhesionSansFraisSansInfos
} from './DEMANDE_ADHESION.config';
import MISE_EN_RELATION from './MISE_EN_RELATION.config';
import PRISE_CONTACT_EXPRESS from './PRISE_CONTACT_EXPRESS.config';
import ASSURANCE_PRET from './PROJET_ASSURANCE_PRET.config';
import AUTO from './PROJET_AUTO.config';
import COMPLEMENTAIRE_ANIMAL from './PROJET_COMPLEMENTAIRE_ANIMAL.config';
import DECES from './PROJET_DECES.config';
import HABITATION from './PROJET_HABITATION.config';
import GAV from './PROJET_GAV.config';
import IJ_HOSPITALISATION from './PROJET_IJ_HOSPITALISATION.config';
import PROJET_MANUEL from './PROJET_MANUEL_DYNAMIQUE_CONFIG.config';
import PREVOYANCE from './PROJET_PREVOYANCE.config';
import EXPATRIE from './PROJET_SANTE_EXPAT.config';
import SANTE_PARTICULIER from './PROJET_SANTE_PART.config';
import SANTE from './PROJET_SANTE_TNS.config';
import MANDAT_SEPA from './MANDAT_SEPA.config';
import RESILIATION_TUTELAIRE from './RESILIATION_TUTELAIRE.config';

export const series: SeriesConfigs = {
  ASSURANCE_PRET,
  AUTO,
  COMPLEMENTAIRE_ANIMAL,
  CONTACT,
  CONTACT_WE_ADVISE: CONTACT,
  DECES,
  DECOUVERTE_ENTREPRISE,
  DECOUVERTE_PROSPECT,
  EXPATRIE,
  GAV,
  HABITATION,
  IJ_HOSPITALISATION,
  MANDAT_SEPA,
  MISE_EN_RELATION,
  PRESCRIPTEUR,
  PREVOYANCE,
  PRISE_CONTACT_EXPRESS,
  PROJET_MANUEL,
  RESILIATION_TUTELAIRE,
  SANTE,
  SANTE_PARTICULIER,
  demandeAdhesionAvecFraisAvecInfos,
  demandeAdhesionAvecFraisSansInfos,
  demandeAdhesionSansFraisAvecInfos,
  demandeAdhesionSansFraisSansInfos
};
