import { yupResolver } from '@hookform/resolvers/yup';
import FormGenericLink from 'components/FormGenericLink/FormGenericLink.component';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputImmatriculation from 'components/Input/InputImmatriculation/InputImmatriculation.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import parseSearch from 'utils/parseSearch';
import Yup from 'utils/Yup';
import { fetchSIV } from '../../fetches/siv.fetch';

type FormValues = {
  immatriculation: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  immatriculation: Yup.string()
    .matches(
      /^([A-Z]{2}-\d{3}-[A-Z]{2})$|^(\d{1,4}-[A-Z]{1,3}-(\d{2}|2A|2B))$/gi,
      "L'immatriculation doit être sous la forme AB-123-CD (ou 123-ABC-45 avant 2009)."
    )
    .required()
}).defined();

const defaultState: FormValues = {
  immatriculation: undefined
};

export default function FormAutoImmatriculation({
  goNextStep,
  initialFormValues,
  formValues,
  setLastFormState,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const [loading, setLoading] = React.useState(false);
  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});
  const id = parseSearch(window.location.search, 'id');

  const { handleSubmit, control, setError } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = async (data: FormValues) => {
    setLoading(true);

    const immat = await fetchSIV(id, data.immatriculation)
      .catch(err => {
        setError('immatriculation', { message: err.message });
      })
      .finally(() => setLoading(false));

    if (immat) {
      goNextStep({
        besoin: {
          vehicule: {
            immatriculation: immat.immat,
            marque: immat.marque,
            modele: immat.modele,
            finition: immat.sraCommercial,
            premiereMiseEnCirculationDate: new Date(immat.date1erCirFr),
            codeSra: immat.sraId,
            typeVehiculeCode: 'AUTO:TYPE_VEHICULE'
          }
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Votre <span>véhicule</span>
      </h1>

      <InputImmatriculation label="Quel est son numéro d'immatriculation ?" name="immatriculation" control={control} />

      <div className="layout-button-container">
        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken} loading={loading}>
          Suivant
        </FormNextButton>
        <FormGenericLink
          onClick={() =>
            goNextStep({
              besoin: {
                vehicule: {
                  immatriculation: undefined,
                  marque: undefined,
                  modele: undefined,
                  finition: undefined,
                  premiereMiseEnCirculationDate: undefined,
                  codeSra: undefined,
                  typeVehiculeCode: 'AUTO:TYPE_VEHICULE'
                }
              }
            })
          }
        >
          Je ne connais pas l&apos;immatriculation
        </FormGenericLink>
      </div>
    </form>
  );
}
