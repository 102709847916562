import React, { ReactElement } from 'react';
import './right-pane.scss';
import TitleFormWithLogo from '../Title/TitleFormWithLogo';

interface MobileFooterInfos {
  title: string;
  siteCourtier?: string;
  nomCourtier?: string;
}

type RightPaneProps = {
  children?: React.ReactNode;
  mobileFooter?: MobileFooterInfos;
  labelButtonBackToSite: string;
};

const RightPane = React.forwardRef<HTMLDivElement, RightPaneProps>(
  // eslint-disable-next-line react/prop-types
  ({ children, mobileFooter, labelButtonBackToSite }, ref): ReactElement => {
    return (
      <div id="right-pane" ref={ref}>
        {children}
        {mobileFooter && (
          <div className={'mobile-info'}>
            <TitleFormWithLogo title={mobileFooter.title} mobile />
            {mobileFooter.siteCourtier && window.location === window.parent.location && (
              <div>
                <a href={mobileFooter.siteCourtier}> {`< ${labelButtonBackToSite ?? 'Retour vers le site'}`}</a>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default RightPane;
RightPane.displayName = 'RightPane';
