import React from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-radio-button.scss';
import classNames from 'classnames';

interface InputRadioButtonProps {
  name: string;
  errors: FieldErrors<any>;
  options: Option[];
  className?: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
}

interface Option<T = string> {
  label: string;
  value: T;
}

export const InputRadioButton = React.forwardRef<HTMLInputElement, InputRadioButtonProps>(
  ({ errors, options, className, ...field }: InputRadioButtonProps, ref) => {
    const error = getIn(field.name, errors);

    return (
      <div className={classNames(className, 'InputRadio')}>
        <div>
          {options.map(option => (
            <label
              key={option.value}
              tabIndex={0}
              className={classNames('InputRadio__button', className, { '-error': error })}
              onKeyDown={e => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                  e.currentTarget.click();
                }
              }}
            >
              {option.label}
              <input tabIndex={-1} type="radio" {...field} value={option.value} ref={ref} />
            </label>
          ))}
        </div>
        <FieldError>{error?.message}</FieldError>
      </div>
    );
  }
);
InputRadioButton.displayName = 'InputRadioButton';

export default InputRadioButton;
