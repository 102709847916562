import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputText from 'components/Input/InputText/InputText.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import { ColoredText } from '../../../components/ColoredText/ColoredText';

type FormValues = {
  interlocuteur: {
    email: string;
    numeroTelephone: string;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  interlocuteur: Yup.object({
    email: Yup.string().max(255).email().required(),
    numeroTelephone: Yup.string()
      .required()
      .transform((val: string) => val.replace(/\s/g, ''))
      .max(255)
      .telephone()
  }).defined()
}).defined();

const defaultState: FormValues = {
  interlocuteur: {
    email: '',
    numeroTelephone: ''
  }
};

export default function FormPrescripteurTnsInfosContact(props: FormProps<FormValues>): ReactElement {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });
  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>
        <ColoredText className={'_secondaire'} text="Informations " /> du contact
      </h2>
      <InputText label="Adresse email" {...register('interlocuteur.email')} errors={errors} />
      <InputText
        label="Téléphone"
        {...register('interlocuteur.numeroTelephone')}
        errors={errors}
        preventTelephone={true}
      />

      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
