import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useMemo } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';
import { ReactComponent as SvgCouple } from 'assets/couple.svg';
import { ReactComponent as SvgSeul } from 'assets/seul.svg';

export type FormValues = {
  nombreEmprunteur: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  nombreEmprunteur: Yup.string().required()
}).defined();

const defaultState: FormValues = {
  nombreEmprunteur: undefined
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormEmprunteurTypeCredit({
  previousFormValues,
  setLastFormState,
  formValues,
  goNextStep,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(previousFormValues), []);

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors, isDirty }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => goNextStep(values))}
    >
      <h1 className="title">
        Combien <span>d&apos;emprunteur</span>
      </h1>

      <InputRadioButtonIcon
        {...register('nombreEmprunteur')}
        errors={errors}
        options={[
          { value: 'UN_EMPRUNTEUR', label: 'Seul', icon: <SvgSeul style={{ width: '65px', height: 'auto' }} /> },
          { value: 'DEUX_EMPRUNTEURS', label: 'À deux', icon: <SvgCouple style={{ width: '100px', height: 'auto' }} /> }
        ]}
      />
      {!!formValues && (
        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      )}
    </AutoSubmissionForm>
  );
}
