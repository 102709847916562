import React, { CSSProperties, ReactNode } from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { getIn } from 'utils';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './input-radio-button-icon.scss';
import classNames from 'classnames';

type InputRadioButtonIconProps = {
  value?: string;
  name: string;
  errors: FieldErrors<any>;
  options: Option[];
  className?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  label?: ReactNode;
  small?: boolean;
  noWrap?: boolean;
  labelStyle?: CSSProperties;
};

interface Option<T = string> {
  value: T;
  label: ReactNode;
  icon?: ReactNode;
}

export const InputRadioButtonIcon = React.forwardRef<HTMLInputElement, InputRadioButtonIconProps>(
  ({ errors, options, className, label, labelStyle, small, noWrap, ...fieldProps }: InputRadioButtonIconProps, ref) => {
    const error = getIn(fieldProps.name, errors);

    return (
      <div
        style={labelStyle}
        className={classNames(className, { 'input-error': error, '-small': small }, 'InputRadioButtonIcon')}
      >
        <div
          className={classNames('InputRadioButtonIcon__label', {
            '-error': error
          })}
          hidden={!label}
        >
          {label}
        </div>
        <div className={classNames('InputRadioButtonIcon__group', { '-noWrap': noWrap })}>
          {options.map(option => (
            <div key={option.value} className={classNames('InputRadioButtonIcon__layout')}>
              <label
                tabIndex={0}
                className={classNames('InputRadioButtonIcon__label', { '-error': error })}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    e.preventDefault();
                    e.currentTarget.click();
                  }
                }}
              >
                <input
                  tabIndex={-1}
                  type="radio"
                  {...fieldProps}
                  onChange={e => {
                    if (fieldProps.onChange) fieldProps.onChange(e);
                  }}
                  value={option.value}
                  ref={ref}
                />
                <span className={classNames('InputRadioButtonIcon__button', { '-error': error })}>
                  {option.icon && <div className="InputRadioButtonIcon__icon">{option.icon}</div>}
                  {option.label}
                </span>
              </label>
            </div>
          ))}
        </div>
        <FieldError>{error?.message}</FieldError>
      </div>
    );
  }
);

InputRadioButtonIcon.displayName = 'InputRadioButtonIcon';

export default InputRadioButtonIcon;
