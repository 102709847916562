import './BarsLevel.scss';
import React, { FC } from 'react';

type BarsLevelProps = {
  numberItem: number;
  numberSelected: number;
};

export const BarsLevel: FC<BarsLevelProps> = ({ numberSelected }: BarsLevelProps) => {
  const array = Array.from(Array(4).keys());
  return (
    <div className={'bars-level'}>
      {array.map((item: any, i) => {
        const className = i < numberSelected ? 'bars-level__bar selected' : 'bars-level__bar';
        return <div key={i} className={className}></div>;
      })}
    </div>
  );
};
