import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';

type FormValues = {
  profil: string;
};

const defaultState: FormValues = {
  profil: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  profil: Yup.string().oneOf(['PARTICULIER', 'ENTREPRISE', 'TNS']).required()
}).defined();

export default function FormPrescripteurChoixProfil(props: FormProps<FormValues>): ReactElement {
  const {
    watch,
    handleSubmit,
    formState: { isDirty, errors },
    register
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => {
        props.goNextStep(values);
      })}
    >
      <h2>Vous souhaitez enregistrer un contact :</h2>
      <InputRadioButtonInline
        {...register('profil')}
        options={[
          { label: 'Particulier', value: 'PARTICULIER' },
          { label: 'Entreprise', value: 'ENTREPRISE' },
          { label: 'TNS', value: 'TNS' }
        ]}
        fullWidthLabel
        errors={errors}
      />
    </AutoSubmissionForm>
  );
}
