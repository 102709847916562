import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputText from 'components/Input/InputText/InputText.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { fetchOrigineEntrepriseOptions, fetchPaysOptions, fetchVilleOptions } from 'fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from 'hooks/useFetchOptionsOnFieldChange.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource, removeProperty } from 'utils';
import Yup from 'utils/Yup';
import { EntrepriseInputsWithFetch } from 'components/Input/EntrepriseInputWithFetch/EntrepriseInputsWithFetch';
import InputDatePicker from '../../components/Input/InputDatePicker/InputDatePicker.component';
import InputSelect from '../../components/Input/InputSelect/InputSelect.component';
import { useFetch } from '../../hooks/useFetch.hook';

type EntrepriseValues = {
  siret: string;
  raisonSociale: string;
  voie: string;
  complementAdresse: string;
  codePostal: string;
  ville: string;
  pays: string;
  origine: string;
  creationDate?: Date;
  statutJuridique?: string;
};

interface FormValues {
  enCoursDeCreation: boolean;
  entreprise: EntrepriseValues | any;
}

const entrepriseFields = {
  raisonSociale: Yup.string().required().max(500),
  voie: Yup.string().required().max(255),
  complementAdresse: Yup.string().max(255).notRequired(),
  codePostal: Yup.string().required(),
  ville: Yup.string().required().max(255),
  pays: Yup.string().required(),
  origine: Yup.string().required(),
  statutJuridique: Yup.string()
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  enCoursDeCreation: Yup.boolean().required(),
  entreprise: Yup.object().when('enCoursDeCreation', {
    is: true,
    then: Yup.object({
      siret: Yup.string().length(0),
      creationDate: Yup.date().nullable(),
      ...entrepriseFields
    }).defined(),
    otherwise: Yup.object({
      siret: Yup.string().required().siret(),
      creationDate: Yup.date().required(),
      ...entrepriseFields
    }).defined()
  })
}).defined();

const defaultState: FormValues = {
  enCoursDeCreation: false,
  entreprise: {
    siret: '',
    raisonSociale: '',
    voie: '',
    complementAdresse: '',
    codePostal: '',
    ville: '',
    pays: 'FR:PAYS',
    creationDate: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  state.type = 'ENTREPRISE';
  return merge(serieState, removeProperty<FormValues>('enCoursDeCreation', state));
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return {
    ...assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
      if (key === 'creationDate') return new Date(item);
      return item;
    }),
    enCoursDeCreation: initialFormValues?.entreprise ? initialFormValues.entreprise.siret === null : false
  };
}

export default function FormManuelEntreprise(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const form = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = form;

  const origineOptions = useFetch(fetchOrigineEntrepriseOptions);
  const villeOptions = useFetchOptionsOnFieldChange(watch, 'entreprise.codePostal', fetchVilleOptions);
  const paysOptions = useFetch(fetchPaysOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const enCoursDeCreation = watch('enCoursDeCreation');
  useEffect(() => {
    if (enCoursDeCreation) {
      setValue('entreprise.siret', '');
      trigger('entreprise.siret');
    }
  }, [enCoursDeCreation]);

  const origineEntreprise = watch('entreprise.origine');

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre entreprise</h2>
      <InputCheckBoxBoolean
        control={control}
        label="Entreprise en cours de création"
        name="enCoursDeCreation"
        errors={errors}
      />
      <div className="layout-field-container">
        <EntrepriseInputsWithFetch form={form} />
      </div>
      <div className="layout-field-container">
        <InputSelect
          label="Origine"
          name="entreprise.origine"
          control={control}
          errors={errors}
          options={origineOptions || []}
        />
        <InputDatePicker
          label={`Date de ${
            origineEntreprise === 'REPRISE:ORIGINE_ENTREPRISE' ? 'reprise' : 'création'
          } de l'entreprise`}
          name={'entreprise.creationDate'}
          errors={errors}
          control={control}
          showMonthDropdown
          showYearDropdown
        />
      </div>
      <InputText label="Adresse du du siège social" {...register('entreprise.voie')} errors={errors} />
      <InputText
        label="Complément d'adresse (facultatif)"
        {...register('entreprise.complementAdresse')}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('entreprise.codePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="entreprise.ville"
          control={control}
          errors={errors}
          options={villeOptions}
        />
      </div>
      <InputTextAutoComplete
        label={'Pays'}
        name={'entreprise.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
