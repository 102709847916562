import { MotifResiliation } from '../forms/RESILIATION/data/MotifResiliation';
import { TypeContrat } from '../forms/RESILIATION/data/TypeContrat';

type BaseResiliationTutelaireProps = {
  idFormulaire: string;
};

export type ClientResiliationTutelaireProps = {
  civilite: string;
  prenom: string;
  nom: string;
  numeroAdherent: string;
  telephone: string;
  email: string;
  motifResiliation: MotifResiliation;
  motifResiliationAutre: string;
  typeContrat: TypeContrat;
  dateResiliationSouhaite: Date;
} & BaseResiliationTutelaireProps;

export type DocumentResiliationTutelaireProps = {
  document: FormData;
  fileName: string;
  fileUploadedName: string;
  numeroClient: string | undefined;
  categorie: string;
} & BaseResiliationTutelaireProps;

const API_URL = `${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/resiliationTutelaire`;

export const useSaveClientResiliationTutelaire = async (
  clientResiliationTutelaire: ClientResiliationTutelaireProps
): Promise<string> => {
  const options = {
    method: 'POST',
    body: JSON.stringify(clientResiliationTutelaire),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const jsonResponse = await fetch(`${API_URL}/client`, options);
  const response = await jsonResponse.json();
  return response.numeroClient;
};

export const useSaveDocumentResiliationTutelaire = async (
  documentResiliationTutelaire: DocumentResiliationTutelaireProps
): Promise<void> => {
  const idFormulaire = documentResiliationTutelaire.idFormulaire;
  const numeroClient = documentResiliationTutelaire.numeroClient;
  const categorie = documentResiliationTutelaire.categorie;
  const endpoint = `${API_URL}/document/${idFormulaire}/${numeroClient}/${categorie}`;
  await fetch(endpoint, { method: 'POST', body: documentResiliationTutelaire.document });
};
