import { yupResolver } from '@hookform/resolvers/yup';
import InputText from 'components/Input/InputText/InputText.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import { ColoredText } from '../../components/ColoredText/ColoredText';
import FormConsent from '../../components/FormConsent/FormConsent.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from '../../components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputTextArea from '../../components/Input/InputTextArea/InputTextArea.component';
import { CIVILITES_OPTIONS } from '../../consts/consts';
import { fetchDureeSejourOptions } from '../../fetches/options.fetch';
import { useFetch } from '../../hooks/useFetch.hook';
import Yup from '../../utils/Yup';
import { FormField, FormGrid, FormRow } from './FormGrid';

import './FormPriseContactExpress.scss';

interface FormValues {
  consentement: boolean;
  description: string | undefined;
  civilite: string;
  prenom: string;
  nom: string;
  contactMail: string;
  contactTelephone: string;
  profil: string;
  dureeSejour?: string;
}

//par défaut
const defaultState: FormValues = {
  consentement: false,
  description: '',
  civilite: '',
  prenom: '',
  nom: '',
  contactMail: '',
  contactTelephone: '',
  profil: 'PARTICULIER',
  dureeSejour: ''
};

export default function FormPriseContactExpress(props: FormProps<FormValues>): ReactElement {
  const dureeSejourValidation = props.showDureeSejour == 'true' ? Yup.string().required() : Yup.string().notRequired();

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    consentement: Yup.boolean().required().oneOf([true], 'La validation du consentement est obligatoire'),
    description: Yup.string(),
    civilite: Yup.string()
      .nullable()
      .required()
      .oneOf(
        CIVILITES_OPTIONS.map(option => option.value),
        'Champ obligatoire'
      ),
    prenom: Yup.string().required().max(255),
    nom: Yup.string().required().max(255),
    contactMail: Yup.string().required().email().max(255),
    contactTelephone: Yup.string().required().telephone(),
    profil: Yup.string().required(),
    dureeSejour: dureeSejourValidation
  }).defined();

  const {
    register,
    formState: { errors, isValid },
    control,
    handleSubmit
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    data.contactTelephone = data.contactTelephone?.replace(/\s/g, '');
    props.goNextStep(data);
  };

  const dureeSejourOptions = useFetch(fetchDureeSejourOptions);

  return (
    <div className={'priseContactExpress'}>
      <form onSubmit={handleSubmit(handleNextClick)}>
        <FormGrid size={'three-quarter'}>
          <h2>
            Vos <ColoredText className={'_secondaire'} text="coordonnées" />
          </h2>
          <FormRow>
            <FormField>
              <InputRadioButtonInline
                {...register('civilite')}
                labelField="Civilité"
                options={CIVILITES_OPTIONS}
                errors={errors}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField size={'half'}>
              <InputText className="layout-flex-basis-auto" label="Prénom" {...register('prenom')} errors={errors} />
            </FormField>
            <FormField size={'half'}>
              <InputText className="layout-flex-basis-auto" label="Nom" {...register('nom')} errors={errors} />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField>
              <InputText
                className="layout-flex-basis-auto"
                label="Adresse mail"
                {...register('contactMail')}
                errors={errors}
              />
            </FormField>
          </FormRow>
          <FormRow>
            <FormField size={'half'}>
              <label>Numéro de téléphone</label>
              <InputText
                className="layout-flex-basis-auto indicatifPays"
                label="(Si numéro étranger, renseignez l'indicatif pays)"
                {...register('contactTelephone')}
                errors={errors}
              />
            </FormField>
          </FormRow>
          {props.showDureeSejour === 'true' && (
            <FormRow>
              <FormField size={'full'}>
                <InputRadioButtonInline
                  className={'dureeSejour'}
                  {...register('dureeSejour')}
                  labelField="Durée du séjour"
                  options={dureeSejourOptions || []}
                  errors={errors}
                />
              </FormField>
            </FormRow>
          )}

          <FormRow className={'votreMessage'}>
            <h2>
              Votre <ColoredText className={'_secondaire'} text="message" />
            </h2>
          </FormRow>
          <FormRow>
            <FormField>
              <InputTextArea label="Facultatif" {...register('description')} errors={errors} />
            </FormField>
          </FormRow>
          <FormConsent
            contenuConsentement={props.contenuConsentement}
            control={control}
            errors={errors}
            label={props.cocheConsentement}
            {...register('consentement')}
          />

          <FormNextButton
            className={'submit-form'}
            disabled={!isValid}
            showCaptcha={props.showCaptcha}
            setCaptchaToken={props.setCaptchaToken}
          >
            Envoyer
          </FormNextButton>
        </FormGrid>
      </form>
    </div>
  );
}
