import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useMemo } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import { fetchTypeLogementOptions } from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { ReactComponent as SvgChalet } from 'assets/chalet.svg';
import { ReactComponent as SvgAppart } from 'assets/appartment.svg';
import { ReactComponent as SvgHouse } from 'assets/house.svg';
import { ReactComponent as SvgHouse2 } from 'assets/maison-plein-pied.svg';

type FormValues = {
  habitation: {
    nature: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  habitation: Yup.object({
    nature: Yup.string().required()
  })
}).defined();

const defaultState: FormValues = {
  habitation: {
    nature: undefined
  }
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormHabitationChoix({
  setLastFormState,
  previousFormValues,
  formValues,
  goNextStep,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(previousFormValues), []);
  const {
    watch,
    handleSubmit,
    register,
    formState: { isDirty, errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  const typeLogementOptions = useFetch(fetchTypeLogementOptions);

  const iconTypeLogement: Record<string, JSX.Element> = {
    'APPARTEMENT:TYPE_LOGEMENT': <SvgAppart style={{ width: '100px', height: 'auto' }} />,
    'MAISON:TYPE_LOGEMENT': <SvgHouse style={{ width: '100px', height: 'auto' }} />,
    'MAISON_PLEIN_PIED:TYPE_LOGEMENT': <SvgHouse2 style={{ width: '100px', height: 'auto' }} />,
    'CHALET:TYPE_LOGEMENT': <SvgChalet style={{ width: '100px', height: 'auto' }} />
  };

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => {
        goNextStep(values);
      })}
    >
      <h1 className="title">
        Quel est le <span>type de logement</span> à assurer ?
      </h1>
      <InputRadioButtonIcon
        {...register('habitation.nature')}
        errors={errors}
        options={typeLogementOptions?.map(element => ({ ...element, icon: iconTypeLogement[element.value] })) || []}
      />

      {!!formValues && (
        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      )}
    </AutoSubmissionForm>
  );
}
