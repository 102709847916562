import * as IBAN from 'iban';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import { isSIRET } from 'siret';
import * as Yup from 'yup';
import * as ibantools from 'ibantools';

// https://github.com/jquense/yup/blob/3ad94724dc23175dd4378ac64c3d5396bebc52f2/src/util/printValue.js
const toString = Object.prototype.toString;
const errorToString = Error.prototype.toString;
const regExpToString = RegExp.prototype.toString;
const symbolToString =
  typeof Symbol !== 'undefined'
    ? Symbol.prototype.toString
    : function () {
        return '';
      };
const SYMBOL_REGEXP = /^Symbol\((.*)\)(.*)$/;

function printNumber(val: number) {
  if (val !== +val) return 'NaN';
  const isNegativeZero = val === 0 && 1 / val < 0;
  return isNegativeZero ? '-0' : '' + val;
}

function printSimpleValue(val: any, quoteStrings: any) {
  if (quoteStrings === void 0) {
    quoteStrings = false;
  }

  if (val === null || val === true || val === false) return '' + val;
  const typeOf = typeof val;
  if (typeOf === 'number') return printNumber(val);
  if (typeOf === 'string') return quoteStrings ? '"' + val + '"' : val;
  if (typeOf === 'function') return '[Function ' + (val.name || 'anonymous') + ']';
  if (typeOf === 'symbol') return symbolToString.call(val).replace(SYMBOL_REGEXP, 'Symbol($1)');
  const tag = toString.call(val).slice(8, -1);
  if (tag === 'Date') return isNaN(val.getTime()) ? '' + val : val.toISOString(val);
  if (tag === 'Error' || val instanceof Error) return '[' + errorToString.call(val) + ']';
  if (tag === 'RegExp') return regExpToString.call(val);
  return null;
}

function printValue(value: any, quoteStrings: any) {
  const result = printSimpleValue(value, quoteStrings);
  if (result !== null) return result;
  return JSON.stringify(
    value,
    function (key, value) {
      const result = printSimpleValue(this[key], quoteStrings);
      if (result !== null) return result;
      return value;
    },
    2
  );
}

/*eslint-disable no-template-curly-in-string*/

const mixed = {
  default: 'Champ invalide.',
  required: 'Champ obligatoire',
  oneOf: "Vous devez saisir l'une des valeurs suivantes: ${values}",
  notOneOf: 'Vous devez saisir une valeur différente de: ${values}',
  notType: function notType(_ref: any) {
    const type = _ref.type,
      value = _ref.value,
      originalValue = _ref.originalValue;
    const isCast = originalValue != null && originalValue !== value;
    let msg =
      'Le champ doit \xEAtre un type `' +
      type +
      '`, ' +
      ('mais la valeur finale \xE9tait: `' + printValue(value, true) + '`') +
      (isCast ? ' (coul\xE9e de la valeur `' + printValue(originalValue, true) + '`).' : '.');

    if (value === null) {
      msg +=
        '\n Si \xAB null \xBB est con\xE7ue comme une valeur vide assurez-vous de marquer le sch\xE9ma comme' +
        ' `.nullable()`';
    }

    console.error(msg);
    if (value == null) {
      return 'Champ obligatoire';
    }
    return 'Champ invalide';
  }
};
const string = {
  length: 'Le champ doit contenir exactement ${length} caractères',
  min: 'Le champ doit contenir au moins ${min} caractères',
  max: 'Le champ doit contenir au plus ${max} caractères',
  matches: 'Le champ doit contenir doit correspondre à la règle: "${regex}"',
  email: 'Le champ doit être un email valide',
  url: 'Le champ doit être une URL valide',
  trim: 'Le champ doit être une chaîne garnie',
  lowercase: 'Le champ doit être une chaîne en minuscule',
  uppercase: 'Le champ doit être une chaîne de majuscules'
};
const number = {
  min: 'Le champ doit être supérieure ou égale à ${min}',
  max: 'Le champ doit être inférieur ou égal à ${max}',
  lessThan: 'Le champ doit être inférieure à ${less}',
  moreThan: 'Le champ doit être supérieure à ${more}',
  positive: 'Le champ doit être un nombre positif',
  negative: 'Le champ doit être un nombre négatif',
  integer: 'Le champ doit être un entier'
};
const date = {
  min: 'Le champ doit être au plus tard ${min}',
  max: 'Le champ doit être au plus tôt ${max}'
};
const _boolean = {};
const object = {
  noUnknown: "Le champ ne peut pas avoir des clés non spécifiées dans la forme de l'objet"
};
const array = {
  min: 'Le champ doit avoir au moins ${min} articles',
  max: 'Le champ doit avoir inférieur ou égal à ${max} articles'
};

const fr = {
  __proto__: null,
  mixed: mixed,
  string: string,
  number: number,
  date: date,
  boolean: _boolean,
  object: object,
  array: array
};

Yup.setLocale(fr);

Yup.addMethod<Yup.StringSchema>(Yup.string, 'siret', function (message = 'Le champ doit contenir un n° SIRET valide') {
  return this.test('isSIRET', message, value => isSIRET(value));
});

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'ignoreEmptyString', function () {
  return this.transform((cv, ov) => {
    return ov === '' || ov === null ? undefined : cv;
  });
});

Yup.addMethod(Yup.date, 'ageMinimum', function (age, message) {
  const dateMin = moment().subtract(age, 'year');
  return this.test('is after', message || `La personne doit être agée d'au moins ${age} ans`, function (value) {
    if (!value) return true;
    return dateMin > moment(value);
  });
});

Yup.addMethod<Yup.StringSchema>(Yup.string, 'iban', function (message = 'Le champ doit contenir un IBAN valide') {
  return this.test('isIban', message, value => IBAN.isValid(value || ''));
});
Yup.addMethod<Yup.StringSchema>(Yup.string, 'bic', function (message = 'Le champ doit contenir un BIC valide') {
  return this.test('isBic', message, value => ibantools.isValidBIC(value || ''));
});

Yup.addMethod(Yup.string, 'isCodif', function (codeType, message) {
  return this.test(
    'is codif',
    message || `La valeure sélectionné doit être sous le format CODE:${codeType}`,
    function (value) {
      if (!value) return true;
      return value.endsWith(`:${codeType}`);
    }
  );
});

Yup.addMethod(Yup.string, 'telephone', function (message = 'Le champ doit être un numéro de téléphone valide') {
  return this.test('isTelephone', message, value => !value || isValidPhoneNumber(value, 'FR'));
});

Yup.addMethod(
  Yup.string,
  'telephoneInternational',
  function (message = 'Le champ doit être un numéro de téléphone valide') {
    return this.test(
      'isTelephoneInternational',
      message,
      value => !value || isValidPhoneNumber(value, 'FR') || isPossiblePhoneNumber(value)
    );
  }
);

export default Yup;
