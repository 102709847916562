import React, { FC } from 'react';
import { RoundedCard } from '../../../components/RoundedCard/RoundedCard';
import classNames from 'classnames';

interface AssureurRowProps {
  proposedQuotes: any;
  quoteMobileDisplay: number;
}

const AssureurRow: FC<AssureurRowProps> = ({ proposedQuotes, quoteMobileDisplay }) => {
  return (
    <div className={'row-assureur'}>
      <div className={'row-assureur title'}>
        <h3 className="form-font-large">Assureur</h3>
      </div>
      <div className={'row-assureur content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          return (
            <RoundedCard
              key={quote.numeroInterne}
              className={classNames(
                'row-assureur__content',
                'card',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <img src={quote.produit.assureur.urlLogo} />
              <span className="form-font-regular">
                {quote.produit.assureur.personne?.raisonSociale ?? quote.produit.assureur.code}{' '}
              </span>
            </RoundedCard>
          );
        })}
      </div>
    </div>
  );
};

export default AssureurRow;
