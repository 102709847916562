import { useEffect, useState } from 'react';

export function useFetch<T>(fetchFunction: () => Promise<T>, dependencies?: any[]): T | undefined {
  const [result, setResult] = useState<T | undefined>(undefined);
  useEffect(() => {
    fetchFunction()
      .then(res => {
        setResult(res);
      })
      .catch(err => {
        console.error(err);
      });
  }, dependencies || []);
  return result;
}
