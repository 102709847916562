import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormBesoinsDeces from '../forms/DECES/FormDecesBesoins.component';
import FormDecesIdentite from '../forms/DECES/FormDecesIdentite.component';

import FormSanteParticulierCoordonne from '../forms/SANTE_PART/FormSanteParticulierCoordonne.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description: 'Vous devez être majeur pour souscrire une assurance Décès.'
  },
  STEP_BESOINS: {
    title: 'Pourquoi ces questions ?',
    description: "Vous pouvez choisir différents niveaux d'Indemnités Journalières"
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description: "Afin de nous permettre d'établir une proposition d'assurance, vous devez fournir vos coordonnées."
  }
};

const projetDecesConfig: FormConfigWithUrl = {
  endpoint: 'projetDeces',
  formSteps: [
    {
      component: FormDecesIdentite,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
    },
    {
      component: FormBesoinsDeces,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_BESOINS} />
    },
    {
      component: FormSanteParticulierCoordonne,
      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />,
      data: { askForConsent: true, showCaptcha: true }
    }
  ]
};

export default projetDecesConfig;
