import FormDecouverteChoixProfil from 'forms/DECOUVERTE_PROSPECT/FormDecouverteChoixProfil.component';
import FormDecouverteCoordonne from 'forms/DECOUVERTE_PROSPECT/FormDecouverteCoordonne.component';
import FormDecouverteDescription from 'forms/DECOUVERTE_PROSPECT/FormDecouverteDescription.component';
import FormDecouverteEntreprise from 'forms/DECOUVERTE_PROSPECT/FormDecouverteEntreprise.component';
import FormDecouverteIdentite from 'forms/DECOUVERTE_PROSPECT/FormDecouverteIdentite.component';
import FormDecouverteSituationPerso from 'forms/DECOUVERTE_PROSPECT/FormDecouverteSituationPerso.component';
import FormDecouverteSituationPro from 'forms/DECOUVERTE_PROSPECT/FormDecouverteSituationPro.component';
import React from 'react';
import { FormConfigWithUrl, StepDescriptionList } from 'types';
import LeftPaneDescription from '../components/LeftPane/LeftPaneDescription.component';
import FormRisqueActivitePro from '../forms/DECOUVERTE_PROSPECT/FormRisqueActivitePro.component';
import FormRisqueBiensPerso from '../forms/DECOUVERTE_PROSPECT/FormRisqueBiensPerso.component';
import FormRisqueFamilleParticulier from '../forms/DECOUVERTE_PROSPECT/FormRisqueFamilleParticulier.component';
import FormRisqueFamilleTNS from '../forms/DECOUVERTE_PROSPECT/FormRisqueFamilleTNS.component';
import FormRisqueRetraite from '../forms/DECOUVERTE_PROSPECT/FormRisqueRetraite.component';
import FormRisqueSalarie from '../forms/DECOUVERTE_PROSPECT/FormRisqueSalarie.component';

const leftPaneDescriptions: StepDescriptionList = {
  STEP_CHOIX_ASSURE: {
    title: '',
    description: ''
  },
  STEP_IDENTITE: {
    title: 'Pourquoi ces questions ?',
    description:
      "Votre âge est une donnée qui rentre en compte dans le calcul du tarif de certains produits d'assurance."
  },
  STEP_SITUATION_PERSO: {
    title: 'Pourquoi ces questions ?',
    description: "Ce sont des données qui rentrent en comptent dans les calculs de plusieurs produits d'assurance."
  },
  STEP_SITUATION_PRO: {
    title: 'Pourquoi ces questions ?',
    description:
      "Votre activité professionnelle est une donnée qui rentre en compte dans le calcul du tarif de plusieurs produits d'assurance."
  },
  STEP_ENTREPRISE: {
    title: 'Pourquoi ces questions ?',
    description: 'Les compagnies d’assurance demandent ces informations pour vérifier votre statut professionnel.'
  },
  STEP_RISQUE: {
    title: "Votre équipement actuel de contrats d'assurance",
    description:
      "En complétant la liste de vos contrats d'assurance actuels, vous nous permettez de vérifier que ces derniers sont toujours en phase avec vos besoins et votre situation. Par la même, nous nous assurerons qu'ils sont optimums avec les produits du marché."
  },
  STEP_COORDONNEES: {
    title: 'Pourquoi ces questions ?',
    description:
      "Le département d’habitation est une donnée qui rentre en compte dans le calcul du tarif de plusieurs produits d'assurance."
  },
  STEP_DESCRIPTION: {
    title: '',
    description: ''
  }
};

const decouverteProspect: FormConfigWithUrl = {
  endpoint: 'decouverte',
  formSteps: [
    {
      component: FormDecouverteChoixProfil,
      next: {
        selectNextStep: (prevStepState: { profil: string }): string | undefined => {
          switch (prevStepState.profil) {
            case 'TNS':
              return 'TNS';
            case 'PARTICULIER':
              return 'PARTICULIER';
          }
        },
        paths: {
          PARTICULIER: [
            {
              component: FormDecouverteIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
            },
            {
              component: FormDecouverteSituationPerso,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PERSO} />
            },
            {
              component: FormDecouverteSituationPro,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
            },
            {
              component: FormRisqueFamilleParticulier,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
            },
            {
              component: FormRisqueBiensPerso,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
            },
            {
              component: FormRisqueRetraite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
            },
            {
              component: FormDecouverteCoordonne,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />
            },
            {
              component: FormDecouverteDescription,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION} />,
              data: { showCaptcha: true }
            }
          ],
          TNS: [
            {
              component: FormDecouverteIdentite,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_IDENTITE} />
            },
            {
              component: FormDecouverteSituationPerso,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PERSO} />
            },
            {
              component: FormDecouverteSituationPro,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_SITUATION_PRO} />
            },
            {
              component: FormDecouverteEntreprise,
              description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_ENTREPRISE} />,
              next: {
                selectNextStep: (prevStepState: {
                  entreprise: {
                    nombreSalaries: number;
                  };
                  nombreSalaries: number;
                }): string | undefined => {
                  if (prevStepState.entreprise.nombreSalaries > 0) return 'TNS_AVEC_SALARIE';
                  else return 'TNS_SANS_SALARIE';
                },
                paths: {
                  TNS_AVEC_SALARIE: [
                    {
                      component: FormRisqueFamilleTNS,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueBiensPerso,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueActivitePro,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueSalarie,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueRetraite,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormDecouverteCoordonne,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />
                    },
                    {
                      component: FormDecouverteDescription,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION} />,
                      data: { showCaptcha: true }
                    }
                  ],
                  TNS_SANS_SALARIE: [
                    {
                      component: FormRisqueFamilleTNS,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueBiensPerso,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueActivitePro,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormRisqueRetraite,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_RISQUE} />
                    },
                    {
                      component: FormDecouverteCoordonne,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_COORDONNEES} />
                    },
                    {
                      component: FormDecouverteDescription,
                      description: <LeftPaneDescription descriptionObj={leftPaneDescriptions.STEP_DESCRIPTION} />,
                      data: { showCaptcha: true }
                    }
                  ]
                }
              }
            }
          ]
        }
      }
    }
  ]
};

export default decouverteProspect;
