import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useMemo } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';

type FormValues = {
  isConducteurSecondaire: string | undefined;
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  isConducteurSecondaire: Yup.string().required()
}).defined();

const defaultState: FormValues = {
  isConducteurSecondaire: undefined
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormAutoUtilisationVehicule({
  setLastFormState,
  previousFormValues,
  formValues,
  goNextStep,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(previousFormValues), []);

  const {
    watch,
    handleSubmit,
    register,
    formState: { isDirty, errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (formValues as FormValues) || initialValues || defaultState
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => {
        goNextStep(values);
      })}
    >
      <h1 className="title">
        Ajouter un <span>conducteur secondaire ?</span>
      </h1>
      <InputRadioButtonInline
        {...register('isConducteurSecondaire')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />

      {!!formValues && (
        <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
          Suivant
        </FormNextButton>
      )}
    </AutoSubmissionForm>
  );
}
