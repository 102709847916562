import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { fetchActiviteOptions } from 'fetches/options.fetch';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import Yup from 'utils/Yup';
import InputTextAutoComplete from '../../components/Input/InputText/InputTextAutoComplete';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import { assignValuesFromSource } from '../../utils';

interface FormValues {
  entreprise: {
    codeAPE: string | undefined;
    secondCodeAPE: string | undefined;
  };
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  entreprise: Yup.object({
    codeAPE: Yup.string().required().isCodif('CODE_APE', 'Vous devez sélectionner une activité existante'),
    secondCodeAPE: Yup.string().isCodif('CODE_APE', 'Vous devez sélectionner une activité existante')
  })
}).defined();

const defaultState: FormValues = {
  entreprise: {
    codeAPE: '',
    secondCodeAPE: ''
  }
};

export default function FormActiviteEntreprise(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, props.initialFormValues || {});

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const activitePrincipaleOptions = useFetchOptionsOnFieldChange(
    watch,
    'entreprise.codeAPE',
    fetchActiviteOptions,
    value => value.length > 2
  );

  const activiteSecondaireOptions = useFetchOptionsOnFieldChange(
    watch,
    'entreprise.secondCodeAPE',
    fetchActiviteOptions,
    value => value.length > 2
  );

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Compléments d&apos;informations</h2>
      <InputTextAutoComplete
        label="Activité principale - Code NAF"
        name="entreprise.codeAPE"
        control={control}
        errors={errors}
        options={activitePrincipaleOptions || []}
      />
      <InputTextAutoComplete
        label="Activité secondaire - Code NAF"
        name="entreprise.secondCodeAPE"
        control={control}
        errors={errors}
        options={activiteSecondaireOptions || []}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
