import React, { CSSProperties, ReactNode } from 'react';
import { ChangeHandler, Control } from 'react-hook-form';
import InputCheckboxButton from './InputCheckboxButton.component';
import InfoBulle from 'components/InfoBulle/InfoBulle';

import './InputCheckboxButtonInline.scss';
import classNames from 'classnames';

interface InputCheckboxButtonInlineProps {
  options: Option[];
  className?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  control: Control<any>;
  label?: ReactNode;
  noCenterLabel?: boolean;
  labelStyle?: CSSProperties;
  column?: boolean | 1 | 2 | 3 | 4;
  tooltip?: string;
}

interface Option {
  name: string;
  label: string;
  tooltip?: string;
}

export const InputCheckboxButtonInline = React.forwardRef<HTMLInputElement, InputCheckboxButtonInlineProps>(
  ({ options, control, label, column, noCenterLabel, tooltip }: InputCheckboxButtonInlineProps, ref) => {
    return (
      <div className={'InputCheckboxInline'}>
        <div>
          <div className={classNames('InputCheckboxInline__label')}>{label}</div>
        </div>
        <div className={classNames('InputCheckboxInline__group')}>
          <div className={classNames('InputCheckboxInline__container', { '-column': column })}>
            {options.map(option => (
              <InputCheckboxButton
                key={option.name}
                name={option.name}
                label={option.label}
                control={control}
                error={false}
                column={column}
                noCenterLabel={noCenterLabel}
                tooltip={option.tooltip}
                ref={ref}
              />
            ))}
          </div>
          {tooltip && <InfoBulle name={`description-${name}`} key={`description-${name}`} text={tooltip || ''} />}
        </div>
      </div>
    );
  }
);
InputCheckboxButtonInline.displayName = 'InputCheckboxButtonInline';

export default InputCheckboxButtonInline;
