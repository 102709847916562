import InputText from 'components/Input/InputText/InputText.component';
import React, { ReactElement } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FormValues } from '../FormMandatSepaTitulaireCompte.component';
import './NumeroAdherent.scss';

type NumeroAdherentType = {
  register: UseFormRegister<FormValues>;
  errors: FieldErrors<FormValues>;
};

export const NumeroAdherent = ({ register, errors }: NumeroAdherentType): ReactElement => {
  return (
    <div className="numeroAdherent__solo">
      <InputText
        label={`Numéro d'adhérent`}
        {...register(`numeroAdherent`)}
        errors={errors}
        tooltip={"Retrouvez votre numéro d'adhérent sur vos relevés de cotisations annuels"}
        className="numeroAdherent__input"
      />
    </div>
  );
};
