import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { fetchTypeInfractionOptions } from 'fetches/options.fetch';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputNumber from 'components/Input/InputNumber/InputNumber.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import { OUI_NON_OPTIONS } from 'consts/consts';

import './Infraction.scss';
import { useFetch } from 'hooks/useFetch.hook';

type EnfantFieldsProps = {
  index: number;
  register: any;
  type: string;
  errors: FieldErrors<any>;
  control: Control<any>;
  watch: UseFormWatch<any>;
  remove: (index: number) => void;
};

export default function Infraction({
  index,
  register,
  type,
  errors,
  control,
  watch,
  remove
}: EnfantFieldsProps): ReactElement {
  const typeInfractionOptions = useFetch(fetchTypeInfractionOptions);

  const nombre = watch(`${type}.infractions.${index}.nombre`) || 0;

  return (
    <div className="Infraction" key={index}>
      <button
        className="Infraction__remove no-default-style"
        onClick={() => {
          remove(index);
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </button>

      <div className="layout-field-container">
        <InputSelect
          name={`${type}.infractions.${index}.typeCode`}
          label="Type d'infraction"
          options={typeInfractionOptions || []}
          control={control}
          errors={errors}
        />
        <InputNumber
          min={0}
          label="Nombre d'infraction"
          {...register(`${type}.infractions.${index}.nombre`)}
          errors={errors}
        />
      </div>
      <InputRadioButtonInline
        labelField={
          nombre > 1 ? 'Ont-elles eu lieu il y a moins de 5 ans ?' : 'A-t-elle eu lieu il y a moins de 5 ans ?'
        }
        {...register(`${type}.infractions.${index}.moins5Ans`)}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
    </div>
  );
}
