import React, { useEffect, useRef, useState } from 'react';
import { format, isValid } from 'date-fns';
import { useController, Control, FieldValues, UseControllerProps } from 'react-hook-form';
import classNames from 'classnames';
import { FieldError } from '../parts/FieldError/FieldError.component';
import { ReactComponent as Chevron } from './chevron.svg';
import './InputDateSelect.scss';
import { getIn } from 'utils';

type iInputDateSelect<T extends FieldValues> = UseControllerProps<T> & {
  name: string;
  label?: React.ReactNode;
  control: Control<T>;
  fullWidth?: boolean;
};

function InputDateSelect<T extends FieldValues>({ name, label, control, fullWidth }: iInputDateSelect<T>): JSX.Element {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isDirty },
    formState: { errors, isSubmitted }
  } = useController({
    name,
    control
  });

  const error = getIn(name, errors);
  const errorMessage = (isDirty || isSubmitted) && error && error.message;

  const [monthState, setMonthState] = useState('');
  const [yearState, setYearState] = useState('');
  const month = useRef('');
  const year = useRef('');
  const monthRef = useRef<HTMLSelectElement>(null);
  const yearRef = useRef<HTMLSelectElement>(null);

  function convertDate(y: string, m: string): Date {
    const yint = parseInt(y, 10);
    const mint = parseInt(m, 10);
    const dt = new Date(yint, mint - 1);

    return dt;
  }

  function handleChange() {
    const date = convertDate(year.current, month.current);
    if (!isValid(date) && !blur) return;
    if (year.current && month.current) {
      onChange(date);
    }
  }

  useEffect(() => {
    if (value && isValid(value)) {
      month.current = format(value, 'MM');
      year.current = format(value, 'yyyy');
      setMonthState(month.current);
      setYearState(year.current);
    }
  }, [value]);

  const currentYear = parseInt(format(new Date(), 'yyyy'), 10);
  const yearList = Array.from({ length: 100 }, (v, k) => k + currentYear - 99).reverse();

  return (
    <div className={classNames('InputDateSelect', { '-fullWidth': fullWidth })} ref={ref}>
      {label && <span className={classNames('InputDateSelect__label', { '-error': errorMessage })}>{label}</span>}
      <div className={classNames('InputDateSelect__fieldGroup', { '-error': errorMessage })}>
        <div className={classNames('InputDateSelect__month', { '-error': errorMessage })}>
          <select
            name={`${name}-month`}
            value={monthState || ''}
            onChange={e => {
              const { value } = e.target;
              month.current = value;
              handleChange();
              setMonthState(value);
            }}
            ref={monthRef}
            onBlur={onBlur}
            className={classNames('InputDateSelect__month__field')}
            data-placeholder={monthState === '' ? 'true' : 'false'}
          >
            <option value="" disabled>
              Mois
            </option>
            <option value="01">Janvier</option>
            <option value="02">Février</option>
            <option value="03">Mars</option>
            <option value="04">Avril</option>
            <option value="05">Mai</option>
            <option value="06">Juin</option>
            <option value="07">Juillet</option>
            <option value="08">Août</option>
            <option value="09">Septembre</option>
            <option value="10">Octobre</option>
            <option value="11">Novembre</option>
            <option value="12">Décembre</option>
          </select>
          <Chevron className="InputDateSelect__chevron" />
        </div>

        <div className={classNames('InputDateSelect__year', { '-error': errorMessage })}>
          <select
            name={`${name}-yeah`}
            placeholder="Année"
            value={yearState || ''}
            onChange={e => {
              const { value } = e.target;
              year.current = value;
              handleChange();
              setYearState(value);
            }}
            ref={yearRef}
            onBlur={onBlur}
            className={classNames('InputDateSelect__year__field')}
            data-placeholder={yearState === '' ? 'true' : 'false'}
          >
            <option value="" disabled>
              Année
            </option>
            {yearList.map(y => (
              <option key={y}>{y}</option>
            ))}
          </select>
          <Chevron className="InputDateSelect__chevron" />
        </div>
      </div>
      <FieldError>{errorMessage}</FieldError>
    </div>
  );
}

InputDateSelect.defaultProps = {
  label: undefined,
  required: undefined,
  maxDate: undefined,
  minDate: undefined,
  fullWidth: undefined
};

export default InputDateSelect;
