import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import {
  fetchSurfaceDependanceLogementOptions,
  fetchSurfaceTerrasseLogementOptions,
  fetchSurfaceVerandaLogementOptions,
  fetchTypePiscineLogementOptions
} from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { merge } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import { OUI_NON_OPTIONS } from 'consts/consts';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import Extend from 'components/Extend/Extend';

type FormValues = {
  habitation: {
    estMonumentHistorique: string | undefined;
    estProcheSiteDangereux: string | undefined;
    avecGarage: string | undefined;
    presenceSousSol: string | undefined;
    presenceDependance: string | undefined;
    surfaceDependance: string | undefined;
    presenceVeranda: string | undefined;
    surfaceVeranda: string | undefined;
    presenceTerrasse: string | undefined;
    surfaceTerrasse: string | undefined;
    typePiscine: string | undefined;
    presenceEquipementsExterieur: string | undefined;
  };
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  habitation: Yup.object({
    estMonumentHistorique: Yup.string().required().nullable(),
    estProcheSiteDangereux: Yup.string().required().nullable(),
    avecGarage: Yup.string().required().nullable(),
    presenceSousSol: Yup.string().required().nullable(),
    presenceDependance: Yup.string().required().nullable(),
    surfaceDependance: Yup.string().when('dependances', {
      is: 'true',
      then: schema => schema.required().nullable(),
      otherwise: schema => schema.nullable()
    }),
    presenceVeranda: Yup.string().required().nullable(),
    surfaceVeranda: Yup.string().when('veranda', {
      is: 'true',
      then: schema => schema.required().nullable(),
      otherwise: schema => schema.nullable()
    }),
    presenceTerrasse: Yup.string().required().nullable(),
    surfaceTerrasse: Yup.string().when('terrasses', {
      is: 'true',
      then: schema => schema.required().nullable(),
      otherwise: schema => schema.nullable()
    }),
    typePiscine: Yup.string().required().nullable(),
    presenceEquipementsExterieur: Yup.string().required().nullable()
  })
}).defined();

const defaultState: FormValues = {
  habitation: {
    estMonumentHistorique: undefined,
    estProcheSiteDangereux: undefined,
    avecGarage: undefined,
    presenceSousSol: undefined,
    presenceDependance: undefined,
    surfaceDependance: undefined,
    presenceVeranda: undefined,
    surfaceVeranda: undefined,
    presenceTerrasse: undefined,
    surfaceTerrasse: undefined,
    typePiscine: undefined,
    presenceEquipementsExterieur: undefined
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, state);
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return assignValuesFromSource(defaultState, initialFormValues || {}, (item: any) => item);
}

export default function FormHabitationCaracteristiques(props: FormProps<FormValues>): ReactElement {
  const initialValues = useMemo(() => getInitialValues(props.previousFormValues), []);
  const typePiscineLogementOptions = useFetch(fetchTypePiscineLogementOptions);
  const surfaceTerrasseLogementOptions = useFetch(fetchSurfaceTerrasseLogementOptions);
  const surfaceVerandaLogementOptions = useFetch(fetchSurfaceVerandaLogementOptions);
  const surfaceDependanceLogementOptions = useFetch(fetchSurfaceDependanceLogementOptions);

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(
      {
        habitation: { ...props.previousFormValues.habitation, ...data.habitation },
        utilisationHabitation: { ...props.previousFormValues.utilisationHabitation }
      },
      AccumulateStateToSeries
    );
  };

  const dependances = watch('habitation.presenceDependance');
  const veranda = watch('habitation.presenceVeranda');
  const terrasses = watch('habitation.presenceTerrasse');

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">Caractéristiques spéciales</h1>
      <InputRadioButtonInline
        labelField="Classé ou inscrit à l’inventaire des monuments historiques ?"
        {...register('habitation.estMonumentHistorique')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <InputRadioButtonInline
        labelField="Votre logement est-il situé à proximité d'un site ou d'une installation dangereuse ?"
        {...register('habitation.estProcheSiteDangereux')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <InputRadioButtonInline
        labelField="Le logement possède t'il un garage ?"
        {...register('habitation.avecGarage')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <InputRadioButtonInline
        labelField="Le logement possède t'il un sous-sol ?"
        {...register('habitation.presenceSousSol')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <InputRadioButtonInline
        labelField="Présence de dépendances ?"
        {...register('habitation.presenceDependance')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <Extend extend={dependances === 'true'}>
        <InputSelect
          label="Surface des dépendances"
          name="habitation.surfaceDependance"
          control={control}
          options={surfaceDependanceLogementOptions || []}
          errors={errors}
        />
      </Extend>
      <InputRadioButtonInline
        labelField="Présence d'une véranda ?"
        {...register('habitation.presenceVeranda')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <Extend extend={veranda === 'true'}>
        <InputSelect
          label="Surface de la véranda"
          name="habitation.surfaceVeranda"
          control={control}
          options={surfaceVerandaLogementOptions || []}
          errors={errors}
        />
      </Extend>
      <InputRadioButtonInline
        labelField="Présence de terrasses ?"
        {...register('habitation.presenceTerrasse')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <Extend extend={terrasses === 'true'}>
        <InputSelect
          label="Surface des terrasses"
          name="habitation.surfaceTerrasse"
          control={control}
          options={surfaceTerrasseLogementOptions || []}
          errors={errors}
        />
      </Extend>

      <InputSelect
        label="Type de piscine"
        name="habitation.typePiscine"
        control={control}
        options={typePiscineLogementOptions || []}
        errors={errors}
      />
      <InputRadioButtonInline
        labelField="Présence d’autres installations extérieures (spa…) ?"
        {...register('habitation.presenceEquipementsExterieur')}
        options={OUI_NON_OPTIONS}
        column={2}
        errors={errors}
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
        Suivant
      </FormNextButton>
    </form>
  );
}
