import React, { useEffect, useRef, useState } from 'react';
import { useController, Control } from 'react-hook-form';
import classNames from 'classnames';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './InputImmatriculation.scss';
import { getIn } from 'utils';

interface iInputImmatriculation {
  name: string;
  label?: React.ReactNode;
  control: Control<any>;
}

function InputImmatriculation({ name, label, control }: iInputImmatriculation): JSX.Element {
  const [focus, setFocus] = useState(false);
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { isDirty },
    formState: { errors, isSubmitted }
  } = useController({
    name,
    control
  });

  const error = getIn(name, errors);
  const errorMessage = (isDirty || isSubmitted) && error && error.message;

  const [part1State, setPart1State] = useState('');
  const [part2State, setPart2State] = useState('');
  const [part3State, setPart3State] = useState('');
  const part1 = useRef('');
  const part2 = useRef('');
  const part3 = useRef('');

  const part1Ref = useRef<HTMLInputElement>(null);
  const part2Ref = useRef<HTMLInputElement>(null);
  const part3Ref = useRef<HTMLInputElement>(null);
  const isDirtyInput = useRef(false);

  let sto: any = null;

  useEffect(() => {
    function select(e: FocusEvent) {
      (e.target as HTMLInputElement).select();
    }

    part2Ref.current?.addEventListener('focus', select);
    part3Ref.current?.addEventListener('focus', select);

    return () => {
      part2Ref.current?.removeEventListener('focus', select);
      part3Ref.current?.removeEventListener('focus', select);
    };
  }, []);

  function handleChange(blur?: boolean) {
    if (!onChange) return;
    const immatriculation = `${part1.current}-${part2.current}-${part3.current}`.replaceAll(' ', '');
    if (!isDirtyInput.current && !blur) {
      return;
    }
    if (part3.current.length && part2.current.length && part1.current.length) {
      onChange(immatriculation);
      return;
    }
    if (!isDirtyInput.current) isDirtyInput.current = true;
    if (blur) {
      onChange(immatriculation);
    }
  }

  function handleFocus() {
    setFocus(true);
    clearTimeout(sto);
  }

  function handleBlur() {
    handleChange();
    sto = setTimeout(async () => {
      setFocus(false);
      handleChange(true);
      if (onBlur) onBlur();
    });
  }

  useEffect(() => {
    if (value) {
      const [p1 = '', p2 = '', p3 = ''] = value.split('-');
      part1.current = p1;
      part2.current = p2;
      part3.current = p3;
      setPart1State(p1);
      setPart2State(p2);
      setPart3State(p3);
    }
  }, [value]);

  return (
    <div className={classNames('InputImmatriculation')} ref={ref}>
      {label && <span className={classNames('InputImmatriculation__label', { '-error': errorMessage })}>{label}</span>}
      <div className={classNames('InputImmatriculation__fieldGroup', { '-error': errorMessage })} data-focus={focus}>
        <div className={classNames('InputImmatriculation__fieldGroup__wrap')}>
          <input
            name={`${name}-part-1`}
            type="text"
            inputMode="text"
            placeholder="AA"
            maxLength={4}
            value={part1State}
            onChange={e => {
              const val = e.target.value.replace(/\W/g, '').toUpperCase();
              part1.current = val;
              handleChange();
              setPart1State(val);
              if (isNaN(parseInt(val)) && val.length === 2) part2Ref?.current?.focus();
              else if (val.length === 4) part2Ref?.current?.focus();
            }}
            ref={part1Ref}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="InputImmatriculation__part1"
          />

          <span className="InputImmatriculation__tiret">-</span>

          <input
            name={`${name}-part-2`}
            type="text"
            inputMode="numeric"
            placeholder={isNaN(parseInt(part1State)) ? '123' : 'AAA'}
            maxLength={3}
            value={part2State}
            onChange={e => {
              const val = e.target.value.replace(/\W/g, '').toUpperCase();
              part2.current = val;
              handleChange();
              setPart2State(val);
              if (val.length === 3) part3Ref?.current?.focus();

              if (val.length === 3) part3Ref?.current?.focus();
            }}
            ref={part2Ref}
            onFocus={() => {
              if (!part1.current) part1Ref.current?.focus();
              handleFocus();
            }}
            onBlur={handleBlur}
            className="InputImmatriculation__part2"
          />

          <span className="InputImmatriculation__tiret">-</span>

          <input
            name={`${name}-part-3`}
            type="text"
            inputMode="text"
            placeholder={isNaN(parseInt(part1State)) ? 'AA' : '12'}
            maxLength={2}
            value={part3State}
            onChange={e => {
              const val = e.target.value.replace(/\W/g, '').toUpperCase();
              part3.current = val;
              handleChange();
              setPart3State(val);
            }}
            ref={part3Ref}
            onFocus={() => {
              if (!part2.current) part2Ref.current?.focus();
              handleFocus();
            }}
            onBlur={handleBlur}
            className="InputImmatriculation__part3"
          />
        </div>
      </div>

      <FieldError>{errorMessage}</FieldError>
    </div>
  );
}

InputImmatriculation.defaultProps = {
  label: undefined,
  required: undefined,
  maxDate: undefined,
  minDate: undefined
};

export default InputImmatriculation;
