import React, { MouseEventHandler, ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProps } from 'types';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import Yup from 'utils/Yup';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { useFetch } from '../../hooks/useFetch.hook';
import { fetchPaysOptions } from '../../fetches/options.fetch';
import EnfantFields from '../SANTE_PART/Enfants/EnfantFields';
import { assignValuesFromSource } from '../../utils';
import FormGenericButton from 'components/FormGenericButton/FormGenericButton.component';

type Enfant = {
  civilite: string | null;
  nom: string | undefined;
  prenom: string | undefined;
  naissanceDate: Date | undefined;
  nationalite: string | undefined;
};

type FormValues = {
  enfants: Enfant[];
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  enfants: Yup.array()
    .of(
      Yup.object({
        civilite: Yup.string()
          .nullable()
          .required()
          .oneOf(
            CIVILITES_OPTIONS.map(option => option.value),
            'Champ obligatoire'
          ),
        nom: Yup.string().required().max(255),
        prenom: Yup.string().required().max(255),
        naissanceDate: Yup.date().required(),
        nationalite: Yup.string().required()
      })
    )
    .min(1)
}).defined();

const defaultState: FormValues = {
  enfants: [
    {
      civilite: '',
      nom: '',
      prenom: '',
      naissanceDate: undefined,
      nationalite: ''
    }
  ]
};

export default function FormSanteParticulierEnfants(props: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(
    defaultState,
    props.initialFormValues || {},
    (item: any, key: any) => {
      if (key === 'enfants') {
        item.map((enfant: Enfant) => {
          if (enfant.naissanceDate) {
            enfant.naissanceDate = new Date(enfant.naissanceDate);
          }
        });
      }
      return item;
    },
    true
  );

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isValid },
    control
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  const pays = useFetch(fetchPaysOptions);
  const enfants = getValues('enfants');

  const deleteChild = (indexToDelete: number) => {
    const newFormValues: Enfant[] = [...getValues('enfants')];
    newFormValues.splice(indexToDelete, 1);
    reset({ enfants: newFormValues }, { keepErrors: true, keepTouched: true, keepDirty: true });
  };

  const addChild: MouseEventHandler = (e): void => {
    e.preventDefault();
    const newFormValues: Enfant[] = [...getValues('enfants')];
    newFormValues.push({
      civilite: '',
      nom: '',
      prenom: '',
      naissanceDate: undefined,
      nationalite: ''
    });
    reset({ enfants: newFormValues }, { keepErrors: true, keepTouched: true, keepDirty: true });
  };

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data);
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1>
        Parlez-nous de <span>vos enfants</span>
      </h1>

      {enfants &&
        enfants.map((item: Enfant, i: number) => {
          return (
            <EnfantFields
              key={i}
              index={i}
              register={register}
              errors={errors}
              control={control}
              pays={pays}
              deleteChild={(index: number) => deleteChild(index)}
            />
          );
        })}

      <div className="layout-button-container">
        <FormGenericButton onClick={addChild}>Ajouter un enfant</FormGenericButton>
        <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} disabled={!isValid}>
          Suivant
        </FormNextButton>
      </div>
    </form>
  );
}
