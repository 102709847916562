import { Option } from 'types';

export interface CodificationResult {
  libelle: string;
  codeInfoString: string;
}

const fetchCodification = (codeType: string, domaine = 'NULL', useMeta = false): Promise<Option[]> =>
  new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/codifications/lister/${domaine}/${codeType}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) reject(Error(res));
        resolve(
          (res.body as CodificationResult[]).map(item => ({
            label: item.libelle,
            value: item.codeInfoString,
            meta: useMeta ? item : undefined
          }))
        );
      })
      .catch(err => reject(err));
  });

export function fetchTypePretOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_PRET', 'REALITE');
}

export function fetchBanqueOptions(): Promise<Option[]> {
  return fetchCodification('BANQUE', 'REALITE');
}

export function fetchTypeTauxOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_TAUX', 'REALITE');
}

export function fetchTypeDifferesOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_PRET_DIFFERE', 'HABILITATION');
}

export function fetchStatusProTypeOptions(): Promise<Option[]> {
  return fetchCodification('STATUT_PROFESSIONNEL_TYPE', 'REALITE');
}

export function fetchSystemeChauffageLogementOptions(): Promise<Option[]> {
  return fetchCodification('SYSTEME_CHAUFFAGE_LOGEMENT', 'REALITE');
}

export function fetchPeriodeInhabitationLogementOptions(): Promise<Option[]> {
  return fetchCodification('PERIODE_INHABITATION_LOGEMENT', 'REALITE');
}

export function fetchTypePiscineLogementOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_PISCINE_LOGEMENT', 'REALITE');
}

export function fetchSurfaceTerrasseLogementOptions(): Promise<Option[]> {
  return fetchCodification('SURFACE_TERRASSE_LOGEMENT', 'REALITE');
}

export function fetchSurfaceVerandaLogementOptions(): Promise<Option[]> {
  return fetchCodification('SURFACE_VERANDA_LOGEMENT', 'REALITE');
}

export function fetchSurfaceDependanceLogementOptions(): Promise<Option[]> {
  return fetchCodification('SURFACE_DEPENDANCE_LOGEMENT', 'REALITE');
}

export function fetchAnneeConstructionOptions(): Promise<Option[]> {
  return fetchCodification('ANNEE_CONSTRUCTION_LOGEMENT', 'REALITE');
}

export function fetchPeriodeLocationLogementOptions(): Promise<Option[]> {
  return fetchCodification('PERIODE_LOCATION_LOGEMENT', 'REALITE');
}

export function fetchNatureLogementOptions(): Promise<Option[]> {
  return fetchCodification('NATURE_LOGEMENT', 'REALITE');
}

export function fetchLogementTypeOptions(): Promise<Option[]> {
  return fetchCodification('LOGEMENT_OCCUPATION_TYPE', 'REALITE');
}

export function fetchEtageLogementOptions(): Promise<Option[]> {
  return fetchCodification('ETAGE_LOGEMENT', 'REALITE');
}

export function fetchTypeLogementOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_LOGEMENT', 'REALITE');
}

export function fetchCouvertureAutoOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_COUVERTURE_AUTO', 'REALITE');
}

export function fetchTypeSinistreOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_SINISTRE_VEHICULE', 'REALITE');
}

export function fetchNatureSinistreOptions(): Promise<Option[]> {
  return fetchCodification('NATURE_SINISTRE', 'REALITE');
}

export function fetchTitulaireOptions(): Promise<Option[]> {
  return fetchCodification('TITULAIRE_CARTE_GRISE_TYPE', 'PORTEFEUILLE');
}

export function fetchResiliationMotifCodeOptions(): Promise<Option[]> {
  return fetchCodification('RESILIATION_ASSURANCE_MOTIF', 'PORTEFEUILLE');
}

export function fetchTypeInfractionOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_INFRACTION_VEHICULE', 'PORTEFEUILLE');
}

export function fetchTypePermisOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_PERMIS_VEHICULE', 'PORTEFEUILLE');
}

export function fetchTypeParkingOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_PARKING', 'PORTEFEUILLE');
}

export function fetchTypeParkingCollectifOptions(): Promise<Option[]> {
  return fetchCodification('SOUS_TYPE_PARKING_COLLECTIF', 'PORTEFEUILLE');
}

export function fetchTypeParkingIndividuelOptions(): Promise<Option[]> {
  return fetchCodification('SOUS_TYPE_PARKING_PRIVE', 'PORTEFEUILLE');
}

export function fetchFrequenceUtilisationOptions(): Promise<Option[]> {
  return fetchCodification('FREQUENCE_UTILISATION_VEHICULE', 'PORTEFEUILLE');
}

export function fetchTypeTrajetOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_TRAJET_VEHICULE', 'PORTEFEUILLE');
}

export function fetchTitulaireCarteGriseOptions(): Promise<Option[]> {
  return fetchCodification('TITULAIRE_CARTE_GRISE_TYPE', 'PORTEFEUILLE');
}

export function fetchTypeAchatOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_ACHAT_VEHICULE', 'PORTEFEUILLE');
}

export function fetchFonctionsOptions(): Promise<Option[]> {
  return fetchCodification('MANDAT_ENTREPRISE', 'REALITE');
}

export function fetchStatutJuridiqueOptions(): Promise<Option[]> {
  return fetchCodification('FORME_JURIDIQUE_TYPE', 'REALITE');
}

export function fetchPaysOptions(): Promise<Option[]> {
  return fetchCodification('PAYS', 'REALITE');
}

export function fetchMotifSejour(): Promise<Option[]> {
  return fetchCodification('MOTIF_SEJOUR_EXPAT', 'REALITE');
}

export function fetchCouvertureAutresPaysExpat(): Promise<Option[]> {
  return fetchCodification('COUVERTURE_AUTRES_PAYS_EXPAT', 'REALITE');
}

export function fetchDureesSejourExpatrieOptions(): Promise<Option[]> {
  return fetchCodification('DUREE_SEJOUR_EXPATRIE', 'REALITE');
}

export function fetchTypeCouverturesExpatOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_COUVERTURE_EXPAT', 'REALITE');
}

export function fetchSituationMatrimonialeOptions(): Promise<Option[]> {
  return fetchCodification('SITUATION_MATRIMONIALE', 'REALITE');
}

export function fetchRacesChiensOptions(): Promise<Option[]> {
  return fetchCodification('RACE_ANIMAL_CHIEN', 'PORTEFEUILLE');
}

export function fetchNiveauxCouvertureIJOptions(): Promise<Option[]> {
  return fetchCodification('NIVEAU_GARANTIE_IJ', 'PORTEFEUILLE');
}

export function fetchNiveauxCouvertureDecesOptions(): Promise<Option[]> {
  return fetchCodification('NIVEAU_GARANTIE_DECES', 'PORTEFEUILLE');
}

export function fetchRacesChatsOptions(): Promise<Option[]> {
  return fetchCodification('RACE_ANIMAL_CHAT', 'PORTEFEUILLE');
}

export function fetchRegimeSecuOptions(): Promise<Option[]> {
  return fetchCodification('REGIME_SECU_TYPE', 'REALITE');
}

export function fetchNbAnneesRegimeSecuOptions(): Promise<Option[]> {
  return fetchCodification('INSCRIPTION_REGIME_SECU_DUREE', 'REALITE');
}

export function fetchCaisseRetraiteOptions(): Promise<Option[]> {
  return fetchCodification('CAISSE_RETRAITE', 'REALITE');
}

export function fetchClassePrevoyanceCarmfOptions(): Promise<Option[]> {
  return fetchCodification('CLASSE_PREVOYANCE_CARMF', 'REALITE');
}

export function fetchClassePrevoyanceCarpvOptions(): Promise<Option[]> {
  return fetchCodification('CLASSE_PREVOYANCE_CARPV', 'REALITE');
}

export function fetchClassePrevoyanceCavecOptions(): Promise<Option[]> {
  return fetchCodification('CLASSE_PREVOYANCE_CAVEC', 'REALITE');
}

export function fetchClassePrevoyanceCavomOptions(): Promise<Option[]> {
  return fetchCodification('CLASSE_PREVOYANCE_CAVOM', 'REALITE');
}

export function fetchAxesIntituleNiveauxSante(): Promise<Option[]> {
  return fetchCodification('INTITULE_NIVEAU_RETENU_SANTE', 'PORTEFEUILLE');
}

export function fetchAxesIntituleNiveauxExpat(): Promise<Option[]> {
  return fetchCodification('INTITULE_NIVEAU_RETENU_EXPATRIE', 'PORTEFEUILLE');
}

export function fetchStatutProOptions(): Promise<Option[]> {
  return fetchCodification('STATUT_PROFESSIONNEL_TYPE', 'REALITE');
}

export function fetchOrigineEntrepriseOptions(): Promise<Option[]> {
  return fetchCodification('ORIGINE_ENTREPRISE', 'REALITE');
}

export function fetchLibelleCodif(code = 'TYPOLOGIE'): Promise<Option[]> {
  return fetchCodification(code, 'PORTEFEUILLE', true);
}

export function fetchTypeIndemnisationOptions(): Promise<Option[]> {
  return fetchCodification('TYPE_INDEMNITE', 'PORTEFEUILLE');
}

export function fetchDureeSejourOptions(): Promise<Option[]> {
  return fetchCodification('DUREE_SEJOUR', 'REALITE');
}

const fetchRegimeFiscalOptions = (): Promise<Option[]> => {
  return fetchCodification('REGIME_FISCAL', 'REALITE');
};

export function fetchActiviteOptions(act: string): Promise<Option[]> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/activites/search/${act.split(':')[0]}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as CodificationResult[]).map(item => ({
            label: item.libelle,
            value: item.codeInfoString,
            meta: item
          }))
        );
      })
      .catch(err => reject(err));
  });
}

type AssureurType = {
  code: string;
  urlLogo: string;
  personne: {
    raisonSociale: string;
    type: string;
  };
};

let cacheAssureurOptions: AssureurType[] | null = null;

export function fetchAssureurOptions(id_formulaire: string | undefined): Promise<AssureurType[]> {
  if (cacheAssureurOptions) return Promise.resolve(cacheAssureurOptions);
  return fetch(`${process.env.REACT_APP_URL_API_FORMULAIRE}formulaires/${id_formulaire}/assureurs`).then(async res => {
    try {
      const data = await res.json();
      cacheAssureurOptions = data;
      return data;
    } catch (err: any) {
      throw new Error(err.message);
    }
  });
}

export interface CatMetierCodifResult {
  code: string;
  libelle: string;
}

export function fetchCategorieMetierOptions(): Promise<Option[]> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/categories/lister`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as CatMetierCodifResult[]).map(item => ({
            label: item.libelle,
            value: item.code,
            meta: undefined
          }))
        );
      })
      .catch(err => reject(err));
  });
}

export function fetchMetierByCategorieOptions(payload: {
  categorie: string;
  metier: string | undefined | null;
}): Promise<Option<string, any>[]> {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/metiers/rechercher/${payload.categorie}/${payload.metier}`
    )
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as CatMetierCodifResult[]).map(item => ({
            label: item.libelle,
            value: item.code,
            meta: item
          }))
        );
      })
      .catch(err => reject(err));
  });
}

export interface SearchEntrepriseResult {
  numeroSIRET: string;
  raisonSociale: string;
  ville: string | null;
  adresse: string | null;
  complementAdresse: string | null;
  codePostal: string | null;
}

export interface ReadEntrepriseResult {
  numeroSIRET: string;
  raisonSociale: string;
  creationStructureDate?: string | null;
  adresse: {
    pays: string;
    ville: string;
    voie: string | null;
    complement: string | null;
    codePostal: string | null;
  };
}

export function fetchRaisonSocialeOptions(raisonSociale: string): Promise<Option<string, SearchEntrepriseResult>[]> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/entreprise/search/${raisonSociale}/NULL`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as SearchEntrepriseResult[]).map(item => ({
            label: `${item.raisonSociale} - ${item.numeroSIRET}`,
            value: item.raisonSociale,
            meta: item
          }))
        );
      })
      .catch(err => reject(err));
  });
}

export function fetchDetailsEntreprise(siret: string): Promise<ReadEntrepriseResult> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/entreprise/lire/${siret}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(res.body);
      })
      .catch(err => reject(err));
  });
}

export interface VilleResult {
  nom: string;
}

export function fetchVilleOptions(codePostal: string): Promise<Option[]> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/communes/search/${codePostal}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) reject(Error(res));
        resolve(
          (res.body as VilleResult[]).map(item => ({
            label: item.nom,
            value: item.nom,
            meta: undefined
          }))
        );
      })
      .catch(err => reject(err));
  });
}

export interface SearchCompagnieResults {
  code: string;
  personne: {
    raisonSociale: string;
  };
}

type SearchMetiersResults = {
  code: string;
  libelle: string;
};
type SearchCetagorieMetiersResults = {
  code: string;
  libelle: string;
};

export function fetchCompagnieOptions(): Promise<Option<string, SearchCompagnieResults>[]> {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/assureur/listerSansLogo`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as SearchCompagnieResults[]).map(item => ({
            label: item.personne.raisonSociale,
            value: item.code,
            meta: item
          }))
        );
      })
      .catch(err => reject(err));
  });
}

const fetchMetiersOptions = (metier: string): Promise<Option<string, SearchMetiersResults>[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/metiers/search/${metier}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve(
          (res.body as SearchMetiersResults[]).map(item => ({
            label: item.libelle,
            value: item.code
          }))
        );
      })
      .catch(err => reject(err));
  });
};
const fetchCategorieByMetierOptions = (metier: string): Promise<Option<string, SearchCetagorieMetiersResults>[]> => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_URL_PTF_API}lyaptf/api/referentiel/metiers/getCategorieByMetier/${metier}`)
      .then(res => res.json())
      .then(res => {
        if (!res.success) return reject(Error(res));
        resolve([
          {
            label: res.body.libelle,
            value: res.body.code
          }
        ]);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const OptionsFetch = {
  fetchMetiersOptions,
  fetchCategorieByMetierOptions,
  fetchRegimeFiscalOptions
};
