import React, { ReactElement } from 'react';
import { FormProps } from 'types';
import './FormDemandeAdhesionStepInfos.scss';
import { Typo } from './ReglesGestion/Typo';
import { FormDemandeAdhesionStepInfosPrevoyanceTNS } from './StepInfoTypoComponent/FormDemandeAdhesionStepInfosPrevoyanceTNS';
import { FormDemandeAdhesionStepInfosSanteParticulier } from './StepInfoTypoComponent/FormDemandeAdhesionStepInfosSanteParticulier';
import { FormDemandeAdhesionStepInfosSanteTNS } from './StepInfoTypoComponent/FormDemandeAdhesionStepInfosSanteTNS';

export default function FormDemandeAdhesionStepInfos(props: FormProps<any>): ReactElement {
  const { initialFormValues } = props;

  const currentTypoString: keyof typeof Typo = initialFormValues?.typologieLabel?.split(':')[0];
  const currentTypo: Typo = Typo[currentTypoString];

  switch (currentTypo) {
    case Typo.SANTE:
      return <FormDemandeAdhesionStepInfosSanteTNS {...props} />;
    case Typo.SANTE_PARTICULIER:
      return <FormDemandeAdhesionStepInfosSanteParticulier {...props} />;
    case Typo.PREVOYANCE:
      return <FormDemandeAdhesionStepInfosPrevoyanceTNS {...props} />;
  }
}
