import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputText from '../../components/Input/InputText/InputText.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import { useCheckEmailPrescripteur } from '../../hooks/useCheckEmailPrescripteur.hook';
import parseSearch from '../../utils/parseSearch';
import './formPrescripteurCheckEmail.scss';

type FormValues = {
  emailPrescripteur: string;
  numeroPrescripteur?: string;
  isEmailValid?: boolean;
};

const defaultState: FormValues = {
  emailPrescripteur: '',
  numeroPrescripteur: undefined,
  isEmailValid: true
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  emailPrescripteur: Yup.string().email().required(),
  isEmailValid: Yup.boolean().notRequired(),
  numeroPrescripteur: Yup.string()
}).defined();

export default function FormPrescripteurCheckEmail(props: FormProps<FormValues>): ReactElement {
  const {
    watch,
    handleSubmit,
    formState: { errors },
    register
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });
  const isEmailValid = watch('isEmailValid');
  const handleNextClick = async (data: FormValues) => {
    const id = parseSearch(window.location.search, 'id');
    const prescripteurResponse = await useCheckEmailPrescripteur(data.emailPrescripteur, id);
    if (prescripteurResponse.isValid) {
      data.numeroPrescripteur = prescripteurResponse.numeroPrescripteur;
      props.goNextStep(data);
    } else data.isEmailValid = false;
  };
  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <div>
        <p className="title">Bienvenue sur le formulaire de saisie de contact</p>
        <p className="subTitle"> Avant de commencer, merci de renseigner votre mail*</p>
        <p className="smallText">
          *Adresse mail utilisée pour le cabinet de courtage pour lequel vous saisissez le contact.
        </p>
      </div>
      <div className="email">
        <InputText label="Adresse email" {...register('emailPrescripteur')} errors={errors} />
        {!isEmailValid && (
          <small style={{ color: 'red' }}>
            Nous n’avons pas de prescripteur correspondant à l’adresse mail saisie. Veuillez vérifier votre saisie et
            recommencer ou contacter le cabinet <b>{props.nomCourtier}.</b>
          </small>
        )}
      </div>
      <div>
        <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
          Valider
        </FormNextButton>
      </div>
    </form>
  );
}
