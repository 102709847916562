import classNames from 'classnames';
import React, { FC } from 'react';
import Loader from 'react-loader-spinner';
import BlocConseilQuotes from '../../../components/BlocConseilQuotes/BlocConseilQuotes';
import { ErrorLabel } from '../../../components/Input/parts/ErrorLabel/ErrorLabel.component';
import { SerieSubmitStatus } from '../../../types';
import './ActionRow.scss';

interface ActionRowProps {
  proposedQuotes: any;
  numeroDevisChoisi?: string;
  setValue: any;
  readOnly?: boolean;
  preconisation: string;
  submitIntermediaryStepState: any;
  quoteMobileDisplay: number;
  withLoader: boolean;
  openDialog: () => void;
}

const ActionRow: FC<ActionRowProps> = ({
  proposedQuotes,
  numeroDevisChoisi,
  setValue,
  readOnly,
  preconisation,
  submitIntermediaryStepState,
  quoteMobileDisplay,
  withLoader,
  openDialog
}) => {
  const submittingStep = submitIntermediaryStepState?.status === SerieSubmitStatus.ONGOING;
  const submitInError = submitIntermediaryStepState?.status === SerieSubmitStatus.FAILED;
  const isFavoriteQuote = !!proposedQuotes.find((quote: any) => quote.favori === true);

  const onClick = (event: any, quote: any) => {
    if (numeroDevisChoisi !== quote.numeroInterne) {
      setValue('numeroDevisChoisi', quote.numeroInterne);
      setValue('assureurCode', quote.produit.assureur.code);
    } else {
      event.preventDefault();
      openDialog();
    }
  };

  return (
    <div className={'row-actions emptyTitle'}>
      {withLoader && submittingStep && (
        <div className="loader">
          <div>
            <Loader type={'TailSpin'} color={'#03606D'} />
            <b>
              Chargement en cours
              <br />
              Merci de patienter.
            </b>
          </div>
        </div>
      )}
      <div className={'container-actions content'}>
        {proposedQuotes.map((quote: any, index: number) => {
          const isChoosenQuote = numeroDevisChoisi === quote.numeroInterne;
          return (
            <div
              key={quote.numeroInterne}
              className={classNames(
                'buttons',
                'item',
                {
                  [`mobile-displayed__${index}`]: index === quoteMobileDisplay
                },
                {
                  [`item__${index < quoteMobileDisplay ? 'prec' : 'next'}`]: index !== quoteMobileDisplay
                },
                `_${index}`
              )}
            >
              <button
                onClick={e => onClick(e, quote)}
                className={classNames('button-choice', 'tertiary', { canceled: readOnly && !isChoosenQuote })}
                disabled={(readOnly && !isChoosenQuote) || submittingStep}
              >
                <span className="form-font-regular">
                  {readOnly ? (isChoosenQuote ? 'Modifier' : 'Non choisi') : 'Choisir'}
                </span>
                {submittingStep && <Loader type="Rings" color="white" height={25} width={25} />}
              </button>
              {quote.favori && preconisation && (
                <div className={'conseil__favori'}>
                  <BlocConseilQuotes conseil={preconisation} />
                </div>
              )}
            </div>
          );
        })}
        <br />
        {!submittingStep && submitInError && (
          <div className={'submit_error'}>
            <ErrorLabel>{submitIntermediaryStepState?.message}</ErrorLabel>
          </div>
        )}
        {!isFavoriteQuote && preconisation && (
          <BlocConseilQuotes className={classNames('conseil__general')} conseil={preconisation} />
        )}
      </div>
    </div>
  );
};

export default ActionRow;
