import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputRadioButtonInline from 'components/Input/InputRadioButton/InputRadioButtonInline.component';
import InputText from 'components/Input/InputText/InputText.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import { CIVILITES_OPTIONS } from 'consts/consts';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import React, { ReactElement, useEffect } from 'react';
import { FormProps } from 'types';
import { assignValuesFromSource } from 'utils';
import Yup from 'utils/Yup';
import FormConsent from '../../components/FormConsent/FormConsent.component';
import { fetchVilleOptions } from '../../fetches/options.fetch';
import { useFetchOptionsOnFieldChange } from '../../hooks/useFetchOptionsOnFieldChange.hook';
import type { FormValues as FormValuesConducteur } from './FormEmprunteurEmprunteursPart1.component';

type FormValues = {
  assure: {
    civilite: string | undefined;
    nom: string | undefined;
    prenom: string | undefined;
    contactMail: string | undefined;
    contactTelephone: string | undefined;
    contactCodePostal: string | undefined;
    ville: string | undefined;
    voie: string | undefined;
    complementAdresse: string | undefined;
  };
};

const defaultState: FormValues = {
  assure: {
    civilite: undefined,
    nom: undefined,
    prenom: undefined,
    contactMail: undefined,
    contactTelephone: undefined,
    contactCodePostal: '',
    ville: '',
    voie: undefined,
    complementAdresse: undefined
  }
};

export default function FormAutopersonne({
  goNextStep,
  initialFormValues,
  setLastFormState,
  previousFormValues,
  contenuConsentement,
  cocheConsentement,
  data,
  showCaptcha,
  setCaptchaToken
}: FormProps<FormValues>): ReactElement {
  const initialValues = assignValuesFromSource(defaultState, initialFormValues || {});

  const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
    assure: Yup.object({
      civilite: Yup.string().nullable().required(),
      nom: Yup.string().required(),
      prenom: Yup.string().required(),
      contactMail: Yup.string().required(),
      contactTelephone: Yup.string().required(),
      contactCodePostal: Yup.string().required(),
      ville: Yup.string().required(),
      voie: Yup.string().required(),
      complementAdresse: Yup.string(),

      ...(data.askForConsent
        ? {
            consentement: Yup.boolean().isTrue(
              'Vous devez obligatoirement cocher cette case pour valider votre demande.'
            )
          }
        : { consentement: Yup.boolean() })
    })
  }).defined();

  const { principal }: FormValuesConducteur<'principal'> = previousFormValues;
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    getValues,
    formState: { errors }
  } = useSerieForm<FormValues>(setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues || defaultState
  });

  useEffect(() => {
    reset({
      assure: {
        ...getValues().assure,
        civilite: principal?.personne.civilite,
        nom: principal?.personne.nom,
        prenom: principal?.personne.prenom
      }
    });
  }, []);

  const handleNextClick = (data: FormValues) => {
    goNextStep({
      assure: {
        civilite: data.assure.civilite,
        ville: data.assure.ville,
        nom: data.assure.nom,
        pays: 'FR:PAYS',
        prenom: data.assure.prenom,
        contactMail: data.assure.contactMail,
        contactTelephone: data.assure.contactTelephone,
        contactCodePostal: data.assure.contactCodePostal,
        voie: data.assure.voie,
        complementAdresse: data.assure.complementAdresse,
        naissanceDate: previousFormValues.principal.personne.naissanceDate,
        statutProfessionnel: previousFormValues.principal.personne.categorieProfessionnelle,
        fumeurRegulier: previousFormValues.principal.personne.fumeurRegulier
      },

      ...(previousFormValues.nombreEmprunteur === 'DEUX_EMPRUNTEURS'
        ? {
            conjoint: {
              civilite: previousFormValues.secondaire.personne.civilite,
              nom: previousFormValues.secondaire.personne.nom,
              prenom: previousFormValues.secondaire.personne.prenom,
              naissanceDate: previousFormValues.secondaire.personne.naissanceDate,
              statutProfessionnel: previousFormValues.secondaire.personne.categorieProfessionnelle,
              fumeurRegulier: previousFormValues.secondaire.personne.fumeurRegulier
            }
          }
        : {}),

      credits: previousFormValues.credits,

      emprunteurs: [
        {
          selection: true,
          rang: 'PRINCIPAL',
          porteChargesLourdes: previousFormValues.principal.emprunteur.porteChargesLourdes,
          travailEnHauteur: previousFormValues.principal.emprunteur.travailEnHauteur,
          deplacementsFrequents: previousFormValues.principal.emprunteur.deplacementsFrequents,
          options: [
            {
              quotiteDC: previousFormValues.principal.quotite,
              quotiteITT: previousFormValues.principal.quotite,
              quotiteIPT: previousFormValues.principal.quotite,
              quotiteIPP: previousFormValues.principal.quotite
            }
          ]
        },
        ...(previousFormValues.nombreEmprunteur === 'DEUX_EMPRUNTEURS'
          ? [
              {
                selection: true,
                rang: 'SECONDAIRE',
                porteChargesLourdes: previousFormValues.secondaire.emprunteur.porteChargesLourdes,
                travailEnHauteur: previousFormValues.secondaire.emprunteur.travailEnHauteur,
                deplacementsFrequents: previousFormValues.secondaire.emprunteur.deplacementsFrequents,
                roleFoyerCode: previousFormValues.secondaire.emprunteur.roleFoyerCode,
                options: [
                  {
                    quotiteDC: previousFormValues.secondaire.quotite,
                    quotiteITT: previousFormValues.secondaire.quotite,
                    quotiteIPT: previousFormValues.secondaire.quotite,
                    quotiteIPP: previousFormValues.secondaire.quotite
                  }
                ]
              }
            ]
          : [])
      ],

      typeEmpruntCode: previousFormValues.typeEmpruntCode,
      natureHabitationCode: previousFormValues.natureHabitationCode
    });
  };
  const villeOptions = useFetchOptionsOnFieldChange(watch, 'assure.contactCodePostal', fetchVilleOptions);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h1 className="title">
        Coordonnées du <span>souscripteur du contrat</span>
      </h1>

      <InputRadioButtonInline
        labelField="Civilité"
        {...register('assure.civilite')}
        options={CIVILITES_OPTIONS}
        column={2}
        errors={errors}
        disabled
      />

      <InputText label="Prénom" {...register('assure.prenom')} errors={errors} disabled />

      <InputText label="Nom" {...register('assure.nom')} errors={errors} disabled />

      <InputText label="Email" {...register('assure.contactMail')} errors={errors} />

      <InputText label="Téléphone" {...register('assure.contactTelephone')} errors={errors} />

      <InputText label="Adresse" {...register('assure.voie')} errors={errors} />

      <InputText label="Complément" {...register('assure.complementAdresse')} errors={errors} />

      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('assure.contactCodePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="assure.ville"
          control={control}
          errors={errors}
          options={villeOptions || []}
        />
      </div>

      {data?.askForConsent && (
        <FormConsent
          contenuConsentement={contenuConsentement}
          control={control}
          errors={errors}
          label={cocheConsentement}
          name="assure.consentement"
        />
      )}
      <FormNextButton showCaptcha={showCaptcha} setCaptchaToken={setCaptchaToken}>
        Envoyer
      </FormNextButton>
    </form>
  );
}
