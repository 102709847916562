import { yupResolver } from '@hookform/resolvers/yup';
import FormNextButton from 'components/FormNextButton/FormNextButton.component';
import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputText from 'components/Input/InputText/InputText.component';
import InputTextAutoComplete from 'components/Input/InputText/InputTextAutoComplete';
import {
  fetchFonctionsOptions,
  fetchPaysOptions,
  fetchStatutJuridiqueOptions,
  fetchVilleOptions
} from 'fetches/options.fetch';
import { useFetch } from 'hooks/useFetch.hook';
import { useFetchOptionsOnFieldChange } from 'hooks/useFetchOptionsOnFieldChange.hook';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import { merge } from 'lodash';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { AccumulateStateToSerie, FormProps } from 'types';
import { assignValuesFromSource, removeProperty } from 'utils';
import Yup from 'utils/Yup';
import { EntrepriseInputsWithFetch } from 'components/Input/EntrepriseInputWithFetch/EntrepriseInputsWithFetch';
import InputNumber from '../../components/Input/InputNumber/InputNumber.component';

type EntrepriseValues = {
  siret: string;
  raisonSociale: string;
  voie: string;
  complementAdresse: string;
  codePostal: string;
  ville: string;
  pays: string;
  nombreSalaries: number;
  statutJuridique: string;
};

interface FormValues {
  enCoursDeCreation: boolean;
  entreprise: EntrepriseValues | any; // TODO strongly type
  assure: {
    mandat: string;
  };
}

const entrepriseFields = {
  raisonSociale: Yup.string().required().max(500),
  voie: Yup.string().required().max(255),
  complementAdresse: Yup.string().max(255).notRequired(),
  codePostal: Yup.string().required(),
  ville: Yup.string().required().max(255),
  pays: Yup.string().required(),
  nombreSalaries: Yup.number().ignoreEmptyString().required().min(0),
  statutJuridique: Yup.string()
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  assure: Yup.object({
    mandat: Yup.string().required()
  }),

  enCoursDeCreation: Yup.boolean().required(),
  entreprise: Yup.object().when('enCoursDeCreation', {
    is: true,
    then: Yup.object({
      siret: Yup.string().length(0),
      ...entrepriseFields
    }).defined(),
    otherwise: Yup.object({
      siret: Yup.string().required().siret(),
      ...entrepriseFields
    }).defined()
  })
}).defined();

const defaultState: FormValues = {
  enCoursDeCreation: false,
  assure: {
    mandat: ''
  },
  entreprise: {
    siret: '',
    raisonSociale: '',
    voie: '',
    complementAdresse: '',
    codePostal: '',
    ville: '',
    pays: '',
    nombreSalaries: 0,
    statutJuridique: ''
  }
};

const AccumulateStateToSeries: AccumulateStateToSerie = (serieState, state) => {
  return merge(serieState, removeProperty<FormValues>('enCoursDeCreation', state));
};

function getInitialValues(initialFormValues?: { [key: string]: any }): Record<string, any> {
  return {
    ...assignValuesFromSource(defaultState, initialFormValues || {}, (item: any, key: string) => {
      if (key === 'creationDate') return new Date(item);
      return item;
    }),
    enCoursDeCreation: initialFormValues?.entreprise ? initialFormValues.entreprise.siret === null : false
  };
}

export default function FormDecouverteEntreprise(props: FormProps<FormValues>): ReactElement {
  // Must handle date parsing here for initial value , if not , InputDatePicker does not parse date at first render
  const initialValues = useMemo(() => getInitialValues(props.initialFormValues), []);

  const form = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    control,
    setValue
  } = form;
  const villeOptions = useFetchOptionsOnFieldChange(watch, 'entreprise.codePostal', fetchVilleOptions);
  const paysOptions = useFetch(fetchPaysOptions);

  const fonctionsOptions = useFetch(fetchFonctionsOptions);
  const statutJuridiquesOptions = useFetch(fetchStatutJuridiqueOptions);

  const handleNextClick = (data: FormValues) => {
    props.goNextStep(data, AccumulateStateToSeries);
  };

  const enCoursDeCreation = watch('enCoursDeCreation');
  useEffect(() => {
    if (enCoursDeCreation) {
      setValue('entreprise.siret', '');
      trigger('entreprise.siret');
    }
  }, [enCoursDeCreation]);

  return (
    <form onSubmit={handleSubmit(handleNextClick)}>
      <h2>Votre entreprise</h2>
      <InputCheckBoxBoolean
        control={control}
        label="Entreprise en cours de création"
        name="enCoursDeCreation"
        errors={errors}
      />
      <div className="layout-field-container">
        <EntrepriseInputsWithFetch form={form} />
      </div>
      <InputText label="Adresse du du siège social" {...register('entreprise.voie')} errors={errors} />
      <InputText
        label="Complément d'adresse (facultatif)"
        {...register('entreprise.complementAdresse')}
        errors={errors}
      />
      <div className="layout-field-container">
        <InputText
          className="layout-flex-basis-auto"
          label="Code postal"
          {...register('entreprise.codePostal')}
          errors={errors}
        />
        <InputTextAutoComplete
          label="Ville"
          name="entreprise.ville"
          control={control}
          errors={errors}
          options={villeOptions}
        />
      </div>
      <InputTextAutoComplete
        label={'Pays'}
        name={'entreprise.pays'}
        errors={errors}
        options={paysOptions ?? []}
        control={control}
      />
      <InputSelect
        label="Votre statut au sein de l'entreprise"
        name="assure.mandat"
        control={control}
        errors={errors}
        options={fonctionsOptions || []}
      />
      <InputSelect
        label="Statut juridique (facultatif)"
        name="entreprise.statutJuridique"
        control={control}
        errors={errors}
        options={statutJuridiquesOptions || []}
      />
      <InputNumber min={0} label="Nombre de salariés" {...register('entreprise.nombreSalaries')} errors={errors} />
      <FormNextButton setCaptchaToken={props.setCaptchaToken} showCaptcha={props.showCaptcha}>
        Suivant
      </FormNextButton>
    </form>
  );
}
