import classNames from 'classnames';
import React, { ReactElement } from 'react';
import Loader from 'react-loader-spinner';
import './FormGenericButton.scss';

interface FormGenericButtonProps {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  className?: string;
}

const FormGenericButton = ({
  children,
  onClick,
  loading = false,
  disabled = false,
  className
}: FormGenericButtonProps): ReactElement => {
  return (
    <div>
      <button
        onClick={onClick}
        type="button"
        disabled={loading || disabled}
        className={classNames(className, 'FormGenericButton')}
      >
        <div className="button-container">
          {loading && (
            <div className="spinner-container">
              <Loader type="Rings" color="white" height={25} width={25} />
            </div>
          )}
          <div className="button-content">{children}</div>
        </div>
      </button>
    </div>
  );
};

export default FormGenericButton;
