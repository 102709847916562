import React, { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Yup from 'utils/Yup';
import { FormProps } from 'types';
import { AutoSubmissionForm } from 'components/AutoSubmissionForm/AutoSubmissionForm.component';
import { useSerieForm } from 'hooks/useSerieForm.hook';
import InputRadioButtonIcon from '../../components/Input/InputRadioButtonIcon/InputRadioButtonIcon.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';

import { ReactComponent as SvgSeul } from 'assets/seul.svg';
import { ReactComponent as SvgCouple } from 'assets/couple.svg';
import { ReactComponent as SvgSeulEnfant } from 'assets/seul-enfants.svg';
import { ReactComponent as SvgCoupleEnfant } from 'assets/couple-enfants.svg';

type FormValues = {
  typeAssures: string;
};

const defaultState: FormValues = {
  typeAssures: ''
};

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  typeAssures: Yup.string().oneOf(['VOUS', 'COUPLE', 'VOUS_ENFANTS', 'COUPLE_ENFANTS']).required()
}).defined();

function getInitalValues(initSource: any) {
  if (!initSource) return undefined;
  if (!initSource.enfants || initSource.enfants.length === 0) {
    if (initSource.conjoint) return { ...defaultState, typeAssures: 'COUPLE' };
    else return { ...defaultState, typeAssures: 'VOUS' };
  } else {
    if (initSource.conjoint) return { ...defaultState, typeAssures: 'COUPLE_ENFANTS' };
    else return { ...defaultState, typeAssures: 'VOUS_ENFANTS' };
  }
}

export default function FormSanteParticulierChoixAssure(props: FormProps<FormValues>): ReactElement {
  const initialValues = getInitalValues(props.initialFormValues);

  const {
    watch,
    handleSubmit,
    register,
    formState: { isDirty, errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: (props.formValues as FormValues) || initialValues || defaultState
  });

  return (
    <AutoSubmissionForm<FormValues>
      watch={watch}
      isDirty={isDirty}
      onSubmit={handleSubmit(values => {
        props.goNextStep(values);
      })}
    >
      <h1>
        Votre <span>projet</span>
      </h1>

      <InputRadioButtonIcon
        label="Qui souhaitez-vous assurer ?"
        errors={errors}
        {...register('typeAssures')}
        options={[
          { label: 'Vous uniquement', value: 'VOUS', icon: <SvgSeul /> },
          { label: 'Votre couple', value: 'COUPLE', icon: <SvgCouple /> },
          { label: 'Vous + un ou plusieurs enfants', value: 'VOUS_ENFANTS', icon: <SvgSeulEnfant /> },
          {
            label: 'Votre couple + un ou plusieurs enfants',
            value: 'COUPLE_ENFANTS',
            icon: <SvgCoupleEnfant />
          }
        ]}
      />
      {(!!props.formValues || !!initialValues) && (
        <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken}>
          Suivant
        </FormNextButton>
      )}
    </AutoSubmissionForm>
  );
}
