import React from 'react';
import { useController, Control, FieldValues, UseControllerProps } from 'react-hook-form';
import classNames from 'classnames';
import { FieldError } from '../parts/FieldError/FieldError.component';
import './InputSlider.scss';
import { getIn } from 'utils';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

type iInputSlider<T extends FieldValues> = UseControllerProps<T> & {
  name: string;
  min?: number;
  max?: number;
  step?: number;
  label?: React.ReactNode;
  control: Control<T>;
  fullWidth?: boolean;
};

function InputSlider<T extends FieldValues>({
  name,
  min,
  max,
  step,
  label,
  control,
  fullWidth
}: iInputSlider<T>): JSX.Element {
  const {
    field: { onChange, value, ref },
    fieldState: { isDirty },
    formState: { errors, isSubmitted }
  } = useController({
    name,
    control
  });

  const error = getIn(name, errors);
  const errorMessage = (isDirty || isSubmitted) && error && error.message;

  return (
    <div className={classNames('InputSlider', { '-fullWidth': fullWidth })} ref={ref}>
      {label && <span className={classNames('InputSlider__label', { '-error': errorMessage })}>{label}</span>}
      <div className={classNames('InputSlider__fieldGroup', { '-error': errorMessage })} data-focus={focus}>
        <Slider min={min} max={max} step={step} value={value} onChange={onChange} />
        <span>{value || 0}%</span>
      </div>
      <FieldError>{errorMessage}</FieldError>
    </div>
  );
}

InputSlider.defaultProps = {
  label: undefined,
  min: undefined,
  max: undefined,
  step: undefined,
  required: undefined,
  maxDate: undefined,
  minDate: undefined,
  fullWidth: undefined
};

export default InputSlider;
